import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiTabsModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { GridModule } from '@lib-widgets/grid';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { HasRolesPipe } from '@lib-mortgage/features/authorization';
import { DossierFormComponent } from '../../../../widgets/dossier-form';
import { DossierInfoComponent } from '../../../../widgets/dossier-info';
import { AddDocumentsToDossierDialogModule } from './components/add-documents-to-dossier-dialog';
import { DossierComponent } from './dossier.component';

@NgModule({
  imports: [
    CommonModule,
    RequestWrapperModule,
    TuiLetModule,
    DossierFormComponent,
    DossierInfoComponent,
    ExecuteWithPipeModule,
    ButtonModule,
    TuiTabsModule,
    GridModule,
    AddDocumentsToDossierDialogModule,
    HasRolesPipe,
  ],
  declarations: [DossierComponent],
  exports: [DossierComponent],
})
export class DossierModule {}
