import groupBy from 'lodash-es/groupBy';
import isNil from 'lodash-es/isNil';
import sum from 'lodash-es/sum';
import { getStringEnumKeys, Nullable, omitNullable } from '@lib-utils';
import {
  CreditDossierDto,
  DocumentCompletenessCheckDto,
  DocumentDto,
  DocumentGroup,
  DocumentTypeVerificationDetails,
  ProductCode,
} from '@lib-archive/api';
import { DocumentGroupTypeMap, DocumentSubtitleMap } from '@lib-archive/api-middleware';
import {
  createDocumentCompletenessForm,
  createDocumentGroupCompletenessForm,
  DocumentsGroup,
  DocumentWithForm,
  sortBorrowers,
} from '@lib-archive/utils';

export function getDocumentErrorsCount(checkDetails: Nullable<DocumentTypeVerificationDetails>) {
  return checkDetails?.checks?.filter((v) => v.ok === false).length ?? 0;
}

export function getDocumentUnckeckedCount(checkDetails: Nullable<DocumentTypeVerificationDetails>) {
  return checkDetails?.checks?.filter((check) => isNil(check.ok)).length ?? 0;
}

export function getUncheckedCount(document: Nullable<DocumentDto>) {
  return (
    (document?.completenessCheck?.hasDigital ? getDocumentUnckeckedCount(document?.verificationSummary?.digital) : 0) +
    (document?.completenessCheck?.hasPaper ? getDocumentUnckeckedCount(document?.verificationSummary?.paper) : 0)
  );
}

export function getTotalDossierUncheckedCount(documents: DocumentDto[]) {
  return sum(documents.map(getUncheckedCount));
}

function getTotalDocumentErrorsCount(document: Nullable<DocumentDto>) {
  return (
    (document?.completenessCheck?.hasDigital ? getDocumentErrorsCount(document?.verificationSummary?.digital) : 0) +
    (document?.completenessCheck?.hasPaper ? getDocumentErrorsCount(document?.verificationSummary?.paper) : 0)
  );
}

export function getTotalDossierErrorsCount(documents: DocumentDto[]) {
  return sum(documents.map(getTotalDocumentErrorsCount));
}

function mapDocumentToCompletenessChecks(documents: DocumentWithForm[]): DocumentCompletenessCheckDto[] {
  return documents.map((value) => ({
    documentId: value.document.id,
    ...omitNullable(value.form.getRawValue()),
  }));
}

export function mapCompletenessChecks(groups: Nullable<DocumentsGroup[]>): DocumentCompletenessCheckDto[] {
  if (!groups) return [];
  return groups.reduce<DocumentCompletenessCheckDto[]>(
    (acc, group) => [...acc, ...mapDocumentToCompletenessChecks(group.data)],
    [],
  );
}

export const isAllGroupsCompleteness = (groups: Nullable<DocumentsGroup[]>) =>
  mapCompletenessChecks(groups).every(
    ({ hasDigital, hasPaper, hasDigitalSignature }) =>
      hasDigital && (isNil(hasPaper) || hasDigitalSignature || hasPaper),
  );

export function getDocumentsGroups(
  documents: DocumentDto[],
  dossier: CreditDossierDto,
  formEnabled: boolean,
): DocumentsGroup[] {
  // Группируем документы по виду или по id заемщика для соответствующего вида документов
  const defaultGroup = dossier.productCode === ProductCode.Auto ? DocumentGroup.AutoLoan : DocumentGroup.MortgageLoan;
  const documentGroups = groupBy(
    documents.map((doc) => ({
      document: doc,
      form: createDocumentCompletenessForm(doc.completenessCheck, doc.documentType, !formEnabled),
    })),
    (item) =>
      item.document.documentType?.group === DocumentGroup.Borrower
        ? // Документы заемщика без привязанного клиента попадают в группу по умолчанию
          item.document.client?.id ?? defaultGroup
        : item.document.documentType?.group,
  );

  const borrowerGroups = sortBorrowers(dossier.borrowers)
    .filter(({ id }) => id && !!documentGroups[id])
    .map((borrower) => ({
      subtitle: borrower.clientType && DocumentSubtitleMap[borrower.clientType].toLocaleUpperCase(),
      title: borrower.name,
      data: documentGroups[borrower.id!],
      form: createDocumentGroupCompletenessForm(documentGroups[borrower.id!], !formEnabled),
    }));

  const otherGroups = getStringEnumKeys<DocumentGroup>(DocumentGroup)
    .filter((group) => !!documentGroups[group])
    .map((group) => ({
      title: DocumentGroupTypeMap.get(group),
      data: documentGroups[group],
      form: createDocumentGroupCompletenessForm(documentGroups[group], !formEnabled),
    }));

  return [...borrowerGroups, ...otherGroups];
}
