/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DossierState } from './dossierState';
import { DateTime } from '@lib-utils';


export interface DossierStateHistoryDto { 
    id?: number;
    timestamp?: DateTime | null;
    status?: DossierState;
    userId?: number | null;
    userName?: string | null;
    actId?: number | null;
    boxId?: number | null;
    boxNumber?: string | null;
}
export namespace DossierStateHistoryDto {
}


