<ng-container *tuiLet="availableProducts$ | async as availableProducts">
  <fnip-toolbar
    [application]="'Архив: ' + (archiveProductService.activeProductName$ | async)"
    [logoutCallBack$]="logout$"
  >
    @if ((availableProducts?.length ?? 0) > 1) {
      <fnip-reactive-radio-block
        fieldId="activeProduct"
        [control]="activeProductControl"
        [radioWidth]="200"
        [options]="availableProducts"
        (valueChange)="archiveProductService.setActiveProduct($event); router.navigate([basePath])"
      />
    }
  </fnip-toolbar>

  <div class="d-flex gap-7" [style.min-height]="'calc(100vh - 100px)'">
    @if (availableProducts?.length) {
      <fnip-expandable-menu [items]="menuItems" />
    }

    <div class="w-100 d-flex flex-column">
      @if (availableProducts?.length) {
        <fnip-breadcrumb class="my-5" [hostUrl]="hostUrl" />
      }

      <router-outlet />
      <fnip-privacy-policy appName="Архив" class="mt-auto pt-6" [version]="archiveVersion" />
    </div>
  </div>
</ng-container>
