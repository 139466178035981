/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentGroup = 'Borrower' | 'MortgageLoan' | 'BankingProgram' | 'LoanSupport' | 'RiskDivision' | 'AutoLoan' | 'AutoDeal' | 'BorrowerQuestionnaire';

export const DocumentGroup = {
    Borrower: 'Borrower' as DocumentGroup,
    MortgageLoan: 'MortgageLoan' as DocumentGroup,
    BankingProgram: 'BankingProgram' as DocumentGroup,
    LoanSupport: 'LoanSupport' as DocumentGroup,
    RiskDivision: 'RiskDivision' as DocumentGroup,
    AutoLoan: 'AutoLoan' as DocumentGroup,
    AutoDeal: 'AutoDeal' as DocumentGroup,
    BorrowerQuestionnaire: 'BorrowerQuestionnaire' as DocumentGroup
};

