import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import ru from '@angular/common/locales/ru';
import { APP_INITIALIZER, inject, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TuiPreviewModule } from '@taiga-ui/addon-preview';
import { TuiAlertModule, TuiDialogModule, TuiRootModule } from '@taiga-ui/core';
import { TuiPdfViewerModule } from '@taiga-ui/kit';
import { ConfigService, InitConfigModule, TaigaUiProvidersModule } from '@lib-config';
import { COMMON_ERROR_MESSAGES, ErrorInterceptorModule, MortgageLikeErrorMapper } from '@lib-error-interceptor';
import { checkBrowserSupport } from '@lib-utils';
import { ApplicationRootComponent, ApplicationRootModule } from '@lib-widgets/application-root';
import { ErrorPageComponent, ErrorPageService, NoPageComponent } from '@lib-widgets/core';
import { FnipDialogModule } from '@lib-widgets/dialog';
import { SidebarModule } from '@lib-widgets/sidebar';
import { APIS as ArchiveApiServices, ArchiveConfiguration } from '@lib-archive/api';
import { ARCHIVE_ERROR_MESSAGES } from '@lib-archive/utils';
import { ElectronicAchiveApiService, MortgageConfiguration } from '@lib-mortgage/api';
import {
  authorizationGuardFn,
  AuthorizationInterceptorModule,
  AuthorizationModule,
  createBearerCredentials,
  getAuthorizationRoutes,
} from '@lib-mortgage/features/authorization';
import { WithoutEmptyFieldsHttpRequestInterceptorModule } from '@lib-mortgage/interceptors';
import { provideGridStateService } from '@lib-mortgage/widgets/grid';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { activeProductGuardFn } from './guards';
import { ArchiveProductService } from './services';
import { ActsComponent } from './views/acts';
import { BoxesComponent } from './views/boxes';
import { DossiersComponent } from './views/dossiers';
import { ReportsComponent } from './views/reports';

registerLocaleData(ru);

const MortgageApiServices = [ElectronicAchiveApiService];

const TUI_MODULES = [
  TuiRootModule,
  TuiDialogModule,
  TuiAlertModule,
  TuiPreviewModule, // Нет метода .forRoot(), необходим импорт модуля в руте. Следить за PreviewDialogService.open()
  TuiPdfViewerModule, // Нет метода .forRoot(), необходим импорт модуля в руте. Следить за TuiPdfViewerService.open()
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SidebarModule.forRoot(),
    TranslateModule.forRoot(), // Необходим для корректной работы `DefaultComponent`
    AuthorizationModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppComponent,
          title: 'Архив',
          canActivate: [authorizationGuardFn],
          children: [
            {
              path: '',
              redirectTo: 'dossiers',
              pathMatch: 'full',
            },
            {
              path: 'dossiers',
              canActivate: [activeProductGuardFn],
              component: DossiersComponent,
              loadChildren: () => import('./views/dossiers').then((m) => m.DossiersModule),
            },
            {
              path: 'acts',
              canActivate: [activeProductGuardFn],
              component: ActsComponent,
              loadChildren: () => import('./views/acts').then((m) => m.ActsModule),
            },
            {
              path: 'boxes',
              canActivate: [activeProductGuardFn],
              component: BoxesComponent,
              loadChildren: () => import('./views/boxes').then((m) => m.BoxesModule),
            },
            {
              path: 'reports',
              canActivate: [activeProductGuardFn],
              component: ReportsComponent,
              loadChildren: () => import('./views/reports').then((m) => m.ReportsModule),
            },
            {
              path: 'error',
              title: 'Ошибка',
              component: ErrorPageComponent,
            },
          ],
        },
        {
          path: 'auth',
          children: getAuthorizationRoutes(environment.frontVersion, 'Архив'),
        },
        {
          path: 'unsupported-browser',
          title: 'Ваш браузер не поддерживается',
          loadComponent: () => import('@lib-widgets/unsupported-browser').then((m) => m.UnsupportedBrowserComponent),
        },
        {
          path: '**',
          title: 'Страница не найдена',
          component: NoPageComponent,
        },
      ],
      { initialNavigation: 'enabledBlocking' },
    ),
    TUI_MODULES,
    TaigaUiProvidersModule,
    InitConfigModule.forRoot('Электронный архив', environment.frontVersion),
    FnipDialogModule,
    ApplicationRootModule,
    HttpClientModule,
    AuthorizationInterceptorModule,
    ErrorInterceptorModule.forRoot({
      messages: [COMMON_ERROR_MESSAGES, ARCHIVE_ERROR_MESSAGES],
      mappers: [MortgageLikeErrorMapper],
    }),
    WithoutEmptyFieldsHttpRequestInterceptorModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: checkBrowserSupport,
      deps: [Router],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'ru' },
    provideGridStateService(),
    ArchiveProductService,
    ErrorPageService,
    ...MortgageApiServices,
    {
      provide: MortgageConfiguration,
      useFactory: () =>
        new MortgageConfiguration({
          basePath: inject(ConfigService).apiUrl,
          credentials: createBearerCredentials(),
        }),
    },
    ...ArchiveApiServices,
    {
      provide: ArchiveConfiguration,
      useFactory: () =>
        new ArchiveConfiguration({
          basePath: inject(ConfigService).archiveApiUrl,
          credentials: createBearerCredentials(),
        }),
    },
  ],
  bootstrap: [ApplicationRootComponent],
})
export class AppModule {}
