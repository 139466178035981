<ng-container *ngIf="routerParams$ | async" />

<h2 class="my-4 font-weight-bold">Журнал отчётов</h2>
<div class="d-flex gap-8">
  <fnip-list-sidebar
    optionName="type"
    hasCalculatedTotal
    [initialOption]="filterFg.value.type"
    [optionMap]="ReportTypeMap"
  >
    <fnip-button appearance="primary" label="Сформировать отчёт" class="mb-5 w-100" btnRouterLink="new" />
  </fnip-list-sidebar>

  <div class="d-flex flex-column w-100 pr-10 gap-5">
    <fnip-filter [form]="filterFg" (valueChanges)="grid?.fetchData({ resetPage: true })">
      <fnip-reactive-input-number
        fnipFilterControl
        fieldId="id"
        placeholder="ID отчёта"
        [control]="filterFg.controls.id"
      />
      <fnip-reactive-input
        fnipFilterControl
        fieldId="initiatorUserName"
        placeholder="Инициатор"
        [control]="filterFg.controls.initiatorUserName"
      />
    </fnip-filter>
    <fnip-grid #grid [gridOptions]="gridOptions" [rowData]="getData$" />
  </div>
</div>
