/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductCode } from './productCode';
import { PersonDto } from './personDto';
import { BoxState } from './boxState';
import { IdNameDto } from './idNameDto';
import { DateTime } from '@lib-utils';
import { CreditDossierDto } from './creditDossierDto';


export interface BoxDto { 
    id?: number;
    state?: BoxState;
    stateSetting?: DateTime;
    allowedStates?: Array<BoxState> | null;
    creator?: PersonDto;
    created?: DateTime;
    dossiers?: Array<CreditDossierDto> | null;
    boxNumber?: string | null;
    osg?: IdNameDto;
    productCode?: ProductCode;
}
export namespace BoxDto {
}


