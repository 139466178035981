/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EaClient } from './eaClient';
import { UploadResultFile } from './uploadResultFile';
import { DocumentTypeDto } from './documentTypeDto';


export interface UploadResultDocument { 
    files?: Array<UploadResultFile> | null;
    documentType?: DocumentTypeDto;
    client?: EaClient;
}

