<fnip-labeled-content
  *ngIf="!isEdit; else editPersonTmp"
  [label]="label ?? (getClientType | executeWith: person?.clientType)"
  [content]="personNameTmp"
  [contentContext]="{ name: person?.name }"
  [inline]="true"
  [disableEllipsis]="true"
/>

<ng-template #personNameTmp let-name="name">
  <div class="d-flex align-items-center gap-2">
    <span class="font-weight-bold font-size-16">{{ name ?? '—' }}</span>
    <fnip-button *ngIf="canEdit" btnSize="s" icon="tuiIconEdit" [actionCallback$]="toggleEditMode" />
  </div>
</ng-template>

<ng-template #editPersonTmp>
  <div class="d-flex align-items-end gap-2">
    <ng-container *ngIf="options; else nameInputTmp">
      <fnip-reactive-select-term-new
        class="flex-1"
        fieldId="id"
        textFieldSize="s"
        labelSize="s"
        termEmptyMessage="Введите первые 3 буквы для поиска"
        [formControl]="form?.controls?.id!"
        [fnipOptionsFromTerm]="options"
        [fnipOptionsExact]="getInitialPersonOption | executeWith: form?.value"
        [minTermLength]="3"
        [label]="label ?? (getClientType | executeWith: person?.clientType)"
        (selectedOptionChange)="selectedPerson($event)"
      />
      <fnip-button btnSize="s" icon="tuiIconPlus" [actionCallback$]="addNewPerson$" />
    </ng-container>
    <fnip-button btnSize="s" icon="tuiIconSave" [actionCallback$]="save$" />
    <fnip-button btnSize="s" icon="tuiIconXCircle" [actionCallback$]="toggleEditMode" />
  </div>
</ng-template>

<ng-template #nameInputTmp>
  <fnip-reactive-input
    fieldId="name"
    textFieldSize="s"
    labelSize="s"
    class="flex-1"
    [label]="label ?? (getClientType | executeWith: person?.clientType)"
    [control]="form.controls.name"
  />
</ng-template>
