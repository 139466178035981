import { PersonDto } from '@lib-archive/api';
import { UserInfoDto } from '@lib-mortgage/api';

export function toPersonDto(user: UserInfoDto): PersonDto {
  return {
    id: user.id,
    name: user.fullName ?? `${user.lastName} ${user.middleName} ${user.firstName}`,
    email: user.email,
  };
}
