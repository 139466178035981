import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TuiLetModule } from '@taiga-ui/cdk';
import isBefore from 'date-fns/isBefore';
import isEmpty from 'lodash-es/isEmpty';
import { filter, map, Observable, tap } from 'rxjs';
import {
  beToFeOptions,
  createOptionsForLocalizedEnum,
  ExecuteWithPipeModule,
  FormGroupOf,
  NotificationService,
  Nullable,
  omitNullable,
  OptionsConfig,
  SelectOption,
  toDateOnlyFormat,
} from '@lib-utils';
import { ButtonModule, DividerModule, LabeledContentComponent } from '@lib-widgets/core';
import { DialogService, injectDialogService } from '@lib-widgets/dialog';
import {
  ReactiveCheckboxModule,
  ReactiveFileInputModule,
  ReactiveInputDateModule,
  ReactiveInputModule,
  ReactiveInputNumberModule,
  ReactiveInputPhoneModule,
  ReactiveSelectModule,
  ReactiveTextAreaModule,
} from '@lib-widgets/reactive-fields';
import {
  ClientType,
  CreditDossierManualDto,
  DirectoryArchiveApiService,
  DossierRole,
  DossierState,
  EmployeeType,
  IdNameDto,
  LoanAgreementState,
  PersonDto,
  ProductCode,
  RegistrationType,
} from '@lib-archive/api';
import {
  AUTO_ROLES,
  ClientTypeMap,
  CreditPurposeMap,
  DossierRoleMap,
  DossierStateTypeMap,
  FeDossierCreateDto,
  FeInsuranceProductType,
  InsuranceProductTypeMap,
  LoanAgreementStateTypeMap,
  NonStandardTypeMap,
  PaymentTypeMap,
  ProofOfIncomeTypeMap,
  RegistrationTypeMap,
} from '@lib-archive/api-middleware';
import {
  createDossierParticipantForm,
  createDossierPersonForm,
  getUserOptions$,
  toInsuranceInfo,
  toPersonDto,
} from '@lib-archive/utils';
import { CreateUserModalComponent } from '@lib-archive/widgets/create-user-modal';
import { ElectronicAchiveApiService } from '@lib-mortgage/api';

@Component({
  selector: 'fnip-dossier-form',
  templateUrl: './dossier-form.component.html',
  styleUrls: ['./dossier-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    DividerModule,
    LabeledContentComponent,
    ReactiveInputModule,
    ReactiveSelectModule,
    ButtonModule,
    ReactiveFileInputModule,
    ExecuteWithPipeModule,
    ReactiveCheckboxModule,
    ReactiveInputDateModule,
    ReactiveInputNumberModule,
    ReactiveInputPhoneModule,
    ReactiveTextAreaModule,
    TuiLetModule,
  ],
  providers: [DialogService],
  standalone: true,
})
export class DossierFormComponent {
  @Input({ required: true }) form!: FormGroupOf<FeDossierCreateDto>;
  @Input({ required: true }) saveCallback$!: (value: CreditDossierManualDto) => Observable<unknown>;
  @Input({ required: true }) creditPrograms$?: Observable<Nullable<IdNameDto[]>>;
  @Input() state?: Nullable<DossierState>;

  private directoryService = inject(DirectoryArchiveApiService);
  private electronicArchiveService = inject(ElectronicAchiveApiService);
  private notificationService = inject(NotificationService);
  private dialogService = injectDialogService();

  protected readonly ProductCode = ProductCode;

  private clientTypeOptions = createOptionsForLocalizedEnum(ClientTypeMap);
  private registrationTypeOptions = createOptionsForLocalizedEnum(RegistrationTypeMap);
  private participantRoleOptions = createOptionsForLocalizedEnum(DossierRoleMap);

  incomeVerificationMethodTypes = createOptionsForLocalizedEnum(ProofOfIncomeTypeMap);

  initialPaymentMethodTypes = createOptionsForLocalizedEnum(PaymentTypeMap);

  getDepartmentInitialOptions = (value: Nullable<string>) => (value ? [{ label: value, value }] : []);

  //TODO: https://tracker.yandex.ru/RARCHIVE-29 - Добавление параметра `productCode`
  departmentOptions$ = (term: string) =>
    this.directoryService
      .apiDirectoryDepartmentGet({
        pageNumber: 1,
        perPage: 10,
        departmentPartName: term,
      })
      .pipe(map(({ data }) => beToFeOptions(data?.data ?? [], { valuePath: 'name' })));

  insuranceProductTypes = [
    ...createOptionsForLocalizedEnum(InsuranceProductTypeMap),
    { label: 'Не используется', value: FeInsuranceProductType.NotUsed },
  ];

  loanAgreementStateOptions = createOptionsForLocalizedEnum(LoanAgreementStateTypeMap).filter(
    ({ value }) => ![LoanAgreementState.Operational, LoanAgreementState.IncludedInTheAct].includes(value),
  );

  purposeOptions = createOptionsForLocalizedEnum(CreditPurposeMap);

  nonStandardTypeOptions = createOptionsForLocalizedEnum(NonStandardTypeMap);

  staticParticipantOptions: Partial<Record<DossierRole, Nullable<SelectOption<PersonDto>[]>>> = {};

  readonly userConfig: OptionsConfig<PersonDto> = {
    labelCallback: (user) => `${user.name} (${user.email})`,
  };

  readonly issuedLoanConfig: OptionsConfig<PersonDto> = { labelPath: 'name' };

  getInitialPersonOption = (
    person: Nullable<FormGroupOf<PersonDto>['value']>,
    config: OptionsConfig<PersonDto>,
  ): SelectOption<PersonDto>[] => beToFeOptions(person?.id ? [omitNullable(person)] : [], config);

  getClientTypeOptions = (productCode: Nullable<ProductCode>, borrowerSelected: boolean) =>
    this.clientTypeOptions
      .filter((option) => productCode !== ProductCode.Auto || option.value === ClientType.Borrower)
      .map((option) => ({
        ...option,
        disabled: borrowerSelected && option.value === ClientType.Borrower,
      }));

  getParticipantRoleOptions = (productCode: Nullable<ProductCode>) =>
    this.participantRoleOptions.filter(
      ({ value }) =>
        value !== DossierRole.AcceptedForSupport && (productCode === ProductCode.Auto || !AUTO_ROLES.includes(value)),
    );

  getRegistrationTypeOptions = (productCode: Nullable<ProductCode>) =>
    this.registrationTypeOptions.filter(
      (option) => productCode !== ProductCode.Auto || option.value === RegistrationType.Kd,
    );

  getUserOptions = (staticOptions: typeof this.staticParticipantOptions, role: Nullable<DossierRole>) =>
    (role && staticOptions[role]) ?? this.userOptions$;

  creditProgramOptions$ = (programs: Nullable<IdNameDto[]>) => beToFeOptions(programs ?? []);
  userOptions$ = getUserOptions$(this.userConfig, this.electronicArchiveService);

  //TODO: https://tracker.yandex.ru/RARCHIVE-29 - Добавление параметра `productCode`
  issuedLoanOptions$ = (name: string) =>
    this.electronicArchiveService
      .apiElectronicAchiveSignatoriesGet(1, 10, { filters: { FullName: name } })
      .pipe(map(({ data }) => beToFeOptions(data?.data?.map(toPersonDto), this.issuedLoanConfig)));

  getDossierState = (state: DossierState) => state && DossierStateTypeMap.get(state);

  isBorrowerSelected = (borrowers: Nullable<FormArray<FormGroupOf<PersonDto>>['value']>) =>
    !!borrowers?.some((person) => person.clientType === ClientType.Borrower);

  addBorrower = (productCode: Nullable<ProductCode>) => () => {
    this.form.controls.borrowers?.push(
      createDossierPersonForm({
        clientType: productCode === ProductCode.Auto ? ClientType.Borrower : undefined,
      }),
    );
  };

  canAddBorrower = (productCode: Nullable<ProductCode>, borrowersCount: Nullable<number>) =>
    productCode === ProductCode.Mortgage || (borrowersCount ?? 0) < 1;

  canRemoveBorrower = (clientType: Nullable<ClientType>, state: Nullable<DossierState>) =>
    clientType !== ClientType.Borrower || !state;

  removeBorrower = (i: number) => () => {
    this.form.controls.borrowers?.removeAt(i);
  };

  addParticipant = () => {
    this.form.controls?.participants?.push(createDossierParticipantForm());
  };

  removeParticipant = (i: number) => () => {
    this.form.controls?.participants?.removeAt(i);
  };

  canAddUser = (role: Nullable<DossierRole>) => role === DossierRole.DealMaker;

  addUser$ = (role: Nullable<DossierRole>, personForm: Nullable<FormGroupOf<PersonDto>>) => () =>
    this.dialogService
      .open<Nullable<PersonDto>, PersonDto>(CreateUserModalComponent, {
        contextData: {
          employeeType: EmployeeType.User,
        },
        hostOptions: {
          isDismissible: false,
        },
      })
      .pipe(
        filter(Boolean),
        tap((person) => {
          personForm?.reset(person);
          if (role) this.staticParticipantOptions[role] = beToFeOptions([person], this.userConfig);
        }),
      );

  clearStaticOptions(role: Nullable<DossierRole>) {
    if (!role) return;
    this.staticParticipantOptions = {
      ...this.staticParticipantOptions,
      [role]: null,
    };
  }

  onChangeNonStandard(value: boolean) {
    if (value) return;
    this.form?.controls.nonStandardType?.reset();
    this.form?.controls.nonStandardComment?.reset();
  }

  save$ = () => {
    const { borrowers, participants, issueLoanDate, plannedEndDate } = this.form.value;
    if (!borrowers?.some((client) => client.clientType === ClientType.Borrower)) {
      this.notificationService.showWarning('Не указан заемщик');
      return null;
    }
    if (!participants?.some(({ role }) => role === DossierRole.ApplicationCreator)) {
      this.notificationService.showWarning('Не указан сотрудник, создавший заявку');
      return null;
    }
    if (!participants?.some(({ role }) => role === DossierRole.DealMaker)) {
      this.notificationService.showWarning('Не указан сотрудник, проведший сделку');
      return null;
    }

    if (!issueLoanDate || !plannedEndDate || !isBefore(issueLoanDate, plannedEndDate)) {
      this.notificationService.showWarning('Плановая дата окончания должна быть позже, чем Дата выдачи');
      return null;
    }

    const insuranceInfo = toInsuranceInfo(this.form.value.insuranceInfo);
    if (this.form.value.productCode === ProductCode.Mortgage && isEmpty(insuranceInfo)) {
      this.notificationService.showWarning('Должен присутствовать хотя бы один тип страхования');
      return null;
    }

    return this.saveCallback$(
      omitNullable({
        ...this.form.value,
        insuranceInfo: toInsuranceInfo(this.form.value.insuranceInfo),
        issuedLoanId: this.form.value.issuedLoan?.id,
        issueLoanDate: toDateOnlyFormat(this.form.value.issueLoanDate),
        plannedEndDate: toDateOnlyFormat(this.form.value.plannedEndDate),
      }),
    );
  };
}
