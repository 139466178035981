import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import isNil from 'lodash-es/isNil';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { tap } from 'rxjs/operators';
import {
  ExecuteWithPipeModule,
  FileInfo,
  FileInfoScan,
  FileInfoScanTypes,
  NotificationService,
  Nullable,
} from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { injectDialogContext } from '@lib-widgets/dialog';
import { getPdfSrc } from '@lib-widgets/document-preview';
import { ACCEPT_FILE_TYPES, ReactiveFileInputModule, ReactiveInputSliderModule } from '@lib-widgets/reactive-fields';
import { DocumentArchiveApiService } from '@lib-archive/api';

export interface ChangeDocumentPageModalContext {
  fileInfo: Nullable<FileInfoScan<number>>;
  pagesCount: number;
  documentId: Nullable<number>;
}

@Component({
  selector: 'fnip-change-document-page-modal',
  templateUrl: './change-document-page-modal.component.html',
  styleUrls: ['./change-document-page-modal.component.scss'],
  imports: [
    ButtonModule,
    ExecuteWithPipeModule,
    NgIf,
    PdfViewerModule,
    ReactiveFileInputModule,
    ReactiveInputSliderModule,
  ],
  standalone: true,
})
export class ChangeDocumentPageModalComponent {
  protected readonly FileInfoScanTypes = FileInfoScanTypes;
  protected readonly context = injectDialogContext<Nullable<number>, ChangeDocumentPageModalContext>();

  private readonly documentArchiveApiService = inject(DocumentArchiveApiService);
  private readonly notificationService = inject(NotificationService);
  private readonly sanitizer = inject(DomSanitizer);

  readonly form = new FormGroup({
    page: new FormControl<Nullable<number>>(1),
    file: new FormControl<Nullable<FileInfo>>(null, Validators.required),
  });

  getPdfSrc = getPdfSrc;

  getAcceptTypes = (type: Nullable<FileInfoScanTypes>) =>
    type === FileInfoScanTypes.Pdf ? [ACCEPT_FILE_TYPES.PDF_TYPE] : [ACCEPT_FILE_TYPES.IMAGE_ALL_TYPES];

  getTifSrc = (urls: Nullable<string[]>, page: Nullable<number>) =>
    this.sanitizer.bypassSecurityTrustResourceUrl(urls?.[(page ?? 1) - 1] ?? '');

  save = () => {
    if (isNil(this.context.data?.documentId) || isNil(this.context.data?.fileInfo?.fileId)) return null;
    return this.documentArchiveApiService
      .apiDocumentDocumentIdFileFileIdSheetSheetNumberPost({
        documentId: this.context.data?.documentId!,
        fileId: this.context.data?.fileInfo?.fileId!,
        sheetNumber: this.form.value.page!,
        file: this.form.value.file?.file,
      })
      .pipe(
        tap(this.notificationService.onError('Ошибка при замене страницы документа')),
        tap(({ data }) => this.context.ref?.close(data)),
      );
  };

  cancel = () => this.context.ref?.close();
}
