import { NgIf } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiDataListModule, TuiHostedDropdownModule } from '@taiga-ui/core';
import { TUI_ARROW } from '@taiga-ui/kit';
import { filter, tap } from 'rxjs';
import { FormGroupOf, Nullable } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { DialogService, injectDialogService } from '@lib-widgets/dialog';
import { ReactiveInputDateModule, ReactiveInputModule } from '@lib-widgets/reactive-fields';
import { FeDossierFilterDto } from '@lib-archive/api-middleware';
import { ArchiveListFilterDialogComponent, ArchiveListFilterDialogModule } from '../archive-list-filter-dialog';

@Component({
  selector: 'fnip-archive-list-filter',
  templateUrl: './archive-list-filter.component.html',
  styleUrls: ['./archive-list-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    ReactiveInputModule,
    ReactiveInputDateModule,
    TuiHostedDropdownModule,
    TuiDataListModule,
    ButtonModule,
    ArchiveListFilterDialogModule,
  ],
  providers: [DialogService],
})
export class ArchiveListFilterComponent {
  readonly arrow = TUI_ARROW;

  @Input() filterForm: Nullable<FormGroupOf<FeDossierFilterDto>>;

  @Input({ transform: booleanAttribute }) hasFiltersPageLink = false;

  showClearButton = false;

  readonly dialogService = injectDialogService();

  clearFilters = () => {
    this.filterForm?.reset();
    this.showClearButton = false;
  };

  openDialog$ = () =>
    this.dialogService
      .open<FeDossierFilterDto>(ArchiveListFilterDialogComponent, {
        hostOptions: {
          label: 'Фильтры',
        },
        contextData: this.filterForm?.value,
      })
      .pipe(
        filter(Boolean),
        tap((value) => {
          this.filterForm?.patchValue(value ?? {});
          this.showClearButton = true;
        }),
      );
}
