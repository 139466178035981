import { Nullable } from '@lib-utils';
import { CreditDossierManualDto } from '@lib-archive/api';
import { FeInsuranceProductType } from './insurance-product-type';

export interface FeDossierCreateDto
  extends Omit<CreditDossierManualDto, 'issueLoanDate' | 'plannedEndDate' | 'insuranceInfo'> {
  insuranceInfo?: Nullable<Record<string, FeInsuranceProductType>>;
  issueLoanDate?: Date;
  plannedEndDate?: Date;
}
