import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule, DividerModule, LabeledContentComponent } from '@lib-widgets/core';
import { GridModule } from '@lib-widgets/grid';
import { ReactiveInputModule, ReactiveTextAreaModule } from '@lib-widgets/reactive-fields';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { HasRolesPipe } from '@lib-mortgage/features/authorization';
import { AddRemoveDossierModalModule } from '../../../../widgets/add-remove-dossier-modal';
import { BoxComponent } from './box.component';
import { BoxFormDialogComponent } from './components/box-form-dialog';

@NgModule({
  imports: [
    CommonModule,
    AddRemoveDossierModalModule,
    BoxFormDialogComponent,
    LabeledContentComponent,
    ReactiveInputModule,
    ReactiveTextAreaModule,
    RequestWrapperModule,
    DividerModule,
    ButtonModule,
    GridModule,
    ExecuteWithPipeModule,
    HasRolesPipe,
  ],
  declarations: [BoxComponent],
  exports: [BoxComponent],
})
export class BoxModule {}
