import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiPaginationModule, TuiTabsModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { GridModule } from '@lib-widgets/grid';
import { ReactiveInputModule } from '@lib-widgets/reactive-fields';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { ArchiveListFilterComponent } from '../archive-list-filter';
import { ScanQrCodeComponent } from '../scan-qr-code';
import { AddRemoveDossierModalComponent } from './add-remove-dossier-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    ExecuteWithPipeModule,
    GridModule,
    ReactiveInputModule,
    RequestWrapperModule,
    TuiPaginationModule,
    TuiTabsModule,
    ArchiveListFilterComponent,
    ScanQrCodeComponent,
  ],
  declarations: [AddRemoveDossierModalComponent],
})
export class AddRemoveDossierModalModule {}
