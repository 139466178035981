/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductCode } from './productCode';


export interface StateModel { 
    product?: ProductCode;
    code?: string | null;
    name?: string | null;
    count?: number;
    order?: number | null;
}
export namespace StateModel {
}


