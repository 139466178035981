import { ACCEPT_FILE_TYPES } from '@lib-widgets/reactive-fields';

export const DOSSIER_FILE_TYPES = [
  ACCEPT_FILE_TYPES.IMAGE_ALL_TYPES,
  ACCEPT_FILE_TYPES.PDF_TYPE,
  ACCEPT_FILE_TYPES.SIGNATURE_TYPE,
  ACCEPT_FILE_TYPES.MSWORD_TYPES,
  ACCEPT_FILE_TYPES.MSWORD_TEMPLATE_TYPES,
  ACCEPT_FILE_TYPES.MSWORD_WITH_MACROS_TYPE,
  ACCEPT_FILE_TYPES.EXCEL_TYPES,
  ACCEPT_FILE_TYPES.EXCEL_TEMPLATE_TYPES,
  ACCEPT_FILE_TYPES.EXCEL_WITH_MACROS_TYPES,
  ACCEPT_FILE_TYPES.CSV_TYPE,
  ACCEPT_FILE_TYPES.OPEN_DOCUMENT_TYPES,
  ACCEPT_FILE_TYPES.RICH_TEXT_TYPE,
  ACCEPT_FILE_TYPES.ARCHIVE_TYPES,
];
