import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import isNil from 'lodash-es/isNil';
import { filter, map, Observable, switchMap, tap } from 'rxjs';
import { getRouteParams$, NotificationService, Nullable, saveFile, toNullableNumber } from '@lib-utils';
import { DialogService, injectDialogService } from '@lib-widgets/dialog';
import { RequestWrapperComponent } from '@lib-widgets/request-wrapper';
import {
  ActArchiveApiService,
  ActDto,
  ActState,
  ActType,
  DossierArchiveApiService,
  ProductCode,
} from '@lib-archive/api';
import { ActStateMap } from '@lib-archive/api-middleware';
import {
  creditDossierWithSelect,
  CreditDossierWithSelect,
  GET_DOSSIER_WITH_SELECT_GRID_OPTIONS,
  getBasePath,
  hasAutoSupportRoleCb,
  hasCuratorRoleCb,
  hasMlcRoleCb,
  hasMortgageSupportRoleCb,
  UNDER_REVIEW_GRID_OPTIONS,
} from '@lib-archive/utils';
import { ArchiveProductService } from '../../../../services';
import {
  AddRemoveDossierModalComponent,
  AddRemoveDossierModalContext,
  GetDossiersConfig,
} from '../../../../widgets/add-remove-dossier-modal';
import { DirectActActionLabelMap, ReverseActActionLabelMap } from './act.maps';

@Component({
  selector: 'fnip-act',
  templateUrl: './act.component.html',
  styleUrls: ['./act.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class ActComponent {
  @ViewChild(RequestWrapperComponent) actWrapper?: RequestWrapperComponent;

  private readonly notificationService = inject(NotificationService);
  private readonly actArchiveApiService = inject(ActArchiveApiService);
  private readonly dossierArchiveApiService = inject(DossierArchiveApiService);
  protected readonly archiveProduct$ = inject(ArchiveProductService).activeProduct$;

  basePath = getBasePath();

  gridOptions = GET_DOSSIER_WITH_SELECT_GRID_OPTIONS({
    basePath: this.basePath,
  });

  hasMlcRole = hasMlcRoleCb();

  hasCuratorRole = hasCuratorRoleCb();

  hasAutoSupportRole = hasAutoSupportRoleCb();

  hasMortgageSupportRole = hasMortgageSupportRoleCb();

  underReviewGridOptions = { ...UNDER_REVIEW_GRID_OPTIONS(this.basePath), context: this };

  act: Nullable<ActDto>;

  dossiers: CreditDossierWithSelect[] = [];

  isActionDisabled = false;

  request$ = getRouteParams$<{ id: string }>().pipe(
    map(({ id }) => toNullableNumber(id)),
    filter((id): id is number => !isNil(id)),
    switchMap((actId) => this.actArchiveApiService.apiActActIdGet({ actId })),
    map(({ data }) => data),
    tap((act) => this.setData(act)),
  );

  /**
   * Действия (Direct):
   * Filling (отправка)     - ЦИК, Куратор авто, Админ, Мультироль
   * Transferred (прием)    - Сопровождение Ипотека, Сопровождение Авто, Мультироль
   * UnderReview (проверка) - Сопровождение Ипотека, Сопровождение Авто, Мультироль
   * @link https://tracker.yandex.ru/FNMRG-2357
   */
  ActionVisibleDirectMap = new Map<ActState, (activeProduct: Nullable<ProductCode>) => boolean>([
    [
      ActState.Filling,
      (activeProduct: Nullable<ProductCode>) =>
        (activeProduct === ProductCode.Mortgage && this.hasMlcRole()) ||
        (activeProduct === ProductCode.Auto && this.hasCuratorRole()),
    ],
    [
      ActState.Transferred,
      (activeProduct: Nullable<ProductCode>) =>
        (activeProduct === ProductCode.Mortgage && this.hasMortgageSupportRole()) ||
        (activeProduct === ProductCode.Auto && this.hasAutoSupportRole()),
    ],
    [
      ActState.UnderReview,
      (activeProduct: Nullable<ProductCode>) =>
        (activeProduct === ProductCode.Mortgage && this.hasMortgageSupportRole()) ||
        (activeProduct === ProductCode.Auto && this.hasAutoSupportRole()),
    ],
  ]);

  /**
   * Действия (Reverse):
   * Filling (отправка)     - Сопровождение Авто, Админ, Мультироль
   * Transferred (прием)    - Куратор авто, Админ, Мультироль
   * UnderReview (проверка) - Куратор авто, Админ, Мультироль
   * @link https://tracker.yandex.ru/FNMRG-2357
   */
  ActionVisibleReverseMap = new Map<ActState, (activeProduct: Nullable<ProductCode>) => boolean>([
    [
      ActState.Filling,
      (activeProduct: Nullable<ProductCode>) => activeProduct === ProductCode.Auto && this.hasAutoSupportRole(),
    ],
    [
      ActState.Transferred,
      (activeProduct: Nullable<ProductCode>) => activeProduct === ProductCode.Auto && this.hasCuratorRole(),
    ],
    [
      ActState.UnderReview,
      (activeProduct: Nullable<ProductCode>) => activeProduct === ProductCode.Auto && this.hasCuratorRole(),
    ],
  ]);

  readonly dialogService = injectDialogService();

  setTransferred$ = () =>
    this.actArchiveApiService.apiActActIdStateTransferredPost({ actId: this.act?.id ?? 0 }).pipe(
      tap(this.notificationService.onSuccess('Акт передан')),
      tap(() => this.actWrapper?.reload()),
    );

  setUnderReview$ = () =>
    this.actArchiveApiService.apiActActIdStateUnderReviewPost({ actId: this.act?.id ?? 0 }).pipe(
      tap(this.notificationService.onSuccess('Акт на проверке')),
      tap(() => this.actWrapper?.reload()),
    );

  setAccepted$ = () =>
    this.actArchiveApiService
      .apiActActIdStateAcceptedPost({
        actId: this.act?.id ?? 0,
        dossierAcceptSupportDto:
          this.dossiers?.map((d) => ({ dossierId: d.dossier.id, acceptSupport: d.selected.value })) ?? [],
      })
      .pipe(
        tap((isSuccess) => this.notificationService.showSuccess(`Акт ${isSuccess ? 'принят' : 'обновлен'}`)),
        tap(() => this.actWrapper?.reload()),
      );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  ActionMap = new Map<ActState, () => Observable<unknown>>([
    [ActState.Filling, this.setTransferred$],
    [ActState.Transferred, this.setUnderReview$],
    [ActState.UnderReview, this.setAccepted$],
  ]);

  isFillingActionDisabled = () => !this.dossiers.length;

  isUnderReviewActionDisabled = () => this.dossiers.map((d) => d.selected.value).some(isNil);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  ActionDisabledMap = new Map([
    [ActState.Filling, this.isFillingActionDisabled],
    [ActState.UnderReview, this.isUnderReviewActionDisabled],
  ]);

  getAction = (state: Nullable<ActState>) => state && this.ActionMap.get(state);

  getActionLabel = (state: Nullable<ActState>, actType: Nullable<ActType>) => {
    if (!state) return '';
    if (actType === ActType.Direct) return DirectActActionLabelMap.get(state);
    return ReverseActActionLabelMap.get(state);
  };

  isUnderReview = (type: Nullable<ActState>) => ActState.UnderReview === type;

  isFilling = (type: Nullable<ActState>) => ActState.Filling === type;

  getActState = (type: Nullable<ActState>) => type && ActStateMap.get(type);

  isActionVisible = (state: Nullable<ActState>, actType: Nullable<ActType>, activeProduct: Nullable<ProductCode>) => {
    const map = actType === ActType.Direct ? this.ActionVisibleDirectMap : this.ActionVisibleReverseMap;
    return state && (map.get(state)?.(activeProduct) ?? false);
  };

  /**
   * Наполнение (Добавить / Изъять)
   * @link https://tracker.yandex.ru/FNMRG-2357
   */
  isFillEditActionsVisible = (activeProduct: Nullable<ProductCode>, actType: Nullable<ActType>) => {
    // ЦИК, Куратор Авто, Администратор или мультироль
    if (actType === ActType.Direct)
      return this.hasMlcRole() || (activeProduct === ProductCode.Auto && this.hasCuratorRole());
    // Сопровождение Авто, Администратор или мультироль
    if (actType === ActType.Reverse) return activeProduct === ProductCode.Auto && this.hasAutoSupportRole();
    return false;
  };

  setIsActionDisabled = () => {
    this.isActionDisabled = (this.ActionDisabledMap.get(this.act?.state ?? ActState.Filling) ?? (() => false))();
  };

  setData(act: Nullable<ActDto>) {
    this.act = act;
    this.dossiers = this.act?.dossiers?.map((d) => creditDossierWithSelect(d, d.acceptToReview)) ?? [];
    this.setIsActionDisabled();
  }

  getActInventory = () =>
    this.actArchiveApiService.apiActActIdInventoryGet({ actId: this.act?.id ?? 0 }, 'response').pipe(tap(saveFile));

  openRemoveDialog = () =>
    this.dialogService
      .open<Nullable<boolean>, AddRemoveDossierModalContext>(AddRemoveDossierModalComponent, {
        hostOptions: {
          size: 'auto',
        },
        contextData: {
          dossiers: this.act?.dossiers,
          removeDossiersRq$: (ids: Nullable<number>[]) => {
            if (!ids.length) return null;
            return this.dossierArchiveApiService.apiDossierDossierIdsActPost({ dossierIds: ids.join(',') });
          },
        },
      })
      .pipe(tap((result) => result && this.actWrapper?.reload()));

  openAddDialog = () =>
    this.dialogService
      .open<Nullable<boolean>, AddRemoveDossierModalContext>(AddRemoveDossierModalComponent, {
        hostOptions: {
          size: 'auto',
        },
        contextData: {
          getDossiersRq$: (config: GetDossiersConfig) =>
            this.actArchiveApiService.apiActActIdDossiersAvailablePost({
              actId: this.act?.id!,
              pageNumber: config.page,
              perPage: config.perPage,
              text: config.filterText!,
            }),
          scanDossierRq$: (qrCode: string) =>
            this.actArchiveApiService
              .apiActActIdDossierByQrPost({ actId: this.act?.id!, qrCode })
              .pipe(map((res) => res.data)),
          addDossierRq$: (dossierId: Nullable<number>) => {
            if (!dossierId || !this.act?.id) return null;
            return this.dossierArchiveApiService.apiDossierDossierIdActActIdPost({ dossierId, actId: this.act.id });
          },
        },
      })
      .pipe(tap((result) => result && this.actWrapper?.reload()));
}
