/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InsuranceProductType } from './insuranceProductType';
import { EaFileInfo } from './eaFileInfo';
import { EaClient } from './eaClient';
import { EaUserModel } from './eaUserModel';
import { RegistrationType } from './registrationType';
import { PaymentType } from './paymentType';
import { CreditPurpose } from './creditPurpose';
import { EaSignatoryModel } from './eaSignatoryModel';
import { DateTime } from '@lib-utils';
import { NonStandardType } from './nonStandardType';
import { ProofOfIncome } from './proofOfIncome';


/**
 * Информация о кредитном досье.
 */
export interface EaCreditDossierInfo { 
    /**
     * Номер кредитного договора
     */
    loanAgreementNumber?: string | null;
    /**
     * Номер кредитной заявки
     */
    loanApplicationNumber?: string | null;
    /**
     * Процентная ставка
     */
    interestRate?: number;
    /**
     * Дата выдачи кредита
     */
    issueLoanDate?: DateTime;
    /**
     * Плановая дата окончания кредитного договора
     */
    plannedEndDate?: DateTime | null;
    incomeVerificationMethod?: ProofOfIncome;
    initialPaymentMethod?: PaymentType;
    /**
     * ID программы кредитования
     */
    creditProgramId?: number | null;
    /**
     * Программа кредитования
     */
    creditProgramName?: string | null;
    /**
     * Заёмщики
     */
    borrowers?: Array<EaClient> | null;
    /**
     * ФИО сотрудника создавшего заявку
     */
    applicationCreatorId?: number;
    applicationCreator?: EaUserModel;
    /**
     * ФИО сотрудника оформившего кредит
     */
    issuedLoanId?: number;
    issuedLoan?: EaSignatoryModel;
    /**
     * ЦИК.
     */
    mortgageLendingCenterId?: number | null;
    registrationType?: RegistrationType;
    /**
     * Страхование.
     */
    insuranceInfo?: { [key: string]: InsuranceProductType; } | null;
    /**
     * Документы.
     */
    files?: Array<EaFileInfo> | null;
    /**
     * Подгрузил 1-ю версию кредитного договора.
     */
    loanAgreementFirstVersionLoaderId?: number;
    loanAgreementFirstVersionLoader?: EaUserModel;
    /**
     * Отправил на верификацию
     */
    verificationInitiatorId?: number;
    verificationInitiator?: EaUserModel;
    /**
     * Офис проведения сделки
     */
    dealDepartmentName?: string | null;
    /**
     * Регион учета в ЦФТ
     */
    cftDepartment?: string | null;
    /**
     * Идентификатор кредитного договора в ЦФТ.
     */
    cftCreditAgreementId?: number | null;
    purpose?: CreditPurpose;
    /**
     * Признак нестандартной заявки.
     */
    nonStandard?: boolean;
    /**
     * Комментарий к нестандартной заявке.
     */
    nonStandardComment?: string | null;
    nonStandardType?: NonStandardType;
    /**
     * Город оформления кредита.
     */
    city?: string | null;
    /**
     * Цессионарий
     */
    assignee?: string | null;
    /**
     * Тип подписи
     */
    signatureType?: string | null;
    /**
     * Наличие Каско (для авто)
     */
    withInsurance?: boolean;
}
export namespace EaCreditDossierInfo {
}


