import { ChangeDetectionStrategy, Component } from '@angular/core';
import { createOptionsForLocalizedEnum } from '@lib-utils';
import { injectDialogContext } from '@lib-widgets/dialog';
import { FeDossierFilterDto, InsuranceProductTypeMap, LoanAgreementStateTypeMap } from '@lib-archive/api-middleware';
import { createDossierFilterForm } from '@lib-archive/utils';

@Component({
  selector: 'fnip-archive-list-filter-dialog',
  templateUrl: './archive-list-filter-dialog.component.html',
  styleUrls: ['./archive-list-filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchiveListFilterDialogComponent {
  readonly context = injectDialogContext<FeDossierFilterDto, FeDossierFilterDto>();

  filtersForm = createDossierFilterForm(this.context.data ?? {});

  loanAgreementStateOptions = createOptionsForLocalizedEnum(LoanAgreementStateTypeMap);

  lifeInsuranceTypeOptions = createOptionsForLocalizedEnum(InsuranceProductTypeMap);

  applyFilters = () => this.context.ref?.close(this.filtersForm.value);
}
