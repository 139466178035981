<div class="d-flex gap-5 align-items-center mb-2 w-fit-content mx-auto">
  <span *ngIf="getFilePagesCount">Страниц в файле: {{ getFilePagesCount | executeWith: selectedIndex }}</span>
  <div *ngIf="filesCount" class="d-flex align-items-center gap-2">
    <fnip-button
      appearance="outline"
      icon="tuiIconChevronLeftLarge"
      btnSize="s"
      [isDisabled]="selectedIndex === 0"
      [actionCallback$]="openPrevious"
    />
    <span class="carousel__pages">{{ selectedIndex + 1 }} / {{ filesCount }}</span>
    <fnip-button
      appearance="outline"
      icon="tuiIconChevronRightLarge"
      btnSize="s"
      [isDisabled]="selectedIndex === filesCount - 1"
      [actionCallback$]="openNext"
    />
  </div>

  <div class="d-flex align-items-center gap-2">
    <fnip-button
      appearance="outline"
      icon="tuiIconMinus"
      btnSize="s"
      [isDisabled]="zoom <= zoomConfig.min"
      [actionCallback$]="zoomOut"
    />
    {{ zoom | percent }}
    <fnip-button
      appearance="outline"
      icon="tuiIconPlus"
      btnSize="s"
      [isDisabled]="zoom >= zoomConfig.max"
      [actionCallback$]="zoomIn"
    />
  </div>

  <div class="d-flex align-items-center gap-2">
    <fnip-button appearance="outline" icon="tuiIconRotateCcw" btnSize="s" [actionCallback$]="rotateCcw" />
    <fnip-button appearance="outline" icon="tuiIconRotateCw" btnSize="s" [actionCallback$]="rotateCw" />
  </div>

  <fnip-button
    *ngIf="hasFullScreenPreview"
    appearance="outline"
    icon="tuiIconMaximize"
    btnSize="s"
    [actionCallback$]="openPreviewCallback"
  />

  <ng-content />
</div>
