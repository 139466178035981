import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule, DividerModule, LabeledContentComponent } from '@lib-widgets/core';
import { GridModule } from '@lib-widgets/grid';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { AddRemoveDossierModalModule } from '../../../../widgets/add-remove-dossier-modal';
import { ActComponent } from './act.component';

@NgModule({
  imports: [
    CommonModule,
    AddRemoveDossierModalModule,
    LabeledContentComponent,
    RequestWrapperModule,
    DividerModule,
    ExecuteWithPipeModule,
    ButtonModule,
    GridModule,
  ],
  declarations: [ActComponent],
  exports: [ActComponent],
})
export class ActModule {}
