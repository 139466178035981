import { Nullable } from '@lib-utils';
import { DocumentCheckState, DocumentDto } from '@lib-archive/api';

export interface FePreviousDocumentComment {
  comment?: Nullable<string>;
  version?: Nullable<number>;
}

export enum DocumentMode {
  Paper = 'paper',
  Digital = 'digital',
}

export function hasMissingChecksOrErrors(document: Nullable<DocumentDto>, mode: DocumentMode) {
  const hasMode = !!document?.completenessCheck?.[mode === DocumentMode.Paper ? 'hasPaper' : 'hasDigital'];
  return hasMode && !!document?.verificationSummary?.[mode]?.checks?.find((c) => !c.ok);
}

export function isDocumentToCheck(document: Nullable<DocumentDto>) {
  return !!document?.state && ![DocumentCheckState.NoErrors, DocumentCheckState.ReadyToSend].includes(document.state);
}
