import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { ReactiveCheckboxModule, ReactiveFileInputModule, ReactiveSelectModule } from '@lib-widgets/reactive-fields';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { AddDocumentsToDossierDialogComponent } from './add-documents-to-dossier-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveCheckboxModule,
    ReactiveSelectModule,
    ReactiveFileInputModule,
    RequestWrapperModule,
    ButtonModule,
    ExecuteWithPipeModule,
  ],
  declarations: [AddDocumentsToDossierDialogComponent],
  exports: [AddDocumentsToDossierDialogComponent],
})
export class AddDocumentsToDossierDialogModule {}
