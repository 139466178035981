/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DossierRole = 'ApplicationCreator' | 'LoanAgreementFirstVersionLoader' | 'VerificationInitiator' | 'DealMaker' | 'AcceptedForSupport' | 'Agent' | 'Curator' | 'Responsible';

export const DossierRole = {
    ApplicationCreator: 'ApplicationCreator' as DossierRole,
    LoanAgreementFirstVersionLoader: 'LoanAgreementFirstVersionLoader' as DossierRole,
    VerificationInitiator: 'VerificationInitiator' as DossierRole,
    DealMaker: 'DealMaker' as DossierRole,
    AcceptedForSupport: 'AcceptedForSupport' as DossierRole,
    Agent: 'Agent' as DossierRole,
    Curator: 'Curator' as DossierRole,
    Responsible: 'Responsible' as DossierRole
};

