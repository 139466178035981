/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, isDevMode, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { DossiersFilterForReports } from '../model/dossiersFilterForReports';
// @ts-ignore
import { OrderByTypes } from '../model/orderByTypes';
// @ts-ignore
import { ReportBuilderTaskDto } from '../model/reportBuilderTaskDto';
// @ts-ignore
import { ReportBuilderTaskDtoPageResultDataResponseData } from '../model/reportBuilderTaskDtoPageResultDataResponseData';
// @ts-ignore
import { ReportBuilderTaskDtoResponseData } from '../model/reportBuilderTaskDtoResponseData';
// @ts-ignore
import { ReportType } from '../model/reportType';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { ArchiveConfiguration }                                     from '../configuration';


export interface ApiReportsByFileReportTypePostRequestParams {
    /** Тип отчета Fnip.Electronic.Archive.Dal.Enums.ReportType.&lt;p&gt;&lt;/p&gt; */
    reportType: ReportType;
    file?: Blob;
}

export interface ApiReportsGetRequestParams {
    pagePage?: number;
    pagePerPage?: number;
    filterId?: number;
    /** &lt;p&gt;&lt;/p&gt; */
    filterReportType?: ReportType;
    filterInitiatorUserId?: number;
    filterInitiatorUserName?: string;
    /** &lt;p&gt;&lt;/p&gt; */
    sortOrderBy?: OrderByTypes;
    sortFieldBy?: string;
}

export interface ApiReportsReportIdFileGetRequestParams {
    reportId: number;
}

export interface ApiReportsReportIdGetRequestParams {
    reportId: number;
}

export interface ApiReportsReportTypePostRequestParams {
    /** Тип отчета Fnip.Electronic.Archive.Dal.Enums.ReportType.&lt;p&gt;&lt;/p&gt; */
    reportType: ReportType;
    /** Фильтр досье. */
    dossiersFilterForReports?: DossiersFilterForReports;
}


const enum AuthorizationType {
  Bearer = 'Bearer',
}

const enum AuthorizationHeader {
  Authorization = 'Authorization',
}

const AcceptHeader = 'Accept';

@Injectable()
export class ReportsArchiveApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new ArchiveConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: ArchiveConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    checkAuthentication(localVarHeaders: any, authType: AuthorizationType, paramName: AuthorizationHeader) {
      // authentication
      const localVarCredential = this.configuration.lookupCredential(authType as string); // name
      if (localVarCredential) {
        return localVarHeaders.set(paramName as string, localVarCredential); // keyParamName
      }

      return localVarHeaders;
    }

    getAcceptType(localVarHttpHeaderAcceptSelected?: string): 'text' | 'json' | 'blob' {
      if (localVarHttpHeaderAcceptSelected) {
        if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
          return 'text';
        } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
          return 'json';
        } else {
          return 'blob';
        }
      }

      return 'json';
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            httpParams = httpParams.append(key!, JSON.stringify(value));
        } else {
            httpParams = httpParams.append(key!, value);
        }

        return httpParams;
    }

    /**
     * Cоздать запрос на генерацию отчета,  используя стратегию поиска по реестру excel.
     * **Required permission**: &#x60;EA.Reports.Create&#x60; - Создание задачи генерации отчета  **Required roles**:  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportsByFileReportTypePost(requestParameters: ApiReportsByFileReportTypePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ReportBuilderTaskDto>;
    public apiReportsByFileReportTypePost(requestParameters: ApiReportsByFileReportTypePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ReportBuilderTaskDto>>;
    public apiReportsByFileReportTypePost(requestParameters: ApiReportsByFileReportTypePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ReportBuilderTaskDto>>;
    public apiReportsByFileReportTypePost(requestParameters: ApiReportsByFileReportTypePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const reportType = requestParameters.reportType;

        const file = requestParameters.file;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let localVarPath = `/api/Reports/ByFile/${this.configuration.encodeParam({name: "reportType", value: reportType, in: "path", style: "simple", explode: false, dataType: "ReportType", dataFormat: undefined})}`;
        return this.httpClient.request<ReportBuilderTaskDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить историю генераций отчетов.
     * **Required permission**: &#x60;EA.Reports.ReadReportsList&#x60; - Чтение списка отчетов.  **Required roles**:  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportsGet(requestParameters: ApiReportsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ReportBuilderTaskDtoPageResultDataResponseData>;
    public apiReportsGet(requestParameters: ApiReportsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ReportBuilderTaskDtoPageResultDataResponseData>>;
    public apiReportsGet(requestParameters: ApiReportsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ReportBuilderTaskDtoPageResultDataResponseData>>;
    public apiReportsGet(requestParameters: ApiReportsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const pagePage = requestParameters.pagePage;

        const pagePerPage = requestParameters.pagePerPage;

        const filterId = requestParameters.filterId;

        const filterReportType = requestParameters.filterReportType;

        const filterInitiatorUserId = requestParameters.filterInitiatorUserId;

        const filterInitiatorUserName = requestParameters.filterInitiatorUserName;

        const sortOrderBy = requestParameters.sortOrderBy;

        const sortFieldBy = requestParameters.sortFieldBy;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pagePage !== undefined && pagePage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagePage, 'page.page');
        }
        if (pagePerPage !== undefined && pagePerPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagePerPage, 'page.perPage');
        }
        if (filterId !== undefined && filterId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterId, 'filter.id');
        }
        if (filterReportType !== undefined && filterReportType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterReportType, 'filter.reportType');
        }
        if (filterInitiatorUserId !== undefined && filterInitiatorUserId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterInitiatorUserId, 'filter.initiatorUserId');
        }
        if (filterInitiatorUserName !== undefined && filterInitiatorUserName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterInitiatorUserName, 'filter.initiatorUserName');
        }
        if (sortOrderBy !== undefined && sortOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrderBy, 'sort.orderBy');
        }
        if (sortFieldBy !== undefined && sortFieldBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortFieldBy, 'sort.fieldBy');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Reports`;
        return this.httpClient.request<ReportBuilderTaskDtoPageResultDataResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить файл отчета.
     * **Required permission**: &#x60;EA.Reports.DownloadReport&#x60; - Скачать отчет  **Required roles**:  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportsReportIdFileGet(requestParameters: ApiReportsReportIdFileGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiReportsReportIdFileGet(requestParameters: ApiReportsReportIdFileGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiReportsReportIdFileGet(requestParameters: ApiReportsReportIdFileGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiReportsReportIdFileGet(requestParameters: ApiReportsReportIdFileGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const reportId = requestParameters.reportId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Reports/${this.configuration.encodeParam({name: "reportId", value: reportId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/file`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу генерации отчета.
     * **Required permission**: &#x60;EA.Reports.Read&#x60; - Чтение отчета  **Required roles**:  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportsReportIdGet(requestParameters: ApiReportsReportIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ReportBuilderTaskDtoResponseData>;
    public apiReportsReportIdGet(requestParameters: ApiReportsReportIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ReportBuilderTaskDtoResponseData>>;
    public apiReportsReportIdGet(requestParameters: ApiReportsReportIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ReportBuilderTaskDtoResponseData>>;
    public apiReportsReportIdGet(requestParameters: ApiReportsReportIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const reportId = requestParameters.reportId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Reports/${this.configuration.encodeParam({name: "reportId", value: reportId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<ReportBuilderTaskDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Cоздать запрос на генерацию отчета.
     * **Required permission**: &#x60;EA.Reports.Create&#x60; - Создание задачи генерации отчета  **Required roles**:  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReportsReportTypePost(requestParameters: ApiReportsReportTypePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ReportBuilderTaskDto>;
    public apiReportsReportTypePost(requestParameters: ApiReportsReportTypePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ReportBuilderTaskDto>>;
    public apiReportsReportTypePost(requestParameters: ApiReportsReportTypePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ReportBuilderTaskDto>>;
    public apiReportsReportTypePost(requestParameters: ApiReportsReportTypePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const reportType = requestParameters.reportType;

        const dossiersFilterForReports = requestParameters.dossiersFilterForReports;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Reports/${this.configuration.encodeParam({name: "reportType", value: reportType, in: "path", style: "simple", explode: false, dataType: "ReportType", dataFormat: undefined})}`;
        return this.httpClient.request<ReportBuilderTaskDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: dossiersFilterForReports,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
