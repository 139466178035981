/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, isDevMode, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreditDossierDtoPageResultDataResponseData } from '../model/creditDossierDtoPageResultDataResponseData';
// @ts-ignore
import { Int32ResponseData } from '../model/int32ResponseData';
// @ts-ignore
import { OrderByTypes } from '../model/orderByTypes';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { ArchiveConfiguration }                                     from '../configuration';


export interface ApiFilteredDossiersByFileCountPostRequestParams {
    file?: Blob;
}

export interface ApiFilteredDossiersByFileDownloadFilesPostRequestParams {
    filterFile?: Blob;
    documentTypeIds?: Array<number>;
}

export interface ApiFilteredDossiersByFilePostRequestParams {
    pagePage?: number;
    pagePerPage?: number;
    /** &lt;p&gt;&lt;/p&gt; */
    sortOrderBy?: OrderByTypes;
    sortFieldBy?: string;
    filterFile?: Blob;
}


const enum AuthorizationType {
  Bearer = 'Bearer',
}

const enum AuthorizationHeader {
  Authorization = 'Authorization',
}

const AcceptHeader = 'Accept';

@Injectable()
export class FilteredDossiersByFileArchiveApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new ArchiveConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: ArchiveConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    checkAuthentication(localVarHeaders: any, authType: AuthorizationType, paramName: AuthorizationHeader) {
      // authentication
      const localVarCredential = this.configuration.lookupCredential(authType as string); // name
      if (localVarCredential) {
        return localVarHeaders.set(paramName as string, localVarCredential); // keyParamName
      }

      return localVarHeaders;
    }

    getAcceptType(localVarHttpHeaderAcceptSelected?: string): 'text' | 'json' | 'blob' {
      if (localVarHttpHeaderAcceptSelected) {
        if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
          return 'text';
        } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
          return 'json';
        } else {
          return 'blob';
        }
      }

      return 'json';
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            httpParams = httpParams.append(key!, JSON.stringify(value));
        } else {
            httpParams = httpParams.append(key!, value);
        }

        return httpParams;
    }

    /**
     * Получить кол-во досье, которые попадают под фильтр отчетов.
     * **Required permission**: &#x60;EA.FilteredDossiers.GetFilteredDossiers&#x60; - Операция фильтрации досье  **Required roles**:  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFilteredDossiersByFileCountPost(requestParameters: ApiFilteredDossiersByFileCountPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Int32ResponseData>;
    public apiFilteredDossiersByFileCountPost(requestParameters: ApiFilteredDossiersByFileCountPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Int32ResponseData>>;
    public apiFilteredDossiersByFileCountPost(requestParameters: ApiFilteredDossiersByFileCountPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Int32ResponseData>>;
    public apiFilteredDossiersByFileCountPost(requestParameters: ApiFilteredDossiersByFileCountPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const file = requestParameters.file;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let localVarPath = `/api/FilteredDossiersByFile/count`;
        return this.httpClient.request<Int32ResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Выгрузка в виде архива файлов кредитных досье, которые попадают под фильтр отчетов.
     * **Required permission**: &#x60;EA.FilteredDossiers.MassiveFilesExport&#x60; - Операция выгрузки файлов досье  **Required roles**:  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFilteredDossiersByFileDownloadFilesPost(requestParameters: ApiFilteredDossiersByFileDownloadFilesPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiFilteredDossiersByFileDownloadFilesPost(requestParameters: ApiFilteredDossiersByFileDownloadFilesPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiFilteredDossiersByFileDownloadFilesPost(requestParameters: ApiFilteredDossiersByFileDownloadFilesPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiFilteredDossiersByFileDownloadFilesPost(requestParameters: ApiFilteredDossiersByFileDownloadFilesPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const filterFile = requestParameters.filterFile;

        const documentTypeIds = requestParameters.documentTypeIds;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (filterFile !== undefined) {
            localVarFormParams = localVarFormParams.append('filter.file', <any>filterFile) as any || localVarFormParams;
        }
        if (documentTypeIds) {
            documentTypeIds.forEach((element) => {
                localVarFormParams = localVarFormParams.append('documentTypeIds', <any>element) as any || localVarFormParams;
            })
        }

        let localVarPath = `/api/FilteredDossiersByFile/downloadFiles`;
        return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить досье, которые попадают под фильтр отчетов.
     * **Required permission**: &#x60;EA.FilteredDossiers.GetFilteredDossiers&#x60; - Операция фильтрации досье  **Required roles**:  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFilteredDossiersByFilePost(requestParameters: ApiFilteredDossiersByFilePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CreditDossierDtoPageResultDataResponseData>;
    public apiFilteredDossiersByFilePost(requestParameters: ApiFilteredDossiersByFilePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CreditDossierDtoPageResultDataResponseData>>;
    public apiFilteredDossiersByFilePost(requestParameters: ApiFilteredDossiersByFilePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CreditDossierDtoPageResultDataResponseData>>;
    public apiFilteredDossiersByFilePost(requestParameters: ApiFilteredDossiersByFilePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const pagePage = requestParameters.pagePage;

        const pagePerPage = requestParameters.pagePerPage;

        const sortOrderBy = requestParameters.sortOrderBy;

        const sortFieldBy = requestParameters.sortFieldBy;

        const filterFile = requestParameters.filterFile;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pagePage !== undefined && pagePage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagePage, 'page.page');
        }
        if (pagePerPage !== undefined && pagePerPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagePerPage, 'page.perPage');
        }
        if (sortOrderBy !== undefined && sortOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrderBy, 'sort.orderBy');
        }
        if (sortFieldBy !== undefined && sortFieldBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortFieldBy, 'sort.fieldBy');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (filterFile !== undefined) {
            localVarFormParams = localVarFormParams.append('filter.file', <any>filterFile) as any || localVarFormParams;
        }

        let localVarPath = `/api/FilteredDossiersByFile`;
        return this.httpClient.request<CreditDossierDtoPageResultDataResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
