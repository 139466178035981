<div class="d-flex flex-column gap-6">
  <fnip-reactive-input-slider
    fieldId="page"
    label="Страница документа"
    labelSize="m"
    [isLabelBold]="true"
    [min]="1"
    [max]="context.data?.pagesCount ?? 0"
    [control]="form.controls.page"
    [requiredIf]="true"
  />
  <pdf-viewer
    *ngIf="context.data?.fileInfo?.type === FileInfoScanTypes.Pdf"
    class="overflow w-100 h-400px"
    [src]="getPdfSrc | executeWith: context.data?.fileInfo?.value"
    [original-size]="false"
    [render-text]="false"
    [show-all]="false"
    [page]="form.controls.page.value"
  />
  <div *ngIf="context.data?.fileInfo?.type === FileInfoScanTypes.Tif" class="w-100 h-400px" [style.overflow]="'auto'">
    <img
      alt="page preview"
      class="w-100"
      [style.object-fit]="'contain'"
      [src]="getTifSrc | executeWith: context.data?.fileInfo?.values : form.controls.page.value"
    />
  </div>
  <fnip-reactive-file-input
    fieldId="file"
    label="Заменить на"
    [accept]="getAcceptTypes | executeWith: context.data?.fileInfo?.type"
    [control]="form.controls.file"
    [isLocalUsage]="true"
    [isRequired]="true"
    [showAcceptCaption]="true"
    [allowPreview]="true"
  />
  <div class="d-flex content-end gap-4">
    <fnip-button label="Отменить" [actionCallback$]="cancel" />
    <fnip-button appearance="primary" label="Сохранить" [isDisabled]="form.invalid" [actionCallback$]="save" />
  </div>
</div>
