<div class="d-flex flex-column gap-7">
  <div class="dossier-card d-flex flex-column gap-5">
    <div class="dossier-card__title">Кредит</div>
    <fnip-divider />

    <fnip-labeled-content
      label="Состояние"
      [content]="statusTemplate"
      [contentContext]="getLoanAgreementState | executeWith: dossier?.loanAgreementState"
      [inline]="true"
    />
    <fnip-labeled-content
      label="№ заявки"
      [content]="isDevMode ? externalLinkTemplate : dossier?.loanApplicationNumber"
      [contentContext]="{
        label: dossier?.loanApplicationNumber,
        link: getOrderLink | executeWith: dossier?.loanApplicationNumber,
      }"
      [inline]="true"
    />
    <fnip-labeled-content label="Процентная ставка" [content]="dossier?.interestRate" [inline]="true" />
    <fnip-labeled-content
      label="Дата выдачи"
      [content]="dateTemplate"
      [contentContext]="dossier?.issueLoanDate"
      [inline]="true"
    />
    <fnip-labeled-content
      label="Плановая дата окончания"
      [content]="dateTemplate"
      [contentContext]="dossier?.plannedEndDate"
      [inline]="true"
    />
    <ng-container *ngIf="dossier?.productCode === ProductCode.Mortgage">
      <fnip-labeled-content
        label="Способ подтверждения дохода"
        [content]="getIncomeConfirmationType | executeWith: dossier?.incomeVerificationMethod"
        [inline]="true"
        [disableEllipsis]="true"
      />
      <fnip-labeled-content
        label="Способ оплаты ПВ"
        [content]="getPaymentType | executeWith: dossier?.initialPaymentMethod"
        [inline]="true"
        [disableEllipsis]="true"
      />
      <fnip-labeled-content
        label="Регион учета в ЦФТ"
        [content]="dossier?.cftDepartment"
        [inline]="true"
        [disableEllipsis]="true"
      />
      <fnip-labeled-content
        label="Рынок"
        [content]="getCreditPurpose | executeWith: dossier?.purpose"
        [inline]="true"
        [disableEllipsis]="true"
      />
    </ng-container>
    <fnip-request-wrapper #creditProgramsRs appearance="inline" [request$]="creditPrograms$">
      <fnip-labeled-content
        label="Программа кредитования"
        [content]="getCreditProgramName | executeWith: creditProgramsRs.data : dossier?.creditProgramId"
        [inline]="true"
        [disableEllipsis]="true"
      />
    </fnip-request-wrapper>
    <fnip-labeled-content
      label="ID КД в ЦФТ"
      [content]="dossier?.cftCreditAgreementId"
      [inline]="true"
      [disableEllipsis]="true"
    />
    <fnip-labeled-content
      *ngIf="dossier?.productCode === ProductCode.Mortgage"
      label="Тип досье"
      [content]="getPhysicalTypeName | executeWith: dossier?.physicalType"
      [inline]="true"
      [disableEllipsis]="true"
    />
    <fnip-labeled-content
      label="Способ регистрации"
      [content]="getRegistrationTypeName | executeWith: dossier?.registrationType"
      [inline]="true"
      [disableEllipsis]="true"
    />
    <fnip-labeled-content
      label="Документы с уЭП"
      [content]="documentsWithSignTmp"
      [inline]="true"
      [disableEllipsis]="true"
    />
    <ng-container *ngIf="dossier?.productCode === ProductCode.Mortgage">
      <fnip-labeled-content
        label="Нестандартная заявка"
        [content]="checkboxTmp"
        [contentContext]="!!dossier?.nonStandard"
        [inline]="true"
        [disableEllipsis]="true"
      />
      <fnip-labeled-content
        *ngIf="dossier?.nonStandard"
        label="Тип нестандартной заявки"
        [content]="getNonStandardTypeName | executeWith: dossier?.nonStandardType"
        [inline]="true"
        [disableEllipsis]="true"
      />
      <fnip-labeled-content
        *ngIf="dossier?.nonStandard"
        label="Комментарий"
        [content]="dossier?.nonStandardComment"
        [inline]="true"
        [disableEllipsis]="true"
      />
    </ng-container>
    <ng-container *ngIf="dossier?.productCode === ProductCode.Auto">
      <fnip-labeled-content label="Город" [content]="dossier?.city" [inline]="true" [disableEllipsis]="true" />
      <fnip-labeled-content label="Автосалон" [content]="dossier?.showroom" [inline]="true" [disableEllipsis]="true" />
      <fnip-labeled-content
        label="Наличие страхования"
        [content]="checkboxTmp"
        [contentContext]="!!dossier?.withInsurance"
        [inline]="true"
        [disableEllipsis]="true"
      />
    </ng-container>
  </div>

  <div class="dossier-card d-flex flex-column gap-5">
    <div class="dossier-card__title">Кредитное досье</div>
    <fnip-divider />

    <fnip-labeled-content
      label="Статус"
      [content]="statusTemplate"
      [contentContext]="getDossierState | executeWith: dossier?.state"
      [inline]="true"
    />

    <fnip-labeled-content
      *ngIf="isStatusWithLocation | executeWith: dossier?.state"
      label="Местонахождение досье"
      [content]="getLocation | executeWith: dossier"
      [inline]="true"
      [disableEllipsis]="true"
    />

    <fnip-labeled-content label="Количество проверок" [content]="dossier?.numberOfChecks" [inline]="true" />
    <fnip-labeled-content
      label="Акт"
      [content]="dossier?.actId ? linkTemplate : null"
      [contentContext]="{ label: dossier?.actId, link: [basePath, 'acts', dossier?.actId] }"
      [inline]="true"
    />
    <fnip-labeled-content
      label="Короб"
      [content]="dossier?.box?.boxNumber ? linkTemplate : null"
      [contentContext]="{ label: dossier?.box?.boxNumber, link: [basePath, 'boxes', dossier?.box?.id] }"
      [inline]="true"
    />
    <fnip-labeled-content label="Порядковый номер в коробе" [content]="dossier?.numberInBox" [inline]="true" />
  </div>

  <div class="dossier-card d-flex flex-column gap-5">
    <div class="dossier-card__title">Участники</div>
    <fnip-divider />
    <fnip-dossier-person-info
      *ngFor="let borrower of sortBorrowers | executeWith: dossier?.borrowers"
      [person]="borrower"
      [canEdit]="'canEditDossierBorrower' | hasPermission"
      [savePerson$]="saveBorrower$"
    />
    <ng-container *ngFor="let participant of dossier?.participants">
      <fnip-labeled-content
        *ngIf="canSeeParticipant | executeWith: participant.role : dossier"
        [label]="getParticipantRole | executeWith: participant.role"
        [content]="participant.participant?.name ?? participant.participant?.email"
        [inline]="true"
        [disableEllipsis]="true"
      />
    </ng-container>
    <fnip-dossier-person-info
      *ngIf="dealMakerMissing | executeWith: dossier?.participants"
      label="Провел сделку"
      [canEdit]="dossier?.state === DossierState.InMlc"
      [addPerson$]="addDealMaker$"
      [savePerson$]="saveDealMaker$"
      [options]="userOptions$"
    />
    <fnip-labeled-content
      label="Оформил кредит"
      [content]="dossier?.issuedLoan?.name ?? dossier?.issuedLoan?.email"
      [inline]="true"
      [disableEllipsis]="true"
    />
    <fnip-labeled-content
      label="Офис проведения сделки"
      [content]="dossier?.dealDepartmentName"
      [inline]="true"
      [disableEllipsis]="true"
    />
  </div>

  <div *ngIf="dossier?.productCode === ProductCode.Mortgage" class="dossier-card d-flex flex-column gap-5">
    <div class="dossier-card__title">Страхование</div>
    <fnip-divider />

    <fnip-labeled-content
      label="Жизнь и здоровье"
      [content]="getInsuranceProductType | executeWith: dossier?.insuranceInfo?.Life"
      [inline]="true"
    />
    <fnip-labeled-content
      label="Титул"
      [content]="getInsuranceProductType | executeWith: dossier?.insuranceInfo?.Title"
      [inline]="true"
    />
    <fnip-labeled-content
      label="Имущество"
      [content]="getInsuranceProductType | executeWith: dossier?.insuranceInfo?.Property"
      [inline]="true"
    />
  </div>
</div>

<ng-template #statusTemplate let-status>
  <div class="archive-status">{{ status }}</div>
</ng-template>

<ng-template #dateTemplate let-date>
  <span>{{ date | date: 'dd.MM.yyyy' }}</span>
</ng-template>

<ng-template #linkTemplate let-label="label" let-link="link">
  <fnip-button
    appearance="link-primary"
    class="wrapper-no-padding"
    [style.--min-content-width]="0"
    [label]="label"
    [btnRouterLink]="link"
  />
</ng-template>

<ng-template #externalLinkTemplate let-label="label" let-link="link">
  <fnip-button
    appearance="link-primary"
    class="wrapper-no-padding"
    btnLinkTarget="_blank"
    [style.--min-content-width]="0"
    [label]="label"
    [btnHref]="link"
  />
</ng-template>

<ng-template #documentsWithSignTmp>
  <p *ngFor="let document of getDocumentsWithSign | executeWith: documents" class="mb-1 font-weight-bold font-size-16">
    {{ document }}
  </p>
</ng-template>

<ng-template #checkboxTmp let-value>
  <tui-primitive-checkbox [value]="value" />
</ng-template>
