<div class="d-flex gap-5 flex-wrap">
  <div class="d-flex w-100">
    <fnip-reactive-input
      placeholder="ФИО, № заявки, № КД"
      fieldId="searchString"
      class="flex-1"
      icon="tuiIconSearchLarge"
      [control]="filterForm?.controls?.text"
      iconAlign="right"
    />
    <fnip-button
      *ngIf="hasFiltersPageLink"
      appearance="primary"
      icon="tuiIconSearch"
      label="Фильтры"
      class="ml-3 align-self-end"
      [actionCallback$]="openDialog$"
    />
    <fnip-button
      *ngIf="hasFiltersPageLink && showClearButton"
      appearance="primary"
      icon="tuiIconUndo"
      class="ml-1 align-self-end"
      [actionCallback$]="clearFilters"
      btnHint="Сбросить фильтры"
    />
  </div>
</div>
