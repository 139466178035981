import { FormControl, FormGroup } from '@angular/forms';
import { calculateSelectAllValue, FormControlsOf, FormGroupOf, Nullable } from '@lib-utils';
import { DocumentCompletenessCheckDto, DocumentDto, DocumentTypeDto } from '@lib-archive/api';

export type DocumentCompletenessType = keyof Pick<
  DocumentCompletenessCheckDto,
  'hasDigital' | 'hasPaper' | 'hasDigitalSignature'
>;

export interface DocumentWithForm {
  document: DocumentDto;
  form: FormGroupOf<DocumentCompletenessCheckDto>;
}

export interface DocumentsGroup {
  title: Nullable<string>;
  subtitle?: Nullable<string>;
  form: FormGroupOf<DocumentCompletenessCheckDto>;
  data: DocumentWithForm[];
}

export const createDocumentCompletenessForm = (
  completenessDto: Nullable<DocumentCompletenessCheckDto>,
  documentType: Nullable<DocumentTypeDto>,
  disabled: boolean,
) =>
  new FormGroup<FormControlsOf<DocumentCompletenessCheckDto>>({
    hasDigital: new FormControl({ value: completenessDto?.hasDigital, disabled }),
    ...(documentType?.hasPaper ? { hasPaper: new FormControl({ value: completenessDto?.hasPaper, disabled }) } : {}),
    ...(documentType?.mayHaveDigitalSignature
      ? { hasDigitalSignature: new FormControl({ value: completenessDto?.hasDigitalSignature, disabled }) }
      : {}),
  });

export const createDocumentGroupCompletenessForm = (documents: Nullable<DocumentWithForm[]>, disabled: boolean) =>
  new FormGroup<FormControlsOf<DocumentCompletenessCheckDto>>({
    hasDigital: new FormControl({
      value: calculateSelectAllValue(documents?.map(({ form }) => form.controls.hasDigital?.value)),
      disabled,
    }),
    hasPaper: new FormControl<Nullable<boolean>>({
      value: calculateSelectAllValue(
        documents?.filter(({ form }) => form.controls.hasPaper).map(({ form }) => form.controls.hasPaper?.value),
      ),
      disabled,
    }),
    hasDigitalSignature: new FormControl<Nullable<boolean>>({
      value: calculateSelectAllValue(
        documents
          ?.filter(({ form }) => form.controls.hasDigitalSignature)
          .map(({ form }) => form.controls.hasDigitalSignature?.value),
      ),
      disabled,
    }),
  });
