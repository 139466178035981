import { Observable } from 'rxjs';
import { Nullable } from '@lib-utils';
import { CreditDossierDto, CreditDossierDtoPageResultDataResponseData } from '@lib-archive/api';

export interface GetDossiersConfig {
  page: number;
  perPage: number;
  filterText: Nullable<string>;
}

export interface AddRemoveDossierModalContext {
  dossiers?: Nullable<CreditDossierDto[]>;
  getDossiersRq$?: (config: GetDossiersConfig) => Observable<CreditDossierDtoPageResultDataResponseData>;
  scanDossierRq$?: (code: string) => Observable<Nullable<CreditDossierDto>>;
  addDossierRq$?: (id: Nullable<number>) => Observable<unknown> | null;
  removeDossiersRq$?: (ids: Nullable<number>[]) => Observable<unknown> | null;
}
