<fnip-dossier-search-card *ngIf="options.length" [label]="label">
  <div class="d-flex flex-column gap-2">
    <div class="d-flex align-items-center">
      <fnip-reactive-checkbox
        size="l"
        fieldId="selectAllField"
        checkboxLabel="Выбрать все"
        [control]="selectAll"
        [isLabelBold]="true"
        (valueChange)="selectAllChange($event)"
      />
    </div>
    <div class="d-flex align-items-center" *ngFor="let option of options; let id = index">
      <fnip-reactive-checkbox
        size="l"
        [fieldId]="'checkbox' + id"
        [control]="option.control"
        [checkboxLabel]="option.label"
        (valueChange)="optionValueChange($event)"
      />
    </div>
  </div>
</fnip-dossier-search-card>
