import { Nullable } from '@lib-utils';
import { CreditDossierDto, DossierState } from '@lib-archive/api';

export function canDeleteDossier(dossier: Nullable<CreditDossierDto>) {
  return (
    dossier?.state &&
    [DossierState.Draft, DossierState.Status2, DossierState.InMlc].includes(dossier.state) &&
    dossier.isManual
  );
}
