/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, isDevMode, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BooleanResponseData } from '../model/booleanResponseData';
// @ts-ignore
import { BoxDtoPageWithStateResultDataResponseData } from '../model/boxDtoPageWithStateResultDataResponseData';
// @ts-ignore
import { BoxDtoResponseData } from '../model/boxDtoResponseData';
// @ts-ignore
import { BoxNumberDto } from '../model/boxNumberDto';
// @ts-ignore
import { BoxState } from '../model/boxState';
// @ts-ignore
import { CreditDossierDtoPageResultDataResponseData } from '../model/creditDossierDtoPageResultDataResponseData';
// @ts-ignore
import { CreditDossierDtoResponseData } from '../model/creditDossierDtoResponseData';
// @ts-ignore
import { DateTime } from '@lib-utils';
// @ts-ignore
import { OrderByTypes } from '../model/orderByTypes';
// @ts-ignore
import { ProductCode } from '../model/productCode';
// @ts-ignore
import { ResponseData } from '../model/responseData';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { ArchiveConfiguration }                                     from '../configuration';


export interface ApiBoxBoxIdDeleteRequestParams {
    /**  */
    boxId: number;
}

export interface ApiBoxBoxIdDossierByQrPostRequestParams {
    /** Идентификатор короба. */
    boxId: number;
    /** Формат (000000; N заявки; N кд;).\&quot; */
    qrCode?: string;
}

export interface ApiBoxBoxIdDossiersAvailablePostRequestParams {
    /** Идентификатор короба. */
    boxId: number;
    /**  */
    text?: string;
    /** Номер страницы */
    pageNumber?: number;
    /** Кол-во элементов на странице */
    perPage?: number;
}

export interface ApiBoxBoxIdGetRequestParams {
    /** Идентификатор досье */
    boxId: number;
}

export interface ApiBoxBoxIdInventoryGetRequestParams {
    /**  */
    boxId: number;
}

export interface ApiBoxBoxIdPostRequestParams {
    /**  */
    boxId: number;
    /**  */
    boxNumberDto?: BoxNumberDto;
}

export interface ApiBoxBoxIdStateTransferredPostRequestParams {
    /**  */
    boxId: number;
}

export interface ApiBoxBoxIdStateWithdrawnPostRequestParams {
    /** Идентификатор короба. */
    boxId: number;
}

export interface ApiBoxGetRequestParams {
    pagePage?: number;
    pagePerPage?: number;
    filterBoxNumber?: string;
    filterOsg?: number;
    /** &lt;p&gt;&lt;/p&gt; */
    filterProductCode?: ProductCode;
    filterCreatedStart?: DateTime;
    filterCreatedFinish?: DateTime;
    filterCreator?: string;
    /** &lt;p&gt;&lt;/p&gt; */
    filterState?: BoxState;
    /** &lt;p&gt;&lt;/p&gt; */
    sortOrderBy?: OrderByTypes;
    sortFieldBy?: string;
}

export interface ApiBoxPostRequestParams {
    /** &lt;p&gt;&lt;/p&gt; */
    product?: ProductCode;
    /**  */
    requestBody?: Array<number>;
}


const enum AuthorizationType {
  Bearer = 'Bearer',
}

const enum AuthorizationHeader {
  Authorization = 'Authorization',
}

const AcceptHeader = 'Accept';

@Injectable()
export class BoxArchiveApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new ArchiveConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: ArchiveConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    checkAuthentication(localVarHeaders: any, authType: AuthorizationType, paramName: AuthorizationHeader) {
      // authentication
      const localVarCredential = this.configuration.lookupCredential(authType as string); // name
      if (localVarCredential) {
        return localVarHeaders.set(paramName as string, localVarCredential); // keyParamName
      }

      return localVarHeaders;
    }

    getAcceptType(localVarHttpHeaderAcceptSelected?: string): 'text' | 'json' | 'blob' {
      if (localVarHttpHeaderAcceptSelected) {
        if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
          return 'text';
        } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
          return 'json';
        } else {
          return 'blob';
        }
      }

      return 'json';
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            httpParams = httpParams.append(key!, JSON.stringify(value));
        } else {
            httpParams = httpParams.append(key!, value);
        }

        return httpParams;
    }

    /**
     * Удаляет короб
     * **Required permission**: &#x60;EA.Box.Delete&#x60; - Удаление  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBoxBoxIdDelete(requestParameters: ApiBoxBoxIdDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiBoxBoxIdDelete(requestParameters: ApiBoxBoxIdDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiBoxBoxIdDelete(requestParameters: ApiBoxBoxIdDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiBoxBoxIdDelete(requestParameters: ApiBoxBoxIdDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const boxId = requestParameters.boxId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Box/${this.configuration.encodeParam({name: "boxId", value: boxId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<BooleanResponseData>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получение списка досье по qr-коду для добавления в заданный короб.
     * **Required permission**: &#x60;EA.Dossier.Read&#x60; - Чтение досье  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  - &#x60;BankManager&#x60; - Менеджер Банка.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBoxBoxIdDossierByQrPost(requestParameters: ApiBoxBoxIdDossierByQrPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CreditDossierDtoResponseData>;
    public apiBoxBoxIdDossierByQrPost(requestParameters: ApiBoxBoxIdDossierByQrPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CreditDossierDtoResponseData>>;
    public apiBoxBoxIdDossierByQrPost(requestParameters: ApiBoxBoxIdDossierByQrPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CreditDossierDtoResponseData>>;
    public apiBoxBoxIdDossierByQrPost(requestParameters: ApiBoxBoxIdDossierByQrPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const boxId = requestParameters.boxId;

        const qrCode = requestParameters.qrCode;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (qrCode !== undefined && qrCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>qrCode, 'qrCode');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Box/${this.configuration.encodeParam({name: "boxId", value: boxId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/dossier/by-qr`;
        return this.httpClient.request<CreditDossierDtoResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получение списка досье для добавления в заданный короб.
     * **Required permission**: &#x60;EA.Dossier.Read&#x60; - Чтение досье  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  - &#x60;BankManager&#x60; - Менеджер Банка.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBoxBoxIdDossiersAvailablePost(requestParameters: ApiBoxBoxIdDossiersAvailablePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CreditDossierDtoPageResultDataResponseData>;
    public apiBoxBoxIdDossiersAvailablePost(requestParameters: ApiBoxBoxIdDossiersAvailablePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CreditDossierDtoPageResultDataResponseData>>;
    public apiBoxBoxIdDossiersAvailablePost(requestParameters: ApiBoxBoxIdDossiersAvailablePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CreditDossierDtoPageResultDataResponseData>>;
    public apiBoxBoxIdDossiersAvailablePost(requestParameters: ApiBoxBoxIdDossiersAvailablePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const boxId = requestParameters.boxId;

        const text = requestParameters.text;

        const pageNumber = requestParameters.pageNumber;

        const perPage = requestParameters.perPage;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (text !== undefined && text !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>text, 'text');
        }
        if (pageNumber !== undefined && pageNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageNumber, 'pageNumber');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'perPage');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Box/${this.configuration.encodeParam({name: "boxId", value: boxId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/dossiers/available`;
        return this.httpClient.request<CreditDossierDtoPageResultDataResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает короб по Id
     * **Required permission**: &#x60;EA.Box.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBoxBoxIdGet(requestParameters: ApiBoxBoxIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BoxDtoResponseData>;
    public apiBoxBoxIdGet(requestParameters: ApiBoxBoxIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BoxDtoResponseData>>;
    public apiBoxBoxIdGet(requestParameters: ApiBoxBoxIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BoxDtoResponseData>>;
    public apiBoxBoxIdGet(requestParameters: ApiBoxBoxIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const boxId = requestParameters.boxId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Box/${this.configuration.encodeParam({name: "boxId", value: boxId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<BoxDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Опись
     * **Required permission**: &#x60;EA.Box.Inventory&#x60; - Печать описи  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBoxBoxIdInventoryGet(requestParameters: ApiBoxBoxIdInventoryGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiBoxBoxIdInventoryGet(requestParameters: ApiBoxBoxIdInventoryGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiBoxBoxIdInventoryGet(requestParameters: ApiBoxBoxIdInventoryGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiBoxBoxIdInventoryGet(requestParameters: ApiBoxBoxIdInventoryGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const boxId = requestParameters.boxId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Box/${this.configuration.encodeParam({name: "boxId", value: boxId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/inventory`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Устанавливает номер для короба
     * **Required permission**: &#x60;EA.Box.SetNumber&#x60; - Устанавка номера  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBoxBoxIdPost(requestParameters: ApiBoxBoxIdPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ResponseData>;
    public apiBoxBoxIdPost(requestParameters: ApiBoxBoxIdPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ResponseData>>;
    public apiBoxBoxIdPost(requestParameters: ApiBoxBoxIdPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ResponseData>>;
    public apiBoxBoxIdPost(requestParameters: ApiBoxBoxIdPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const boxId = requestParameters.boxId;

        const boxNumberDto = requestParameters.boxNumberDto;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Box/${this.configuration.encodeParam({name: "boxId", value: boxId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<ResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: boxNumberDto,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Переводит короб в состояние Fnip.Electronic.Archive.Dal.Enums.BoxState.OffsiteStorage
     * **Required permission**: &#x60;EA.Box.Transferred&#x60; - Перевод в состояние \&quot;Внеофисное хранение\&quot;  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBoxBoxIdStateTransferredPost(requestParameters: ApiBoxBoxIdStateTransferredPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiBoxBoxIdStateTransferredPost(requestParameters: ApiBoxBoxIdStateTransferredPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiBoxBoxIdStateTransferredPost(requestParameters: ApiBoxBoxIdStateTransferredPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiBoxBoxIdStateTransferredPost(requestParameters: ApiBoxBoxIdStateTransferredPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const boxId = requestParameters.boxId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Box/${this.configuration.encodeParam({name: "boxId", value: boxId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/Transferred`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Переводит короб в состояние Fnip.Electronic.Archive.Dal.Enums.BoxState.Withdrawn
     * **Required permission**: &#x60;EA.Box.Withdrawn&#x60; - Перевод в состояние \&quot;Изьят\&quot;  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBoxBoxIdStateWithdrawnPost(requestParameters: ApiBoxBoxIdStateWithdrawnPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiBoxBoxIdStateWithdrawnPost(requestParameters: ApiBoxBoxIdStateWithdrawnPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiBoxBoxIdStateWithdrawnPost(requestParameters: ApiBoxBoxIdStateWithdrawnPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiBoxBoxIdStateWithdrawnPost(requestParameters: ApiBoxBoxIdStateWithdrawnPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const boxId = requestParameters.boxId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Box/${this.configuration.encodeParam({name: "boxId", value: boxId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/Withdrawn`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает страницу с коробами по фильтру
     * **Required permission**: &#x60;EA.Box.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBoxGet(requestParameters: ApiBoxGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BoxDtoPageWithStateResultDataResponseData>;
    public apiBoxGet(requestParameters: ApiBoxGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BoxDtoPageWithStateResultDataResponseData>>;
    public apiBoxGet(requestParameters: ApiBoxGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BoxDtoPageWithStateResultDataResponseData>>;
    public apiBoxGet(requestParameters: ApiBoxGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const pagePage = requestParameters.pagePage;

        const pagePerPage = requestParameters.pagePerPage;

        const filterBoxNumber = requestParameters.filterBoxNumber;

        const filterOsg = requestParameters.filterOsg;

        const filterProductCode = requestParameters.filterProductCode;

        const filterCreatedStart = requestParameters.filterCreatedStart;

        const filterCreatedFinish = requestParameters.filterCreatedFinish;

        const filterCreator = requestParameters.filterCreator;

        const filterState = requestParameters.filterState;

        const sortOrderBy = requestParameters.sortOrderBy;

        const sortFieldBy = requestParameters.sortFieldBy;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pagePage !== undefined && pagePage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagePage, 'page.page');
        }
        if (pagePerPage !== undefined && pagePerPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagePerPage, 'page.perPage');
        }
        if (filterBoxNumber !== undefined && filterBoxNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterBoxNumber, 'filter.boxNumber');
        }
        if (filterOsg !== undefined && filterOsg !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterOsg, 'filter.osg');
        }
        if (filterProductCode !== undefined && filterProductCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterProductCode, 'filter.productCode');
        }
        if (filterCreatedStart !== undefined && filterCreatedStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterCreatedStart, 'filter.created.start');
        }
        if (filterCreatedFinish !== undefined && filterCreatedFinish !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterCreatedFinish, 'filter.created.finish');
        }
        if (filterCreator !== undefined && filterCreator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterCreator, 'filter.creator');
        }
        if (filterState !== undefined && filterState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterState, 'filter.state');
        }
        if (sortOrderBy !== undefined && sortOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrderBy, 'sort.orderBy');
        }
        if (sortFieldBy !== undefined && sortFieldBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortFieldBy, 'sort.fieldBy');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Box`;
        return this.httpClient.request<BoxDtoPageWithStateResultDataResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Создаёт новый короб
     * **Required permission**: &#x60;EA.Box.Create&#x60; - Создание  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBoxPost(requestParameters: ApiBoxPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BoxDtoResponseData>;
    public apiBoxPost(requestParameters: ApiBoxPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BoxDtoResponseData>>;
    public apiBoxPost(requestParameters: ApiBoxPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BoxDtoResponseData>>;
    public apiBoxPost(requestParameters: ApiBoxPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const product = requestParameters.product;

        const requestBody = requestParameters.requestBody;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Box`;
        return this.httpClient.request<BoxDtoResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: requestBody,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
