import omit from 'lodash-es/omit';
import { Nullable, omitNullable, toDateTimeFormat } from '@lib-utils';
import { DossiersFilterForReports } from '@lib-archive/api';
import { FeDossiersFilterForReports } from '@lib-archive/api-middleware';

export function getDossierFilters(formValue: Nullable<FeDossiersFilterForReports>): DossiersFilterForReports {
  return {
    ...omitNullable(omit(formValue, 'leftDateBorderDate', 'rightDateBorderDate', 'containsDocumentsList')),
    leftDateBorder: toDateTimeFormat(formValue?.leftDateBorderDate),
    rightDateBorder: toDateTimeFormat(formValue?.rightDateBorderDate),
    containsDocuments: formValue?.containsDocumentsList?.join(','),
  };
}
