<fnip-request-wrapper [request$]="request$">
  <div class="d-flex gap-7">
    <div class="d-flex flex-column gap-7">
      <div class="act-card d-flex flex-column gap-5">
        <div data-testid="act-card-title" class="act-card__title">Акт № {{ act?.id }}</div>
        <fnip-divider />
        <fnip-labeled-content
          label="Статус"
          data-testid="act-card-status"
          [content]="statusTemplate"
          [contentContext]="getActState | executeWith: act?.state"
          [inline]="true"
        ></fnip-labeled-content>
        <fnip-labeled-content
          label="Количество досье"
          data-testid="act-card-dossiers-count"
          [content]="act?.dossiers?.length"
          [inline]="true"
        />
        <fnip-labeled-content
          label="Дата передачи на сопровождение"
          data-testid="act-card-state-date"
          [content]="act?.stateSetting | date"
          [inline]="true"
        />
      </div>
      <div class="d-flex flex-column gap-3">
        <fnip-button
          *ngIf="isActionVisible | executeWith: act?.state : act?.type : (archiveProduct$ | async)"
          appearance="primary"
          btnSize="l"
          data-testid="act-action-button"
          class="w-100"
          [isDisabled]="isActionDisabled"
          [label]="getActionLabel | executeWith: act?.state : act?.type"
          [actionCallback$]="getAction | executeWith: act?.state"
        />
        <fnip-button
          appearance="secondary-light-grey"
          btnSize="l"
          data-testid="act-back-button"
          icon="assets/icons/back.svg"
          label="К списку актов"
          class="w-100"
          [btnRouterLink]="['../']"
        />
      </div>
    </div>
    <div class="d-flex flex-column gap-7 mr-10 w-100">
      <div *ngIf="isFilling | executeWith: act?.state" class="d-flex gap-3">
        <ng-container *ngIf="isFillEditActionsVisible | executeWith: (archiveProduct$ | async) : act?.type">
          <fnip-button
            data-testid="act-remove-dossier-button"
            appearance="secondary-light-grey"
            btnSize="l"
            label="Изъять из акта"
            class="w-200px"
            [actionCallback$]="openRemoveDialog"
            [isDisabled]="!dossiers.length"
          />
          <fnip-button
            data-testid="act-add-dossier-button"
            appearance="secondary-light-grey"
            btnSize="l"
            label="Добавить"
            class="w-200px"
            [actionCallback$]="openAddDialog"
          />
        </ng-container>

        <fnip-button
          data-testid="act-inventory-button"
          appearance="secondary-light-grey"
          btnSize="l"
          label="Опись досье"
          class="w-200px"
          [actionCallback$]="getActInventory"
        />
      </div>
      <fnip-grid
        *ngIf="!(isUnderReview | executeWith: act?.state)"
        [gridOptions]="gridOptions"
        [rowData]="dossiers"
        [hasModeSelection]="false"
      />
      <fnip-grid
        *ngIf="isUnderReview | executeWith: act?.state"
        [gridOptions]="underReviewGridOptions"
        [rowData]="dossiers"
        [hasModeSelection]="false"
      />
    </div>
  </div>
</fnip-request-wrapper>

<ng-template #statusTemplate let-status>
  <div class="act-status">{{ status }}</div>
</ng-template>
