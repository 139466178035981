/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ActState = 'Filling' | 'Transferred' | 'UnderReview' | 'Accepted' | 'Withdrawn';

export const ActState = {
    Filling: 'Filling' as ActState,
    Transferred: 'Transferred' as ActState,
    UnderReview: 'UnderReview' as ActState,
    Accepted: 'Accepted' as ActState,
    Withdrawn: 'Withdrawn' as ActState
};

