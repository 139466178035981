import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { calculateSelectAllValue, NotificationService, Nullable, PATCH_OPTIONS, saveFile } from '@lib-utils';
import {
  DocumentArchiveApiService,
  DocumentGroup,
  DocumentTypeDto,
  FilteredDossiersArchiveApiService,
  FilteredDossiersByFileArchiveApiService,
} from '@lib-archive/api';
import { DocumentGroupTypeMap } from '@lib-archive/api-middleware';
import { DossierSearchStrategy, getDossierFilters } from '@lib-archive/utils';
import { DossierSearchStateService } from '../../../../services';
import { Option } from '../../../../widgets/select-options-island';

@Component({
  selector: 'fnip-documents-download',
  templateUrl: './documents-download.component.html',
  styleUrls: ['./documents-download.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsDownloadComponent {
  actionForm = new FormGroup({
    selectAll: new FormControl<Nullable<boolean>>(false),
    eSign: new FormControl<boolean>({ value: false, disabled: true }),
    allVersions: new FormControl<boolean>({ value: false, disabled: true }),
  });

  columns = [
    [DocumentGroup.Borrower],
    [DocumentGroup.MortgageLoan],
    [DocumentGroup.AutoLoan],
    [DocumentGroup.BankingProgram, DocumentGroup.LoanSupport, DocumentGroup.RiskDivision],
  ];

  getDocumentTypes$: Observable<Option<DocumentTypeDto>[]> = this.documentArchiveApiService
    .apiDocumentTypesGet({})
    .pipe(
      map((resp) => resp.data ?? []),
      map((docTypes) =>
        docTypes.map((docType) => ({
          label: docType.name ?? '',
          value: docType,
          control: new FormControl<Nullable<boolean>>(false),
        })),
      ),
    );

  dossierCount = String(this.dossierSearchStateService.dossierCount ?? '');

  constructor(
    private readonly documentArchiveApiService: DocumentArchiveApiService,
    private readonly dossierSearchStateService: DossierSearchStateService,
    private readonly filteredDossierService: FilteredDossiersArchiveApiService,
    private readonly filteredDossierFileService: FilteredDossiersByFileArchiveApiService,
    private readonly notificationService: NotificationService,
  ) {}

  filterOptionsByDocumentGroup = (documentGroups: DocumentGroup, options: Option<DocumentTypeDto>[] = []) =>
    options.filter((option) => option.value?.group === documentGroups);

  getGroupTitle = (documentGroup: DocumentGroup) => DocumentGroupTypeMap.get(documentGroup);

  selectAllChange = (options: Option<DocumentTypeDto>[] = [], value: Nullable<boolean>) =>
    options.forEach((o) => o.control.setValue(value));

  optionValueChange = (options: Option<DocumentTypeDto>[] = []) =>
    this.actionForm.controls.selectAll.setValue(
      calculateSelectAllValue(options.map((o) => o.control.value)),
      PATCH_OPTIONS,
    );

  downloadsDocuments$ = (options: Option<DocumentTypeDto>[]) => () => {
    const documentTypeIds = options
      .filter((o) => o.control.value)
      .map((o) => o.value.id)
      .filter(Boolean) as number[];

    const downloadDocsRq$ =
      this.dossierSearchStateService.searchStrategy === DossierSearchStrategy.FromExcel
        ? this.filteredDossierFileService.apiFilteredDossiersByFileDownloadFilesPost(
            {
              filterFile: this.dossierSearchStateService.registryFile?.file ?? undefined,
              documentTypeIds: documentTypeIds,
            },
            'response',
          )
        : this.filteredDossierService.apiFilteredDossiersDownloadFilesPost(
            {
              downloadFilesByFilteredDossiersRequest: {
                filter: getDossierFilters(this.dossierSearchStateService.formValue),
                documentTypeIds,
              },
            },
            'response',
          );

    return downloadDocsRq$.pipe(tap(this.notificationService.onError('Ошибка загрузки')), tap(saveFile));
  };
}
