import { ColDef, GridOptions } from '@ag-grid-community/core';
import { Observable } from 'rxjs';
import { Nullable } from '@lib-utils';
import {
  CustomActionAppearance,
  dateCellDef,
  exactWidth,
  getActionCellDef,
  reactiveButtonCheckboxCellDef,
  reactiveCheckboxCellDef,
  tagCellDef,
} from '@lib-widgets/grid';
import { CreditDossierDto } from '@lib-archive/api';
import {
  DefaultTagClass,
  DossierStateTypeRecord,
  LoanAgreementStateClassMap,
  LoanAgreementStateTypeMap,
  PhysicalTypeMap,
} from '@lib-archive/api-middleware';
import { hasPermissionCellDefs } from '@lib-mortgage/widgets/grid';
import { canDeleteDossier } from './can-delete-dossier';
import { CreditDossierWithSelect } from './credit-dossier-with-select';

export const GET_DOSSIER_WITH_SELECT_GRID_OPTIONS = (config: {
  basePath: string;
  showSelect?: boolean;
  toggleDosserSelection?: (dossier: CreditDossierDto, value: boolean) => void;
}): GridOptions<CreditDossierWithSelect> => ({
  getRowId: (params) => params.data.dossier.id?.toString() ?? '',
  defaultColDef: {
    initialWidth: 140,
  },
  columnTypes: {
    selected: {
      field: 'selected',
      suppressCellFlash: true,
      headerName: '',
      ...exactWidth(55),
      ...reactiveCheckboxCellDef({
        getFieldId: ({ data }) => `dossierSelected-${data?.dossier?.id}`,
        onValueChange: (params, value) => config.toggleDosserSelection?.(params.data.dossier, value),
      }),
    },
  },
  columnDefs: [
    ...(config.showSelect ? [{ type: 'selected' }] : []),
    {
      field: 'dossier.loanApplicationNumber',
      headerName: '№ Заявки',
      initialWidth: 100,
    },
    {
      field: 'dossier.loanAgreementNumber',
      headerName: '№ КД',
      initialWidth: 160,
    },
    {
      headerName: '№ акта',
      ...getActionCellDef<CreditDossierWithSelect>({
        appearance: CustomActionAppearance.Link,
        getActionLabel: (data) => data?.dossier.actId?.toString(),
        getLink: (data) => [config.basePath, 'acts', data?.dossier.actId!],
      }),
      initialWidth: 100,
    },
    {
      headerName: '№ короба',
      ...getActionCellDef<CreditDossierWithSelect>({
        appearance: CustomActionAppearance.Link,
        getActionLabel: (data) => data?.dossier.box?.boxNumber,
        getLink: (data) => [config.basePath, 'boxes', data?.dossier.box?.id!],
      }),
      initialWidth: 100,
    },
    {
      colId: 'dossier.name',
      headerName: 'ФИО клиента',
      minWidth: 200,
      initialFlex: 1,
      valueGetter: (params) => params.data?.dossier.borrowers?.[0]?.name,
    },
    {
      field: 'dossier.issueLoanDate',
      headerName: 'Дата выдачи',
      ...dateCellDef,
    },
    {
      field: 'dossier.physicalType',
      headerName: 'Тип',
      refData: PhysicalTypeMap,
    },
    {
      field: 'dossier.state',
      headerName: 'Статус',
      minWidth: 200,
      initialFlex: 1,
      maxWidth: 300,
      ...tagCellDef({
        labelMap: DossierStateTypeRecord,
        getDefaultTagClass: () => DefaultTagClass,
      }),
    },
    {
      field: 'dossier.stateSetting',
      headerName: 'Дата статуса',
      ...dateCellDef,
    },
    {
      field: 'dossier.loanAgreementState',
      headerName: 'Состояние',
      ...tagCellDef({
        labelMap: Object.fromEntries(LoanAgreementStateTypeMap),
        getDefaultTagClass: ({ value }) => LoanAgreementStateClassMap[value],
      }),
    },
    {
      headerName: '',
      initialPinned: 'right',
      lockPinned: true,
      ...getActionCellDef<CreditDossierWithSelect>({
        icon: 'tuiIconEye',
        getLink: (data) => [config.basePath, 'dossiers', data?.dossier.id!],
      }),
    },
  ],
});

export const UNDER_REVIEW_GRID_OPTIONS = (basePath: string): GridOptions<CreditDossierWithSelect> => ({
  getRowId: (params) => params.data.dossier.id?.toString() ?? '',
  columnDefs: [
    {
      colId: 'dossierNumbers',
      headerName: 'Номера',
      valueFormatter: (params) =>
        `ЗК: ${params.data?.dossier.loanApplicationNumber}\nКД: ${params.data?.dossier.loanAgreementNumber}`,
      cellStyle: { 'white-space': 'pre' },
      autoHeight: true,
    },
    {
      colId: 'dossier.name',
      headerName: 'ФИО клиента',
      minWidth: 200,
      valueGetter: (params) => params.data?.dossier.borrowers?.[0]?.name,
      autoHeight: true,
      wrapText: true,
    },
    {
      field: 'dossier.mortgageLendingCenterId',
      headerName: 'ЦИК',
      minWidth: 200,
      initialFlex: 1,
      valueGetter: (params) => params.data?.dossier.cftDepartment,
    },
    {
      field: 'dossier.state',
      headerName: 'Статус',
      minWidth: 200,
      ...tagCellDef({
        labelMap: DossierStateTypeRecord,
        getDefaultTagClass: () => DefaultTagClass,
      }),
    },
    {
      field: 'selected',
      colId: 'actions',
      headerName: '',
      initialPinned: 'right',
      lockPinned: true,
      ...reactiveButtonCheckboxCellDef({
        getFieldId: ({ data }) => `dossierSelected-${data?.dossier?.id}`,
        onValueChange: (params) => params.context.setIsActionDisabled(),
      }),
    },
    {
      colId: 'actions',
      headerName: '',
      initialPinned: 'right',
      lockPinned: true,
      ...getActionCellDef<CreditDossierWithSelect>({
        icon: 'assets/icons/book.svg',
        getLink: (data) => [basePath, 'dossiers', data?.dossier?.id!],
        linkTarget: '_blank',
      }),
    },
  ],
});

export const GET_DOSSIERS_GRID_OPTIONS = (config: {
  basePath: string;
  hasRowSelection?: boolean;
  hasSorting?: boolean;
  deleteDossier$?: (id: Nullable<number>) => () => Observable<unknown>;
}): GridOptions<CreditDossierDto> => ({
  defaultColDef: {
    initialWidth: 140,
  },
  rowSelection: config.hasRowSelection ? 'multiple' : undefined,
  columnDefs: [
    ...(config.hasRowSelection
      ? [
          {
            valueGetter: () => '',
            headerName: '',
            field: 'id',
            colId: 'select',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            initialPinned: 'left',
            lockPinned: true,
            lockVisible: true,
            ...exactWidth(50),
          } satisfies ColDef<CreditDossierDto>,
        ]
      : []),
    {
      field: 'loanApplicationNumber',
      headerName: '№ Заявки',
      sortable: config.hasSorting,
      initialWidth: 100,
    },
    {
      field: 'loanAgreementNumber',
      headerName: '№ КД',
      sortable: config.hasSorting,
      initialWidth: 160,
    },
    {
      headerName: '№ акта',
      ...getActionCellDef<CreditDossierDto>({
        appearance: CustomActionAppearance.Link,
        getActionLabel: (data) => data?.actId?.toString(),
        getLink: (data) => [config.basePath, 'acts', data?.actId!],
      }),
      initialWidth: 100,
    },
    {
      headerName: '№ короба',
      ...getActionCellDef<CreditDossierDto>({
        appearance: CustomActionAppearance.Link,
        getActionLabel: (data) => data?.box?.boxNumber,
        getLink: (data) => [config.basePath, 'boxes', data?.box?.id!],
      }),
      initialWidth: 100,
    },
    {
      colId: 'name',
      headerName: 'ФИО клиента',
      minWidth: 200,
      initialFlex: 1,
      valueGetter: (params) => params.data?.borrowers?.[0]?.name,
    },
    {
      field: 'issueLoanDate',
      headerName: 'Дата выдачи',
      sortable: config.hasSorting,
      ...dateCellDef,
    },
    {
      field: 'cftDepartment',
      headerName: 'Регион учёта в ЦФТ',
      sortable: config.hasSorting,
      initialWidth: 160,
    },
    {
      field: 'physicalType',
      headerName: 'Тип',
      refData: PhysicalTypeMap,
    },
    {
      field: 'state',
      headerName: 'Статус',
      minWidth: 200,
      initialFlex: 1,
      sortable: config.hasSorting,
      maxWidth: 300,
      ...tagCellDef({
        labelMap: DossierStateTypeRecord,
        getDefaultTagClass: () => DefaultTagClass,
      }),
    },
    {
      field: 'stateSetting',
      headerName: 'Дата статуса',
      sortable: config.hasSorting,
      initialSort: config.hasSorting ? 'desc' : undefined,
      ...dateCellDef,
    },
    {
      field: 'loanAgreementState',
      headerName: 'Состояние',
      sortable: config.hasSorting,
      ...tagCellDef({
        labelMap: Object.fromEntries(LoanAgreementStateTypeMap),
        getDefaultTagClass: ({ value }) => LoanAgreementStateClassMap[value],
      }),
    },
    {
      headerName: '',
      initialPinned: 'right',
      lockPinned: true,
      ...getActionCellDef<CreditDossierDto>({
        icon: 'tuiIconEye',
        getLink: (data) => [config.basePath, 'dossiers', data?.id!],
      }),
    },
    ...(config?.deleteDossier$
      ? hasPermissionCellDefs<CreditDossierDto>('canDeleteManualDossier', {
          colId: 'deleteDossier',
          headerName: '',
          initialPinned: 'right',
          lockPinned: true,
          lockVisible: true,
          ...getActionCellDef<CreditDossierDto>({
            icon: 'tuiIconTrash',
            getAction$: (data) => (canDeleteDossier(data) ? config?.deleteDossier$?.(data?.id) : null),
          }),
        })
      : []),
  ],
});
