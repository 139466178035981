<div class="d-flex mb-4 flex-column gap-2">
  <div class="input-wrapper">
    <h4 class="my-1">Дата выдачи</h4>
    <div class="d-flex gap-2">
      <fnip-reactive-input-date
        fieldId="issueLoanDateFrom"
        label="От"
        class="w-50"
        size="l"
        [control]="filtersForm.controls.issueLoanDateFrom"
      />
      <fnip-reactive-input-date
        fieldId="issueLoanDateTo"
        label="До"
        class="w-50"
        size="l"
        [control]="filtersForm.controls.issueLoanDateTo"
      />
    </div>
  </div>

  <fnip-reactive-checkbox
    fieldId="withoutAct"
    label="Без акта"
    size="l"
    class="w-fit-content"
    [control]="filtersForm.controls.withoutAct"
    (valueChange)="$event === false && filtersForm.controls.actId?.reset()"
  />

  <fnip-reactive-input-number
    *ngIf="filtersForm.controls.withoutAct?.value !== true"
    label="Номер акта"
    fieldId="actId"
    size="l"
    [control]="filtersForm.controls.actId"
  />

  <fnip-reactive-select
    label="Состояние"
    fieldId="loanAgreementState"
    size="l"
    [control]="filtersForm.controls.loanAgreementState"
    [options]="loanAgreementStateOptions"
  />

  <fnip-reactive-select
    label="Страхование"
    fieldId="lifeInsuranceTypes"
    size="l"
    [control]="filtersForm.controls.lifeInsuranceTypes"
    [options]="lifeInsuranceTypeOptions"
  />

  <fnip-reactive-input
    label="Регион учёта в ЦФТ"
    fieldId="cftDepartment"
    size="l"
    [control]="filtersForm.controls.cftDepartment"
  />

  <fnip-reactive-input
    label="Офис проведения сделки"
    fieldId="dealDepartmentName"
    size="l"
    hint="Наименование подразделения, отделение выдачи"
    [control]="filtersForm.controls.dealDepartmentName"
  />
</div>

<div class="d-flex">
  <fnip-button
    btnSize="l"
    class="ml-auto"
    appearance="primary"
    label="Применить фильтры"
    [actionCallback$]="applyFilters"
  />
</div>
