import { DossierState } from '@lib-archive/api';

/**
 * Статусы досье, на которых возможна подгрузка документов сотрудником ЦИК/Куратором
 */
export const MLC_STATES_FOR_DOC_UPLOAD = [
  DossierState.Draft,
  DossierState.Status2,
  DossierState.InMlc,
  DossierState.InCarShowroom,
  DossierState.UnderRework,
  DossierState.AcceptedByTheCurator,
];

/**
 * Статусы ЦИК
 */
export const MLC_STATES = [
  DossierState.InMlc,
  DossierState.Completed,
  DossierState.IncludedInTheAct,
  DossierState.UnderRework,
  DossierState.ReworkCompleted,
];

/**
 * Статусы куратора
 */
export const CURATOR_STATES = [
  DossierState.InCarShowroom,
  DossierState.IncludedInTheAct,
  DossierState.UnderRework,
  DossierState.ReworkCompleted,
];

/**
 * Статусы Сопровождения
 */
export const SUPPORT_STATES = [
  DossierState.SentSupport,
  DossierState.UnderReview,
  DossierState.AcceptedForSupport,
  DossierState.ReworkSent,
  DossierState.OffsiteStorage,
  DossierState.NeedImprovement,
  DossierState.SentRework,
];
