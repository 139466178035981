export * from './act.service';
import { ActArchiveApiService } from './act.service';
export * from './box.service';
import { BoxArchiveApiService } from './box.service';
export * from './debug.service';
import { DebugArchiveApiService } from './debug.service';
export * from './directory.service';
import { DirectoryArchiveApiService } from './directory.service';
export * from './document.service';
import { DocumentArchiveApiService } from './document.service';
export * from './documentTemplate.service';
import { DocumentTemplateArchiveApiService } from './documentTemplate.service';
export * from './dossier.service';
import { DossierArchiveApiService } from './dossier.service';
export * from './dossierUpload.service';
import { DossierUploadArchiveApiService } from './dossierUpload.service';
export * from './filteredDossiers.service';
import { FilteredDossiersArchiveApiService } from './filteredDossiers.service';
export * from './filteredDossiersByFile.service';
import { FilteredDossiersByFileArchiveApiService } from './filteredDossiersByFile.service';
export * from './product.service';
import { ProductArchiveApiService } from './product.service';
export * from './reports.service';
import { ReportsArchiveApiService } from './reports.service';
export * from './testDataGenerator.service';
import { TestDataGeneratorArchiveApiService } from './testDataGenerator.service';
export * from './user.service';
import { UserArchiveApiService } from './user.service';
export const APIS = [ActArchiveApiService, BoxArchiveApiService, DebugArchiveApiService, DirectoryArchiveApiService, DocumentArchiveApiService, DocumentTemplateArchiveApiService, DossierArchiveApiService, DossierUploadArchiveApiService, FilteredDossiersArchiveApiService, FilteredDossiersByFileArchiveApiService, ProductArchiveApiService, ReportsArchiveApiService, TestDataGeneratorArchiveApiService, UserArchiveApiService];
