import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import isNil from 'lodash-es/isNil';
import { filter, finalize, map, switchMap, tap } from 'rxjs';
import { getRouteParams$, NotificationService, Nullable, saveFile, toNullableNumber } from '@lib-utils';
import { DialogService, injectDialogService } from '@lib-widgets/dialog';
import { RequestWrapperComponent } from '@lib-widgets/request-wrapper';
import { BoxArchiveApiService, BoxDto, BoxState, CreditDossierDto, DossierArchiveApiService } from '@lib-archive/api';
import { BoxStateMap } from '@lib-archive/api-middleware';
import { SUPPORT_ROLES } from '@lib-archive/utils';
import { GET_DOSSIERS_GRID_OPTIONS, getBasePath } from '@lib-archive/utils';
import { AddRemoveDossierModalComponent, GetDossiersConfig } from '../../../../widgets/add-remove-dossier-modal';
import { BoxActionLabelMap } from './box.maps';
import { BoxFormDialogComponent } from './components/box-form-dialog';

@Component({
  selector: 'fnip-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class BoxComponent {
  @ViewChild(RequestWrapperComponent) boxWrapper?: RequestWrapperComponent;

  gridOptions = GET_DOSSIERS_GRID_OPTIONS({ basePath: getBasePath() });

  box: Nullable<BoxDto>;

  BoxState = BoxState;

  boxNumberControl = new FormControl<Nullable<string>>(null, Validators.maxLength(32));

  dossiers: CreditDossierDto[] = [];

  isActionDisabled = false;

  isEditBoxNumber = false;

  request$ = getRouteParams$<{ id: Nullable<string> }>().pipe(
    map(({ id }) => toNullableNumber(id)),
    filter((id): id is number => !isNil(id)),
    switchMap((boxId) => this.boxArchiveApiService.apiBoxBoxIdGet({ boxId })),
    map(({ data }) => data),
    tap((box) => this.setData(box)),
  );

  readonly dialogService = injectDialogService();

  constructor(
    private readonly notificationService: NotificationService,
    private readonly boxArchiveApiService: BoxArchiveApiService,
    private readonly dossierArchiveApiService: DossierArchiveApiService,
  ) {}

  SUPPORT_ROLES = SUPPORT_ROLES;

  toggleEditMode = () => {
    this.isEditBoxNumber = !this.isEditBoxNumber;
  };

  saveBoxNumber$ = () => {
    if (!this.box?.id) return null;
    return this.boxArchiveApiService
      .apiBoxBoxIdPost({ boxId: this.box.id, boxNumberDto: { boxNumber: this.boxNumberControl.value } })
      .pipe(
        tap(() => this.boxWrapper?.reload()),
        tap(this.notificationService.onError('Ошибка при сохранении номера короба')),
        finalize(() => (this.isEditBoxNumber = false)),
      );
  };

  setTransferred$ = () =>
    this.dialogService
      .open<Nullable<boolean>>(BoxFormDialogComponent, {
        hostOptions: {
          size: 'auto',
        },
        contextData: {
          id: this.box?.id,
          boxNumber: this.box?.boxNumber,
        },
      })
      .pipe(
        filter(Boolean),
        switchMap(() => this.boxArchiveApiService.apiBoxBoxIdStateTransferredPost({ boxId: this.box?.id ?? 0 })),
        tap(() => this.boxWrapper?.reload()),
        tap(this.notificationService.onSuccess('Короб отправлен')),
        tap(this.notificationService.onError()),
      );

  setWithdrawn$ = () =>
    this.boxArchiveApiService.apiBoxBoxIdStateWithdrawnPost({ boxId: this.box?.id ?? 0 }).pipe(
      tap(this.notificationService.onSuccess('Короб изъят')),
      tap(this.notificationService.onError()),
      tap(() => this.boxWrapper?.reload()),
    );

  setTransferredBack$ = () =>
    this.boxArchiveApiService.apiBoxBoxIdStateTransferredPost({ boxId: this.box?.id ?? 0 }).pipe(
      tap(this.notificationService.onSuccess('Короб отправлен обратно')),
      tap(this.notificationService.onError()),
      tap(() => this.boxWrapper?.reload()),
    );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  ActionMap = new Map([
    [BoxState.Filling, this.setTransferred$],
    [BoxState.OffsiteStorage, this.setWithdrawn$],
    [BoxState.Withdrawn, this.setTransferredBack$],
  ]);

  isFillingActionDisabled = () => !this.dossiers.length;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  ActionDisabledMap = new Map([[BoxState.Filling, this.isFillingActionDisabled]]);

  getAction = (state: Nullable<BoxState>) => state && this.ActionMap.get(state);

  getActionLabel = (state: Nullable<BoxState>) => (state && BoxActionLabelMap.get(state)) ?? '';

  isNotInStorage = (type: Nullable<BoxState>) => type !== BoxState.OffsiteStorage;

  getBoxState = (type: Nullable<BoxState>) => type && BoxStateMap.get(type);

  setIsActionDisabled = () => {
    this.isActionDisabled = (this.ActionDisabledMap.get(this.box?.state ?? BoxState.Filling) ?? (() => false))();
  };

  setData(box: Nullable<BoxDto>) {
    this.box = box;
    this.boxNumberControl.setValue(box?.boxNumber);
    this.dossiers = this.box?.dossiers ?? [];
    this.setIsActionDisabled();
  }

  getBoxInventory = () =>
    this.boxArchiveApiService.apiBoxBoxIdInventoryGet({ boxId: this.box?.id ?? 0 }, 'response').pipe(tap(saveFile));

  openRemoveDialog = () =>
    this.dialogService
      .open<Nullable<boolean>>(AddRemoveDossierModalComponent, {
        hostOptions: { size: 'auto' },
        contextData: {
          dossiers: this.box?.dossiers,
          removeDossiersRq$: (ids: Nullable<number>[]) => {
            if (!ids.length) return null;
            return this.dossierArchiveApiService.apiDossierDossierIdsBoxPost({ dossierIds: ids.join(',') });
          },
        },
      })
      .pipe(tap((result) => result && this.boxWrapper?.reload()));

  openAddDialog = () =>
    this.dialogService
      .open<Nullable<boolean>>(AddRemoveDossierModalComponent, {
        hostOptions: { size: 'auto' },
        contextData: {
          getDossiersRq$: (config: GetDossiersConfig) =>
            this.boxArchiveApiService.apiBoxBoxIdDossiersAvailablePost({
              boxId: this.box?.id!,
              pageNumber: config.page,
              perPage: config.perPage,
              text: config.filterText!,
            }),
          scanDossierRq$: (qrCode: string) =>
            this.boxArchiveApiService
              .apiBoxBoxIdDossierByQrPost({ qrCode, boxId: this.box?.id! })
              .pipe(map((res) => res.data)),
          addDossierRq$: (dossierId: Nullable<number>) => {
            if (!dossierId || !this.box?.id) return null;
            return this.dossierArchiveApiService.apiDossierDossierIdBoxBoxIdPost({ dossierId, boxId: this.box.id });
          },
        },
      })
      .pipe(tap((result) => result && this.boxWrapper?.reload()));
}
