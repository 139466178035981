import { FromGeneratedEnum } from '@lib-utils';
import {
  ActState,
  ActType,
  BoxState,
  ClientType,
  CreditPurpose,
  DocumentCheckState,
  DocumentGroup,
  DossierRole,
  DossierState,
  InsuranceProductType,
  LoanAgreementState,
  NonStandardType,
  PaymentType,
  PhysicalType,
  ProductCode,
  ProofOfIncome,
  RegistrationType,
  ReportBuilderTaskStatus,
  ReportType,
} from '@lib-archive/api';

export const DossierStateTypeMap = new Map([
  [DossierState.Draft, 'Черновик'],
  [DossierState.Status2, 'Статус #2'],
  [DossierState.InMlc, 'В ЦИК'],
  [DossierState.InCarShowroom, 'В автосалоне'],
  [DossierState.Completed, 'Укомплектовано'],
  [DossierState.IncludedInTheAct, 'Включен в акт'],
  [DossierState.UnderRework, 'На доработке'],
  [DossierState.ReworkCompleted, 'Доработка выполнена'],
  [DossierState.ReworkSent, 'Доработка отправлена'],
  [DossierState.SentSupport, 'Отправлено на сопровождение'],
  [DossierState.UnderReview, 'На проверке'],
  [DossierState.AcceptedForSupport, 'Принято в сопровождение'],
  [DossierState.OffsiteStorage, 'Внеофисное хранение'],
  [DossierState.Lost, 'Утеряно'],
  [DossierState.WithdrawnOnDemand, 'Изъято по требованию'],
  [DossierState.Sold, 'Продан'],
  [DossierState.IssuedBeforeImplementation, 'Выдано до внедрения ЭД'],
  [DossierState.AcceptedByTheCurator, 'Принято куратором'],
  [DossierState.NeedImprovement, 'Требуются улучшения'],
]);

export const DossierStateTypeRecord = Object.fromEntries(DossierStateTypeMap) as FromGeneratedEnum<DossierState>;

export const LoanAgreementStateTypeMap = new Map([
  [LoanAgreementState.Issued, 'Выдан'],
  [LoanAgreementState.Expired, 'Просрочен'],
  [LoanAgreementState.IncludedInTheAct, 'Включен в акт'],
  [LoanAgreementState.Closed, 'Закрыт'],
  [LoanAgreementState.Operational, 'Работает'],
  [LoanAgreementState.Sold, 'Продан'],
  [LoanAgreementState.Securitized, 'Секьюритизирован'],
]);

export const ProofOfIncomeTypeMap = new Map([
  [ProofOfIncome.IncomeTaxRate, '2-НДФЛ'],
  [ProofOfIncome.PensionFundReference, 'Выписка из ПФР'],
  [ProofOfIncome.TaxDeclaration, 'Налоговая декларация'],
  [ProofOfIncome.PensionCertificate, 'Справка о размере пенсии'],
  [ProofOfIncome.BankFormReference, 'Справка по форме банка'],
  [ProofOfIncome.HomesteadBookStatement, 'Выписка из похозяйственной книги'],
  [ProofOfIncome.NoProof, 'Без подтверждения'],
  [ProofOfIncome.BusinessNoProof, 'Бизнес - без подтверждения'],
  [ProofOfIncome.EmploymentNoProof, 'Найм - без подтверждения'],
  [ProofOfIncome.ProfessionalIncomeTaxCertificate, 'Справка о профдоходе (самозанятые)'],
]);

export const ClientTypeMap: Record<ClientType, string> = {
  Borrower: 'Заемщик',
  Coborrower: 'Созаемщик',
  Guarantor: 'Поручитель',
};

export const DossierRoleMap: FromGeneratedEnum<DossierRole> = {
  [DossierRole.AcceptedForSupport]: 'Сопровождение',
  [DossierRole.Agent]: 'Агент',
  [DossierRole.ApplicationCreator]: 'Создал заявку',
  [DossierRole.Curator]: 'Куратор',
  [DossierRole.DealMaker]: 'Провел сделку',
  [DossierRole.LoanAgreementFirstVersionLoader]: 'Подгрузил 1-ю версию КД',
  [DossierRole.Responsible]: 'Ответственный за досье',
  [DossierRole.VerificationInitiator]: 'Отправил на верификацию',
};

export const AUTO_ROLES = [DossierRole.Agent, DossierRole.Curator, DossierRole.Responsible];

export const CreditPurposeMap: FromGeneratedEnum<CreditPurpose> = {
  [CreditPurpose.PrimaryMarket]: 'Новостройка',
  [CreditPurpose.SecondaryMarket]: 'Готовое жильё',
};

export const DocumentSubtitleMap: Record<ClientType, string> = {
  Borrower: 'Документы заемщика',
  Coborrower: 'Документы созаемщика',
  Guarantor: 'Документы поручителя',
};

export const PaymentTypeMap = new Map([
  [PaymentType.CashMoneyToSeller, 'Наличными продавцу'],
  [PaymentType.MoneyTransferToSellerFromAb, 'Перевод денег на счет продавца со счета в АБ'],
  [PaymentType.MoneyTransferToSellerNotFromAb, 'Перевод денег на счет продавца не со счета в АБ'],
  [PaymentType.InclusionToLetterOfCredit, 'Включение в аккредитив'],
  [PaymentType.IndividualBankSafe, 'ИБС'],
  [PaymentType.StateSubsidyIncludingMaternalCapital, 'Гос.субсидия(включая средства Материнского капитала)'],
  [PaymentType.UsingANominalAccount, 'На номинальный счёт'],
]);

export const DocumentGroupTypeMap = new Map([
  [DocumentGroup.Borrower, 'Документы заемщика'],
  [DocumentGroup.MortgageLoan, 'Документы по ипотечному кредиту'],
  [DocumentGroup.BankingProgram, 'Документы банковской программы'],
  [DocumentGroup.LoanSupport, 'Документы сопровождения кредита'],
  [DocumentGroup.RiskDivision, 'Документы подразделения риски'],
  [DocumentGroup.AutoLoan, 'Документы по автокредиту'],
  [DocumentGroup.AutoDeal, 'Документы по ТС и автосалона по сделке'],
  [DocumentGroup.BorrowerQuestionnaire, 'Анкета заемщика'],
]);

export const DocumentCheckStateMap = new Map([
  [DocumentCheckState.NoCheck, 'Не проверен'],
  [DocumentCheckState.UnderReview, 'На проверке'],
  [DocumentCheckState.HasErrors, 'С ошибками'],
  [DocumentCheckState.NoErrors, 'Без ошибок'],
  [DocumentCheckState.ReadyToSend, 'Готов к отправке'],
]);

export const ActStateMap = new Map([
  [ActState.Filling, 'Наполнение'],
  [ActState.Transferred, 'Передано'],
  [ActState.UnderReview, 'На проверке'],
  [ActState.Accepted, 'Принято'],
  [ActState.Withdrawn, 'Изъят'],
]);

export const ActStateRecord = Object.fromEntries(ActStateMap) as FromGeneratedEnum<ActState>;

export const ActTypeMap = new Map<ActType, string>([
  [ActType.Direct, 'На сопровождение'],
  [ActType.Reverse, 'На доработку'],
]);

export const BoxStateMap = new Map([
  [BoxState.Filling, 'Наполнение'],
  [BoxState.OffsiteStorage, 'Внеофисное хранение'],
  [BoxState.Withdrawn, 'Изъят'],
]);

export const BoxStateRecord = Object.fromEntries(BoxStateMap) as FromGeneratedEnum<BoxState>;

export const InsuranceProductTypeMap = new Map([
  [InsuranceProductType.Ass, 'АСС'],
  [InsuranceProductType.Kss, 'КСС'],
  [InsuranceProductType.KssPlus, 'КСС+'],
]);

export const ReportTypeMap: Record<ReportType, string> = {
  Credits: 'Кредиты',
  Errors: 'Ошибки',
  ErrorsArchive: 'Ошибки архив',
};

export const ReportBuilderTaskStatusMap = new Map<ReportBuilderTaskStatus, string>([
  [ReportBuilderTaskStatus.New, 'Новая задача'],
  [ReportBuilderTaskStatus.InProgres, 'Генерация отчета'],
  [ReportBuilderTaskStatus.Done, 'Сформирован'],
  [ReportBuilderTaskStatus.Fail, 'Ошибка'],
]);

export const ReportBuilderTaskStatusRecord = Object.fromEntries(ReportBuilderTaskStatusMap) as Record<
  ReportBuilderTaskStatus,
  string
>;

export const PhysicalTypeMap: FromGeneratedEnum<PhysicalType> = {
  [PhysicalType.Paper]: 'Бумажное досье',
  [PhysicalType.PartPaper]: 'Частично бумажное досье',
  [PhysicalType.Digital]: 'Электронное досье',
};

export const RegistrationTypeMap: FromGeneratedEnum<RegistrationType> = {
  [RegistrationType.EcpPlusPaperElectricRegistrationPlusPaperContract]: 'Электронная регистрация + бумажная закладная',
  [RegistrationType.EcpPlusPaperElectricRegistrationPlusElectricContract]:
    'Электронная регистрация + электронная закладная',
  [RegistrationType.KdPlusDduDkp]: 'КД бумага + ДДУ/ДКП ЭЦП + бумажная закладная',
  [RegistrationType.StandartRegistration]: 'Стандартная регистрация',
  [RegistrationType.Kd]: 'КД бумага',
};

export const NonStandardTypeMap: FromGeneratedEnum<NonStandardType> = {
  [NonStandardType.HouseWithLand]: 'Дом с земельным участком',
  [NonStandardType.Other]: 'Другое',
};

export const ProductCodeMap: FromGeneratedEnum<ProductCode> = {
  [ProductCode.Auto]: 'Автокредиты',
  [ProductCode.Mortgage]: 'Ипотечные кредиты',
};
