/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PageCommand } from './pageCommand';
import { SortFieldRequest } from './sortFieldRequest';
import { DossiersFilterForReports } from './dossiersFilterForReports';


export interface DossiersFilterForReportsPageFilterCommand { 
    page?: PageCommand;
    filter?: DossiersFilterForReports;
    sort?: SortFieldRequest;
}

