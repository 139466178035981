import { FormControl } from '@angular/forms';
import { Nullable } from '@lib-utils';
import { CreditDossierDto } from '@lib-archive/api';

export interface CreditDossierWithSelect {
  dossier: CreditDossierDto;
  selected: FormControl<Nullable<boolean>>;
}

export const creditDossierWithSelect = (
  dossier: CreditDossierDto,
  selected?: Nullable<boolean>,
): CreditDossierWithSelect => ({
  dossier,
  selected: new FormControl<Nullable<boolean>>(selected),
});
