import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DossiersRoutingModule } from './dossiers-routing.module';
import { DossiersComponent } from './dossiers.component';
import { DocumentModule } from './views/document';
import { DossierModule } from './views/dossier';
import { DossierNewComponent } from './views/dossier-new';

@NgModule({
  declarations: [DossiersComponent],
  imports: [CommonModule, DossiersRoutingModule, DossierNewComponent, DocumentModule, DossierModule],
  exports: [DossiersComponent],
})
export class DossiersModule {}
