/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReportBuilderTaskStatus = 'New' | 'InProgres' | 'Done' | 'Fail';

export const ReportBuilderTaskStatus = {
    New: 'New' as ReportBuilderTaskStatus,
    InProgres: 'InProgres' as ReportBuilderTaskStatus,
    Done: 'Done' as ReportBuilderTaskStatus,
    Fail: 'Fail' as ReportBuilderTaskStatus
};

