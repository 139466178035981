/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RegistrationType = 'StandartRegistration' | 'KdPlusDduDkp' | 'EcpPlusPaperElectricRegistrationPlusPaperContract' | 'EcpPlusPaperElectricRegistrationPlusElectricContract' | 'Kd' | 'Undefined';

export const RegistrationType = {
    StandartRegistration: 'StandartRegistration' as RegistrationType,
    KdPlusDduDkp: 'KdPlusDduDkp' as RegistrationType,
    EcpPlusPaperElectricRegistrationPlusPaperContract: 'EcpPlusPaperElectricRegistrationPlusPaperContract' as RegistrationType,
    EcpPlusPaperElectricRegistrationPlusElectricContract: 'EcpPlusPaperElectricRegistrationPlusElectricContract' as RegistrationType,
    Kd: 'Kd' as RegistrationType,
    Undefined: 'Undefined' as RegistrationType
};

