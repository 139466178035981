import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiPaginationModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { FilterModule } from '@lib-widgets/filter';
import { GridModule } from '@lib-widgets/grid';
import { ListSidebarModule } from '@lib-widgets/menu';
import { ReactiveInputModule } from '@lib-widgets/reactive-fields';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { HasRolesPipe } from '@lib-mortgage/features/authorization';
import { BoxListComponent } from './box-list.component';

@NgModule({
  imports: [
    CommonModule,
    ListSidebarModule,
    ReactiveInputModule,
    ButtonModule,
    ExecuteWithPipeModule,
    RequestWrapperModule,
    GridModule,
    TuiPaginationModule,
    FilterModule,
    TuiLetModule,
    HasRolesPipe,
  ],
  declarations: [BoxListComponent],
  exports: [BoxListComponent],
})
export class BoxListModule {}
