import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiTagModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule, DividerModule } from '@lib-widgets/core';
import { ReactiveCheckboxModule } from '@lib-widgets/reactive-fields';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { DossierSearchCardComponent } from '../../../../widgets/dossier-search-card';
import { SelectOptionsIslandModule } from '../../../../widgets/select-options-island';
import { DocumentsDownloadComponent } from './documents-download.component';

@NgModule({
  imports: [
    CommonModule,
    TuiTagModule,
    ButtonModule,
    DividerModule,
    DossierSearchCardComponent,
    ReactiveCheckboxModule,
    ExecuteWithPipeModule,
    RequestWrapperModule,
    SelectOptionsIslandModule,
  ],
  declarations: [DocumentsDownloadComponent],
  exports: [DocumentsDownloadComponent],
})
export class DocumentsDownloadModule {}
