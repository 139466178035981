import { Injectable } from '@angular/core';
import { FileInfo, Nullable } from '@lib-utils';
import { FeDossiersFilterForReports } from '@lib-archive/api-middleware';
import { DossierSearchStrategy } from '@lib-archive/utils';

@Injectable({
  providedIn: 'root',
})
export class DossierSearchStateService {
  formValue: Nullable<FeDossiersFilterForReports>;

  searchStrategy: Nullable<DossierSearchStrategy>;

  registryFile: FileInfo | null = null;

  dossierCount = 0;
}
