import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { beToFeOptions, FormControlsOf, NotificationService, Nullable } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { injectDialogContext } from '@lib-widgets/dialog';
import { ReactiveInputModule, ReactiveSelectModule } from '@lib-widgets/reactive-fields';
import { BoxArchiveApiService, BoxNumberDto, DirectoryArchiveApiService } from '@lib-archive/api';

interface BoxFormDialogContext {
  id: number;
  boxNumber: Nullable<string>;
}

@Component({
  selector: 'fnip-box-form-dialog',
  templateUrl: './box-form-dialog.component.html',
  styleUrls: ['./box-form-dialog.component.scss'],
  imports: [ReactiveInputModule, ReactiveSelectModule, ButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class BoxFormDialogComponent {
  readonly dialogContext = injectDialogContext<Nullable<boolean>, BoxFormDialogContext>();

  form = new FormGroup<FormControlsOf<BoxNumberDto>>({
    boxNumber: new FormControl(this.dialogContext.data?.boxNumber, [Validators.required, Validators.maxLength(32)]),
    osg: new FormControl(null, [Validators.required]),
  });

  //TODO: https://tracker.yandex.ru/RARCHIVE-29 - Добавление параметра `productCode`
  osgOptions$ = this.directoryArchiveApiService
    .apiDirectoryOsgGet({ ids: '[]' })
    .pipe(map(({ data }) => beToFeOptions(data)));

  updateBox$ = () =>
    this.boxArchiveApiService
      .apiBoxBoxIdPost({ boxId: this.dialogContext.data?.id!, boxNumberDto: this.form.value })
      .pipe(
        tap(() => this.dialogContext.ref?.close(true)),
        tap(this.notificationService.onError()),
      );

  constructor(
    public readonly directoryArchiveApiService: DirectoryArchiveApiService,
    public readonly boxArchiveApiService: BoxArchiveApiService,
    private notificationService: NotificationService,
  ) {}
}
