import { GridApi, GridOptions } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import isNil from 'lodash-es/isNil';
import { EMPTY, filter, forkJoin, map, Observable, switchMap, tap } from 'rxjs';
import {
  calculateSelectAllValue,
  FormGroupOf,
  getRouteParams,
  getRouteParams$,
  NotificationService,
  Nullable,
  PATCH_OPTIONS,
  saveFile,
  toNullableNumber,
} from '@lib-utils';
import type { ButtonComponent } from '@lib-widgets/core';
import { ConfirmationDialogType, DialogService, injectDialogService } from '@lib-widgets/dialog';
import {
  getActionCellDef,
  GridGetDataCallback,
  reactiveCheckboxCellDef,
  ReactiveCheckboxHeaderComponent,
  tagCellDef,
  TagCellListValue,
  tagListCellDef,
} from '@lib-widgets/grid';
import { RequestWrapperComponent } from '@lib-widgets/request-wrapper';
import {
  BoxShortDto,
  BoxState,
  CreditDossierDto,
  CreditDossierDtoResponseData,
  CreditDossierManualDto,
  DirectoryArchiveApiService,
  DocumentArchiveApiService,
  DocumentCheckState,
  DocumentCompletenessCheckDto,
  DocumentDto,
  DocumentDtoArrayResponseData,
  DocumentTemplateArchiveApiService,
  DossierArchiveApiService,
  DossierState,
  DossierStateHistoryDto,
  ProductCode,
} from '@lib-archive/api';
import { DefaultTagClass, DocumentCheckStateClassMap, DocumentCheckStateMap } from '@lib-archive/api-middleware';
import {
  createDossierForm,
  CURATOR_STATES,
  DocumentCompletenessType,
  DocumentsGroup,
  DocumentWithForm,
  DOSSIER_HISTORY_GRID_OPTIONS,
  getBasePath,
  hasCuratorRoleCb,
  hasMlcRoleCb,
  hasSupportRoleCb,
  MLC_ROLES,
  MLC_STATES,
  MLC_STATES_FOR_DOC_UPLOAD,
  SUPPORT_ROLES,
  SUPPORT_STATES,
} from '@lib-archive/utils';
import { ArchiveProductService } from '../../../../services';
import { AddDocumentsToDossierDialogComponent } from './components/add-documents-to-dossier-dialog';
import { DossierTab } from './dossier.enums';
import {
  getDocumentErrorsCount,
  getDocumentsGroups,
  getTotalDossierErrorsCount,
  getTotalDossierUncheckedCount,
  getUncheckedCount,
  isAllGroupsCompleteness,
  mapCompletenessChecks,
} from './dossier.functions';
import { DossierTabMap, ReworkButtonLabelMap } from './dossier.maps';

interface ActionButtonData {
  isAvailable: (dossier: Nullable<CreditDossierDto>) => Nullable<boolean>;
  actionId: string;
  label?: string;
  getLabel?: (dossier: Nullable<CreditDossierDto>) => string;
  action$?: ButtonComponent['actionCallback$'];
  getAction$?: (dossier: Nullable<CreditDossierDto>) => ButtonComponent['actionCallback$'];
}

const GET_GRID_OPTIONS = (group: DocumentsGroup, context: DossierComponent): GridOptions<DocumentWithForm> => {
  const dossierUnderReview = context.isUnderReview(context.dossier?.state ?? DossierState.InMlc);
  return {
    context,
    getRowId: (params) => params.data.document.id?.toString() ?? '',
    rowSelection: 'multiple',
    defaultColDef: {
      initialWidth: 70,
    },
    tooltipShowDelay: 0,
    columnDefs: [
      {
        field: 'document.documentType.name',
        headerName: 'Название',
        minWidth: 200,
        initialFlex: 1,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: 'Бум',
        headerComponent: ReactiveCheckboxHeaderComponent,
        headerComponentParams: {
          control: group.form?.controls?.hasPaper,
          fieldId: `hasPaper-${group.title}`,
        },
        valueGetter: (params) => params.data?.form?.controls?.hasPaper,
        ...reactiveCheckboxCellDef({
          getFieldId: ({ data }) => `hasPaper-${data?.document?.id}`,
          onValueChange: () => context.checkDocumentChange(group.form, group.data, 'hasPaper'),
        }),
        initialWidth: 90,
      },
      {
        headerName: 'Эл',
        headerComponent: ReactiveCheckboxHeaderComponent,
        headerComponentParams: {
          control: group.form?.controls?.hasDigital,
          fieldId: `hasDigital-${group.title}`,
        },
        valueGetter: (params) => params?.data?.form?.controls?.hasDigital,
        ...reactiveCheckboxCellDef({
          getFieldId: ({ data }) => `hasDigital-${data?.document?.id}`,
          onValueChange: () => context.checkDocumentChange(group.form, group.data, 'hasDigital'),
        }),
      },
      {
        headerName: 'Ошибки',
        colId: 'errors',
        initialHide: !dossierUnderReview,
        valueGetter: (params): TagCellListValue => {
          const { document } = params?.data || {};
          const missingCount = getUncheckedCount(document);
          if (document?.state === DocumentCheckState.NoErrors && missingCount > 0) {
            return {
              items: [
                {
                  value: `Не проверено: ${missingCount}`,
                  status: 'warning',
                  hint: 'Необходимо произвести дополнительную проверку в документе',
                },
              ],
            };
          }
          const digitalErrorsCount = document?.completenessCheck?.hasDigital
            ? getDocumentErrorsCount(document?.verificationSummary?.digital)
            : 0;
          const paperErrorsCount = document?.completenessCheck?.hasPaper
            ? getDocumentErrorsCount(document?.verificationSummary?.paper)
            : 0;
          return {
            items: [digitalErrorsCount && `Э: ${digitalErrorsCount}`, paperErrorsCount && `Б: ${paperErrorsCount}`]
              .filter(Boolean)
              .map((value) => ({ value: String(value), status: 'error' })),
          };
        },
        ...tagListCellDef(),
        initialWidth: 160,
      },
      {
        field: 'document.version',
        headerName: 'Версия',
        initialWidth: 100,
      },
      {
        headerName: 'пЭП/УКЭП',
        headerComponent: ReactiveCheckboxHeaderComponent,
        headerComponentParams: {
          control: group.form?.controls?.hasDigitalSignature,
          fieldId: `hasDigitalSignature-${group.title}`,
        },
        valueGetter: (params) => params.data?.form?.controls?.hasDigitalSignature,
        ...reactiveCheckboxCellDef({
          getFieldId: ({ data }) => `hasDigitalSignature-${data?.document?.id}`,
          onValueChange: () => context.checkDocumentChange(group.form, group.data, 'hasDigitalSignature'),
        }),
        initialWidth: 120,
      },
      {
        field: 'document.state',
        headerName: 'Статус',
        initialWidth: 200,
        ...tagCellDef({
          labelMap: Object.fromEntries(DocumentCheckStateMap),
          getDefaultTagClass: ({ value }) => DocumentCheckStateClassMap[value] ?? DefaultTagClass,
        }),
      },
      {
        field: 'document.filesNew.length',
        headerName: 'Файлы',
        initialWidth: 100,
      },
      {
        field: 'document.pages',
        headerName: 'Страницы',
        initialWidth: 120,
      },
      {
        headerName: '',
        initialPinned: 'right',
        lockPinned: true,
        ...getActionCellDef<DocumentWithForm>({
          icon: 'tuiIconEye',
          getLink: (data) => context.getDocumentRoute(data?.document.id!),
        }),
      },
    ],
  };
};

@Component({
  selector: 'fnip-dossier',
  templateUrl: './dossier.component.html',
  styleUrls: ['./dossier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class DossierComponent {
  @ViewChild(RequestWrapperComponent) dossierWrapper?: RequestWrapperComponent;

  private readonly directoryService = inject(DirectoryArchiveApiService);

  readonly basePath = getBasePath();
  readonly addDocumentDisabledReason = `Добавить документ можно только на статусе досье ${
    this.archiveProductService.activeProduct === ProductCode.Mortgage
      ? '"В ЦИК" или "На доработке"'
      : '“В автосалоне”, “Принято куратором”, “На доработке”'
  }`;

  isMortgageProduct = this.archiveProductService.activeProduct === ProductCode.Mortgage;

  isAutoProduct = this.archiveProductService.activeProduct === ProductCode.Auto;

  historyGridOptions = DOSSIER_HISTORY_GRID_OPTIONS(this.basePath);

  routerParams = getRouteParams();

  DossierTab = DossierTab;

  activeItemIndex = DossierTab.Documents;

  dossier: Nullable<CreditDossierDto>;

  documentsGroups: Nullable<DocumentsGroup[]>;

  documents: DocumentDto[] = [];

  selectedDocuments = new Set<number>();

  approveDossierLabel: Nullable<string>;

  getHistory$ = (dossierId: Nullable<number>): Nullable<GridGetDataCallback<DossierStateHistoryDto>> => {
    if (!dossierId) return null;
    return (page) =>
      this.dossierArchiveApiService
        .apiDossierDossierIdHistoryGet({ dossierId, ...page })
        .pipe(map(({ data }) => data ?? []));
  };

  readonly dialogService = injectDialogService();

  constructor(
    private readonly dossierArchiveApiService: DossierArchiveApiService,
    private readonly documentArchiveApiService: DocumentArchiveApiService,
    private readonly documentTemplateArchiveApiService: DocumentTemplateArchiveApiService,
    private readonly notificationService: NotificationService,
    private readonly archiveProductService: ArchiveProductService,
  ) {}

  MLC_ROLES = MLC_ROLES;

  SUPPORT_ROLES = SUPPORT_ROLES;

  hasMlcRole = hasMlcRoleCb();

  hasCuratorRole = hasCuratorRoleCb();

  hasSupportRole = hasSupportRoleCb();

  currentTabLabel = (tab: DossierTab) => DossierTabMap.get(tab);

  dossierRq$: Observable<[CreditDossierDtoResponseData, DocumentDtoArrayResponseData]> = getRouteParams$<{
    dossierId: Nullable<string>;
  }>().pipe(
    map(({ dossierId }) => toNullableNumber(dossierId)),
    filter((dossierId): dossierId is number => !isNil(dossierId)),
    switchMap((dossierId) =>
      forkJoin([
        this.dossierArchiveApiService.apiDossierDossierIdGet({ dossierId }),
        this.documentArchiveApiService.apiDocumentDossierDossierIdGet({ dossierId }),
      ]),
    ),
    tap(([dossierResp, documentsResp]) => this.setData(dossierResp.data, documentsResp.data ?? [])),
  );

  creditPrograms$ = this.dossierRq$.pipe(
    switchMap(([dossierResp]) =>
      this.directoryService.apiDirectoryCreditProgramsGet({ productCode: dossierResp?.data?.productCode }),
    ),
    map(({ data }) => data),
  );

  setData(dossier: Nullable<CreditDossierDto>, documents: DocumentDto[]) {
    this.documentsGroups = getDocumentsGroups(
      documents,
      dossier ?? {},
      (dossier?.state === DossierState.InMlc && this.hasMlcRole()) ||
        (dossier?.state === DossierState.InCarShowroom && this.hasCuratorRole()),
    );
    this.approveDossierLabel = this.getApproveDossierButtonLabel(this.documentsGroups, dossier?.state);
    this.documents = documents;
    this.dossier = dossier;
  }

  getDocumentRoute(documentId: number): Array<number | string> {
    return [this.basePath, 'dossiers', this.routerParams.dossierId, 'document', documentId];
  }

  getZipDossierFiles$ = (dossierId: Nullable<number>, selectedIds?: Nullable<Set<number>>) => () => {
    if (isNil(dossierId)) return null;
    return this.documentArchiveApiService
      .apiDocumentDossierDossierIdZipGet(
        { dossierId, request: selectedIds ? { documentIds: Array.from(selectedIds) } : undefined },
        'response',
      )
      .pipe(tap(saveFile), tap(this.notificationService.onError('Не удалось скачать документы')));
  };

  getTitlePage$ = (dossierId: Nullable<number>) => () => {
    if (isNil(dossierId)) return null;
    return this.documentTemplateArchiveApiService
      .apiDocumentTemplateDossierDossierIdPost({ dossierId }, 'response')
      .pipe(tap(saveFile), tap(this.notificationService.onError('Не удалось скачать титульный лист')));
  };

  isUnderReview = (type: Nullable<DossierState>) => type === DossierState.UnderReview;

  isInMlc = (type: Nullable<DossierState>) => type === DossierState.InMlc;

  isDossierEditable = (state: Nullable<DossierState>) =>
    state && [DossierState.Draft, DossierState.Status2].includes(state);

  canWithdrawOnDemand = (state: Nullable<DossierState>) =>
    state &&
    ((this.hasMlcRole() && MLC_STATES.includes(state)) ||
      (this.hasCuratorRole() && CURATOR_STATES.includes(state)) ||
      (this.hasSupportRole() && SUPPORT_STATES.includes(state)));

  withdrawOnDemandEnabled = (box: Nullable<BoxShortDto>) => !box || box.state !== BoxState.OffsiteStorage;

  canAddDocument = (state: Nullable<DossierState>) =>
    (state && MLC_STATES_FOR_DOC_UPLOAD.includes(state)) || this.hasSupportRole();

  getApproveDossierButtonLabel = (documentsGroups: Nullable<DocumentsGroup[]>, state: Nullable<DossierState>) => {
    const approveLabel = state === DossierState.InCarShowroom ? 'Принять' : 'Укомплектовать';
    return isAllGroupsCompleteness(documentsGroups) ? approveLabel : 'Сохранить';
  };

  isDisabledAcceptToSupportButton = (documents: DocumentDto[]) => !!getTotalDossierUncheckedCount(documents);

  acceptToSupportLabel = (documents: DocumentDto[]) =>
    !getTotalDossierErrorsCount(documents) ? 'Принять на сопровождение' : 'Отправить на доработку';

  approveDossier$ = (dossierId: Nullable<number>, documentsGroups: Nullable<DocumentsGroup[]>) => () => {
    if (isNil(dossierId)) return EMPTY;
    return this.dossierArchiveApiService
      .apiDossierDossierIdStateCompletedPost({
        dossierId,
        documentCompletenessCheckDto: mapCompletenessChecks(documentsGroups),
      })
      .pipe(
        tap(({ data }) => this.notificationService.showSuccess(`Досье ${data ? 'укомплектовано' : 'обновлено'}`)),
        tap(() => this.dossierWrapper?.reload()),
      );
  };

  acceptToSupport$ = () => {
    const dossierId = this.dossier?.id;
    if (isNil(dossierId)) return EMPTY;
    return this.dossierArchiveApiService.apiDossierDossierIdStateAcceptedForSupportPost({ dossierId }).pipe(
      tap(
        this.notificationService.onSuccess(
          `Досье ${
            !getTotalDossierErrorsCount(this.documents) ? 'принято на сопровождение' : 'отправлено на доработку'
          }`,
        ),
      ),
      tap(() => this.dossierWrapper?.reload()),
    );
  };

  inCarShowroomAction$ = (dossierId: number) =>
    this.dossierArchiveApiService.apiDossierDossierIdStateInCarShowroomPost({ dossierId }).pipe(
      tap(this.notificationService.onSuccess('Досье в Автосалоне')),
      tap(() => this.dossierWrapper?.reload()),
    );

  inMlcAction$ = (dossierId: number) =>
    this.dossierArchiveApiService.apiDossierDossierIdStateInMlcPost({ dossierId }).pipe(
      tap(this.notificationService.onSuccess('Досье в ЦИК')),
      tap(() => this.dossierWrapper?.reload()),
    );

  moveToLost$ = () => {
    const dossierId = this.dossier?.id;
    if (isNil(dossierId)) return EMPTY;
    return this.dossierArchiveApiService.apiDossierDossierIdStateLostPost({ dossierId }).pipe(
      tap(this.notificationService.onSuccess('Досье утеряно')),
      tap(() => this.dossierWrapper?.reload()),
    );
  };

  foundAction$ = () => {
    const dossierId = this.dossier?.id;
    if (isNil(dossierId)) return EMPTY;
    return this.dossierArchiveApiService.apiDossierDossierIdStateReturnFromLostPost({ dossierId }).pipe(
      tap(this.notificationService.onSuccess('Досье найдено')),
      tap(() => this.dossierWrapper?.reload()),
    );
  };

  moveToMlcOrInCarShowroom$ = (productCode: Nullable<ProductCode>) => () => {
    const action$ = productCode === ProductCode.Mortgage ? this.inMlcAction$ : this.inCarShowroomAction$;
    const id = this.dossier?.id;
    return isNil(id) ? EMPTY : action$(id);
  };

  moveToStatus2$ = () => {
    const dossierId = this.dossier?.id;
    if (isNil(dossierId)) return EMPTY;
    return this.dossierArchiveApiService.apiDossierDossierIdStateStatus2Post({ dossierId }).pipe(
      tap(this.notificationService.onSuccess(`Досье переведено в Статус #2`)),
      tap(() => this.dossierWrapper?.reload()),
    );
  };

  moveToReview$ = () => {
    const dossierId = this.dossier?.id;
    if (isNil(dossierId)) return EMPTY;
    return this.dossierArchiveApiService.apiDossierDossierIdStateUnderReviewPost({ dossierId }).pipe(
      tap(this.notificationService.onSuccess(`Досье отправлено на проверку`)),
      tap(() => this.dossierWrapper?.reload()),
    );
  };

  withdrawOnDemand$ = () => {
    const dossierId = this.dossier?.id;
    if (isNil(dossierId)) return EMPTY;
    return this.dialogService
      .openConfirmation<Nullable<string>, unknown>({
        contextData: {
          type: ConfirmationDialogType.Input,
          label: 'Местонахождение досье',
          title: 'Изъять досье по требованию',
          okText: 'Изъять',
        },
      })
      .pipe(
        filter(Boolean),
        switchMap((location) =>
          this.dossierArchiveApiService.apiDossierDossierIdStateWithdrawnOnDemandPost({
            dossierId,
            withdrawnOnDemandDto: { location },
          }),
        ),
        tap(() => this.dossierWrapper?.reload()),
        tap(this.notificationService.onError()),
      );
  };

  returnFromWithdraw$ = () => {
    const dossierId = this.dossier?.id;
    if (isNil(dossierId)) return EMPTY;
    return this.dossierArchiveApiService.apiDossierDossierIdStateReturnFromWithdrawnOnDemandPost({ dossierId }).pipe(
      tap(() => this.dossierWrapper?.reload()),
      tap(this.notificationService.onError()),
    );
  };

  withdrawFromBox$ = () => {
    const id = this.dossier?.id;
    if (isNil(id)) return EMPTY;
    return this.dossierArchiveApiService.apiDossierDossierIdsBoxPost({ dossierIds: id.toString() }).pipe(
      tap(() => this.dossierWrapper?.reload()),
      tap(this.notificationService.onError()),
    );
  };

  dossierActionButtons: ActionButtonData[] = [
    {
      getLabel: (dossier) => (dossier?.state === DossierState.Draft ? 'Отправить в Статус #2' : 'Вернуть в Статус #2'),
      actionId: 'send-to-status-2-button',
      isAvailable: (dossier) =>
        dossier?.state === DossierState.Draft ||
        (dossier?.isManual &&
          ((dossier?.state === DossierState.InMlc && this.hasMlcRole()) ||
            (dossier?.state === DossierState.InCarShowroom && this.hasCuratorRole()))),
      action$: this.moveToStatus2$,
    },
    {
      getLabel: (dossier) =>
        dossier?.productCode === ProductCode.Mortgage ? 'Отправить В ЦИК' : 'Отправить в автосалон',
      actionId: 'dossier-ready-button',
      isAvailable: (dossier) => dossier?.state === DossierState.Status2,
      getAction$: (dossier) => this.moveToMlcOrInCarShowroom$(dossier?.productCode),
    },
    {
      label: 'Досье утеряно',
      actionId: 'dossier-lost-button',
      isAvailable: (dossier) =>
        dossier?.state &&
        ((this.hasMlcRole() && MLC_STATES.includes(dossier.state)) ||
          (this.hasCuratorRole() && CURATOR_STATES.includes(dossier.state)) ||
          (this.hasSupportRole() && SUPPORT_STATES.includes(dossier.state))),
      action$: this.moveToLost$,
    },
    {
      label: 'Досье найдено',
      actionId: 'dossier-found-button',
      isAvailable: (dossier) => dossier?.state === DossierState.Lost,
      action$: this.foundAction$,
    },
    {
      label: 'Вернуть',
      actionId: 'dossier-return-from-withdraw-button',
      isAvailable: (dossier) => dossier?.state === DossierState.WithdrawnOnDemand,
      action$: this.returnFromWithdraw$,
    },
    {
      label: 'Вернуть на проверку',
      actionId: 'dossier-move-to-review-button',
      isAvailable: (dossier) => dossier?.state === DossierState.AcceptedForSupport && this.hasSupportRole(),
      action$: this.moveToReview$,
    },
    {
      label: 'Изъять из короба',
      actionId: 'dossier-withdraw-from-box-button',
      isAvailable: (dossier) =>
        dossier?.state === DossierState.AcceptedForSupport && !!dossier?.box && this.hasSupportRole(),
      action$: this.withdrawFromBox$,
    },
    {
      label: 'Доработать досье',
      actionId: 'dossier-return-to-mlc-button',
      isAvailable: (dossier) =>
        dossier?.state &&
        (([DossierState.Completed, DossierState.IncludedInTheAct].includes(dossier.state) && this.hasMlcRole()) ||
          (dossier.state === DossierState.IncludedInTheAct && this.hasCuratorRole())),
      getAction$: (dossier) => this.moveToMlcOrInCarShowroom$(dossier?.productCode),
    },
    {
      label: 'На доработку',
      actionId: 'dossier-rework-button',
      isAvailable: (dossier) => this.isAcceptedByCuratorActionAvailable(dossier?.state),
      getAction$: (dossier) => this.underReworkAction$(dossier?.id),
    },
  ];

  getDocumentGroupGridOptions = (group: DocumentsGroup) => GET_GRID_OPTIONS(group, this);

  // FNMRG-2318: Действия на статусах На доработке и Доработка выполнена должны быть доступны обеим ролям
  isReworkActionAvailable = (state: Nullable<DossierState>) =>
    state &&
    ([DossierState.UnderRework, DossierState.ReworkCompleted].includes(state) ||
      (this.hasSupportRole() && state === DossierState.ReworkSent));

  isInCarShowroomActionAvailable = (state: Nullable<DossierState>) =>
    state && state === DossierState.InCarShowroom && this.hasCuratorRole();

  isAcceptedByCuratorActionAvailable = (state: Nullable<DossierState>) =>
    state && state === DossierState.AcceptedByTheCurator && this.hasCuratorRole();

  isUnderReworkActionAvailable = (state: Nullable<DossierState>) =>
    state && state === DossierState.UnderRework && this.hasCuratorRole();

  isSentSupportActionAvailable = (state: Nullable<DossierState>) =>
    state && state === DossierState.SentSupport && this.hasSupportRole();

  reworkButtonLabel = (type: Nullable<DossierState>) => (type && ReworkButtonLabelMap.get(type)) ?? '';

  reworkCompletedAction$ = (dossierId: number) =>
    this.dossierArchiveApiService.apiDossierDossierIdStateReworkCompletedPost({ dossierId }).pipe(
      tap(() => this.dossierWrapper?.reload()),
      tap(this.notificationService.onSuccess('Доработка выполнена')),
    );

  reworkSentAction$ = (dossierId: number) =>
    this.dossierArchiveApiService.apiDossierDossierIdStateReworkSentPost({ dossierId }).pipe(
      tap(() => this.dossierWrapper?.reload()),
      tap(this.notificationService.onSuccess(`Доработка отправлена`)),
    );

  reworkUnderReviewAction$ = (dossierId: number) =>
    this.dossierArchiveApiService.apiDossierDossierIdStateUnderReviewPost({ dossierId }).pipe(
      tap(() => this.dossierWrapper?.reload()),
      tap(this.notificationService.onSuccess(`Принято на проверку`)),
    );

  underReworkAction$ = (dossierId: Nullable<number>) => () => {
    if (isNil(dossierId)) return null;
    return this.dossierArchiveApiService.apiDossierDossierIdStateUnderReworkPost({ dossierId }).pipe(
      tap(() => this.dossierWrapper?.reload()),
      tap(this.notificationService.onSuccess(`На доработке`)),
    );
  };

  underReviewAction$ = (dossierId: Nullable<number>) => () => {
    if (isNil(dossierId)) return null;
    return this.reworkUnderReviewAction$(dossierId);
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  ReworkActionMap = new Map([
    [DossierState.UnderRework, this.reworkCompletedAction$],
    [DossierState.ReworkCompleted, this.reworkUnderReviewAction$],
    [DossierState.ReworkSent, this.reworkUnderReviewAction$],
  ]);

  reworkAction$ = (type: Nullable<DossierState>, dossierId: Nullable<number>) => () => {
    const action = type && this.ReworkActionMap.get(type);
    if (!action || isNil(dossierId)) return EMPTY;
    return action(dossierId);
  };

  acceptByCuratorAction$ = (dossierId: Nullable<number>, documentsGroups: Nullable<DocumentsGroup[]>) => () => {
    if (isNil(dossierId)) return null;
    return this.dossierArchiveApiService
      .apiDossierDossierIdStateAcceptedByTheCuratorPost({
        dossierId,
        documentCompletenessCheckDto: mapCompletenessChecks(documentsGroups),
      })
      .pipe(
        tap(() => this.dossierWrapper?.reload()),
        tap(this.notificationService.onSuccess('Принято куратором')),
        tap(this.notificationService.onError('Ошибка при принятии куратором')),
      );
  };

  sentToSupportAction$ = (dossierId: Nullable<number>) => () => {
    if (isNil(dossierId)) return null;
    return this.dossierArchiveApiService.apiDossierDossierIdStateSentSupportPost({ dossierId }).pipe(
      tap(() => this.dossierWrapper?.reload()),
      tap(this.notificationService.onSuccess('Отправлено на сопровождение')),
      tap(this.notificationService.onError('Ошибка при отправке на сопровождение')),
    );
  };

  needImprovementAction$ = (dossierId: Nullable<number>) => () => {
    if (isNil(dossierId)) return null;
    return this.dossierArchiveApiService.apiDossierDossierIdStateNeedImprovementPost({ dossierId }).pipe(
      tap(() => this.dossierWrapper?.reload()),
      tap(this.notificationService.onSuccess('Требуется улучшение')),
      tap(this.notificationService.onError('Ошибка при отправке на улучшение')),
    );
  };

  isReworkButtonDisabled = (type: Nullable<DossierState>, documents: DocumentDto[]) =>
    type === DossierState.UnderRework &&
    documents.some((d) => d.state && ![DocumentCheckState.NoErrors, DocumentCheckState.NoCheck].includes(d.state));

  openAddDocumentDialog = () => {
    const { id: dossierId, productCode, borrowers } = this.dossier ?? {};

    if (isNil(dossierId)) return EMPTY;

    return this.dialogService
      .open<Nullable<boolean>>(AddDocumentsToDossierDialogComponent, {
        hostOptions: { size: 'auto' },
        contextData: { dossierId, productCode, borrowers },
      })
      .pipe(tap((result) => result && this.dossierWrapper?.reload()));
  };

  documentSelectionChanged = (api: GridApi<DocumentWithForm>, data: DocumentWithForm[]) => {
    const allIds = data.map((doc) => doc.document.id);
    const selectedIds = api.getSelectedRows().map((doc) => doc.document.id);
    allIds.forEach((id) => {
      if (!id) return;
      if (selectedIds.includes(id)) {
        this.selectedDocuments.add(id);
      } else {
        this.selectedDocuments.delete(id);
      }
    });
  };

  checkDocumentChange = (
    selectAllForm: Nullable<FormGroupOf<DocumentCompletenessCheckDto>>,
    groupData: DocumentWithForm[],
    type: DocumentCompletenessType,
  ) => {
    const data = groupData.filter(({ form }) => form.controls?.[type]).map(({ form }) => form.controls?.[type]?.value);
    selectAllForm?.controls?.[type]?.setValue(calculateSelectAllValue(data), PATCH_OPTIONS); // avoid triggering value changes
    this.approveDossierLabel = this.getApproveDossierButtonLabel(this.documentsGroups, this.dossier?.state);
  };

  handleCheckAll$ = (group: DocumentsGroup, type: DocumentCompletenessType) => {
    const selectAllControl = group.form?.controls?.[type];
    if (!selectAllControl || !group.data) return null;
    return selectAllControl.valueChanges.pipe(
      tap((value) => {
        group.data.forEach(({ form }) => {
          form?.controls?.[type]?.setValue(value);
        });
        this.approveDossierLabel = this.getApproveDossierButtonLabel(this.documentsGroups, this.dossier?.state);
      }),
    );
  };

  getDossierForm = (dossier: Nullable<CreditDossierDto>) => createDossierForm(dossier);

  saveDossierChanges$ = (value: Nullable<CreditDossierManualDto>) => {
    const dossierId = this.dossier?.id;
    if (!value || isNil(dossierId)) return EMPTY;
    return this.dossierArchiveApiService.apiDossierDossierIdPut({ dossierId, creditDossierManualDto: value }).pipe(
      tap(() => this.dossierWrapper?.reload()),
      tap(this.notificationService.onSuccess('Досье обновлено')),
      tap(this.notificationService.onError()),
    );
  };

  trackByActionId(_index: number, item: ActionButtonData) {
    return item.actionId;
  }
}
