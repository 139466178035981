<h2 class="my-4">Электронное кредитное досье</h2>
<div
  *tuiLet="searchStrategyControl.value === DossierSearchStrategy.FromExcel as isRegistrySearch"
  class="d-flex mr-8 gap-5"
>
  <ng-container *ngIf="updateDossierCount$ | executeWith: isRegistrySearch | async" />
  <ng-container *ngIf="updateSavedFormValue$ | async" />
  <ng-container *ngIf="updateSavedRegistryFile$ | async" />
  <ng-container *ngIf="handleFormState | executeWith: isRegistrySearch" />

  <div class="w-400px d-flex flex-column gap-5">
    <fnip-dossier-search-card label="Результаты">
      <div class="d-flex flex-column gap-2">
        <fnip-divider></fnip-divider>
        <div class="d-flex content-space-between align-items-center">
          <span>Найдено досье</span>
          <tui-loader size="s" [showLoader]="!!isLoading" [inheritColor]="true" [overlay]="true">
            <tui-tag style="min-width: 32px" [value]="((dossierCount$ | async) ?? '') + ''" />
          </tui-loader>
        </div>
        <fnip-divider />

        <fnip-button
          appearance="primary"
          label="Скачать найденные досье"
          class="w-100"
          [btnRouterLink]="['..', 'documents-download']"
        />
        <fnip-button
          appearance="secondary-light-grey"
          label="Показать результаты"
          class="w-100"
          [actionCallback$]="openSearchResultModal | executeWith: isRegistrySearch"
        />
        <fnip-button
          appearance="secondary-light-grey"
          label='Сформировать отчёт "Кредиты"'
          class="w-100"
          [actionCallback$]="generateReport$ | executeWith: ReportType.Credits : isRegistrySearch"
        />
        <tui-notification
          *ngIf="generatedReports[ReportType.Credits] as creditsReportId"
          status="info"
          [hasIcon]="false"
        >
          Запрос на формирование отчёта {{ ReportTypeMap[ReportType.Credits] }} с ID {{ creditsReportId }} отправлен
        </tui-notification>
        <fnip-button
          appearance="secondary-light-grey"
          label='Сформировать отчёт "Ошибки"'
          class="w-100"
          [actionCallback$]="generateReport$ | executeWith: ReportType.Errors : isRegistrySearch"
        />
        <tui-notification *ngIf="generatedReports[ReportType.Errors] as errorsReportId" status="info" [hasIcon]="false">
          Запрос на формирование отчёта {{ ReportTypeMap[ReportType.Errors] }} с ID {{ errorsReportId }} отправлен
        </tui-notification>
        <fnip-button label="Сбросить фильтры" class="w-100" [actionCallback$]="resetFiltersForm" />
        <fnip-button label="Назад" class="w-100" [btnRouterLink]="['..']" />
      </div>
    </fnip-dossier-search-card>

    <fnip-dossier-search-card label="Стратегии поиска">
      <div class="d-flex flex-column gap-2">
        <fnip-reactive-radio-group
          fieldId="searchStrategy"
          direction="vertical"
          canClear
          [control]="searchStrategyControl"
          [options]="searchStrategyOptions"
          (valueChange)="dossierSearchStateService.searchStrategy = $event"
        />
        <ng-container *ngIf="isRegistrySearch">
          <fnip-divider />
          <fnip-reactive-file-input
            label="Файл excel"
            fieldId="excelFile"
            [accept]="registryFileTypes"
            [control]="registryFileControl"
            [multiple]="false"
            [allowDownload]="false"
            [allowPreview]="false"
            [isLocalUsage]="true"
          />
        </ng-container>
      </div>
    </fnip-dossier-search-card>
  </div>
  <div class="flex-1 d-grid gap-5 dossier-search-form">
    <div class="d-flex flex-column gap-5">
      <fnip-dossier-search-card label="Дата выдачи">
        <div class="d-flex gap-2">
          <fnip-reactive-input-date
            fieldId="leftDateBorder"
            placeholder="От"
            class="w-50"
            [control]="filtersForm.controls.leftDateBorderDate"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-date
            fieldId="rightDateBorder"
            placeholder="До"
            class="w-50"
            [control]="filtersForm.controls.rightDateBorderDate"
            [textfieldCleaner]="true"
          />
        </div>
      </fnip-dossier-search-card>

      <fnip-dossier-search-card label="Продукт">
        <fnip-reactive-select
          fieldId="productCode"
          placeholder="Выберите продукт"
          [control]="filtersForm.controls.productCode"
          [options]="productCodeOptions"
          [textfieldCleaner]="true"
        />
      </fnip-dossier-search-card>

      <fnip-dossier-search-card label="Статус досье">
        <fnip-reactive-select
          fieldId="state"
          placeholder="Выберите статус"
          [control]="filtersForm.controls.state"
          [options]="dossierStateOptions"
          [textfieldCleaner]="true"
        />
      </fnip-dossier-search-card>

      <fnip-dossier-search-card label="Состояние">
        <fnip-reactive-select
          fieldId="loanAgreementState"
          placeholder="Выберите состояние"
          [control]="filtersForm.controls.loanAgreementState"
          [options]="loanAgreementStateOptions"
          [textfieldCleaner]="true"
        />
      </fnip-dossier-search-card>

      <fnip-dossier-search-card label="Способ регистрации">
        <fnip-reactive-select
          fieldId="registrationType"
          placeholder="Выберите способ"
          [control]="filtersForm.controls.registrationType"
          [options]="registrationTypeOptions"
          [textfieldCleaner]="true"
        />
      </fnip-dossier-search-card>
    </div>
    <div class="d-flex flex-column gap-5">
      <fnip-dossier-search-card label="Сопровожденец">
        <fnip-reactive-input
          fieldId="supportFullName"
          placeholder="ФИО сопровожденца"
          [control]="filtersForm.controls.supportFullName"
          [textfieldCleaner]="true"
        />
      </fnip-dossier-search-card>

      <fnip-dossier-search-card label="Куратор">
        <fnip-reactive-input
          fieldId="curatorFullName"
          placeholder="ФИО куратора"
          [control]="filtersForm.controls.curatorFullName"
          [textfieldCleaner]="true"
        />
      </fnip-dossier-search-card>

      <fnip-dossier-search-card label="Агент">
        <fnip-reactive-input
          fieldId="agentFullName"
          placeholder="ФИО агента"
          [control]="filtersForm.controls.agentFullName"
          [textfieldCleaner]="true"
        />
      </fnip-dossier-search-card>

      <fnip-dossier-search-card label="Содержит документы">
        <fnip-reactive-multi-select
          fieldId="containsDocumentsList"
          placeholder="Документы"
          hasSearch
          [control]="filtersForm.controls.containsDocumentsList"
          [options]="dossierDocumentOptions$"
          [textfieldCleaner]="true"
        />
      </fnip-dossier-search-card>
    </div>
    <div class="d-flex flex-column gap-5">
      <fnip-dossier-search-card label="В акте">
        <fnip-reactive-input-number
          fieldId="actId"
          placeholder="№ акта"
          [control]="filtersForm.controls.actId"
          [textfieldCleaner]="true"
        />
      </fnip-dossier-search-card>

      <fnip-dossier-search-card label="Досье в коробе">
        <fnip-reactive-radio-group
          direction="vertical"
          fieldId="inBox"
          [control]="filtersForm.controls.inBox"
          [options]="inBoxOptions"
          [textfieldCleaner]="true"
        />
      </fnip-dossier-search-card>

      <fnip-dossier-search-card label="Информация о сделке">
        <div class="d-flex flex-column gap-4">
          <fnip-reactive-input
            fieldId="creditProgram"
            placeholder="Программа кредитования"
            [textfieldCleaner]="true"
            [control]="filtersForm.controls.creditProgram"
          />
          <fnip-reactive-input
            fieldId="region"
            placeholder="Регион выдачи кредита"
            [textfieldCleaner]="true"
            [control]="filtersForm.controls.loanIssueRegion"
          />
          <fnip-reactive-input
            fieldId="dealDepartmentName"
            placeholder="Отделение выдачи КД"
            [textfieldCleaner]="true"
            [control]="filtersForm.controls.dealDepartmentName"
          />
        </div>
      </fnip-dossier-search-card>
    </div>
  </div>
</div>
