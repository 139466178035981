import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ConfigService } from '@lib-config';
import { ErrorPageService } from '@lib-widgets/core';
import { ArchiveProductService } from '../../services';

export const activeProductGuardFn: CanActivateFn = () => {
  const archiveProductService = inject(ArchiveProductService);
  const errorPageService = inject(ErrorPageService);
  const router = inject(Router);
  const configService = inject(ConfigService);

  return archiveProductService.loadAvailableProducts$().pipe(
    map((products) => {
      if (!products?.length) {
        errorPageService.setConfig({
          title: 'У вас нет подключенных продуктов в ЭА',
          message: 'Обратитесь к администратору для получения прав доступа',
          btnLabel: 'Перейти в единый кабинет',
          btnHref: configService.hostUrl,
        });

        return router.createUrlTree(['error']);
      }

      errorPageService.clearConfig();
      return true;
    }),
  );
};
