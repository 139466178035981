/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, isDevMode, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BooleanResponseData } from '../model/booleanResponseData';
// @ts-ignore
import { CreditDossierDtoPageWithStateResultDataResponseData } from '../model/creditDossierDtoPageWithStateResultDataResponseData';
// @ts-ignore
import { CreditDossierDtoResponseData } from '../model/creditDossierDtoResponseData';
// @ts-ignore
import { CreditDossierManualDto } from '../model/creditDossierManualDto';
// @ts-ignore
import { DateTime } from '@lib-utils';
// @ts-ignore
import { DocumentCompletenessCheckDto } from '../model/documentCompletenessCheckDto';
// @ts-ignore
import { DossierState } from '../model/dossierState';
// @ts-ignore
import { DossierStateHistoryDtoPageResultDataResponseData } from '../model/dossierStateHistoryDtoPageResultDataResponseData';
// @ts-ignore
import { IdInfoDtoResponseData } from '../model/idInfoDtoResponseData';
// @ts-ignore
import { InsuranceProductType } from '../model/insuranceProductType';
// @ts-ignore
import { Int64ArrayResponseData } from '../model/int64ArrayResponseData';
// @ts-ignore
import { Int64ListResponseData } from '../model/int64ListResponseData';
// @ts-ignore
import { Int64ResponseData } from '../model/int64ResponseData';
// @ts-ignore
import { LoanAgreementState } from '../model/loanAgreementState';
// @ts-ignore
import { OrderByTypes } from '../model/orderByTypes';
// @ts-ignore
import { PersonDto } from '../model/personDto';
// @ts-ignore
import { ProductCode } from '../model/productCode';
// @ts-ignore
import { WithdrawnOnDemandDto } from '../model/withdrawnOnDemandDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { ArchiveConfiguration }                                     from '../configuration';


export interface ApiDossierAppApplicationIdGetRequestParams {
    /** Id заявки */
    applicationId: number;
}

export interface ApiDossierAvailableForActByQrGetRequestParams {
    /** Формат \&quot;000000; N заявки; N кд;\&quot; */
    qrCode?: string;
}

export interface ApiDossierAvailableForBoxByQrGetRequestParams {
    /** Формат \&quot;000000; N заявки; N кд;\&quot; */
    qrCode?: string;
}

export interface ApiDossierBorrowerPersonIdPutRequestParams {
    personId: number;
    body?: string;
}

export interface ApiDossierDossierIdActActIdPostRequestParams {
    /**  */
    dossierId: number;
    /**  */
    actId: number;
}

export interface ApiDossierDossierIdBoxBoxIdPostRequestParams {
    /**  */
    dossierId: number;
    /**  */
    boxId: number;
}

export interface ApiDossierDossierIdDealMakerPostRequestParams {
    /**  */
    dossierId: number;
    /**  */
    personDto?: PersonDto;
}

export interface ApiDossierDossierIdDeleteRequestParams {
    /**  */
    dossierId: number;
}

export interface ApiDossierDossierIdForcedDeleteRequestParams {
    /**  */
    dossierId: number;
}

export interface ApiDossierDossierIdGetRequestParams {
    /** Идентификатор досье */
    dossierId: number;
}

export interface ApiDossierDossierIdHistoryGetRequestParams {
    /** Идентификатор досье */
    dossierId: number;
    page?: number;
    perPage?: number;
}

export interface ApiDossierDossierIdPutRequestParams {
    /**  */
    dossierId: number;
    /**  */
    creditDossierManualDto?: CreditDossierManualDto;
}

export interface ApiDossierDossierIdStateAcceptedByTheCuratorPostRequestParams {
    /**  */
    dossierId: number;
    /**  */
    documentCompletenessCheckDto?: Array<DocumentCompletenessCheckDto>;
}

export interface ApiDossierDossierIdStateAcceptedForSupportPostRequestParams {
    /** Досье */
    dossierId: number;
}

export interface ApiDossierDossierIdStateCompletedPostRequestParams {
    /** Досье */
    dossierId: number;
    /** Массив проверок комплектности документов в досье */
    documentCompletenessCheckDto?: Array<DocumentCompletenessCheckDto>;
}

export interface ApiDossierDossierIdStateInCarShowroomPostRequestParams {
    /**  */
    dossierId: number;
}

export interface ApiDossierDossierIdStateInMlcPostRequestParams {
    /** Досье */
    dossierId: number;
}

export interface ApiDossierDossierIdStateLostPostRequestParams {
    /**  */
    dossierId: number;
}

export interface ApiDossierDossierIdStateNeedImprovementPostRequestParams {
    /**  */
    dossierId: number;
}

export interface ApiDossierDossierIdStateReturnFromLostPostRequestParams {
    /**  */
    dossierId: number;
}

export interface ApiDossierDossierIdStateReturnFromWithdrawnOnDemandPostRequestParams {
    /**  */
    dossierId: number;
}

export interface ApiDossierDossierIdStateReworkCompletedPostRequestParams {
    /** Досье */
    dossierId: number;
}

export interface ApiDossierDossierIdStateReworkSentPostRequestParams {
    /** Досье */
    dossierId: number;
}

export interface ApiDossierDossierIdStateSentSupportPostRequestParams {
    /**  */
    dossierId: number;
}

export interface ApiDossierDossierIdStateStatus2PostRequestParams {
    /** Досье */
    dossierId: number;
}

export interface ApiDossierDossierIdStateUnderReviewPostRequestParams {
    /** Досье */
    dossierId: number;
}

export interface ApiDossierDossierIdStateUnderReworkPostRequestParams {
    /** Досье */
    dossierId: number;
}

export interface ApiDossierDossierIdStateWithdrawnOnDemandPostRequestParams {
    /** Досье */
    dossierId: number;
    /**  */
    withdrawnOnDemandDto?: WithdrawnOnDemandDto;
}

export interface ApiDossierDossierIdsActPostRequestParams {
    /** id досье через запятую */
    dossierIds: string;
}

export interface ApiDossierDossierIdsBoxPostRequestParams {
    /** id досье через запятую */
    dossierIds: string;
}

export interface ApiDossierGetRequestParams {
    pagePage?: number;
    pagePerPage?: number;
    filterActId?: number;
    filterInBox?: boolean;
    filterText?: string;
    filterIssueLoanDateStart?: DateTime;
    filterIssueLoanDateFinish?: DateTime;
    /** &lt;p&gt;&lt;/p&gt; */
    filterLoanAgreementState?: LoanAgreementState;
    /** &lt;p&gt;&lt;/p&gt; */
    filterInsuranceProductType?: InsuranceProductType;
    filterIssuedLoan?: string;
    filterLoanApplicationNumber?: string;
    filterCftDepartment?: string;
    /** &lt;p&gt;&lt;/p&gt; */
    filterProductCode?: ProductCode;
    filterInAct?: boolean;
    filterDealDepartmentName?: string;
    filterCreatedStart?: DateTime;
    filterCreatedFinish?: DateTime;
    filterCreator?: string;
    /** &lt;p&gt;&lt;/p&gt; */
    filterState?: DossierState;
    /** &lt;p&gt;&lt;/p&gt; */
    sortOrderBy?: OrderByTypes;
    sortFieldBy?: string;
}

export interface ApiDossierNewPostRequestParams {
    /**  */
    creditDossierManualDto?: CreditDossierManualDto;
}

export interface ApiDossierStateAcceptedByTheCuratorPostRequestParams {
    /**  */
    requestBody?: Array<number>;
}


const enum AuthorizationType {
  Bearer = 'Bearer',
}

const enum AuthorizationHeader {
  Authorization = 'Authorization',
}

const AcceptHeader = 'Accept';

@Injectable()
export class DossierArchiveApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new ArchiveConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: ArchiveConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    checkAuthentication(localVarHeaders: any, authType: AuthorizationType, paramName: AuthorizationHeader) {
      // authentication
      const localVarCredential = this.configuration.lookupCredential(authType as string); // name
      if (localVarCredential) {
        return localVarHeaders.set(paramName as string, localVarCredential); // keyParamName
      }

      return localVarHeaders;
    }

    getAcceptType(localVarHttpHeaderAcceptSelected?: string): 'text' | 'json' | 'blob' {
      if (localVarHttpHeaderAcceptSelected) {
        if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
          return 'text';
        } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
          return 'json';
        } else {
          return 'blob';
        }
      }

      return 'json';
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            httpParams = httpParams.append(key!, JSON.stringify(value));
        } else {
            httpParams = httpParams.append(key!, value);
        }

        return httpParams;
    }

    /**
     * Возвращает Id досье по Id заявки
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierAppApplicationIdGet(requestParameters: ApiDossierAppApplicationIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Int64ResponseData>;
    public apiDossierAppApplicationIdGet(requestParameters: ApiDossierAppApplicationIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Int64ResponseData>>;
    public apiDossierAppApplicationIdGet(requestParameters: ApiDossierAppApplicationIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Int64ResponseData>>;
    public apiDossierAppApplicationIdGet(requestParameters: ApiDossierAppApplicationIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const applicationId = requestParameters.applicationId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/app/${this.configuration.encodeParam({name: "applicationId", value: applicationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<Int64ResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает список досье (статус - Укомплектовано) по QR-коду
     * **Required permission**: &#x60;EA.Dossier.Read&#x60; - Чтение досье  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  - &#x60;BankManager&#x60; - Менеджер Банка.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierAvailableForActByQrGet(requestParameters: ApiDossierAvailableForActByQrGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CreditDossierDtoResponseData>;
    public apiDossierAvailableForActByQrGet(requestParameters: ApiDossierAvailableForActByQrGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CreditDossierDtoResponseData>>;
    public apiDossierAvailableForActByQrGet(requestParameters: ApiDossierAvailableForActByQrGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CreditDossierDtoResponseData>>;
    public apiDossierAvailableForActByQrGet(requestParameters: ApiDossierAvailableForActByQrGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const qrCode = requestParameters.qrCode;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (qrCode !== undefined && qrCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>qrCode, 'qrCode');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/available-for-act/by-qr`;
        return this.httpClient.request<CreditDossierDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает список досье (статус - Принято в сопровождение, не включенные в дургой короб) по QR-коду
     * **Required permission**: &#x60;EA.Dossier.Read&#x60; - Чтение досье  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  - &#x60;BankManager&#x60; - Менеджер Банка.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierAvailableForBoxByQrGet(requestParameters: ApiDossierAvailableForBoxByQrGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CreditDossierDtoResponseData>;
    public apiDossierAvailableForBoxByQrGet(requestParameters: ApiDossierAvailableForBoxByQrGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CreditDossierDtoResponseData>>;
    public apiDossierAvailableForBoxByQrGet(requestParameters: ApiDossierAvailableForBoxByQrGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CreditDossierDtoResponseData>>;
    public apiDossierAvailableForBoxByQrGet(requestParameters: ApiDossierAvailableForBoxByQrGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const qrCode = requestParameters.qrCode;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (qrCode !== undefined && qrCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>qrCode, 'qrCode');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/available-for-box/by-qr`;
        return this.httpClient.request<CreditDossierDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновляет заемщика
     * **Required permission**: &#x60;EA.Dossier.Edit&#x60; - Изменение досье  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierBorrowerPersonIdPut(requestParameters: ApiDossierBorrowerPersonIdPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierBorrowerPersonIdPut(requestParameters: ApiDossierBorrowerPersonIdPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierBorrowerPersonIdPut(requestParameters: ApiDossierBorrowerPersonIdPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierBorrowerPersonIdPut(requestParameters: ApiDossierBorrowerPersonIdPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const personId = requestParameters.personId;

        const body = requestParameters.body;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Dossier/borrower/${this.configuration.encodeParam({name: "personId", value: personId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<BooleanResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Добавляет досье в акт
     * **Required permission**: &#x60;EA.Dossier.AddToAct&#x60; - Добавление досье в акт  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdActActIdPost(requestParameters: ApiDossierDossierIdActActIdPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdActActIdPost(requestParameters: ApiDossierDossierIdActActIdPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdActActIdPost(requestParameters: ApiDossierDossierIdActActIdPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdActActIdPost(requestParameters: ApiDossierDossierIdActActIdPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;

        const actId = requestParameters.actId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/act/${this.configuration.encodeParam({name: "actId", value: actId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Добавляет досье в короб
     * **Required permission**: &#x60;EA.Dossier.AddToBox&#x60; - Добавление досье в короб  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdBoxBoxIdPost(requestParameters: ApiDossierDossierIdBoxBoxIdPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdBoxBoxIdPost(requestParameters: ApiDossierDossierIdBoxBoxIdPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdBoxBoxIdPost(requestParameters: ApiDossierDossierIdBoxBoxIdPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdBoxBoxIdPost(requestParameters: ApiDossierDossierIdBoxBoxIdPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;

        const boxId = requestParameters.boxId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/box/${this.configuration.encodeParam({name: "boxId", value: boxId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновляет проведенца сделки
     * **Required permission**: &#x60;EA.Dossier.Edit&#x60; - Изменение досье  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdDealMakerPost(requestParameters: ApiDossierDossierIdDealMakerPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdDealMakerPost(requestParameters: ApiDossierDossierIdDealMakerPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdDealMakerPost(requestParameters: ApiDossierDossierIdDealMakerPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdDealMakerPost(requestParameters: ApiDossierDossierIdDealMakerPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;

        const personDto = requestParameters.personDto;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/deal-maker`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: personDto,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Удаление ручного досье
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdDelete(requestParameters: ApiDossierDossierIdDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdDelete(requestParameters: ApiDossierDossierIdDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdDelete(requestParameters: ApiDossierDossierIdDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdDelete(requestParameters: ApiDossierDossierIdDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<BooleanResponseData>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Удаление досье из бд
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdForcedDelete(requestParameters: ApiDossierDossierIdForcedDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdForcedDelete(requestParameters: ApiDossierDossierIdForcedDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdForcedDelete(requestParameters: ApiDossierDossierIdForcedDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdForcedDelete(requestParameters: ApiDossierDossierIdForcedDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/forced`;
        return this.httpClient.request<BooleanResponseData>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает досье по Id
     * **Required permission**: &#x60;EA.Dossier.Read&#x60; - Чтение досье  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  - &#x60;BankManager&#x60; - Менеджер Банка.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdGet(requestParameters: ApiDossierDossierIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CreditDossierDtoResponseData>;
    public apiDossierDossierIdGet(requestParameters: ApiDossierDossierIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CreditDossierDtoResponseData>>;
    public apiDossierDossierIdGet(requestParameters: ApiDossierDossierIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CreditDossierDtoResponseData>>;
    public apiDossierDossierIdGet(requestParameters: ApiDossierDossierIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<CreditDossierDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает историю статусов досье по Id.
     * **Required permission**: &#x60;EA.Dossier.Read&#x60; - Чтение досье  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  - &#x60;BankManager&#x60; - Менеджер Банка.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdHistoryGet(requestParameters: ApiDossierDossierIdHistoryGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<DossierStateHistoryDtoPageResultDataResponseData>;
    public apiDossierDossierIdHistoryGet(requestParameters: ApiDossierDossierIdHistoryGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<DossierStateHistoryDtoPageResultDataResponseData>>;
    public apiDossierDossierIdHistoryGet(requestParameters: ApiDossierDossierIdHistoryGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<DossierStateHistoryDtoPageResultDataResponseData>>;
    public apiDossierDossierIdHistoryGet(requestParameters: ApiDossierDossierIdHistoryGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;

        const page = requestParameters.page;

        const perPage = requestParameters.perPage;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'perPage');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/history`;
        return this.httpClient.request<DossierStateHistoryDtoPageResultDataResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновляет досье
     * **Required permission**: &#x60;EA.Dossier.Edit&#x60; - Изменение досье  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdPut(requestParameters: ApiDossierDossierIdPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CreditDossierDtoResponseData>;
    public apiDossierDossierIdPut(requestParameters: ApiDossierDossierIdPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CreditDossierDtoResponseData>>;
    public apiDossierDossierIdPut(requestParameters: ApiDossierDossierIdPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CreditDossierDtoResponseData>>;
    public apiDossierDossierIdPut(requestParameters: ApiDossierDossierIdPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;

        const creditDossierManualDto = requestParameters.creditDossierManualDto;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<CreditDossierDtoResponseData>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: creditDossierManualDto,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.AcceptedByTheCurator
     * **Required permission**: &#x60;EA.Dossier.AcceptedByTheCurator&#x60; - Перевод в состояние \&quot;Принято куратором\&quot;  **Required roles**:  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateAcceptedByTheCuratorPost(requestParameters: ApiDossierDossierIdStateAcceptedByTheCuratorPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateAcceptedByTheCuratorPost(requestParameters: ApiDossierDossierIdStateAcceptedByTheCuratorPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateAcceptedByTheCuratorPost(requestParameters: ApiDossierDossierIdStateAcceptedByTheCuratorPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateAcceptedByTheCuratorPost(requestParameters: ApiDossierDossierIdStateAcceptedByTheCuratorPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;

        const documentCompletenessCheckDto = requestParameters.documentCompletenessCheckDto;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/AcceptedByTheCurator`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: documentCompletenessCheckDto,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.AcceptedForSupport
     * **Required permission**: &#x60;EA.Dossier.AcceptedForSupport&#x60; - Перевод в состояние \&quot;Принято на сопровождение\&quot;  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateAcceptedForSupportPost(requestParameters: ApiDossierDossierIdStateAcceptedForSupportPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateAcceptedForSupportPost(requestParameters: ApiDossierDossierIdStateAcceptedForSupportPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateAcceptedForSupportPost(requestParameters: ApiDossierDossierIdStateAcceptedForSupportPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateAcceptedForSupportPost(requestParameters: ApiDossierDossierIdStateAcceptedForSupportPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/AcceptedForSupport`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.Completed
     * **Required permission**: &#x60;EA.Dossier.Completed&#x60; - Перевод состояние \&quot;Укомплектовано\&quot;  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateCompletedPost(requestParameters: ApiDossierDossierIdStateCompletedPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateCompletedPost(requestParameters: ApiDossierDossierIdStateCompletedPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateCompletedPost(requestParameters: ApiDossierDossierIdStateCompletedPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateCompletedPost(requestParameters: ApiDossierDossierIdStateCompletedPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;

        const documentCompletenessCheckDto = requestParameters.documentCompletenessCheckDto;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/Completed`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: documentCompletenessCheckDto,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.InCarShowroom
     * **Required permission**: &#x60;EA.Dossier.InCarShowroom&#x60; - Перевод в состояние \&quot;В автосалоне\&quot;  **Required roles**:  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateInCarShowroomPost(requestParameters: ApiDossierDossierIdStateInCarShowroomPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateInCarShowroomPost(requestParameters: ApiDossierDossierIdStateInCarShowroomPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateInCarShowroomPost(requestParameters: ApiDossierDossierIdStateInCarShowroomPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateInCarShowroomPost(requestParameters: ApiDossierDossierIdStateInCarShowroomPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/InCarShowroom`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.InMlc для досье в статусе Fnip.Electronic.Archive.Dal.Enums.DossierState.Lost
     * **Required permission**: &#x60;EA.Dossier.InMlc&#x60; - Перевод в состояние \&quot;В ЦИК\&quot;  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateInMlcPost(requestParameters: ApiDossierDossierIdStateInMlcPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateInMlcPost(requestParameters: ApiDossierDossierIdStateInMlcPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateInMlcPost(requestParameters: ApiDossierDossierIdStateInMlcPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateInMlcPost(requestParameters: ApiDossierDossierIdStateInMlcPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/InMlc`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.Lost
     * **Required permission**: &#x60;EA.Dossier.Lost&#x60; - Перевод в состояние \&quot;Утеряно\&quot;  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateLostPost(requestParameters: ApiDossierDossierIdStateLostPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateLostPost(requestParameters: ApiDossierDossierIdStateLostPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateLostPost(requestParameters: ApiDossierDossierIdStateLostPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateLostPost(requestParameters: ApiDossierDossierIdStateLostPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/Lost`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.NeedImprovement
     * **Required permission**: &#x60;EA.Dossier.NeedImprovement&#x60; - Требуется доработка  **Required roles**:  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateNeedImprovementPost(requestParameters: ApiDossierDossierIdStateNeedImprovementPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateNeedImprovementPost(requestParameters: ApiDossierDossierIdStateNeedImprovementPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateNeedImprovementPost(requestParameters: ApiDossierDossierIdStateNeedImprovementPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateNeedImprovementPost(requestParameters: ApiDossierDossierIdStateNeedImprovementPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/NeedImprovement`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Вернуть из статуса Fnip.Electronic.Archive.Dal.Enums.DossierState.Lost
     * **Required permission**: &#x60;EA.Dossier.Lost&#x60; - Перевод в состояние \&quot;Утеряно\&quot;  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateReturnFromLostPost(requestParameters: ApiDossierDossierIdStateReturnFromLostPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateReturnFromLostPost(requestParameters: ApiDossierDossierIdStateReturnFromLostPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateReturnFromLostPost(requestParameters: ApiDossierDossierIdStateReturnFromLostPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateReturnFromLostPost(requestParameters: ApiDossierDossierIdStateReturnFromLostPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/ReturnFromLost`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Вернуть из статуса Fnip.Electronic.Archive.Dal.Enums.DossierState.WithdrawnOnDemand
     * **Required permission**: &#x60;EA.Dossier.WithdrawnOnDemand&#x60; - Перевод в состояние \&quot;Изьято по требованию\&quot;  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateReturnFromWithdrawnOnDemandPost(requestParameters: ApiDossierDossierIdStateReturnFromWithdrawnOnDemandPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateReturnFromWithdrawnOnDemandPost(requestParameters: ApiDossierDossierIdStateReturnFromWithdrawnOnDemandPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateReturnFromWithdrawnOnDemandPost(requestParameters: ApiDossierDossierIdStateReturnFromWithdrawnOnDemandPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateReturnFromWithdrawnOnDemandPost(requestParameters: ApiDossierDossierIdStateReturnFromWithdrawnOnDemandPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/ReturnFromWithdrawnOnDemand`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.ReworkCompleted
     * **Required permission**: &#x60;EA.Dossier.ReworkCompleted&#x60; - Перевод в состояние \&quot;Доработка выполнена\&quot;  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateReworkCompletedPost(requestParameters: ApiDossierDossierIdStateReworkCompletedPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateReworkCompletedPost(requestParameters: ApiDossierDossierIdStateReworkCompletedPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateReworkCompletedPost(requestParameters: ApiDossierDossierIdStateReworkCompletedPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateReworkCompletedPost(requestParameters: ApiDossierDossierIdStateReworkCompletedPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/ReworkCompleted`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.ReworkSent
     * **Required permission**: &#x60;EA.Dossier.ReworkSent&#x60; - Перевод в состояние \&quot;Доработка отправлена\&quot;  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateReworkSentPost(requestParameters: ApiDossierDossierIdStateReworkSentPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateReworkSentPost(requestParameters: ApiDossierDossierIdStateReworkSentPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateReworkSentPost(requestParameters: ApiDossierDossierIdStateReworkSentPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateReworkSentPost(requestParameters: ApiDossierDossierIdStateReworkSentPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/ReworkSent`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.SentSupport
     * **Required permission**: &#x60;EA.Dossier.SentSupport&#x60; - Перевод в состояние \&quot;Отправлено на сопровождение\&quot;  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateSentSupportPost(requestParameters: ApiDossierDossierIdStateSentSupportPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateSentSupportPost(requestParameters: ApiDossierDossierIdStateSentSupportPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateSentSupportPost(requestParameters: ApiDossierDossierIdStateSentSupportPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateSentSupportPost(requestParameters: ApiDossierDossierIdStateSentSupportPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/SentSupport`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.Status2 для досье в статусе Fnip.Electronic.Archive.Dal.Enums.DossierState.Draft
     * **Required permission**: &#x60;EA.Dossier.Status2&#x60; - Перевод в состояние \&quot;Статус #2\&quot;  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateStatus2Post(requestParameters: ApiDossierDossierIdStateStatus2PostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateStatus2Post(requestParameters: ApiDossierDossierIdStateStatus2PostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateStatus2Post(requestParameters: ApiDossierDossierIdStateStatus2PostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateStatus2Post(requestParameters: ApiDossierDossierIdStateStatus2PostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/Status2`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.UnderReview  для досье в статусе Fnip.Electronic.Archive.Dal.Enums.DossierState.Lost  или в статусе Fnip.Electronic.Archive.Dal.Enums.DossierState.ReworkSent
     * **Required permission**: &#x60;EA.Dossier.UnderReview&#x60; - Перевод в состояние \&quot;На проверке\&quot;  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateUnderReviewPost(requestParameters: ApiDossierDossierIdStateUnderReviewPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateUnderReviewPost(requestParameters: ApiDossierDossierIdStateUnderReviewPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateUnderReviewPost(requestParameters: ApiDossierDossierIdStateUnderReviewPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateUnderReviewPost(requestParameters: ApiDossierDossierIdStateUnderReviewPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/UnderReview`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.UnderRework
     * **Required permission**: &#x60;EA.Dossier.UnderRework&#x60; - Перевод в состояние \&quot;На доработке\&quot;  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateUnderReworkPost(requestParameters: ApiDossierDossierIdStateUnderReworkPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateUnderReworkPost(requestParameters: ApiDossierDossierIdStateUnderReworkPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateUnderReworkPost(requestParameters: ApiDossierDossierIdStateUnderReworkPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateUnderReworkPost(requestParameters: ApiDossierDossierIdStateUnderReworkPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/UnderRework`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.WithdrawnOnDemand
     * **Required permission**: &#x60;EA.Dossier.WithdrawnOnDemand&#x60; - Перевод в состояние \&quot;Изьято по требованию\&quot;  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdStateWithdrawnOnDemandPost(requestParameters: ApiDossierDossierIdStateWithdrawnOnDemandPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDossierDossierIdStateWithdrawnOnDemandPost(requestParameters: ApiDossierDossierIdStateWithdrawnOnDemandPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDossierDossierIdStateWithdrawnOnDemandPost(requestParameters: ApiDossierDossierIdStateWithdrawnOnDemandPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDossierDossierIdStateWithdrawnOnDemandPost(requestParameters: ApiDossierDossierIdStateWithdrawnOnDemandPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;

        const withdrawnOnDemandDto = requestParameters.withdrawnOnDemandDto;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/WithdrawnOnDemand`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: withdrawnOnDemandDto,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Удаляет досье из акта
     * **Required permission**: &#x60;EA.Dossier.AddToAct&#x60; - Добавление досье в акт  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdsActPost(requestParameters: ApiDossierDossierIdsActPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Int64ArrayResponseData>;
    public apiDossierDossierIdsActPost(requestParameters: ApiDossierDossierIdsActPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Int64ArrayResponseData>>;
    public apiDossierDossierIdsActPost(requestParameters: ApiDossierDossierIdsActPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Int64ArrayResponseData>>;
    public apiDossierDossierIdsActPost(requestParameters: ApiDossierDossierIdsActPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierIds = requestParameters.dossierIds;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierIds", value: dossierIds, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/act`;
        return this.httpClient.request<Int64ArrayResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Удаляет досье из короба
     * **Required permission**: &#x60;EA.Dossier.AddToBox&#x60; - Добавление досье в короб  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierDossierIdsBoxPost(requestParameters: ApiDossierDossierIdsBoxPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Int64ArrayResponseData>;
    public apiDossierDossierIdsBoxPost(requestParameters: ApiDossierDossierIdsBoxPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Int64ArrayResponseData>>;
    public apiDossierDossierIdsBoxPost(requestParameters: ApiDossierDossierIdsBoxPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Int64ArrayResponseData>>;
    public apiDossierDossierIdsBoxPost(requestParameters: ApiDossierDossierIdsBoxPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierIds = requestParameters.dossierIds;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier/${this.configuration.encodeParam({name: "dossierIds", value: dossierIds, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/box`;
        return this.httpClient.request<Int64ArrayResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает страницу с досье по фильтру
     * **Required permission**: &#x60;EA.Dossier.Read&#x60; - Чтение досье  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  - &#x60;BankManager&#x60; - Менеджер Банка.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierGet(requestParameters: ApiDossierGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CreditDossierDtoPageWithStateResultDataResponseData>;
    public apiDossierGet(requestParameters: ApiDossierGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CreditDossierDtoPageWithStateResultDataResponseData>>;
    public apiDossierGet(requestParameters: ApiDossierGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CreditDossierDtoPageWithStateResultDataResponseData>>;
    public apiDossierGet(requestParameters: ApiDossierGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const pagePage = requestParameters.pagePage;

        const pagePerPage = requestParameters.pagePerPage;

        const filterActId = requestParameters.filterActId;

        const filterInBox = requestParameters.filterInBox;

        const filterText = requestParameters.filterText;

        const filterIssueLoanDateStart = requestParameters.filterIssueLoanDateStart;

        const filterIssueLoanDateFinish = requestParameters.filterIssueLoanDateFinish;

        const filterLoanAgreementState = requestParameters.filterLoanAgreementState;

        const filterInsuranceProductType = requestParameters.filterInsuranceProductType;

        const filterIssuedLoan = requestParameters.filterIssuedLoan;

        const filterLoanApplicationNumber = requestParameters.filterLoanApplicationNumber;

        const filterCftDepartment = requestParameters.filterCftDepartment;

        const filterProductCode = requestParameters.filterProductCode;

        const filterInAct = requestParameters.filterInAct;

        const filterDealDepartmentName = requestParameters.filterDealDepartmentName;

        const filterCreatedStart = requestParameters.filterCreatedStart;

        const filterCreatedFinish = requestParameters.filterCreatedFinish;

        const filterCreator = requestParameters.filterCreator;

        const filterState = requestParameters.filterState;

        const sortOrderBy = requestParameters.sortOrderBy;

        const sortFieldBy = requestParameters.sortFieldBy;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pagePage !== undefined && pagePage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagePage, 'page.page');
        }
        if (pagePerPage !== undefined && pagePerPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagePerPage, 'page.perPage');
        }
        if (filterActId !== undefined && filterActId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterActId, 'filter.actId');
        }
        if (filterInBox !== undefined && filterInBox !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterInBox, 'filter.inBox');
        }
        if (filterText !== undefined && filterText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterText, 'filter.text');
        }
        if (filterIssueLoanDateStart !== undefined && filterIssueLoanDateStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterIssueLoanDateStart, 'filter.issueLoanDate.start');
        }
        if (filterIssueLoanDateFinish !== undefined && filterIssueLoanDateFinish !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterIssueLoanDateFinish, 'filter.issueLoanDate.finish');
        }
        if (filterLoanAgreementState !== undefined && filterLoanAgreementState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterLoanAgreementState, 'filter.loanAgreementState');
        }
        if (filterInsuranceProductType !== undefined && filterInsuranceProductType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterInsuranceProductType, 'filter.insuranceProductType');
        }
        if (filterIssuedLoan !== undefined && filterIssuedLoan !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterIssuedLoan, 'filter.issuedLoan');
        }
        if (filterLoanApplicationNumber !== undefined && filterLoanApplicationNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterLoanApplicationNumber, 'filter.loanApplicationNumber');
        }
        if (filterCftDepartment !== undefined && filterCftDepartment !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterCftDepartment, 'filter.cftDepartment');
        }
        if (filterProductCode !== undefined && filterProductCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterProductCode, 'filter.productCode');
        }
        if (filterInAct !== undefined && filterInAct !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterInAct, 'filter.inAct');
        }
        if (filterDealDepartmentName !== undefined && filterDealDepartmentName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterDealDepartmentName, 'filter.dealDepartmentName');
        }
        if (filterCreatedStart !== undefined && filterCreatedStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterCreatedStart, 'filter.created.start');
        }
        if (filterCreatedFinish !== undefined && filterCreatedFinish !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterCreatedFinish, 'filter.created.finish');
        }
        if (filterCreator !== undefined && filterCreator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterCreator, 'filter.creator');
        }
        if (filterState !== undefined && filterState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterState, 'filter.state');
        }
        if (sortOrderBy !== undefined && sortOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrderBy, 'sort.orderBy');
        }
        if (sortFieldBy !== undefined && sortFieldBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortFieldBy, 'sort.fieldBy');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Dossier`;
        return this.httpClient.request<CreditDossierDtoPageWithStateResultDataResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Добавляет ручное досье
     * **Required permission**: &#x60;EA.Dossier.Create&#x60; - Создание досье  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierNewPost(requestParameters: ApiDossierNewPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDtoResponseData>;
    public apiDossierNewPost(requestParameters: ApiDossierNewPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDtoResponseData>>;
    public apiDossierNewPost(requestParameters: ApiDossierNewPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDtoResponseData>>;
    public apiDossierNewPost(requestParameters: ApiDossierNewPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const creditDossierManualDto = requestParameters.creditDossierManualDto;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Dossier/new`;
        return this.httpClient.request<IdInfoDtoResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: creditDossierManualDto,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установить статус Fnip.Electronic.Archive.Dal.Enums.DossierState.AcceptedByTheCurator
     * **Required permission**: &#x60;EA.Dossier.AcceptedByTheCurator&#x60; - Перевод в состояние \&quot;Принято куратором\&quot;  **Required roles**:  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDossierStateAcceptedByTheCuratorPost(requestParameters: ApiDossierStateAcceptedByTheCuratorPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Int64ListResponseData>;
    public apiDossierStateAcceptedByTheCuratorPost(requestParameters: ApiDossierStateAcceptedByTheCuratorPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Int64ListResponseData>>;
    public apiDossierStateAcceptedByTheCuratorPost(requestParameters: ApiDossierStateAcceptedByTheCuratorPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Int64ListResponseData>>;
    public apiDossierStateAcceptedByTheCuratorPost(requestParameters: ApiDossierStateAcceptedByTheCuratorPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const requestBody = requestParameters.requestBody;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Dossier/state/AcceptedByTheCurator`;
        return this.httpClient.request<Int64ListResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: requestBody,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
