import { GridOptions } from '@ag-grid-community/core';
import { CustomActionAppearance, dateTimeCellDef, getActionCellDef, tagCellDef } from '@lib-widgets/grid';
import { DossierStateHistoryDto } from '@lib-archive/api';
import { DefaultTagClass, DossierStateTypeRecord } from '@lib-archive/api-middleware';

export const DOSSIER_HISTORY_GRID_OPTIONS = (basePath: string): GridOptions<DossierStateHistoryDto> => ({
  columnDefs: [
    {
      field: 'timestamp',
      headerName: 'Дата',
      ...dateTimeCellDef(),
    },
    {
      initialFlex: 1,
      field: 'userName',
      headerName: 'Инициатор',
      initialWidth: 200,
    },
    {
      field: 'status',
      headerName: 'Статус',
      ...tagCellDef({
        labelMap: DossierStateTypeRecord,
        getDefaultTagClass: () => DefaultTagClass,
      }),
    },
    {
      field: 'boxNumber',
      headerName: 'Номер короба',
    },
    {
      headerName: 'Акт',
      ...getActionCellDef<DossierStateHistoryDto>({
        getAppearance: (data) => (data?.actId ? CustomActionAppearance.Link : CustomActionAppearance.Hidden),
        getActionLabel: (data) => data?.actId?.toString(),
        getLink: (data) => (data?.actId ? [basePath, 'acts', data.actId] : null),
      }),
    },
  ],
});
