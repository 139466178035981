<tui-scrollbar *ngIf="filePreviewInfos?.length">
  <cdk-virtual-scroll-viewport
    tuiScrollable
    [itemSize]="galleryItemSize"
    orientation="horizontal"
    class="tui-zero-scrollbar mx-auto"
    [style.width]="getGalleryWidth | executeWith: filePreviewInfos?.length"
    [style.height.px]="galleryItemSize + galleryItemGap"
  >
    <div class="d-flex gap-2 mb-2 align-items-center">
      <div
        *ngFor="let fileInfo of filePreviewInfos; let i = index"
        class="cursor-pointer d-flex content-center align-items-center p-1"
        [class.active]="selectedIndex === i"
        [style.height.px]="galleryItemSize"
        [style.min-width.px]="galleryItemSize"
        (click)="selectedIndex = i; selectedIndexChange.emit(i)"
      >
        <tui-loader *ngIf="fileInfo.status !== FilePreviewStatus.Done" size="l" class="w-100" />
        <tui-svg *ngIf="fileInfo.error" src="tuiIconXCircleLarge" />
        <ng-container
          *ngIf="!fileInfo.error && fileInfo.status === FilePreviewStatus.Done"
          [ngSwitch]="fileInfo.file?.type"
        >
          <img
            *ngSwitchCase="FileInfoScanTypes.Image"
            class="w-100 h-100"
            [style.object-fit]="'contain'"
            [alt]="fileInfo.file?.fileId"
            [src]="byPassSecureUrl | executeWith: fileInfo.file?.value"
          />
          <img
            *ngSwitchCase="FileInfoScanTypes.Tif"
            class="w-100 h-100"
            [style.object-fit]="'contain'"
            [alt]="fileInfo.file?.fileId"
            [src]="byPassSecureUrl | executeWith: fileInfo.file?.value"
          />
          <pdf-viewer
            *ngSwitchCase="FileInfoScanTypes.Pdf"
            class="w-100 h-100"
            [src]="getPdfSrc | executeWith: fileInfo.file?.value"
            [render-text]="false"
            [original-size]="false"
            [show-all]="false"
          ></pdf-viewer>
          <div *ngSwitchDefault class="d-flex font-size-18">
            <tui-svg src="tuiIconFileLarge" />
            <fnip-ellipsis-hint [content]="fileInfo.file?.fileType" />
          </div>
        </ng-container>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</tui-scrollbar>
