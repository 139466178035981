/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DossierState = 'Draft' | 'Status2' | 'InMlc' | 'Completed' | 'IncludedInTheAct' | 'UnderRework' | 'ReworkCompleted' | 'ReworkSent' | 'SentSupport' | 'UnderReview' | 'AcceptedForSupport' | 'OffsiteStorage' | 'Lost' | 'WithdrawnOnDemand' | 'Sold' | 'IssuedBeforeImplementation' | 'InCarShowroom' | 'AcceptedByTheCurator' | 'NeedImprovement' | 'SentRework';

export const DossierState = {
    Draft: 'Draft' as DossierState,
    Status2: 'Status2' as DossierState,
    InMlc: 'InMlc' as DossierState,
    Completed: 'Completed' as DossierState,
    IncludedInTheAct: 'IncludedInTheAct' as DossierState,
    UnderRework: 'UnderRework' as DossierState,
    ReworkCompleted: 'ReworkCompleted' as DossierState,
    ReworkSent: 'ReworkSent' as DossierState,
    SentSupport: 'SentSupport' as DossierState,
    UnderReview: 'UnderReview' as DossierState,
    AcceptedForSupport: 'AcceptedForSupport' as DossierState,
    OffsiteStorage: 'OffsiteStorage' as DossierState,
    Lost: 'Lost' as DossierState,
    WithdrawnOnDemand: 'WithdrawnOnDemand' as DossierState,
    Sold: 'Sold' as DossierState,
    IssuedBeforeImplementation: 'IssuedBeforeImplementation' as DossierState,
    InCarShowroom: 'InCarShowroom' as DossierState,
    AcceptedByTheCurator: 'AcceptedByTheCurator' as DossierState,
    NeedImprovement: 'NeedImprovement' as DossierState,
    SentRework: 'SentRework' as DossierState
};

