import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, map, tap } from 'rxjs';
import { NotificationService, Nullable } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import {
  CreditDossierManualDto,
  DirectoryArchiveApiService,
  DossierArchiveApiService,
  ProductCode,
} from '@lib-archive/api';
import { createDossierForm, getBasePath } from '@lib-archive/utils';
import { ArchiveProductService } from '../../../../services';
import { DossierFormComponent } from '../../../../widgets/dossier-form';

@Component({
  selector: 'fnip-dossier-new',
  templateUrl: './dossier-new.component.html',
  styleUrls: ['./dossier-new.component.scss'],
  imports: [ButtonModule, DossierFormComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DossierNewComponent {
  readonly productCode = inject(ArchiveProductService).activeProduct ?? ProductCode.Mortgage;

  form = createDossierForm({ productCode: this.productCode });

  readonly basePath = getBasePath();
  private dossierApiService = inject(DossierArchiveApiService);
  private router = inject(Router);
  private notificationService = inject(NotificationService);
  private readonly directoryService = inject(DirectoryArchiveApiService);

  createDossier$ = (dossier: Nullable<CreditDossierManualDto>) => {
    if (!dossier) return EMPTY;
    return this.dossierApiService.apiDossierNewPost({ creditDossierManualDto: dossier }).pipe(
      tap(({ data }) => this.router.navigate([this.basePath, 'dossiers', data?.id])),
      tap(this.notificationService.onError('Ошибка при создании досье')),
    );
  };

  creditPrograms$ = this.directoryService
    .apiDirectoryCreditProgramsGet({ productCode: this.productCode })
    .pipe(map(({ data }) => data));
}
