/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileType } from './fileType';


/**
 * Передаваемый файл
 */
export interface EaFileInfo { 
    /**
     * Имя файла
     */
    name?: string | null;
    /**
     * Bucket minIO
     */
    bucket?: string | null;
    /**
     * Имя объекта
     */
    objectName?: string;
    type?: FileType;
    /**
     * Id клиента.
     */
    clientId?: number | null;
    /**
     * Размер файла.
     */
    length?: number;
}
export namespace EaFileInfo {
}


