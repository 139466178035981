import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { debounceTime, delay, finalize, map, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import {
  beToFeOptions,
  byInterval,
  createOptionsForLocalizedEnum,
  createOptionsForNullableBoolean,
  DEBOUNCE_TIME_DEFAULT,
  FileInfo,
  FormControlsOf,
  NotificationService,
  Nullable,
  omitNullable,
  saveFile,
} from '@lib-utils';
import { DialogService, injectDialogService } from '@lib-widgets/dialog';
import { ACCEPT_FILE_TYPES } from '@lib-widgets/reactive-fields';
import {
  DocumentArchiveApiService,
  FilteredDossiersArchiveApiService,
  FilteredDossiersByFileArchiveApiService,
  ReportBuilderTaskStatus,
  ReportsArchiveApiService,
  ReportType,
} from '@lib-archive/api';
import {
  DossierStateTypeMap,
  LoanAgreementStateTypeMap,
  ProductCodeMap,
  RegistrationTypeMap,
  ReportTypeMap,
} from '@lib-archive/api-middleware';
import { FeDossiersFilterForReports } from '@lib-archive/api-middleware';
import { DossierSearchStrategy, DossierSearchStrategyMap, getDossierFilters } from '@lib-archive/utils';
import { DossierSearchStateService } from '../../../../services';
import { ViewDossiersModalComponent } from '../../../../widgets/view-dossiers-modal';

@Component({
  selector: 'fnip-dossier-search',
  templateUrl: './dossier-search.component.html',
  styleUrls: ['./dossier-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class DossierSearchComponent {
  readonly dialogService = injectDialogService();

  filtersForm = new FormGroup<FormControlsOf<FeDossiersFilterForReports>>({
    leftDateBorderDate: new FormControl(null),
    rightDateBorderDate: new FormControl(null),
    productCode: new FormControl(null),
    loanAgreementState: new FormControl(),
    state: new FormControl(null),
    supportFullName: new FormControl(null),
    curatorFullName: new FormControl(null),
    agentFullName: new FormControl(null),
    creditProgram: new FormControl(null),
    loanIssueRegion: new FormControl(null),
    actId: new FormControl(null),
    inBox: new FormControl(null),
    registrationType: new FormControl(),
    dealDepartmentName: new FormControl(),
    containsDocumentsList: new FormControl(),
  });

  registryFileControl = new FormControl<FileInfo | null>(null);

  searchStrategyControl = new FormControl<Nullable<DossierSearchStrategy>>(null);

  registryFileTypes = [ACCEPT_FILE_TYPES.CSV_TYPE, ACCEPT_FILE_TYPES.EXCEL_TYPES];

  updateDossierCount$ = (isRegistrySearch: boolean) => {
    const paramsChanges$: Observable<unknown> = isRegistrySearch
      ? this.registryFileControl.valueChanges
      : this.filtersForm.valueChanges;
    return paramsChanges$.pipe(
      debounceTime(DEBOUNCE_TIME_DEFAULT),
      tap(() => (this.dossierCount$ = this.getDossierCount$(isRegistrySearch))),
    );
  };

  updateSavedFormValue$ = this.filtersForm.valueChanges.pipe(
    tap((value) => (this.dossierSearchStateService.formValue = omitNullable(value))),
  );

  updateSavedRegistryFile$ = this.registryFileControl.valueChanges.pipe(
    tap((value) => (this.dossierSearchStateService.registryFile = value)),
  );

  dossierCount$: Nullable<Observable<number>>;

  dossierDocumentOptions$ = this.documentArchiveApiService
    .apiDocumentTypesGet({})
    .pipe(map((res) => beToFeOptions(res.data)));

  searchStrategyOptions = createOptionsForLocalizedEnum(DossierSearchStrategyMap).map((option) => ({
    ...option,
    disabled: option.value !== DossierSearchStrategy.FromExcel,
  }));

  loanAgreementStateOptions = createOptionsForLocalizedEnum(LoanAgreementStateTypeMap);

  dossierStateOptions = createOptionsForLocalizedEnum(DossierStateTypeMap);

  inBoxOptions = createOptionsForNullableBoolean('В коробе', 'Не в коробе', 'Все');

  productCodeOptions = createOptionsForLocalizedEnum(ProductCodeMap);

  registrationTypeOptions = createOptionsForLocalizedEnum(RegistrationTypeMap);

  DossierSearchStrategy = DossierSearchStrategy;

  ReportType = ReportType;

  ReportTypeMap = ReportTypeMap;

  generatedReports: Partial<Record<ReportType, Nullable<number>>> = {
    [ReportType.Credits]: null,
    [ReportType.Errors]: null,
  };

  isLoading = false;

  constructor(
    protected readonly dossierSearchStateService: DossierSearchStateService,
    private readonly documentArchiveApiService: DocumentArchiveApiService,
    private readonly filteredDossierService: FilteredDossiersArchiveApiService,
    private readonly filteredDossierByFileService: FilteredDossiersByFileArchiveApiService,
    private readonly notificationService: NotificationService,
    private readonly reportsService: ReportsArchiveApiService,
  ) {
    this.filtersForm.patchValue(this.dossierSearchStateService.formValue || {});
    this.registryFileControl.patchValue(this.dossierSearchStateService.registryFile);
    this.searchStrategyControl.patchValue(this.dossierSearchStateService.searchStrategy);
  }

  openSearchResultModal = (isRegistrySearch: boolean) => () =>
    this.dialogService.open(ViewDossiersModalComponent, {
      hostOptions: {
        size: 'auto',
      },
      contextData: {
        getDossiers$: (page: number, perPage: number) => this.getDossiers$(page, perPage, isRegistrySearch),
      },
    });

  generateReport$ = (reportType: ReportType, isRegistrySearch: boolean) => () => {
    const generateReportRq$ = isRegistrySearch
      ? this.reportsService.apiReportsByFileReportTypePost({ reportType, file: this.registryFileControl.value?.file })
      : this.reportsService.apiReportsReportTypePost({
          reportType,
          dossiersFilterForReports: getDossierFilters(omitNullable(this.filtersForm.value)),
        });

    const checkTaskStatus$ = (reportId: number) =>
      byInterval(this.reportsService.apiReportsReportIdGet({ reportId })).pipe(
        map(({ data }) => data),
        skipWhile(
          (task) =>
            !task?.status || ![ReportBuilderTaskStatus.Done, ReportBuilderTaskStatus.Fail].includes(task.status),
        ),
        take(1),
      );

    return generateReportRq$.pipe(
      tap((res) => {
        this.generatedReports = {
          ...this.generatedReports,
          [reportType]: res.id,
        };
      }),
      switchMap(({ id }) => (id ? checkTaskStatus$(id) : EMPTY)),
      switchMap((task) =>
        task?.status === ReportBuilderTaskStatus.Done && task.id
          ? this.reportsService.apiReportsReportIdFileGet({ reportId: task.id }, 'response')
          : throwError(() => new HttpErrorResponse({ error: { message: 'Ошибка при формировании отчёта' } })),
      ),
      tap(saveFile),
      tap(this.notificationService.onError('Не удалось сформировать отчёт')),
      finalize(() => (this.generatedReports[reportType] = null)),
    );
  };

  resetFiltersForm = () => this.filtersForm.reset();

  handleFormState = (isRegistrySearch: boolean) => {
    if (isRegistrySearch) {
      this.filtersForm.disable();
      this.registryFileControl.enable();
    } else {
      this.filtersForm.enable();
      this.registryFileControl.disable();
    }
  };

  private getDossiers$(page: number, perPage: number, isRegistrySearch: boolean) {
    if (isRegistrySearch) {
      const filterFile = this.registryFileControl.value?.file;
      return filterFile
        ? this.filteredDossierByFileService.apiFilteredDossiersByFilePost({
            pagePage: page,
            pagePerPage: perPage,
            filterFile,
          })
        : of({});
    } else {
      return this.filteredDossierService.apiFilteredDossiersPost({
        dossiersFilterForReportsPageFilterCommand: {
          page: { page, perPage },
          filter: getDossierFilters(omitNullable(this.filtersForm.value)),
        },
      });
    }
  }

  private getDossierCount$(isRegistrySearch: boolean) {
    this.isLoading = true;
    let dossierCountRq$: Observable<{ data?: Nullable<number> }>;
    if (isRegistrySearch) {
      const file = this.registryFileControl.value?.file;
      dossierCountRq$ = file
        ? this.filteredDossierByFileService.apiFilteredDossiersByFileCountPost({ file })
        : of({ data: null }).pipe(delay(0)); // no file - no search result
    } else {
      dossierCountRq$ = this.filteredDossierService.apiFilteredDossiersCountPost({
        dossiersFilterForReports: getDossierFilters(omitNullable(this.filtersForm.value)),
      });
    }
    return dossierCountRq$.pipe(
      map((res) => res?.data ?? 0),
      tap((count) => (this.dossierSearchStateService.dossierCount = count)),
      finalize(() => (this.isLoading = false)),
    );
  }
}
