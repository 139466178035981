/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProofOfIncome = 'IncomeTaxRate' | 'PensionFundReference' | 'TaxDeclaration' | 'PensionCertificate' | 'BankFormReference' | 'HomesteadBookStatement' | 'NoProof' | 'BusinessNoProof' | 'EmploymentNoProof' | 'ProfessionalIncomeTaxCertificate' | 'BusinessOwner' | 'AccountStatement';

export const ProofOfIncome = {
    IncomeTaxRate: 'IncomeTaxRate' as ProofOfIncome,
    PensionFundReference: 'PensionFundReference' as ProofOfIncome,
    TaxDeclaration: 'TaxDeclaration' as ProofOfIncome,
    PensionCertificate: 'PensionCertificate' as ProofOfIncome,
    BankFormReference: 'BankFormReference' as ProofOfIncome,
    HomesteadBookStatement: 'HomesteadBookStatement' as ProofOfIncome,
    NoProof: 'NoProof' as ProofOfIncome,
    BusinessNoProof: 'BusinessNoProof' as ProofOfIncome,
    EmploymentNoProof: 'EmploymentNoProof' as ProofOfIncome,
    ProfessionalIncomeTaxCertificate: 'ProfessionalIncomeTaxCertificate' as ProofOfIncome,
    BusinessOwner: 'BusinessOwner' as ProofOfIncome,
    AccountStatement: 'AccountStatement' as ProofOfIncome
};

