import { inject } from '@angular/core';
import { RoleType } from '@lib-utils';
import { AuthorizationStorageService } from '@lib-mortgage/features/authorization';

export const MLC_ROLES = [RoleType.EaMlc, RoleType.EaAdmin, RoleType.SuperAdmin];

export const CURATOR_ROLES = [RoleType.EaAutoCurator, RoleType.EaAdmin, RoleType.SuperAdmin];

export const FRONT_OFFICE_ROLES = [RoleType.EaMlc, RoleType.EaAutoCurator, RoleType.EaAdmin, RoleType.SuperAdmin];

export const MORTGAGE_SUPPORT_ROLES = [RoleType.EaSupport, RoleType.EaAdmin, RoleType.SuperAdmin];

export const AUTO_SUPPORT_ROLES = [RoleType.EaAutoSupport, RoleType.EaAdmin, RoleType.SuperAdmin];

export const SUPPORT_ROLES = [RoleType.EaSupport, RoleType.EaAutoSupport, RoleType.EaAdmin, RoleType.SuperAdmin];

/**
 * Коллбэк на наличие роли сотрудника ЦИК ипотеки
 * @param authorizationStorageService
 * @returns
 */
export function hasMlcRoleCb(authorizationStorageService = inject(AuthorizationStorageService)) {
  return () => authorizationStorageService.hasRoles(MLC_ROLES);
}

/**
 * Коллбэк на наличие роли Куратора авто
 * @param authorizationStorageService
 * @returns
 */
export function hasCuratorRoleCb(authorizationStorageService = inject(AuthorizationStorageService)) {
  return () => authorizationStorageService.hasRoles(CURATOR_ROLES);
}

/**
 * Коллбэк на наличие ролей ЦИК или Куратора
 * @param authorizationStorageService
 * @returns
 */
export function hasFrontOfficeRoleCb(authorizationStorageService = inject(AuthorizationStorageService)) {
  return () => authorizationStorageService.hasRoles(FRONT_OFFICE_ROLES);
}

/**
 * Коллбэк на наличие ролей сопровождения ипотеки и авто
 * @param authorizationStorageService
 * @returns
 */
export function hasMortgageSupportRoleCb(authorizationStorageService = inject(AuthorizationStorageService)) {
  return () => authorizationStorageService.hasRoles(MORTGAGE_SUPPORT_ROLES);
}

/**
 * Коллбэк на наличие ролей сопровождения ипотеки и авто
 * @param authorizationStorageService
 * @returns
 */
export function hasAutoSupportRoleCb(authorizationStorageService = inject(AuthorizationStorageService)) {
  return () => authorizationStorageService.hasRoles(AUTO_SUPPORT_ROLES);
}

/**
 * Коллбэк на наличие ролей сопровождения ипотеки и авто
 * @param authorizationStorageService
 * @returns
 */
export function hasSupportRoleCb(authorizationStorageService = inject(AuthorizationStorageService)) {
  return () => authorizationStorageService.hasRoles(SUPPORT_ROLES);
}
