import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { calculateSelectAllValue, Nullable, PATCH_OPTIONS } from '@lib-utils';
import { Option } from './select-options-island.interfaces';

@Component({
  selector: 'fnip-select-options-island',
  templateUrl: './select-options-island.component.html',
  styleUrls: ['./select-options-island.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionsIslandComponent<T> {
  @Input() label: Nullable<string>;
  @Input() options: Option<T>[] = [];
  @Output() valueChange = new EventEmitter();

  selectAll = new FormControl<Nullable<boolean>>(false);

  selectAllChange = (value: Nullable<boolean>) => {
    this.options.forEach((o) => o.control.setValue(value, PATCH_OPTIONS));
    this.valueChange.emit(value);
  };

  optionValueChange = (value: Nullable<boolean>) => {
    this.selectAll.setValue(calculateSelectAllValue(this.options.map((o) => o.control.value)), PATCH_OPTIONS);
    this.valueChange.emit(value);
  };
}
