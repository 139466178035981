<h2 class="my-4">Электронноe кредитное досье</h2>

<fnip-request-wrapper #request [request$]="getDocumentTypes$">
  <div class="d-flex mr-8 gap-5">
    <fnip-dossier-search-card class="w-400px" label="Скачать документы">
      <div class="gap-2 d-flex flex-column">
        <fnip-divider />
        <div class="content-space-between d-flex">
          <span>Выбрано досье</span>
          <tui-tag style="min-width: 32px" [value]="dossierCount" />
        </div>
        <fnip-divider />
        <div class="content-space-between d-flex">
          <strong>Выбрать все документы</strong>
          <fnip-reactive-checkbox
            fieldId="selectAll"
            size="l"
            [control]="actionForm.controls.selectAll"
            (valueChange)="selectAllChange($any(request.data), $event)"
          />
        </div>
        <div class="content-space-between d-flex">
          <strong>Электронная подпись</strong>
          <fnip-reactive-checkbox fieldId="eSign" size="l" [control]="actionForm.controls.eSign" />
        </div>
        <div class="content-space-between d-flex">
          <strong>Выгрузить все версии документов</strong>
          <fnip-reactive-checkbox fieldId="allVersions" size="l" [control]="actionForm.controls.allVersions" />
        </div>

        <fnip-button
          appearance="primary"
          label="Скачать"
          class="w-100"
          [isDisabled]="actionForm.controls.selectAll.value === false"
          [actionCallback$]="downloadsDocuments$($any(request.data))"
        />

        <fnip-button
          appearance="secondary-light-grey"
          label="К фильтру"
          class="w-100"
          [btnRouterLink]="['..', 'new']"
        />
      </div>
    </fnip-dossier-search-card>
    <div *ngIf="columns.length" class="flex-1 d-grid gap-5 documents-download-form">
      <div *ngFor="let column of columns" class="d-flex flex-column gap-5">
        <fnip-select-options-island
          *ngFor="let groups of column"
          [label]="getGroupTitle | executeWith: groups"
          [options]="filterOptionsByDocumentGroup | executeWith: groups : $any(request.data)"
          (valueChange)="optionValueChange($any(request.data))"
        />
      </div>
    </div>
  </div>
</fnip-request-wrapper>
