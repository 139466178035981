import { NgIf, PercentPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ExecuteWithPipeModule, Nullable } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';

interface ZoomConfig {
  min: number;
  max: number;
  step: number;
}

@Component({
  selector: 'fnip-document-preview-panel',
  standalone: true,
  imports: [NgIf, PercentPipe, ButtonModule, ExecuteWithPipeModule],
  templateUrl: './document-preview-panel.component.html',
  styleUrls: ['./document-preview-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewPanelComponent implements OnChanges {
  @Input() filesCount = 0;

  @Input() hasFullScreenPreview: Nullable<boolean>;

  @Input() getFilePagesCount: Nullable<(fileIndex: number) => number>;

  @Input() selectedIndex = 0;

  @Input() zoom = 1;

  @Input() rotation = 0;

  @Output() selectedIndexChange = new EventEmitter<number>();

  @Output() zoomChange = new EventEmitter<number>();

  @Output() rotationChange = new EventEmitter<number>();

  @Output() openPreview = new EventEmitter<void>();

  zoomConfig: ZoomConfig = {
    max: 5,
    min: 0.25,
    step: 0.25,
  };

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedIndex' in changes || 'filesCount' in changes) {
      this.resetControls();
    }
  }

  zoomIn = () => {
    this.zoom += this.zoomConfig.step;
    this.zoomChange.emit(this.zoom);
  };

  zoomOut = () => {
    this.zoom -= this.zoomConfig.step;
    this.zoomChange.emit(this.zoom);
  };

  rotateCw = () => {
    this.rotation += 90;
    this.rotationChange.emit(this.rotation);
  };

  rotateCcw = () => {
    this.rotation -= 90;
    this.rotationChange.emit(this.rotation);
  };

  openPrevious = () => {
    this.selectedIndex = this.selectedIndex - 1;
    this.selectedIndexChange.emit(this.selectedIndex);
  };

  openNext = () => {
    this.selectedIndex = this.selectedIndex + 1;
    this.selectedIndexChange.emit(this.selectedIndex);
  };

  openPreviewCallback = () => this.openPreview.emit();

  private resetControls() {
    this.rotation = 0;
    this.zoom = 1;
    this.rotationChange.emit(this.rotation);
    this.zoomChange.emit(this.zoom);
  }
}
