import { DocumentCheckState, LoanAgreementState } from '@lib-archive/api';

export const DefaultTagClass = 'light-gray-tag';

export const LoanAgreementStateClassMap = {
  [LoanAgreementState.Issued]: 'success-tag',
  [LoanAgreementState.Expired]: 'error-tag',
};

export const DocumentCheckStateClassMap = {
  [DocumentCheckState.HasErrors]: 'error-tag',
  [DocumentCheckState.NoErrors]: 'success-tag',
  [DocumentCheckState.ReadyToSend]: 'success-tag',
};
