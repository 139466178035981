<ng-container *ngIf="scanCode$ | async" />

<fnip-reactive-input
  fieldId="qrCode"
  label="QR code"
  hint="Для корректной работы сканера убедитесь, что данное поле активно"
  [control]="qrCodeControl"
  [hasAutoFocus]="true"
  [isReadOnly]="!isDevMode"
/>
