import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, fromEvent, Observable, tap } from 'rxjs';
import { DEBOUNCE_TIME_DEFAULT, IS_DEV_MODE_KEY, Nullable, PATCH_OPTIONS } from '@lib-utils';
import { ReactiveInputModule } from '@lib-widgets/reactive-fields';

@Component({
  selector: 'fnip-scan-qr-code',
  imports: [AsyncPipe, NgIf, ReactiveInputModule],
  standalone: true,
  templateUrl: './scan-qr-code.component.html',
  styleUrls: ['./scan-qr-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanQrCodeComponent {
  @Input() set isLoading(value: boolean) {
    this.qrCodeControl.setValue(value ? 'Идет загрузка...' : '', PATCH_OPTIONS);
  }

  @Output() codeScanned = new EventEmitter<Nullable<string>>();

  qrCodeControl = new FormControl('');

  readonly isDevMode = !!localStorage.getItem(IS_DEV_MODE_KEY);

  scanCode$: Observable<unknown> = this.isDevMode
    ? this.qrCodeControl.valueChanges.pipe(
        debounceTime(DEBOUNCE_TIME_DEFAULT),
        filter(Boolean),
        tap((value) => this.codeScanned.emit(value)),
      )
    : fromEvent<KeyboardEvent>(inject(ElementRef).nativeElement, 'keydown').pipe(
        tap((event) => (this.qrCode = (this.qrCode ?? '') + event.key)),
        debounceTime(DEBOUNCE_TIME_DEFAULT),
        tap(() => {
          this.codeScanned.emit(this.qrCode);
          this.qrCode = null;
        }),
      );

  private qrCode: Nullable<string>;
}
