/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentCompletenessCheckDto } from './documentCompletenessCheckDto';
import { FileDto } from './fileDto';
import { DocumentCompleteness } from './documentCompleteness';
import { DocumentCheckState } from './documentCheckState';
import { PersonDto } from './personDto';
import { DocumentVerificationSummary } from './documentVerificationSummary';
import { DocumentTypeDto } from './documentTypeDto';
import { DateTime } from '@lib-utils';


export interface DocumentDto { 
    id?: number;
    state?: DocumentCheckState;
    stateSetting?: DateTime;
    allowedStates?: Array<DocumentCheckState> | null;
    creator?: PersonDto;
    created?: DateTime;
    documentType?: DocumentTypeDto;
    version?: number | null;
    files?: Array<number> | null;
    filesNew?: Array<FileDto> | null;
    pages?: number;
    client?: PersonDto;
    completeness?: DocumentCompleteness;
    verificationSummary?: DocumentVerificationSummary;
    completenessCheck?: DocumentCompletenessCheckDto;
    digitalComment?: string | null;
    paperComment?: string | null;
}
export namespace DocumentDto {
}


