import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentComponent } from './views/document';
import { DossierComponent } from './views/dossier';
import { DossierNewComponent } from './views/dossier-new';

const routes: Routes = [
  {
    path: '',
    title: 'Список досье',
    loadComponent: () => import('./views/archive-list').then((m) => m.ArchiveListComponent),
  },
  {
    path: 'new',
    component: DossierNewComponent,
    title: 'Создание досье',
  },
  {
    path: ':dossierId',
    component: DossierComponent,
    title: (route) => 'Досье №' + route.params.dossierId,
  },
  {
    path: ':dossierId/document/:documentId',
    component: DocumentComponent,
    title: (route) => 'Документ №' + route.params.documentId,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DossiersRoutingModule {}
