import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports.component';
import { DocumentsDownloadModule } from './views/documents-download';
import { DossierSearchModule } from './views/dossier-search';
import { ReportListModule } from './views/report-list';

@NgModule({
  declarations: [ReportsComponent],
  imports: [CommonModule, ReportsRoutingModule, DocumentsDownloadModule, DossierSearchModule, ReportListModule],
})
export class ReportsModule {}
