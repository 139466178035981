import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActComponent } from './views/act/act.component';

const routes: Routes = [
  {
    path: '',
    title: 'Список актов',
    loadComponent: () => import('./views/act-list').then((m) => m.ActListComponent),
  },
  {
    path: ':id',
    component: ActComponent,
    title: (route) => 'Акт №' + route.params.id,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ActsRoutingModule {}
