/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CreditPurpose = 'PrimaryMarket' | 'SecondaryMarket' | 'RealEstatePledge' | 'GeneralPurpose';

export const CreditPurpose = {
    PrimaryMarket: 'PrimaryMarket' as CreditPurpose,
    SecondaryMarket: 'SecondaryMarket' as CreditPurpose,
    RealEstatePledge: 'RealEstatePledge' as CreditPurpose,
    GeneralPurpose: 'GeneralPurpose' as CreditPurpose
};

