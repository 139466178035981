import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import find from 'lodash-es/find';
import zip from 'lodash-es/zip';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import {
  FileInfo,
  FileInfoScan,
  FileInfoScanTypes,
  getRouteParams$,
  NotificationService,
  Nullable,
  RoleType,
  saveFile,
  toNullableNumber,
} from '@lib-utils';
import { ConfirmationDialogType, DialogService, injectDialogService } from '@lib-widgets/dialog';
import { mapBlobResponseFileInfoScan } from '@lib-widgets/document-preview';
import { RequestWrapperComponent } from '@lib-widgets/request-wrapper';
import {
  CreditDossierDto,
  DocumentArchiveApiService,
  DocumentCheckState,
  DocumentDto,
  DocumentTypeVerificationDetails,
  DossierArchiveApiService,
  DossierState,
} from '@lib-archive/api';
import { DocumentCheckStateMap } from '@lib-archive/api-middleware';
import {
  createDocumentCheckForm,
  getBasePath,
  hasFrontOfficeRoleCb,
  hasSupportRoleCb,
  updateDocumentCheckForm,
} from '@lib-archive/utils';
import {
  ChangeDocumentPageModalComponent,
  ChangeDocumentPageModalContext,
} from '@lib-archive/widgets/change-document-page-modal';
import { skipTrim } from '@lib-mortgage/interceptors';
import { DOSSIER_FILE_TYPES } from '../../utils';
import { DocumentMode, FePreviousDocumentComment, hasMissingChecksOrErrors, isDocumentToCheck } from './document.utils';

@Component({
  selector: 'fnip-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class DocumentComponent {
  @ViewChild(RequestWrapperComponent) documentWrapper?: RequestWrapperComponent;

  document: Nullable<DocumentDto>;

  isLastDocumentVersion = true;

  documents: DocumentDto[] = [];

  dossier: Nullable<CreditDossierDto>;
  totalVersionCount = 0;

  checkState: DocumentTypeVerificationDetails = {};

  form = createDocumentCheckForm();
  previousDocumentComments: Nullable<FePreviousDocumentComment[]>;
  fileControl = new FormControl<FileInfo[]>([]);
  hasDigitalSignatureControl = new FormControl<boolean>(false);

  mode = DocumentMode.Digital;

  readonly DocumentMode = DocumentMode;
  readonly RoleType = RoleType;
  readonly allowedFileTypes = DOSSIER_FILE_TYPES;

  readonly basePath = getBasePath();

  readonly dialogService = injectDialogService();

  get areChecksReadOnly(): boolean {
    return !this.isLastDocumentVersion || !this.isDossierUnderReview(this.dossier?.state) || !this.hasSupportRole();
  }

  get dossierPath() {
    return [this.basePath, 'dossiers', this.dossier?.id];
  }

  documentId$ = getRouteParams$<{ documentId: string; dossierId: string }>().pipe(
    switchMap(({ documentId, dossierId }) =>
      forkJoin([
        of(toNullableNumber(documentId)),
        this.dossierArchiveApiService.apiDossierDossierIdGet({ dossierId: toNullableNumber(dossierId) ?? 0 }),
        this.documentArchiveApiService.apiDocumentDossierDossierIdGet({ dossierId: toNullableNumber(dossierId) ?? 0 }),
      ]),
    ),
    tap(([documentId, dossierResp, documentsResp]) => {
      this.dossier = dossierResp.data;
      this.documents = documentsResp.data ?? [];
      this.document = this.documents.find((value) => value.id === documentId);
      this.fileControl.reset();
      this.hasDigitalSignatureControl.reset(!!this.document?.completenessCheck?.hasDigitalSignature);
      this.mode =
        hasMissingChecksOrErrors(this.document, DocumentMode.Paper) &&
        !hasMissingChecksOrErrors(this.document, DocumentMode.Digital)
          ? DocumentMode.Paper
          : DocumentMode.Digital;
    }),
    map(([documentId]) => documentId),
  );

  constructor(
    private readonly dossierArchiveApiService: DossierArchiveApiService,
    private readonly documentArchiveApiService: DocumentArchiveApiService,
    protected readonly notificationService: NotificationService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  hasFrontOfficeRole = hasFrontOfficeRoleCb();

  hasSupportRole = hasSupportRoleCb();

  canChangeDocumentPage = (fileInfo: Nullable<FileInfoScan<number>>, document: Nullable<DocumentDto>) =>
    (fileInfo?.type === FileInfoScanTypes.Pdf &&
      !this.hasConvertedPdf(document?.filesNew?.find((file) => file.id === fileInfo?.fileId)?.name)) ||
    fileInfo?.type === FileInfoScanTypes.Tif;

  deleteCurrentFile$ = (file: Nullable<FileInfoScan<number>>, document: Nullable<DocumentDto>) => () => {
    if (!file?.fileId || !document?.id) return null;
    return this.dialogService
      .openConfirmation<boolean>({
        contextData: {
          type: ConfirmationDialogType.Question,
          text: 'Вы действительно хотите удалить файл?',
        },
        hostOptions: {
          isDismissible: false,
        },
      })
      .pipe(
        filter(Boolean),
        switchMap(() =>
          this.documentArchiveApiService.apiDocumentDocumentIdFileIdDelete({
            documentId: document.id!,
            id: file.fileId!,
          }),
        ),
        tap(() => this.documentWrapper?.reload()),
      );
  };

  openChangePageModal$ = (fileInfo: Nullable<FileInfoScan<number>>, document: Nullable<DocumentDto>) => () =>
    this.dialogService
      .open<boolean, ChangeDocumentPageModalContext>(ChangeDocumentPageModalComponent, {
        contextData: {
          fileInfo,
          documentId: document?.id,
          pagesCount:
            fileInfo?.values?.length ?? document?.filesNew?.find((file) => file.id === fileInfo?.fileId)?.pages ?? 0,
        },
        hostOptions: {
          isDismissible: false,
        },
      })
      .pipe(
        filter(Boolean),
        // При замене страницы создается новая версия документа, нужно перейти на страницу с соответствующим id
        tap((documentId) => this.router.navigate(['..', documentId], { relativeTo: this.activatedRoute })),
      );

  getDeleteBtnHint = (document: Nullable<DocumentDto>) => {
    if (!document?.filesNew?.length) return 'Документ пуст';
    if (document.filesNew.length === 1) return 'Нельзя удалить последний файл из документа';
    return 'Удалить файл';
  };

  getFileIds = (document: Nullable<DocumentDto>) => document?.filesNew?.map(({ id }) => id!);

  getFileIdsWithPreview = (document: Nullable<DocumentDto>) =>
    document?.filesNew?.filter(({ name }) => this.hasMiniPreview(name)).map(({ id }) => id!);

  getDocumentHistoryRq$ = (documentId: number) =>
    this.documentArchiveApiService.apiDocumentDocumentIdHistoryGet({ documentId }).pipe(
      map((res) => res.data ?? []),
      tap((data) => {
        this.totalVersionCount = data?.length;
        this.previousDocumentComments = data
          .filter(({ verificationSummary }) => !!verificationSummary?.digital?.comment)
          .map(({ verificationSummary, version }) => ({
            comment: verificationSummary?.digital?.comment,
            version,
          }));
      }),
    );

  getPreviousDocumentCommentsForms = (
    currentDocumentVersion: Nullable<number>,
    previousDocumentComments: Nullable<FePreviousDocumentComment[]>,
    totalVersionCount: number,
  ) => {
    if (!currentDocumentVersion || !previousDocumentComments?.length || totalVersionCount < 1) return;

    const data = previousDocumentComments.filter(({ version }) => version !== currentDocumentVersion);

    return data?.length ? data : null;
  };

  navigateToDossier = () => {
    this.router.navigate(this.dossierPath);
  };

  openDossierInNewWindow = () => {
    const url = this.router.serializeUrl(this.router.createUrlTree(this.dossierPath));
    window.open(url, '_blank');
  };

  isDossierUnderReview = (type: Nullable<DossierState>) => type === DossierState.UnderReview;

  isDossierUnderRework = (type: Nullable<DossierState>) => type === DossierState.UnderRework;

  canUploadDocumentForSupport = (state: Nullable<DossierState>) =>
    this.hasSupportRole() && !!state && [DossierState.UnderReview, DossierState.AcceptedForSupport].includes(state);

  canUploadDocumentForFrontOffice = (state: Nullable<DossierState>) =>
    this.hasFrontOfficeRole() && state === DossierState.UnderRework;

  isDossierEditable = (type: Nullable<DossierState>) =>
    this.isDossierUnderRework(type) || this.isDossierUnderReview(type);

  getDocumentState = (type: Nullable<DocumentCheckState>) => type && DocumentCheckStateMap.get(type);

  hasNextCheck = (documentId: Nullable<number>, mode: DocumentMode) => {
    return this.needPaperModeCheck(mode) || !!this.getNextCheckDocument(documentId);
  };

  getErrorsCount = (document: Nullable<DocumentDto>, mode: DocumentMode) =>
    document?.verificationSummary?.[mode]?.checks?.reduce((total, check) => total + (check.ok === false ? 1 : 0), 0);

  getActionButtonLabel = (hasNextCheck: boolean) =>
    hasNextCheck ? 'Сохранить и перейти к следующему' : 'Сохранить и вернуться к досье';

  getDocumentCheckResult() {
    if (this.checkState) this.checkState.comment = this.form.controls.comment.value;

    zip(this.form.value.checks ?? [], this.checkState?.checks ?? []).forEach(([value, checkResult]) => {
      if (!checkResult) return;
      checkResult.ok = value;
    });
    return { [this.mode]: this.checkState };
  }

  saveAndNext$ = (hasNextCheck: boolean) => () =>
    this.documentArchiveApiService
      .apiDocumentDocumentIdCheckPost(
        { documentId: this.document?.id ?? 0, documentVerificationSummary: this.getDocumentCheckResult() },
        undefined,
        undefined,
        {
          context: skipTrim(),
        },
      )
      .pipe(
        tap(this.notificationService.onSuccess('Документ успешно обновлен')),
        tap(this.notificationService.onError('Ошибка при сохранении')),
        tap(() => {
          if (hasNextCheck) this.navigateToNextCheck();
          else this.navigateToDossier();
        }),
      );

  toggleDocumentMode = () => {
    this.mode = this.mode === DocumentMode.Digital ? DocumentMode.Paper : DocumentMode.Digital;
  };

  setCheckState = (document: Nullable<DocumentDto>, mode: DocumentMode) => {
    this.checkState =
      (mode === DocumentMode.Paper ? document?.verificationSummary?.paper : document?.verificationSummary?.digital) ??
      {};
    updateDocumentCheckForm(this.form, this.checkState, this.areChecksReadOnly);
  };

  getFileByIdCallback$ = (id: number) => {
    const fileName = this.document?.filesNew?.find((file) => file.id === id)?.name;
    const action$ = this.hasConvertedPdf(fileName)
      ? this.documentArchiveApiService
          .apiDocumentFileIdConvertedPdfGet({ id }, 'response')
          .pipe(catchError(() => this.documentArchiveApiService.apiDocumentFileIdGet({ id }, 'response')))
      : this.documentArchiveApiService.apiDocumentFileIdGet({ id }, 'response');
    return action$.pipe(mapBlobResponseFileInfoScan(id));
  };

  getFilePreviewByIdCallback$ = (id: number) =>
    this.documentArchiveApiService.apiDocumentFileIdConvertedPng100hFirstPageGet({ id }, 'response').pipe(
      catchError(() => this.documentArchiveApiService.apiDocumentFileIdGet({ id }, 'response')),
      mapBlobResponseFileInfoScan(id),
    );

  getFilePagesCountCallback = (document: Nullable<DocumentDto>) => {
    if (!document) return null;
    return (fileId: number) => document.filesNew?.find((file) => file.id === fileId)?.pages ?? 0;
  };

  isCommentRequired = (checkResults: Nullable<Nullable<boolean>[]>) => checkResults?.some((v) => !v);

  getZipDocumentFiles$ = () => {
    if (!this.document?.id) return null;
    return this.documentArchiveApiService
      .apiDocumentDocumentIdZipGet({ documentId: this.document.id }, 'response')
      .pipe(tap(saveFile));
  };

  onUploadFiles$ = () => {
    return this.documentArchiveApiService
      .apiDocumentDossierDossierIdPost({
        dossierId: this.dossier?.id ?? 0,
        documentTypeId: this.document?.documentType?.id,
        clientId: this.document?.client?.id,
        files: this.fileControl.value?.map((f) => f.file) as Blob[],
        hasDigitalSignature: this.document?.documentType?.mayHaveDigitalSignature
          ? !!this.hasDigitalSignatureControl.value
          : undefined,
      })
      .pipe(
        tap(this.notificationService.onSuccess('Файлы успешно загружены')),
        tap(this.notificationService.onError('Ошибка загрузки')),
        tap(() => this.navigateToDossier()),
      );
  };

  private navigateToNextCheck() {
    if (this.needPaperModeCheck(this.mode)) {
      this.toggleDocumentMode();
    } else {
      this.router.navigate([...this.dossierPath, ...['document', this.getNextCheckDocument(this.document?.id)?.id]]);
    }
  }

  private getNextCheckDocument(documentId: Nullable<number>) {
    const currentDocIndex = this.documents.findIndex((document) => document.id === documentId);
    return find(this.documents, isDocumentToCheck, currentDocIndex + 1);
  }

  private needPaperModeCheck(mode: DocumentMode) {
    return mode === DocumentMode.Digital && hasMissingChecksOrErrors(this.document, DocumentMode.Paper);
  }

  private hasConvertedPdf(name: Nullable<string>) {
    return name?.toLocaleLowerCase().includes('.doc');
  }

  private hasMiniPreview(name: Nullable<string>) {
    return ['.doc', '.pdf', '.tif'].some((ext) => name?.toLocaleLowerCase().includes(ext));
  }
}
