import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@lib-widgets/core';
import { FilterModule } from '@lib-widgets/filter';
import { GridModule } from '@lib-widgets/grid';
import { ListSidebarModule } from '@lib-widgets/menu';
import { ReactiveInputModule, ReactiveInputNumberModule } from '@lib-widgets/reactive-fields';
import { ReportListComponent } from './report-list.component';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    FilterModule,
    GridModule,
    ListSidebarModule,
    ReactiveInputNumberModule,
    ReactiveInputModule,
  ],
  declarations: [ReportListComponent],
})
export class ReportListModule {}
