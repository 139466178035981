<div *tuiLet="form.controls.productCode?.value as productCode" class="d-flex flex-column gap-7">
  <div class="dossier-card d-flex flex-column gap-6">
    <div class="dossier-card__title">Кредит</div>
    <fnip-divider />

    <fnip-labeled-content
      *ngIf="state"
      label="Статус досье"
      [content]="statusTemplate"
      [contentContext]="getDossierState | executeWith: state"
      [inline]="true"
    />

    <div class="d-flex gap-5">
      <fnip-reactive-input
        data-testid="loan-agreement-number-input"
        fieldId="loanAgreementNumber"
        label="№ Кр. договора"
        textFieldSize="l"
        class="flex-1"
        [control]="form.controls.loanAgreementNumber"
        [requiredIf]="true"
      />
      <fnip-reactive-select
        data-testid="loan-agreement-state-select"
        fieldId="loanAgreementState"
        label="Текущее состояние кредита"
        textFieldSize="l"
        class="flex-1"
        [control]="form.controls.loanAgreementState"
        [options]="loanAgreementStateOptions"
        [requiredIf]="true"
      />
    </div>
    <div class="d-flex gap-5">
      <fnip-reactive-input
        data-testid="loan-application-number-input"
        fieldId="loanApplicationNumber"
        label="№ заявки"
        textFieldSize="l"
        class="flex-1"
        [control]="form.controls.loanApplicationNumber"
        [requiredIf]="true"
      />
      <fnip-reactive-input-number
        data-testid="interest-rate-input"
        fieldId="interestRate"
        textFieldSize="l"
        label="Процентная ставка"
        class="flex-1"
        [control]="form.controls.interestRate"
        [requiredIf]="true"
      />
    </div>
    <div class="d-flex gap-5">
      <fnip-reactive-input-date
        data-testid="issue-loan-date-input"
        fieldId="issueLoanDate"
        textFieldSize="l"
        label="Дата выдачи"
        class="flex-1"
        [control]="form.controls.issueLoanDate"
        [hasToDateDirective]="true"
        [requiredIf]="true"
      />
      <fnip-reactive-input-date
        data-testid="planned-end-date-input"
        fieldId="plannedEndDate"
        textFieldSize="l"
        label="Плановая дата окончания"
        class="flex-1"
        [control]="form.controls.plannedEndDate"
        [hasToDateDirective]="true"
        [requiredIf]="true"
      />
    </div>
    <div class="d-flex gap-5">
      <fnip-reactive-select
        *ngIf="productCode === ProductCode.Mortgage"
        data-testid="income-verification-method-select"
        fieldId="incomeVerificationMethod"
        textFieldSize="l"
        label="Способ подтверждения дохода"
        class="flex-1"
        [control]="form.controls.incomeVerificationMethod"
        [options]="incomeVerificationMethodTypes"
        [requiredIf]="true"
      />
      <fnip-reactive-select
        data-testid="credit-program-select"
        fieldId="creditProgramId"
        textFieldSize="l"
        label="Программа"
        class="flex-1"
        [control]="form.controls.creditProgramId"
        [options]="creditProgramOptions$ | executeWith: (creditPrograms$ | async)"
        [requiredIf]="true"
      />
    </div>
    <div *ngIf="productCode === ProductCode.Mortgage" class="d-flex gap-5">
      <fnip-reactive-select
        data-testid="initial-payment-method-select"
        fieldId="initialPaymentMethod"
        textFieldSize="l"
        label="Способ оплаты ПВ"
        class="flex-1"
        [control]="form.controls.initialPaymentMethod"
        [options]="initialPaymentMethodTypes"
        [requiredIf]="true"
      />
      <fnip-reactive-select
        data-testid="purpose-select"
        fieldId="purpose"
        textFieldSize="l"
        label="Рынок"
        class="flex-1"
        [control]="form.controls.purpose"
        [options]="purposeOptions"
        [requiredIf]="true"
      />
    </div>
    <ng-container *ngIf="productCode === ProductCode.Auto">
      <div class="d-flex gap-5">
        <fnip-reactive-input
          data-testid="city-input"
          fieldId="city"
          label="Город"
          textFieldSize="l"
          class="flex-1"
          [control]="form.controls.city"
        />
        <fnip-reactive-input
          data-testid="showroom-input"
          fieldId="showroom"
          label="Автосалон"
          textFieldSize="l"
          class="flex-1"
          [control]="form.controls.showroom"
        />
      </div>
      <fnip-reactive-checkbox
        data-testid="insurance-checkbox"
        fieldId="withInsurance"
        checkboxLabel="Наличие страхования"
        size="l"
        class="flex-1"
        [control]="form.controls.withInsurance"
      />
    </ng-container>

    <fnip-reactive-select
      data-testid="registration-type-select"
      fieldId="registrationType"
      textFieldSize="l"
      label="Способ регистрации"
      class="flex-1"
      [control]="form.controls.registrationType"
      [options]="getRegistrationTypeOptions | executeWith: productCode"
    />

    <!-- TODO: Уточнить у Илья Куклин. Возможно стоит переименовать на "Офис учета в ЦФТ" -->
    <fnip-reactive-select
      hasSearch
      fieldId="cftDepartment"
      textFieldSize="l"
      label="Регион учета в ЦФТ"
      class="flex-1"
      [control]="form.controls.cftDepartment"
      [options]="departmentOptions$"
      [initialOptions]="getDepartmentInitialOptions | executeWith: form.controls.cftDepartment?.value"
      [requiredIf]="true"
    />

    <fnip-reactive-select
      hasSearch
      fieldId="dealDepartmentIdName"
      textFieldSize="l"
      label="Офис проведения сделки"
      class="flex-1"
      [control]="form.controls.dealDepartmentName"
      [options]="departmentOptions$"
      [initialOptions]="getDepartmentInitialOptions | executeWith: form.controls.dealDepartmentName?.value"
      [requiredIf]="true"
    />

    <fnip-reactive-input-number
      fieldId="cftCreditAgreementId"
      label="ID КД в ЦФТ"
      textFieldSize="l"
      class="flex-1"
      [control]="form.controls.cftCreditAgreementId"
    />

    <ng-container *ngIf="productCode === ProductCode.Mortgage">
      <fnip-reactive-checkbox
        data-testid="non-standard-checkbox"
        size="l"
        fieldId="nonStandard"
        checkboxLabel="Нестандартная заявка"
        [isLabelBold]="true"
        [control]="form.controls.nonStandard"
        (valueChange)="onChangeNonStandard($event)"
      />

      <fnip-reactive-select
        *ngIf="form.controls.nonStandard?.value"
        data-testid="non-standard-type"
        label="Тип нестандартной заявки"
        textFieldSize="l"
        fieldId="nonStandardType"
        class="flex-1"
        [control]="form.controls.nonStandardType"
        [options]="nonStandardTypeOptions"
        [requiredIf]="true"
      />

      <fnip-reactive-text-area
        *ngIf="form.controls.nonStandard?.value"
        data-testid="non-standard-comment"
        label="Комментарий"
        textFieldSize="l"
        fieldId="nonStandardComment"
        class="flex-1"
        [control]="form.controls.nonStandardComment"
        [requiredIf]="true"
      />
    </ng-container>
  </div>

  <div
    *tuiLet="isBorrowerSelected | executeWith: form.controls.borrowers?.value as borrowerSelected"
    class="dossier-card d-flex flex-column gap-6"
  >
    <div class="dossier-card__title">Участники</div>

    <div
      *ngFor="let borrowerFg of form.controls.borrowers?.controls; let i = index; let last = last"
      class="d-flex flex-column gap-6"
      [class.mb-2]="last"
    >
      <div class="d-flex gap-3">
        <fnip-reactive-select
          textFieldSize="l"
          label="Тип участника"
          class="flex-1"
          [fieldId]="'clientType' + i"
          [control]="borrowerFg.controls.clientType"
          [isReadOnly]="!!borrowerFg.value.id"
          [options]="getClientTypeOptions | executeWith: productCode : borrowerSelected"
        />
        <fnip-button
          *ngIf="canRemoveBorrower | executeWith: borrowerFg.controls.clientType?.value : state"
          appearance="outline"
          btnSize="m"
          icon="tuiIconTrash"
          class="mt-7"
          [actionCallback$]="removeBorrower | executeWith: i"
        />
      </div>
      <fnip-reactive-input label="ФИО" textFieldSize="l" [control]="borrowerFg.controls.name" [fieldId]="'name' + i" />
      <div class="d-flex gap-6">
        <fnip-reactive-input
          label="Email"
          textFieldSize="l"
          class="flex-1"
          [fieldId]="'email' + i"
          [control]="borrowerFg.controls.email"
        />
        <fnip-reactive-input-phone
          label="Номер телефона"
          textFieldSize="l"
          class="flex-1"
          [fieldId]="'phone' + i"
          [control]="borrowerFg.controls.phone"
        />
      </div>
      <fnip-divider *ngIf="!last" />
    </div>

    <fnip-button
      *ngIf="canAddBorrower | executeWith: productCode : form.controls.borrowers?.length"
      btnSize="l"
      appearance="outline"
      label="Добавить участника"
      icon="tuiIconPlusLarge"
      class="w-100"
      [actionCallback$]="addBorrower | executeWith: productCode"
    />

    <fnip-divider />

    <div class="dossier-card__title">Прочие роли</div>

    <fnip-reactive-select
      fieldId="issuedLoanId"
      textFieldSize="l"
      label="ФИО сотрудника, оформившего кредит"
      emptyTermMessage="Введите первые 3 буквы для поиска"
      hasSearch
      [control]="form.controls.issuedLoan?.controls?.id"
      [minTermLength]="3"
      [requiredIf]="true"
      [initialOptions]="getInitialPersonOption | executeWith: form.controls.issuedLoan?.value : issuedLoanConfig"
      [options]="issuedLoanOptions$"
      (selectedOptionChange)="form.controls.issuedLoan?.patchValue($event || {})"
    />

    <div
      *ngFor="let participantFg of form.controls.participants?.controls; let i = index"
      class="d-flex flex-column gap-6 mb-2"
    >
      <fnip-divider />
      <div class="d-flex gap-3">
        <fnip-reactive-select
          textFieldSize="l"
          label="Роль"
          class="flex-1"
          [fieldId]="'role' + i"
          [control]="participantFg.controls.role"
          [options]="getParticipantRoleOptions | executeWith: productCode"
        />
        <fnip-button
          appearance="outline"
          btnSize="m"
          icon="tuiIconTrash"
          class="mt-7"
          [actionCallback$]="removeParticipant | executeWith: i"
        />
      </div>

      <div class="d-flex align-items-center gap-6">
        <fnip-reactive-select
          textFieldSize="l"
          label="ФИО"
          emptyTermMessage="Введите первые 3 буквы для поиска"
          hasSearch
          class="flex-1"
          [fieldId]="'participant' + i"
          [control]="participantFg?.controls?.participant?.controls?.id"
          [minTermLength]="3"
          [requiredIf]="true"
          [initialOptions]="
            getInitialPersonOption | executeWith: participantFg?.controls?.participant?.value : userConfig
          "
          [options]="getUserOptions | executeWith: staticParticipantOptions : participantFg.controls.role?.value"
          (selectedOptionChange)="participantFg?.controls?.participant?.patchValue($event || {})"
          (valueChange)="clearStaticOptions(participantFg.controls.role?.value)"
        />
        <fnip-button
          *ngIf="canAddUser | executeWith: participantFg.controls.role?.value"
          appearance="outline"
          icon="tuiIconPlus"
          btnHint="Добавить пользователя"
          class="mt-7"
          [actionCallback$]="
            addUser$ | executeWith: participantFg.controls.role?.value : participantFg?.controls?.participant
          "
        />
      </div>
    </div>

    <fnip-button
      btnSize="l"
      appearance="outline"
      icon="tuiIconPlusLarge"
      label="Добавить сотрудника"
      class="w-100"
      [actionCallback$]="addParticipant"
    />
  </div>

  <div *ngIf="productCode === ProductCode.Mortgage" class="dossier-card d-flex flex-column gap-6">
    <div class="dossier-card__title">Страхование</div>
    <fnip-divider />
    <fnip-reactive-select
      data-testid="insurance-info-life-select"
      fieldId="insuranceInfoLife"
      textFieldSize="l"
      label="Жизнь и здоровье"
      [control]="form.controls.insuranceInfo?.controls?.Life"
      [options]="insuranceProductTypes"
      [requiredIf]="true"
    />
    <fnip-reactive-select
      data-testid="insurance-info-title-select"
      fieldId="insuranceInfoTitle"
      textFieldSize="l"
      label="Титул"
      [control]="form.controls.insuranceInfo?.controls?.Title"
      [options]="insuranceProductTypes"
      [requiredIf]="true"
    />
    <fnip-reactive-select
      data-testid="insurance-info-property-select"
      fieldId="insuranceInfoProperty"
      textFieldSize="l"
      label="Имущество"
      [control]="form.controls.insuranceInfo?.controls?.Property"
      [options]="insuranceProductTypes"
      [requiredIf]="true"
    />
  </div>

  <fnip-button
    data-testid="save-button"
    btnSize="l"
    appearance="primary"
    class="w-100"
    label="Сохранить"
    [isDisabled]="!form?.valid"
    [actionCallback$]="save$"
  />
</div>

<ng-template #statusTemplate let-status>
  <div class="archive-status">{{ status }}</div>
</ng-template>
