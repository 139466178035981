import { TuiDay } from '@taiga-ui/cdk';
import { Nullable } from '@lib-utils';
import { InsuranceProductType, LoanAgreementState } from '@lib-archive/api';

export interface FeDossierFilterDto {
  text?: Nullable<string>;
  issueLoanDateFrom?: Nullable<TuiDay>;
  issueLoanDateTo?: Nullable<TuiDay>;
  loanAgreementState?: Nullable<LoanAgreementState>;
  lifeInsuranceTypes?: Nullable<InsuranceProductType>;
  cftDepartment?: Nullable<string>;
  dealDepartmentName?: Nullable<string>;
  actId?: Nullable<number>;
  withoutAct?: Nullable<boolean>;
}
