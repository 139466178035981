/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InsuranceProductType = 'Kss' | 'KssPlus' | 'Ass';

export const InsuranceProductType = {
    Kss: 'Kss' as InsuranceProductType,
    KssPlus: 'KssPlus' as InsuranceProductType,
    Ass: 'Ass' as InsuranceProductType
};

