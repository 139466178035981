import { HttpErrorResponse } from '@angular/common/http';
import { FileInfoScan, Nullable } from '@lib-utils';

export interface FilePreviewInfo<T> {
  fileId?: T;
  file?: Nullable<FileInfoScan<T>>;
  status?: FilePreviewStatus;
  error?: Nullable<HttpErrorResponse>;
}

export enum FilePreviewType {
  Full = 'full',
  Mini = 'mini',
}

export enum FilePreviewStatus {
  None = 'none',
  Loading = 'loading',
  Done = 'done',
}
