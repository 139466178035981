import isNil from 'lodash-es/isNil';
import { Nullable } from '@lib-utils';
import { InsuranceProductType } from '@lib-archive/api';
import { FeInsuranceProductType } from '@lib-archive/api-middleware';

export function toInsuranceInfo(
  feInfo: Nullable<Record<string, Nullable<FeInsuranceProductType>>>,
): Nullable<Record<string, InsuranceProductType>> {
  if (isNil(feInfo)) return feInfo;
  const insuranceInfo: Record<string, InsuranceProductType> = {};
  Object.entries(feInfo).forEach(([key, value]) => {
    if (value && value !== 'NotUsed') insuranceInfo[key] = value;
  });
  return insuranceInfo;
}
