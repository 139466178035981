import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import isNil from 'lodash-es/isNil';
import { concatMap, debounceTime, finalize, from, map, Observable, of, tap } from 'rxjs';
import { DEBOUNCE_TIME_DEFAULT, NotificationService, Nullable } from '@lib-utils';
import { injectDialogContext } from '@lib-widgets/dialog';
import { CreditDossierDto } from '@lib-archive/api';
import {
  createDossierFilterForm,
  creditDossierWithSelect,
  GET_DOSSIER_WITH_SELECT_GRID_OPTIONS,
  GET_DOSSIERS_GRID_OPTIONS,
  getBasePath,
} from '@lib-archive/utils';
import { AddRemoveDossierModalContext } from './add-remove-dossier-modal-context';

enum AddRemoveDossierModalTab {
  DossierList,
  SelectedDossiers,
}

@Component({
  selector: 'fnip-add-remove-dossier-modal',
  templateUrl: './add-remove-dossier-modal.component.html',
  styleUrls: ['./add-remove-dossier-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddRemoveDossierModalComponent {
  readonly dialogContext = injectDialogContext<Nullable<boolean>, AddRemoveDossierModalContext>();

  AddRemoveDossierModalTab = AddRemoveDossierModalTab;

  activeTab = AddRemoveDossierModalTab.DossierList;

  readonly basePath = getBasePath();

  gridOptions = GET_DOSSIER_WITH_SELECT_GRID_OPTIONS({
    basePath: this.basePath,
    showSelect: true,
    toggleDosserSelection: (dossier, value) => this.toggleDosserSelection(dossier, value),
  });

  selectedDossiersGridOptions = GET_DOSSIERS_GRID_OPTIONS({ basePath: this.basePath });

  page = 0;

  pages = 0;

  isQrActive = false;

  isScanning = false;

  isRemoveMode = !isNil(this.dialogContext.data?.dossiers);

  selectedDossiers: CreditDossierDto[] = [];

  filterForm = createDossierFilterForm();

  filterFormChanged$ = this.filterForm.valueChanges.pipe(
    debounceTime(DEBOUNCE_TIME_DEFAULT),
    tap(() => (this.dossierList$ = this.getDossiers())),
  );

  dossierList$ = this.getDossiers();

  constructor(
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {}

  getActionButtonLabel = (itemsCount: number) =>
    `${this.isRemoveMode ? 'Изъять' : 'Добавить'} выбранное (${itemsCount})`;

  getDossiers() {
    if (this.isRemoveMode) return of(this.dialogContext.data?.dossiers?.map((d) => creditDossierWithSelect(d, false)));
    if (!this.dialogContext.data?.getDossiersRq$) return null;
    return this.dialogContext.data
      .getDossiersRq$({
        page: this.page,
        perPage: 12,
        filterText: this.filterForm?.value?.text,
      })
      .pipe(
        tap((res) => (this.pages = res?.data?.page?.pages ?? 0)),
        map((res) => res?.data?.data ?? []),
        map((dossiers) =>
          dossiers.map((dossier) => creditDossierWithSelect(dossier, this.isDossierSelected(dossier.id))),
        ),
      );
  }
  isDossierSelected(id: number | undefined): boolean {
    return this.selectedDossiers.some((item) => item.id === id);
  }

  scanDossier(qrCode: Nullable<string>) {
    if (!qrCode || !this.dialogContext.data?.scanDossierRq$) return;
    this.isScanning = true;
    this.dialogContext.data
      ?.scanDossierRq$(qrCode)
      ?.pipe(
        tap((res) => this.addDossierToSelection(res)),
        tap(this.notificationService.onError('Досье не найдено')),
        tap(() => (this.dossierList$ = this.getDossiers())),
        finalize(() => {
          this.cdr.markForCheck();
          this.isScanning = false;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  toggleDosserSelection(dossier: CreditDossierDto, selected: boolean) {
    if (selected) {
      this.addDossierToSelection(dossier);
    } else {
      this.selectedDossiers = this.selectedDossiers.filter((item) => item.id !== dossier.id);
    }
  }

  toggleQrCodeMode = () => {
    this.isQrActive = !this.isQrActive;
    if (this.isQrActive) this.activeTab = AddRemoveDossierModalTab.SelectedDossiers;
  };

  addDossierToSelection(dossier: Nullable<CreditDossierDto>) {
    if (!dossier || this.selectedDossiers.some((item) => item.id === dossier.id)) return;
    this.selectedDossiers = [...this.selectedDossiers, dossier];
  }

  saveChangesCallback$ = () => {
    let action$: Nullable<Observable<unknown>>;
    const { addDossierRq$, removeDossiersRq$ } = this.dialogContext.data ?? {};
    if (this.isRemoveMode && removeDossiersRq$) {
      action$ = removeDossiersRq$(this.selectedDossiers.map(({ id }) => id));
    }
    if (!this.isRemoveMode && addDossierRq$) {
      action$ = from(this.selectedDossiers).pipe(concatMap((item) => addDossierRq$(item.id)!));
    }
    if (!action$) return null;
    return action$.pipe(
      tap(this.notificationService.onError('Что-то пошло не так')),
      finalize(() => this.dialogContext.ref?.close(true)),
    );
  };
}
