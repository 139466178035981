<div class="d-flex flex-column gap-6">
  <h2>Добавить пользователя</h2>
  <fnip-reactive-input fieldId="name" label="ФИО" textFieldSize="l" [control]="form.controls.name" />
  <fnip-reactive-input fieldId="email" label="Email" textFieldSize="l" [control]="form.controls.email" />
  <fnip-reactive-input-phone fieldId="phone" label="Номер телефона" textFieldSize="l" [control]="form.controls.phone" />

  <div class="d-flex content-end gap-4">
    <fnip-button label="Отменить" [actionCallback$]="cancel" />
    <fnip-button appearance="primary" label="Добавить" [isDisabled]="form.invalid" [actionCallback$]="save$" />
  </div>
</div>
