<ng-container *ngIf="filterFormChanged$ | async" />

<div class="d-flex gap-5 align-items-end">
  <fnip-archive-list-filter class="flex-2" [filterForm]="filterForm" />
  <fnip-button
    *ngIf="!isRemoveMode"
    appearance="primary"
    class="flex-1 w-100"
    [label]="isQrActive ? 'Завершить сканирование' : 'Сканировать QR'"
    [actionCallback$]="toggleQrCodeMode"
  />
  <fnip-button
    appearance="primary"
    class="flex-1 w-100"
    [label]="getActionButtonLabel | executeWith: selectedDossiers.length"
    [actionCallback$]="saveChangesCallback$"
  />
</div>

<fnip-scan-qr-code *ngIf="isQrActive" class="mt-2" [isLoading]="isScanning" (codeScanned)="scanDossier($event)" />

<tui-tabs [(activeItemIndex)]="activeTab">
  <button tuiTab>Список досье</button>
  <button tuiTab>Выбранные досье</button>
</tui-tabs>

<ng-container [ngSwitch]="activeTab">
  <fnip-request-wrapper *ngSwitchCase="AddRemoveDossierModalTab.DossierList" #wrapper [request$]="dossierList$">
    <fnip-grid class="mb-8" [gridOptions]="gridOptions" [rowData]="$any(wrapper.data)" />
    <tui-pagination
      *ngIf="pages > 1"
      [length]="pages"
      [(index)]="page"
      [sidePadding]="1"
      (indexChange)="dossierList$ = getDossiers()"
    />
  </fnip-request-wrapper>
  <fnip-grid
    *ngSwitchCase="AddRemoveDossierModalTab.SelectedDossiers"
    [gridOptions]="selectedDossiersGridOptions"
    [rowData]="selectedDossiers"
  />
</ng-container>
