import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TuiPreviewDialogService, TuiPreviewModule } from '@taiga-ui/addon-preview';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiButtonModule, TuiDialogContext, TuiNotificationModule } from '@taiga-ui/core';
import { TuiCarouselModule, TuiPaginationModule, TuiPdfViewerService } from '@taiga-ui/kit';
import { saveAs } from 'file-saver';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { catchError, from, mergeMap, Observable, of, tap } from 'rxjs';
import { ExecuteWithPipeModule, FileInfoScan, FileInfoScanTypes, Nullable } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { LoaderModule, RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { DocumentPreviewGalleryComponent } from './document-preview-gallery';
import { DocumentPreviewPanelComponent } from './document-preview-panel';
import { getPdfSrc } from './document-preview.utils';
import { FilePreviewInfo, FilePreviewStatus, FilePreviewType } from './file-preview-info';

@Component({
  selector: 'fnip-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    DocumentPreviewPanelComponent,
    DocumentPreviewGalleryComponent,
    TuiCarouselModule,
    TuiButtonModule,
    PdfViewerModule,
    TuiNotificationModule,
    ButtonModule,
    ExecuteWithPipeModule,
    TuiPaginationModule,
    TuiLetModule,
    TuiPreviewModule,
    LoaderModule,
    RequestWrapperModule,
  ],
})
export class DocumentPreviewComponent<FileId> implements OnChanges {
  /**
   * ID файлов
   */
  @Input() fileIds: Nullable<FileId[]>;

  @Input() set activeFileId(fileId: Nullable<FileId>) {
    if (!fileId) return;
    this.selectedIndex = this.fileIds?.indexOf(fileId) ?? 0;
  }

  /**
   * ID файлов, по которым есть мини-превью
   */
  @Input() previewFileIds: Nullable<FileId[]>;

  /**
   * Коллбэк на получение объекта FileInfoScan по ID файла
   */
  @Input() getFileByIdCallback$?: (fileId: FileId) => Observable<Nullable<FileInfoScan<FileId>>>;

  /**
   * Коллбэк на получение FileInfoScan для мини-превью по ID файла
   */
  @Input() getFilePreviewByIdCallback$?: (fileId: FileId) => Observable<Nullable<FileInfoScan<FileId>>>;

  /**
   * Наличие кнопки полноэкранного превью
   */
  @Input() hasFullScreenPreview = false;

  /**
   * Коллбэк на получение количества страниц в файле
   */
  @Input() getFilePagesCount: Nullable<(fileId: FileId) => number>;

  /**
   * Максимальное количество одновременно загружаемых файлов
   */
  @Input() maxConcurrent = 5;

  /**
   * Событие на изменение просматриваемого файла. Отправляет ID файла
   */
  @Output() fileInfoScanChange = new EventEmitter<FileId>();

  FileInfoScanTypes = FileInfoScanTypes;

  currentFile: Nullable<FileInfoScan<FileId>>;

  preview$: Nullable<Observable<unknown>>;

  selectedIndex = 0;

  fullScreenPreviewIndex = 0;

  zoom = 1;

  rotation = 0;

  loadFiles$: Record<string, Nullable<Observable<Nullable<FileInfoScan<FileId>>>>> = {};

  filePreviewInfos: Record<FilePreviewType, Nullable<FilePreviewInfo<FileId>[]>> = {
    full: null,
    mini: null,
  };

  FilePreviewType = FilePreviewType;

  FilePreviewStatus = FilePreviewStatus;

  getPdfSrc = getPdfSrc;

  constructor(
    private previewDialogService: TuiPreviewDialogService,
    private sanitizer: DomSanitizer,
    private pdfService: TuiPdfViewerService,
    public cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('fileIds' in changes) this.selectedIndex = 0;
  }

  setLoadFileById$ = (id: FileId) => {
    this.loadFiles$[id as string] = this.getFile$(id, this.getFileByIdCallback$, FilePreviewType.Full);
  };

  setCurrentFile = (files: Nullable<FilePreviewInfo<FileId>[]>, index: number) => {
    this.currentFile = files?.[index]?.file;
  };

  getFileInfo = (
    files: Nullable<FilePreviewInfo<FileId>[]>,
    fileId: Nullable<FileId>,
  ): Nullable<FilePreviewInfo<FileId>> => files?.find((fileInfo) => fileInfo.fileId === fileId);

  openPdfPreview(src: string | SafeResourceUrl) {
    return this.pdfService.open(src);
  }

  getFilePagesCountByIndex = (fileIds: Nullable<FileId[]>, getFilePagesCount: Nullable<(fileId: FileId) => number>) => {
    if (!fileIds || !getFilePagesCount) return null;
    return (index: number) => getFilePagesCount(fileIds[index]);
  };

  openImagePreview(preview: TemplateRef<TuiDialogContext<void>>) {
    return this.previewDialogService.open(preview);
  }

  openPreview$ = (preview: TemplateRef<TuiDialogContext<void>>) => {
    if (this.currentFile?.type && [FileInfoScanTypes.Image, FileInfoScanTypes.Tif].includes(this.currentFile.type))
      return this.openImagePreview(preview);
    if (this.currentFile?.type === FileInfoScanTypes.Pdf)
      return this.openPdfPreview(this.byPassSecureUrl(this.currentFile.value));
    return null;
  };

  byPassSecureUrl = (src?: string) => this.sanitizer.bypassSecurityTrustResourceUrl(src ?? '');

  downloadFile = (file: Nullable<FileInfoScan<FileId>>) => () => {
    if (!file) return;
    return saveAs(file.value, file.fileName ?? undefined);
  };

  initPreviewData$ = (
    fileIds: Nullable<FileId[]>,
    previewFileIds: Nullable<FileId[]>,
    fileCallback$: Nullable<(fileId: FileId) => Observable<Nullable<FileInfoScan<FileId>>>>,
    previewFileCallback$: Nullable<(fileId: FileId) => Observable<Nullable<FileInfoScan<FileId>>>>,
  ) => {
    if (!fileCallback$ || !fileIds?.length) {
      this.filePreviewInfos = {
        full: null,
        mini: null,
      };
      return;
    }
    // Формируем массивы filePreviewInfos в зависимости от поступивших id
    // Сразу ставим status = loading для тех файлов, по которым запустим загрузку здесь, чтобы избежать триггера lazy load
    this.filePreviewInfos[FilePreviewType.Full] = fileIds.map((fileId) => ({
      fileId,
      status: previewFileIds?.includes(fileId) ? FilePreviewStatus.None : FilePreviewStatus.Loading,
    }));
    this.filePreviewInfos[FilePreviewType.Mini] = previewFileIds?.map((fileId) => ({
      fileId,
      status: FilePreviewStatus.Loading,
    }));
    // Очищаем карту для ленивой подгрузки/ретраев
    this.loadFiles$ = {};
    // Запускаем загрузку превью файлов и файлов, по которым превью нет
    return from(fileIds).pipe(
      mergeMap((fileId) => {
        const action$ = previewFileIds?.includes(fileId)
          ? this.getFile$(fileId, previewFileCallback$, FilePreviewType.Mini)
          : this.getFile$(fileId, fileCallback$, FilePreviewType.Full);
        return action$?.pipe(catchError(() => of(null))) ?? of(null);
      }, this.maxConcurrent),
    );
  };

  initLazyLoadFiles = (filePreviewInfos: Nullable<FilePreviewInfo<FileId>[]>, currentIndex: number) => {
    if (!filePreviewInfos) return;

    const { fileId, status } = filePreviewInfos[currentIndex] || {};

    // Триггерим ленивую загрузку файла на выбранной странице превью, если она уже не идет
    if (fileId && status === FilePreviewStatus.None) this.setLoadFileById$(fileId);
  };

  getFile$ = (
    fileId: Nullable<FileId>,
    getFileByIdCallback$: Nullable<(fileId: FileId) => Observable<Nullable<FileInfoScan<FileId>>>>,
    previewType: FilePreviewType,
  ) => {
    if (!fileId || !getFileByIdCallback$) return null;

    this.updateFilePreviews(
      {
        fileId,
        file: null,
        status: FilePreviewStatus.Loading,
        error: null,
      },
      previewType,
    );

    return getFileByIdCallback$?.(fileId).pipe(
      tap({
        next: (file) => {
          this.updateFilePreviews({ fileId, file, status: FilePreviewStatus.Done }, previewType);
          this.cdr.detectChanges();
        },
        error: (error) => {
          this.updateFilePreviews({ fileId, error, status: FilePreviewStatus.Done }, previewType);
          this.cdr.detectChanges();
        },
      }),
    );
  };

  getPreviewGallery = (
    fullPreviews: Nullable<FilePreviewInfo<FileId>[]>,
    miniPreviews: Nullable<FilePreviewInfo<FileId>[]>,
  ) => fullPreviews?.map((preview) => miniPreviews?.find((mp) => mp.fileId === preview.fileId) ?? preview);

  openInNewWindow(el: HTMLImageElement) {
    window.open('')?.document.write(el.outerHTML);
  }

  openPrevious = () => {
    this.selectedIndex = this.selectedIndex - 1;
    this.emitFileChange(this.selectedIndex);
  };

  openNext = () => {
    this.selectedIndex = this.selectedIndex + 1;
    this.emitFileChange(this.selectedIndex);
  };

  emitFileChange(index: number) {
    const fileId = this.fileIds?.[index];
    if (fileId) this.fileInfoScanChange.next(fileId);
  }

  private updateFilePreviews(updateItem: FilePreviewInfo<FileId>, previewType: FilePreviewType) {
    this.filePreviewInfos[previewType] = this.filePreviewInfos[previewType]?.map((fileInfo) =>
      fileInfo.fileId === updateItem.fileId ? updateItem : fileInfo,
    );
  }
}
