import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TuiLoaderModule, TuiScrollbarModule, TuiSvgModule } from '@taiga-ui/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ExecuteWithPipeModule, FileInfoScanTypes, Nullable } from '@lib-utils';
import { EllipsisHintComponent } from '@lib-widgets/core';
import { getPdfSrc } from '../document-preview.utils';
import { FilePreviewInfo, FilePreviewStatus } from '../file-preview-info';

@Component({
  selector: 'fnip-document-preview-gallery',
  templateUrl: './document-preview-gallery.component.html',
  styleUrls: ['./document-preview-gallery.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    EllipsisHintComponent,
    ExecuteWithPipeModule,
    TuiLoaderModule,
    TuiScrollbarModule,
    TuiSvgModule,
    ScrollingModule,
    PdfViewerModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewGalleryComponent<FileId> {
  @Input() selectedIndex: Nullable<number>;

  @Input() filePreviewInfos: Nullable<FilePreviewInfo<FileId>[]>;

  @Output() selectedIndexChange = new EventEmitter<number>();

  galleryItemSize = 80;

  galleryItemGap = 8;

  FileInfoScanTypes = FileInfoScanTypes;

  FilePreviewStatus = FilePreviewStatus;

  readonly sanitizer = inject(DomSanitizer);

  getPdfSrc = getPdfSrc;

  getGalleryWidth = (files: Nullable<number>) =>
    `min(${(this.galleryItemSize + this.galleryItemGap) * (files ?? 1)}px, 100%)`;

  byPassSecureUrl = (src?: string) => this.sanitizer.bypassSecurityTrustResourceUrl(src ?? '');
}
