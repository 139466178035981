<fnip-request-wrapper [request$]="request$">
  <div class="d-flex gap-7">
    <div class="d-flex flex-column gap-7">
      <div class="box-card d-flex flex-column gap-5">
        <div data-testid="box-card-title" class="box-card__title">Короб № {{ box?.id }}</div>
        <fnip-divider />
        <fnip-labeled-content
          label="Статус"
          data-testid="box-card-status"
          [content]="statusTemplate"
          [contentContext]="getBoxState | executeWith: box?.state"
          [inline]="true"
        ></fnip-labeled-content>
        <fnip-labeled-content
          label="Количество досье"
          data-testid="box-card-dossier-count"
          [content]="box?.dossiers?.length"
          [inline]="true"
        />
        <fnip-labeled-content
          *ngIf="!isEditBoxNumber; else editBoxNumberTmp"
          label="Номер короба"
          data-testid="box-card-box-number"
          [content]="boxNumberTemplate"
          [inline]="true"
        />
        <fnip-labeled-content
          data-testid="box-card-osg"
          label="ОСГ"
          [content]="box?.osg?.name"
          [disableEllipsis]="true"
          [inline]="true"
        />
      </div>
      <div class="d-flex flex-column gap-3">
        <fnip-button
          *ngIf="SUPPORT_ROLES | hasRoles"
          data-testid="box-action-button"
          appearance="primary"
          btnSize="l"
          class="w-100"
          [isDisabled]="isActionDisabled"
          [label]="getActionLabel | executeWith: box?.state"
          [actionCallback$]="getAction | executeWith: box?.state"
        />
        <fnip-button
          appearance="secondary-light-grey"
          btnSize="l"
          icon="assets/icons/back.svg"
          label="К списку коробов"
          class="w-100"
          [btnRouterLink]="['../']"
        />
      </div>
    </div>
    <div class="mr-10 d-flex flex-column gap-7 w-100">
      <div *ngIf="isNotInStorage | executeWith: box?.state" class="d-flex gap-3">
        <ng-container *ngIf="SUPPORT_ROLES | hasRoles">
          <fnip-button
            data-testid="box-remove-dossier-button"
            appearance="secondary-light-grey"
            btnSize="l"
            label="Изъять из короба"
            [style.width.px]="240"
            [actionCallback$]="openRemoveDialog"
            [isDisabled]="!dossiers.length"
          />
          <fnip-button
            data-testid="box-add-dossier-button"
            appearance="secondary-light-grey"
            btnSize="l"
            label="Добавить"
            class="w-200px"
            [actionCallback$]="openAddDialog"
            [btnHint]="box?.boxNumber ? null : 'Перед добавлением досье задайте номер коробу'"
            [icon]="box?.boxNumber ? null : 'tuiIconAlertCircle'"
            [isDisabled]="!box?.boxNumber"
          />
        </ng-container>
        <fnip-button
          data-testid="box-inventory-button"
          appearance="secondary-light-grey"
          btnSize="l"
          label="Опись досье"
          class="w-200px"
          [actionCallback$]="getBoxInventory"
        />
      </div>
      <fnip-grid [gridOptions]="gridOptions" [rowData]="dossiers" />
    </div>
  </div>
</fnip-request-wrapper>

<ng-template #statusTemplate let-status>
  <div class="box-status">{{ status }}</div>
</ng-template>

<ng-template #boxNumberTemplate>
  <div class="d-flex align-items-center gap-2">
    <span *ngIf="box?.boxNumber" class="box-number font-weight-bold font-size-16">{{ box?.boxNumber }}</span>
    <fnip-button
      *ngIf="box?.state === BoxState.Filling && (SUPPORT_ROLES | hasRoles)"
      data-testid="box-number-edit-button"
      btnSize="s"
      icon="tuiIconEdit"
      [actionCallback$]="toggleEditMode"
    />
  </div>
</ng-template>

<ng-template #editBoxNumberTmp>
  <div class="d-flex align-items-end gap-2">
    <fnip-reactive-input
      fieldId="boxNumber"
      data-testid="box-number-input"
      textFieldSize="s"
      label="Номер короба"
      labelSize="s"
      class="flex-1"
      [control]="boxNumberControl"
    />
    <fnip-button
      data-testid="box-number-save-button"
      btnSize="s"
      icon="tuiIconSave"
      [isDisabled]="boxNumberControl.invalid"
      [actionCallback$]="saveBoxNumber$"
    />
    <fnip-button
      data-testid="box-number-cancel-button"
      btnSize="s"
      icon="tuiIconXCircle"
      [actionCallback$]="toggleEditMode"
    />
  </div>
</ng-template>
