import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentsDownloadComponent } from './views/documents-download';
import { DossierSearchComponent } from './views/dossier-search';
import { ReportListComponent } from './views/report-list';

const routes: Routes = [
  {
    path: '',
    title: 'Список отчетов',
    component: ReportListComponent,
  },
  {
    path: 'new',
    component: DossierSearchComponent,
    title: 'Формирование отчета',
  },
  {
    path: 'documents-download',
    component: DocumentsDownloadComponent,
    title: 'Скачивание документов',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
