<h2 class="my-4">Короб</h2>

<fnip-reactive-input
  data-testid="box-number-input"
  class="mb-3"
  fieldId="boxNumber"
  label="Номер короба"
  textFieldSize="l"
  [control]="form.controls.boxNumber"
/>
<fnip-reactive-select
  data-testid="osg-select"
  class="mb-10"
  fieldId="osg"
  label="ОСГ"
  textFieldSize="l"
  [control]="form.controls.osg"
  [options]="osgOptions$"
/>

<div class="actions">
  <fnip-button
    data-testid="action-button"
    appearance="primary"
    btnSize="l"
    label="Отправить"
    [actionCallback$]="updateBox$"
    [isDisabled]="form.invalid"
  />
</div>
