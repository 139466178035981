/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InsuranceProductType } from './insuranceProductType';
import { DossierParticipantDto } from './dossierParticipantDto';
import { ProductCode } from './productCode';
import { PersonDto } from './personDto';
import { RegistrationType } from './registrationType';
import { PaymentType } from './paymentType';
import { CreditPurpose } from './creditPurpose';
import { LoanAgreementState } from './loanAgreementState';
import { NonStandardType } from './nonStandardType';
import { ProofOfIncome } from './proofOfIncome';


export interface CreditDossierManualDto { 
    loanAgreementNumber?: string | null;
    loanAgreementState?: LoanAgreementState;
    loanApplicationNumber?: string | null;
    interestRate?: number;
    issueLoanDate?: string | null;
    plannedEndDate?: string | null;
    incomeVerificationMethod?: ProofOfIncome;
    initialPaymentMethod?: PaymentType;
    creditProgramId?: number;
    actId?: number | null;
    borrowers?: Array<PersonDto> | null;
    participants?: Array<DossierParticipantDto> | null;
    issuedLoanId?: number;
    issuedLoan?: PersonDto;
    insuranceInfo?: { [key: string]: InsuranceProductType; } | null;
    registrationType?: RegistrationType;
    purpose?: CreditPurpose;
    nonStandard?: boolean | null;
    nonStandardComment?: string | null;
    nonStandardType?: NonStandardType;
    productCode?: ProductCode;
    city?: string | null;
    showroom?: string | null;
    withInsurance?: boolean;
    dealDepartmentName?: string | null;
    cftDepartment?: string | null;
    cftCreditAgreementId?: number | null;
}
export namespace CreditDossierManualDto {
}


