/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DossierState } from './dossierState';
import { InsuranceProductType } from './insuranceProductType';
import { ProductCode } from './productCode';
import { RegistrationType } from './registrationType';
import { DatePeriod } from './datePeriod';
import { LoanAgreementState } from './loanAgreementState';
import { DateTime } from '@lib-utils';


export interface DossiersFilterForReports { 
    created?: DatePeriod;
    creator?: string | null;
    state?: DossierState;
    actId?: number | null;
    inBox?: boolean | null;
    text?: string | null;
    issueLoanDate?: DatePeriod;
    loanAgreementState?: LoanAgreementState;
    insuranceProductType?: InsuranceProductType;
    issuedLoan?: string | null;
    loanApplicationNumber?: string | null;
    cftDepartment?: string | null;
    productCode?: ProductCode;
    inAct?: boolean | null;
    leftDateBorder?: DateTime | null;
    rightDateBorder?: DateTime | null;
    productType?: string | null;
    status?: string | null;
    dossierState?: DossierState;
    supportFullName?: string | null;
    curatorFullName?: string | null;
    agentFullName?: string | null;
    creditProgram?: string | null;
    loanIssueRegion?: string | null;
    registrationType?: RegistrationType;
    dealDepartmentName?: string | null;
    containsDocuments?: string | null;
}
export namespace DossiersFilterForReports {
}


