import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Nullable } from '@lib-utils';
import { DocumentTypeVerificationDetails } from '@lib-archive/api';

export type DocumentCheckForm = FormGroup<{
  checks: FormArray<FormControl<Nullable<boolean>>>;
  comment: FormControl<Nullable<string>>;
}>;

export const createDocumentCheckForm = (): DocumentCheckForm =>
  new FormGroup({
    comment: new FormControl<Nullable<string>>(''),
    checks: new FormArray<FormControl<Nullable<boolean>>>([]),
  });

export const updateDocumentCheckForm = (
  form: DocumentCheckForm,
  checkResults: DocumentTypeVerificationDetails,
  disabled: boolean,
) => {
  form.controls.checks.clear();
  checkResults.checks?.forEach((checkResult) => {
    form.controls.checks.push(new FormControl<Nullable<boolean>>(checkResult.ok, [Validators.required]));
  });

  form.controls.comment.reset();
  form.controls.comment.setValue(checkResults.comment);

  if (disabled) form.disable();
  else form.enable();
};
