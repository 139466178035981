<ng-container *ngIf="preview$ | async" />
<ng-container
  *ngIf="
    initPreviewData$
      | executeWith: fileIds : previewFileIds : getFileByIdCallback$ : getFilePreviewByIdCallback$
      | async
  "
/>
<ng-container *ngIf="initLazyLoadFiles | executeWith: filePreviewInfos[FilePreviewType.Full] : selectedIndex" />
<ng-container *ngIf="setCurrentFile | executeWith: filePreviewInfos[FilePreviewType.Full] : selectedIndex" />

<div *ngIf="fileIds && fileIds.length > 1" class="carousel">
  <div tuiCarouselButtons class="carousel__body">
    <fnip-button icon="tuiIconChevronLeftLarge" [isDisabled]="selectedIndex === 0" [actionCallback$]="openPrevious" />
    <tui-carousel [itemsCount]="1" [(index)]="selectedIndex">
      <ng-container *ngFor="let fileId of fileIds">
        <ng-container *tuiItem [ngTemplateOutlet]="filePreviewTemplate" [ngTemplateOutletContext]="{ fileId }" />
      </ng-container>
    </tui-carousel>
    <fnip-button
      icon="tuiIconChevronRightLarge"
      [isDisabled]="selectedIndex === fileIds.length - 1"
      [actionCallback$]="openNext"
    />
  </div>
</div>

<div *ngIf="fileIds?.length === 1">
  <ng-container *ngTemplateOutlet="filePreviewTemplate; context: { fileId: fileIds?.[0] }" />
</div>

<div *ngIf="!fileIds?.length" class="missing-files d-flex content-center align-items-center">
  <span>Файл(-лы) отсутствуют</span>
</div>

<fnip-document-preview-panel
  class="d-block mt-2"
  [hasFullScreenPreview]="hasFullScreenPreview"
  [getFilePagesCount]="getFilePagesCountByIndex | executeWith: fileIds : getFilePagesCount"
  [filesCount]="fileIds?.length ?? 0"
  [(selectedIndex)]="selectedIndex"
  [(zoom)]="zoom"
  [(rotation)]="rotation"
  (openPreview)="preview$ = openPreview$(imagePreviewTemplate)"
  (selectedIndexChange)="emitFileChange($event)"
>
  <ng-content select="[fnipExtraDocumentPreviewActions]"></ng-content>
</fnip-document-preview-panel>

<fnip-document-preview-gallery
  [filePreviewInfos]="
    getPreviewGallery | executeWith: filePreviewInfos[FilePreviewType.Full] : filePreviewInfos[FilePreviewType.Mini]
  "
  [(selectedIndex)]="selectedIndex"
  (selectedIndexChange)="emitFileChange($event)"
></fnip-document-preview-gallery>

<ng-template #filePreviewTemplate let-fileId="fileId">
  <ng-container *tuiLet="getFileInfo | executeWith: filePreviewInfos[FilePreviewType.Full] : fileId as fileInfo">
    <ng-container *ngIf="loadFiles$[fileId] | async" />

    <fnip-loader
      *ngIf="fileInfo"
      [isLoading]="fileInfo.status === FilePreviewStatus.Loading"
      [error]="fileInfo.error"
      [showContent]="!!fileInfo.file"
      (retry)="setLoadFileById$(fileId)"
    >
      <ng-container [ngSwitch]="fileInfo.file?.type">
        <div *ngSwitchCase="FileInfoScanTypes.Image" class="img-container w-100">
          <img
            class="position-absolute"
            [style.height.%]="zoom * 100"
            [style.transform]="'rotate(' + rotation + 'deg)'"
            [alt]="fileInfo.file?.fileId"
            [src]="byPassSecureUrl | executeWith: fileInfo.file?.value"
          />
        </div>

        <div *ngSwitchCase="FileInfoScanTypes.Tif" class="img-container w-100">
          <img
            *ngFor="let url of fileInfo.file?.values"
            [style.height.%]="zoom * 100"
            [style.transform]="'rotate(' + rotation + 'deg)'"
            [alt]="fileInfo.file?.fileId"
            [src]="byPassSecureUrl | executeWith: url"
          />
        </div>

        <div *ngSwitchCase="FileInfoScanTypes.Pdf" class="w-100">
          <pdf-viewer
            class="overflow w-100"
            [src]="getPdfSrc | executeWith: fileInfo.file?.value"
            [zoom]="zoom"
            [rotation]="rotation"
            [render-text]="true"
            [original-size]="false"
            [show-all]="true"
          ></pdf-viewer>
        </div>

        <div *ngSwitchDefault class="preview-unavailable-container d-flex content-center align-items-center">
          <tui-notification status="warning" [hasIcon]="false">
            <div class="d-flex mt-5 flex-column align-items-center gap-2">
              Предпросмотр недоступен, нажмите кнопку, чтобы скачать файл
              <fnip-button label="Скачать файл" [actionCallback$]="downloadFile | executeWith: fileInfo.file">
              </fnip-button>
            </div>
          </tui-notification>
        </div>
      </ng-container>
    </fnip-loader>
  </ng-container>
</ng-template>

<ng-template #imagePreviewTemplate let-preview>
  <tui-preview [rotatable]="true" [zoomable]="true">
    <tui-preview-pagination
      *ngIf="currentFile?.values"
      [(index)]="fullScreenPreviewIndex"
      [length]="currentFile?.values?.length ?? 0"
    ></tui-preview-pagination>
    <button
      tuiIconButton
      tuiPreviewAction
      icon="tuiIconImgLarge"
      title="Открыть изображение в новой вкладке"
      (click)="openInNewWindow(previewImg)"
    ></button>
    <button
      tuiIconButton
      tuiPreviewAction
      icon="tuiIconCloseLarge"
      title="Закрыть"
      (click)="preview.complete(); fullScreenPreviewIndex = 0"
    ></button>
    <img
      #previewImg
      [alt]="currentFile?.fileId"
      [src]="
        byPassSecureUrl
          | executeWith: (currentFile?.values ? currentFile?.values?.[fullScreenPreviewIndex] : currentFile?.value)
      "
    />
  </tui-preview>
</ng-template>
