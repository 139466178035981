import { inject } from '@angular/core';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { beToFeOptions, OptionsConfig } from '@lib-utils';
import { PersonDto, UserArchiveApiService } from '@lib-archive/api';
import { ElectronicAchiveApiService } from '@lib-mortgage/api';
import { getUserQuery } from './get-user-query';
import { toPersonDto } from './to-person-dto';

//TODO: https://tracker.yandex.ru/RARCHIVE-29 - Добавление параметра `productCode` и объединение методов
export const getUserOptions$ = (
  config: OptionsConfig<PersonDto> = {},
  electronicArchiveService = inject(ElectronicAchiveApiService),
  userArchiveService = inject(UserArchiveApiService),
) => {
  return (query: string) =>
    electronicArchiveService.apiElectronicAchiveUsersGet(1, 10, { filters: getUserQuery(query) }).pipe(
      switchMap(({ data }) =>
        data?.data?.length
          ? of(data?.data?.map(toPersonDto))
          : userArchiveService
              .apiUserGet({ pagePage: 1, pagePerPage: 10, filterText: query })
              .pipe(map(({ data }) => data?.data)),
      ),
      map((options) => beToFeOptions(options, config)),
    );
};
