<ng-container *ngIf="routerParams$ | async" />

<h2 class="my-4 font-weight-bold">Коробы</h2>
<div *tuiLet="archiveProductService.activeProduct$ | async as activeProduct" class="d-flex gap-8">
  <fnip-list-sidebar
    hasCount
    hasCalculatedTotal
    optionName="state"
    [optionMap]="stateMap"
    [optionCountMap$]="stateCount$"
    [initialOption]="filters.value.state"
  >
    <fnip-button
      *ngIf="SUPPORT_ROLES | hasRoles"
      data-testid="box-create-button"
      appearance="primary"
      class="mb-5 w-100"
      label="Создать короб"
      icon="tuiIconPlus"
      [actionCallback$]="createBoxAndNavigate | executeWith: activeProduct"
    />
  </fnip-list-sidebar>

  <div class="d-flex flex-column w-100 pr-10 gap-5">
    <fnip-filter [form]="filters" (valueChanges)="grid.fetchData({ resetPage: true })">
      <fnip-reactive-input
        data-testid="box-number-filter"
        fnipFilterControl
        placeholder="№ Короба"
        [control]="filters.controls.boxNumber"
        fieldId="actId"
      />
    </fnip-filter>

    <fnip-grid
      #grid
      [gridOptions]="gridOptions"
      [rowData]="getBoxes$ | executeWith: activeProduct"
      [columnsCheckVisibleParams]="columnsCheckVisibleParams"
    />
  </div>
</div>
