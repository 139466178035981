export enum DossierSearchStrategy {
  Attached = 'attached',
  InWork = 'inWork',
  FromExcel = 'fromExcel',
}

export const DossierSearchStrategyMap = new Map<DossierSearchStrategy, string>([
  [DossierSearchStrategy.Attached, 'По закрепленным офисам'],
  [DossierSearchStrategy.InWork, 'Досье в работе'],
  [DossierSearchStrategy.FromExcel, 'Реестр досье из файла excel'],
]);
