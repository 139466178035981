import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { TuiPrimitiveCheckboxModule } from '@taiga-ui/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigService } from '@lib-config';
import { ExecuteWithPipeModule, IS_DEV_MODE_KEY, NotificationService, Nullable } from '@lib-utils';
import { ButtonModule, DividerModule, LabeledContentComponent } from '@lib-widgets/core';
import { DialogService, injectDialogService } from '@lib-widgets/dialog';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import {
  CreditDossierDto,
  CreditPurpose,
  DocumentDto,
  DossierArchiveApiService,
  DossierParticipantDto,
  DossierRole,
  DossierState,
  EmployeeType,
  IdNameDto,
  InsuranceProductType,
  LoanAgreementState,
  NonStandardType,
  PaymentType,
  PersonDto,
  PhysicalType,
  ProductCode,
  ProofOfIncome,
  RegistrationType,
} from '@lib-archive/api';
import {
  CreditPurposeMap,
  DossierRoleMap,
  DossierStateTypeMap,
  InsuranceProductTypeMap,
  LoanAgreementStateTypeMap,
  NonStandardTypeMap,
  PaymentTypeMap,
  PhysicalTypeMap,
  ProofOfIncomeTypeMap,
  RegistrationTypeMap,
} from '@lib-archive/api-middleware';
import { getBasePath, getUserOptions$, sortBorrowers } from '@lib-archive/utils';
import { CreateUserModalComponent } from '@lib-archive/widgets/create-user-modal';
import { HasPermissionPipeModule, PermissionService } from '@lib-mortgage/features/authorization';
import { DossierPersonInfoComponent } from '../dossier-person-info';

@Component({
  selector: 'fnip-dossier-info',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    DividerModule,
    HasPermissionPipeModule,
    LabeledContentComponent,
    ExecuteWithPipeModule,
    DossierPersonInfoComponent,
    RequestWrapperModule,
    TuiPrimitiveCheckboxModule,
  ],
  templateUrl: './dossier-info.component.html',
  styleUrls: ['./dossier-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class DossierInfoComponent {
  @Input() dossier: Nullable<CreditDossierDto>;
  @Input() documents: Nullable<DocumentDto[]>;
  @Input({ required: true }) creditPrograms$?: Observable<Nullable<IdNameDto[]>>;
  @Output() personChanged = new EventEmitter<void>();

  private readonly dialogService = injectDialogService();
  private readonly dossierArchiveApiService = inject(DossierArchiveApiService);
  private readonly notificationService = inject(NotificationService);
  private readonly permissionService = inject(PermissionService);

  readonly DossierState = DossierState;
  readonly ProductCode = ProductCode;
  readonly isDevMode = !!localStorage.getItem(IS_DEV_MODE_KEY);
  readonly basePath = getBasePath();
  readonly configService = inject(ConfigService);

  getDocumentsWithSign = (documents: Nullable<DocumentDto[]>) =>
    documents
      ?.filter((doc) => doc.filesNew?.some((file) => file.name?.toLocaleLowerCase().includes('.sig')))
      .map(({ documentType }) => documentType?.name);

  getCreditPurpose = (purpose: Nullable<CreditPurpose>) => purpose && CreditPurposeMap[purpose];

  getDossierState = (type: Nullable<DossierState>) => type && DossierStateTypeMap.get(type);

  getLoanAgreementState = (type: Nullable<LoanAgreementState>) => type && LoanAgreementStateTypeMap.get(type);

  getInsuranceProductType = (type: Nullable<InsuranceProductType>) =>
    type ? InsuranceProductTypeMap.get(type) : 'Не используется';

  isStatusWithLocation = (type: Nullable<DossierState>) =>
    type && [DossierState.WithdrawnOnDemand, DossierState.OffsiteStorage].includes(type);

  getIncomeConfirmationType = (type: Nullable<ProofOfIncome>) => type && ProofOfIncomeTypeMap.get(type);

  getPaymentType = (type: Nullable<PaymentType>) => type && PaymentTypeMap.get(type);

  getOrderLink = (orderId: Nullable<string>) => `${this.configService.hostUrl}/orders/edit/${orderId}`;

  sortBorrowers = sortBorrowers;

  getCreditProgramName = (creditPrograms: Nullable<IdNameDto[]>, id: Nullable<number>) =>
    creditPrograms?.find((program) => program.id === id)?.name;

  getPhysicalTypeName = (type: Nullable<PhysicalType>) => type && PhysicalTypeMap[type];

  getRegistrationTypeName = (type: Nullable<RegistrationType>) => type && RegistrationTypeMap[type];

  getLocation = (dossier: Nullable<CreditDossierDto>) =>
    dossier?.state === DossierState.WithdrawnOnDemand ? dossier.withdrawnOnDemandLocation : dossier?.box?.osg?.name;

  getNonStandardTypeName = (type: Nullable<NonStandardType>) => type && NonStandardTypeMap[type];

  canSeeParticipant = (role: Nullable<DossierRole>, dossier: Nullable<CreditDossierDto>) =>
    role !== DossierRole.AcceptedForSupport ||
    (this.permissionService.checkExactPermissionFor('canSeeAcceptedToSupport') &&
      dossier?.state === DossierState.AcceptedForSupport);

  getParticipantRole = (role: Nullable<DossierRole>) => (role && DossierRoleMap[role]) ?? 'Неизвестная роль';

  dealMakerMissing = (participants: Nullable<DossierParticipantDto[]>) =>
    !participants?.some(({ role }) => role === DossierRole.DealMaker);

  userOptions$ = getUserOptions$();

  addDealMaker$ = () =>
    this.dialogService.open<Nullable<PersonDto>, PersonDto>(CreateUserModalComponent, {
      contextData: {
        employeeType: EmployeeType.User,
      },
      hostOptions: {
        isDismissible: false,
      },
    });

  saveDealMaker$ = (dealMaker: PersonDto) => {
    if (!this.dossier?.id) return of(null);
    return this.dossierArchiveApiService
      .apiDossierDossierIdDealMakerPost({ dossierId: this.dossier.id, personDto: dealMaker })
      .pipe(
        tap(this.notificationService.onError('Ошибка при добавлении проведенца сделки')),
        tap(() => this.personChanged.emit()),
      );
  };

  saveBorrower$ = (borrower: PersonDto) => {
    if (!borrower?.id) return of(null);
    // Запрос на бэк упадет, если не экранировать строку с помощью ""
    return this.dossierArchiveApiService
      .apiDossierBorrowerPersonIdPut({ personId: borrower.id!, body: `"${borrower.name ?? ''}"` })
      .pipe(
        tap(() => this.personChanged.emit()),
        tap(this.notificationService.onError('Ошибка при сохранении ФИО')),
      );
  };
}
