import { HttpResponse } from '@angular/common/http';
import last from 'lodash-es/last';
import { PDFSource } from 'ng2-pdf-viewer';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import UTIF from 'utif2';
import {
  FileInfoScan,
  FileInfoScanTypes,
  getFileName,
  getFileType,
  isKnownMimeType,
  MimeTypesExtensionsMap,
  Nullable,
} from '@lib-utils';

/**
 * @deprecated Не обрабатывает tif и не сохраняет имя файла.
 * Использовать mapBlobResponseFileInfoScan
 */
export const mapBlobFileInfoScan = <T>(fileId: T) =>
  map((blob: Blob): FileInfoScan<T> => {
    const type = getFileType(blob.type);
    const value = URL.createObjectURL(blob);
    return {
      value,
      type: type === FileInfoScanTypes.Tif ? FileInfoScanTypes.Other : type,
      fileId,
      fileType: getFileExtension(blob.type),
    };
  });

export const mapBlobResponseFileInfoScan = <T>(fileId: T) =>
  switchMap((res: HttpResponse<Blob>): Observable<Nullable<FileInfoScan<T>>> => {
    if (!res.body) return of(null);
    const type = getFileType(res.body.type);
    const fileName = getFileName(res.headers?.get('content-disposition'));
    if (type === FileInfoScanTypes.Tif) {
      return from(res.body.arrayBuffer()).pipe(
        map(decodeTiff),
        map((values) => ({
          value: values[0],
          type,
          fileId,
          fileType: getFileExtension(res.body!.type, fileName),
          fileName,
          values,
        })),
      );
    }
    return of({
      value: URL.createObjectURL(res.body),
      type,
      fileId,
      fileType: getFileExtension(res.body.type, fileName),
      fileName,
    });
  });

export const getFileExtension = (fileType: string, fileName?: Nullable<string>) => {
  if (isKnownMimeType(fileType)) return MimeTypesExtensionsMap[fileType];

  return last(fileName?.split('.') ?? fileType.split('/'))?.toLocaleUpperCase();
};

export function getPdfSrc(url: Nullable<string>): PDFSource {
  return { url: url ?? undefined };
}

function decodeTiff(buffer: ArrayBuffer): string[] {
  const ifds = UTIF.decode(buffer);
  return ifds.map((ifd) => {
    UTIF.decodeImage(buffer, ifd);
    const rgba = UTIF.toRGBA8(ifd); // Uint8Array with RGBA pixels
    const cnv = document.createElement('canvas');
    cnv.width = ifd.width;
    cnv.height = ifd.height;
    const ctx = cnv.getContext('2d'),
      imgd = ctx?.createImageData(cnv.width, cnv.height);
    if (imgd) {
      for (let i = 0; i < rgba.length; i++) imgd.data[i] = rgba[i];
      ctx?.putImageData(imgd, 0, 0);
    }
    return cnv.toDataURL();
  });
}
