import { NgModule } from '@angular/core';
import { ActsRoutingModule } from './acts-routing.module';
import { ActsComponent } from './acts.component';
import { ActModule } from './views/act';

@NgModule({
  declarations: [ActsComponent],
  exports: [ActsComponent],
  imports: [ActModule, ActsRoutingModule],
})
export class ActsModule {}
