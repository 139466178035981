import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiBadgeModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { AccordionItemComponent, ButtonModule, DividerModule, LabeledContentComponent } from '@lib-widgets/core';
import { DocumentPreviewComponent, ExtraDocumentPreviewActionsDirective } from '@lib-widgets/document-preview';
import {
  ReactiveButtonCheckboxModule,
  ReactiveCheckboxModule,
  ReactiveFileInputModule,
  ReactiveTextAreaModule,
} from '@lib-widgets/reactive-fields';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { DocumentVersionModule } from '../../../../widgets/document-version';
import { DocumentComponent } from './document.component';

@NgModule({
  imports: [
    CommonModule,
    RequestWrapperModule,
    ButtonModule,
    DividerModule,
    DocumentVersionModule,
    ExecuteWithPipeModule,
    LabeledContentComponent,
    ReactiveButtonCheckboxModule,
    ReactiveCheckboxModule,
    ReactiveTextAreaModule,
    ReactiveFileInputModule,
    TuiBadgeModule,
    TuiLetModule,
    DocumentPreviewComponent,
    ExtraDocumentPreviewActionsDirective,
    AccordionItemComponent,
  ],
  declarations: [DocumentComponent],
  exports: [DocumentComponent],
})
export class DocumentModule {}
