<fnip-request-wrapper #dossierRq [request$]="dossierRq$">
  <div>
    <h2 class="my-4">Договор № {{ dossier?.loanAgreementNumber }}</h2>
  </div>

  <div *tuiLet="isDossierEditable | executeWith: dossier?.state as isEditable" class="d-flex gap-7">
    <div class="d-flex flex-column gap-3">
      <fnip-dossier-form
        *ngIf="isEditable"
        class="w-600px"
        [creditPrograms$]="creditPrograms$"
        [form]="getDossierForm | executeWith: dossier"
        [state]="dossier?.state"
        [saveCallback$]="saveDossierChanges$"
      />
      <fnip-dossier-info
        *ngIf="!isEditable"
        [creditPrograms$]="creditPrograms$"
        [dossier]="dossier"
        [documents]="documents"
        (personChanged)="dossierRq.reload()"
      />
      <div class="d-flex flex-column gap-3">
        <ng-container *ngFor="let action of dossierActionButtons; trackBy: trackByActionId">
          <fnip-button
            *ngIf="action.isAvailable | executeWith: dossier"
            appearance="primary"
            btnSize="l"
            class="w-100"
            [attr.data-testid]="action.actionId"
            [label]="action.getLabel ? (action.getLabel | executeWith: dossier) : action.label"
            [actionCallback$]="action.getAction$ ? (action.getAction$ | executeWith: dossier) : action.action$"
          />
        </ng-container>
        <ng-container *ngIf="canWithdrawOnDemand | executeWith: dossier?.state">
          <fnip-button
            *tuiLet="withdrawOnDemandEnabled | executeWith: dossier?.box as withdrawActive"
            data-testid="dossier-withdraw-on-demand-button"
            appearance="primary"
            btnSize="l"
            btnHintAppearance="onDark"
            label="Изъять по требованию"
            class="w-100"
            [actionCallback$]="withdrawOnDemand$"
            [btnHint]="withdrawActive ? null : 'Сначала необходимо изъять короб'"
            [icon]="withdrawActive ? null : 'tuiIconAlertCircle'"
          />
        </ng-container>
        <fnip-button
          appearance="secondary-light-grey"
          btnSize="l"
          icon="assets/icons/back.svg"
          label="К списку досье"
          class="w-100"
          [btnRouterLink]="['../../', 'dossiers']"
        />
      </div>
    </div>
    <div class="d-flex flex-column gap-7 w-100 mr-10">
      <div class="dossier-tabs">
        <tui-tabs [(activeItemIndex)]="activeItemIndex">
          <button tuiTab class="ml-6">Кредитное досье</button>
          <button tuiTab>История движения</button>
        </tui-tabs>
        <div class="m-6">{{ currentTabLabel | executeWith: activeItemIndex }}</div>
      </div>
      <ng-container *ngIf="activeItemIndex === DossierTab.Documents">
        <div class="d-flex">
          <fnip-button
            class="mr-3"
            appearance="secondary-light-grey"
            btnSize="l"
            icon="tuiIconDownload"
            [label]="'Скачать выбранное (' + selectedDocuments.size + ')'"
            [btnStyle]="{ width: '280px' }"
            [isDisabled]="!selectedDocuments.size"
            [actionCallback$]="getZipDossierFiles$ | executeWith: dossier?.id : selectedDocuments"
          />
          <fnip-button
            class="mr-3"
            appearance="secondary-light-grey"
            btnSize="l"
            icon="tuiIconDownload"
            label="Скачать"
            [actionCallback$]="getZipDossierFiles$ | executeWith: dossier?.id"
          />
          <fnip-button
            class="mr-3"
            appearance="secondary-light-grey"
            btnSize="l"
            icon="tuiIconDownload"
            label="Скачать титульный лист"
            [actionCallback$]="getTitlePage$ | executeWith: dossier?.id"
          />
          <fnip-button
            *tuiLet="canAddDocument | executeWith: dossier?.state as addDocumentEnabled"
            appearance="secondary-light-grey"
            btnHintAppearance="onDark"
            btnSize="l"
            icon="tuiIconPlus"
            label="Добавить документ"
            [actionCallback$]="openAddDocumentDialog"
            [isDisabled]="!addDocumentEnabled"
            [btnHint]="addDocumentEnabled ? null : addDocumentDisabledReason"
            [icon]="addDocumentEnabled ? null : 'tuiIconAlertCircle'"
          />
        </div>

        <div *ngFor="let group of documentsGroups">
          <ng-container *ngIf="handleCheckAll$ | executeWith: group : 'hasDigital' | async" />
          <ng-container *ngIf="handleCheckAll$ | executeWith: group : 'hasPaper' | async" />
          <ng-container *ngIf="handleCheckAll$ | executeWith: group : 'hasDigitalSignature' | async" />

          <p *ngIf="group.subtitle" class="documents-subtitle text-dark-grey">{{ group.subtitle }}</p>
          <p class="documents-title">{{ group.title }}</p>
          <fnip-grid
            [rowData]="group.data"
            [gridOptions]="getDocumentGroupGridOptions | executeWith: group"
            (selectionChanged)="documentSelectionChanged($event.api, group.data)"
          />
        </div>

        <div class="d-flex content-end gap-2">
          <ng-container *ngIf="isMortgageProduct">
            <fnip-button
              *ngIf="(isInMlc | executeWith: dossier?.state) && (MLC_ROLES | hasRoles)"
              appearance="primary"
              btnSize="l"
              [label]="approveDossierLabel"
              [actionCallback$]="approveDossier$ | executeWith: dossier?.id : documentsGroups"
            />
            <fnip-button
              *ngIf="isReworkActionAvailable | executeWith: dossier?.state"
              appearance="primary"
              btnSize="l"
              [label]="reworkButtonLabel | executeWith: dossier?.state"
              [isDisabled]="isReworkButtonDisabled | executeWith: dossier?.state : documents"
              [actionCallback$]="reworkAction$ | executeWith: dossier?.state : dossier?.id"
            />
          </ng-container>
          <ng-container *ngIf="isAutoProduct">
            <fnip-button
              *ngIf="isInCarShowroomActionAvailable | executeWith: dossier?.state"
              appearance="primary"
              btnSize="l"
              [label]="approveDossierLabel"
              [actionCallback$]="acceptByCuratorAction$ | executeWith: dossier?.id : documentsGroups"
            />
            <fnip-button
              *ngIf="
                (isAcceptedByCuratorActionAvailable | executeWith: dossier?.state) ||
                (isUnderReworkActionAvailable | executeWith: dossier?.state)
              "
              appearance="primary"
              btnSize="l"
              label="Отправить на сопровождение"
              [actionCallback$]="sentToSupportAction$ | executeWith: dossier?.id"
            />
            <fnip-button
              *ngIf="isUnderReworkActionAvailable | executeWith: dossier?.state"
              appearance="primary"
              btnSize="l"
              label="Доработка выполнена"
              [actionCallback$]="acceptToSupport$"
            />
            <fnip-button
              *ngIf="(isSentSupportActionAvailable | executeWith: dossier?.state) && !dossier?.actId"
              appearance="primary"
              btnSize="l"
              label="Принять на проверку"
              [actionCallback$]="underReviewAction$ | executeWith: dossier?.id"
            />
            <fnip-button
              *ngIf="(isUnderReview | executeWith: dossier?.state) && (SUPPORT_ROLES | hasRoles)"
              appearance="primary"
              btnSize="l"
              label="Вернуть по акту"
              [actionCallback$]="needImprovementAction$ | executeWith: dossier?.id"
            />
          </ng-container>
          <fnip-button
            *ngIf="(isUnderReview | executeWith: dossier?.state) && (SUPPORT_ROLES | hasRoles)"
            appearance="primary"
            btnSize="l"
            [label]="acceptToSupportLabel | executeWith: documents"
            [isDisabled]="isDisabledAcceptToSupportButton | executeWith: documents"
            [actionCallback$]="acceptToSupport$"
          />
        </div>
      </ng-container>

      <fnip-grid
        *ngIf="activeItemIndex === DossierTab.History"
        [rowData]="getHistory$ | executeWith: dossier?.id"
        [gridOptions]="historyGridOptions"
      />
    </div>
  </div>
</fnip-request-wrapper>
