import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BoxListComponent } from './views/box-list/box-list.component';
import { BoxComponent } from './views/box/box.component';

const routes: Routes = [
  {
    path: '',
    title: 'Список коробов',
    component: BoxListComponent,
  },
  {
    path: ':id',
    component: BoxComponent,
    title: (route) => 'Короб №' + route.params.id,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BoxesRoutingModule {}
