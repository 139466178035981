<h2 class="my-4">Создать новое досье</h2>
<div class="w-600px d-flex flex-column gap-3">
  <fnip-dossier-form [form]="form" [saveCallback$]="createDossier$" [creditPrograms$]="creditPrograms$" />

  <fnip-button
    appearance="outline"
    btnSize="l"
    icon="assets/icons/back.svg"
    label="К списку досье"
    class="w-100"
    [btnRouterLink]="[basePath, 'dossiers']"
  />
</div>
