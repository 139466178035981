import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { beToFeOptions, findById, Nullable } from '@lib-utils';
import { DocumentDto } from '@lib-archive/api';

@Component({
  selector: 'fnip-document-version',
  templateUrl: './document-version.component.html',
  styleUrls: ['./document-version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentVersionComponent {
  @Input({ required: true }) documentId!: number;
  @Input({ required: true }) historyDocuments$!: Observable<DocumentDto[]>;

  @Output() versionChanged = new EventEmitter<{ document: DocumentDto; isLastVersion: boolean }>();

  versionControl = new FormControl<number>(0, { nonNullable: true });

  getVersionOptions = (historyDocuments: DocumentDto[]) => beToFeOptions(historyDocuments, { valuePath: 'version' });

  setInitialVersion = (documentId: number, documents: DocumentDto[]) =>
    this.versionControl.setValue(findById(documents, documentId)?.version ?? 0);

  isLastVersion = (version: number, documents: DocumentDto[]) => version === documents[documents.length - 1]?.version;

  setLastVersion = (documents: DocumentDto[]) => () => {
    const lastDocument = documents[documents.length - 1];
    this.versionControl.setValue(lastDocument?.version ?? 0);
    this.versionChanged.emit({ document: lastDocument, isLastVersion: true });
  };

  onVersionChanged = (document: Nullable<DocumentDto>, documents: DocumentDto[]) => {
    if (!document) return;
    this.versionChanged.emit({ document, isLastVersion: this.isLastVersion(document.version ?? 0, documents) });
  };
}
