import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, RouterOutlet } from '@angular/router';
import { TuiLetModule } from '@taiga-ui/cdk';
import { tap } from 'rxjs/operators';
import { ConfigService } from '@lib-config';
import { BreadcrumbComponent, PrivacyPolicyComponent } from '@lib-widgets/core';
import { ReactiveRadioBlockModule } from '@lib-widgets/reactive-fields';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { getBasePath } from '@lib-archive/utils';
import { getLogout$ } from '@lib-mortgage/features/authorization';
import { ExpandableMenuComponent, MenuItem, ToolbarModule } from '@lib-mortgage/widgets/layout';
import { environment } from '../environments/environment';
import { ArchiveProductService } from './services';

@Component({
  standalone: true,
  selector: 'fnip-archive-app-component',
  templateUrl: './app.component.html',
  imports: [
    TuiLetModule,
    ToolbarModule,
    BreadcrumbComponent,
    RequestWrapperModule,
    ReactiveRadioBlockModule,
    ExpandableMenuComponent,
    PrivacyPolicyComponent,
    AsyncPipe,
    RouterOutlet,
  ],
})
export class AppComponent {
  readonly basePath = getBasePath();
  readonly logout$ = getLogout$();
  readonly archiveVersion = environment.frontVersion;

  readonly archiveProductService = inject(ArchiveProductService);
  readonly router = inject(Router);
  readonly hostUrl = inject(ConfigService).hostUrl;

  readonly activeProductControl = new FormControl();

  readonly availableProducts$ = this.archiveProductService.availableProducts$.pipe(
    tap(() => this.activeProductControl.setValue(this.archiveProductService.activeProduct, { emitEvent: false })),
  );

  readonly menuItems: MenuItem[] = [
    {
      label: 'Логистика и верификация досье',
      icon: 'assets/icons/archive/document.svg',
      link: `${this.basePath}/dossiers`,
    },
    {
      label: 'Акты приема-передачи',
      icon: 'assets/icons/archive/folder.svg',
      link: `${this.basePath}/acts`,
    },
    {
      label: 'Коробы',
      icon: 'assets/icons/archive/box.svg',
      link: `${this.basePath}/boxes`,
    },
    {
      label: 'Журнал отчётов',
      icon: 'assets/icons/archive/report.svg',
      link: `${this.basePath}/reports`,
    },
  ];
}
