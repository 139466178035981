import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiIslandModule } from '@taiga-ui/kit';
import { Nullable } from '@lib-utils';

@Component({
  selector: 'fnip-dossier-search-card',
  standalone: true,
  imports: [TuiIslandModule],
  templateUrl: './dossier-search-card.component.html',
  styleUrls: ['./dossier-search-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DossierSearchCardComponent {
  @Input() label: Nullable<string>;
}
