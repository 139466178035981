/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentGroup } from './documentGroup';


export interface DocumentTypeDto { 
    id?: number;
    name?: string | null;
    group?: DocumentGroup;
    groupName?: string | null;
    hasPaper?: boolean;
    mayHaveDigitalSignature?: boolean;
}
export namespace DocumentTypeDto {
}


