import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiGroupModule } from '@taiga-ui/core';
import { TuiBadgeModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule, TestAttributesDirective } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { ReactiveButtonDropdownModule } from '@lib-widgets/reactive-fields';
import { DocumentVersionComponent } from './document-version.component';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    ExecuteWithPipeModule,
    ReactiveButtonDropdownModule,
    TuiBadgeModule,
    TuiGroupModule,
    TestAttributesDirective,
  ],
  declarations: [DocumentVersionComponent],
  exports: [DocumentVersionComponent],
})
export class DocumentVersionModule {}
