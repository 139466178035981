import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { FormControlsOf, NotificationService, Nullable, omitNullable } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { injectDialogContext } from '@lib-widgets/dialog';
import { ReactiveInputModule, ReactiveInputPhoneModule } from '@lib-widgets/reactive-fields';
import { PersonDto, UserArchiveApiService } from '@lib-archive/api';

@Component({
  selector: 'fnip-create-user-modal',
  standalone: true,
  imports: [ButtonModule, ReactiveInputModule, ReactiveInputPhoneModule],
  templateUrl: './create-user-modal.component.html',
  styleUrls: ['./create-user-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateUserModalComponent {
  private readonly userApiService = inject(UserArchiveApiService);
  private readonly notificationService = inject(NotificationService);
  readonly context = injectDialogContext<Nullable<PersonDto>, PersonDto>();

  form = new FormGroup<FormControlsOf<PersonDto>>({
    id: new FormControl(this.context.data?.id),
    name: new FormControl(this.context.data?.name, Validators.required),
    email: new FormControl(this.context.data?.email, Validators.required),
    phone: new FormControl(this.context.data?.phone),
    clientType: new FormControl(this.context.data?.clientType),
    employeeType: new FormControl(this.context.data?.employeeType),
  });

  cancel = () => this.context.ref?.close();

  save$ = () =>
    this.userApiService.apiUserPost({ personDto: omitNullable(this.form.value) }).pipe(
      tap(this.notificationService.onError()),
      tap(({ data }) => this.context.ref?.close(data)),
    );
}
