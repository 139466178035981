/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, isDevMode, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ActDtoPageWithStateResultDataResponseData } from '../model/actDtoPageWithStateResultDataResponseData';
// @ts-ignore
import { ActDtoResponseData } from '../model/actDtoResponseData';
// @ts-ignore
import { ActState } from '../model/actState';
// @ts-ignore
import { ActType } from '../model/actType';
// @ts-ignore
import { BooleanResponseData } from '../model/booleanResponseData';
// @ts-ignore
import { CreditDossierDtoPageResultDataResponseData } from '../model/creditDossierDtoPageResultDataResponseData';
// @ts-ignore
import { CreditDossierDtoResponseData } from '../model/creditDossierDtoResponseData';
// @ts-ignore
import { DateTime } from '@lib-utils';
// @ts-ignore
import { DossierAcceptSupportDto } from '../model/dossierAcceptSupportDto';
// @ts-ignore
import { OrderByTypes } from '../model/orderByTypes';
// @ts-ignore
import { ProductCode } from '../model/productCode';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { ArchiveConfiguration }                                     from '../configuration';


export interface ApiActActIdDossierByQrPostRequestParams {
    /** Идентификатор акта. */
    actId: number;
    /** Формат (000000; N заявки; N кд;).\&quot; */
    qrCode?: string;
}

export interface ApiActActIdDossiersAvailablePostRequestParams {
    /** Идентификатор акта. */
    actId: number;
    /**  */
    text?: string;
    /** Номер страницы */
    pageNumber?: number;
    /** Кол-во элементов на странице */
    perPage?: number;
}

export interface ApiActActIdGetRequestParams {
    /** Идентификатор досье */
    actId: number;
}

export interface ApiActActIdInventoryGetRequestParams {
    /**  */
    actId: number;
}

export interface ApiActActIdStateAcceptedPostRequestParams {
    /** Акт */
    actId: number;
    /** Результат проверки */
    dossierAcceptSupportDto?: Array<DossierAcceptSupportDto>;
}

export interface ApiActActIdStateTransferredPostRequestParams {
    /**  */
    actId: number;
}

export interface ApiActActIdStateUnderReviewPostRequestParams {
    /**  */
    actId: number;
}

export interface ApiActGetRequestParams {
    pagePage?: number;
    pagePerPage?: number;
    filterId?: number;
    /** &lt;p&gt;&lt;/p&gt; */
    filterProductCode?: ProductCode;
    /** &lt;p&gt;&lt;/p&gt; */
    filterType?: ActType;
    filterCreatedStart?: DateTime;
    filterCreatedFinish?: DateTime;
    filterCreator?: string;
    /** &lt;p&gt;&lt;/p&gt; */
    filterState?: ActState;
    /** &lt;p&gt;&lt;/p&gt; */
    sortOrderBy?: OrderByTypes;
    sortFieldBy?: string;
}

export interface ApiActPostRequestParams {
    /** &lt;p&gt;&lt;/p&gt; */
    type?: ActType;
    /** &lt;p&gt;&lt;/p&gt; */
    product?: ProductCode;
    /**  */
    requestBody?: Array<number>;
}


const enum AuthorizationType {
  Bearer = 'Bearer',
}

const enum AuthorizationHeader {
  Authorization = 'Authorization',
}

const AcceptHeader = 'Accept';

@Injectable()
export class ActArchiveApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new ArchiveConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: ArchiveConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    checkAuthentication(localVarHeaders: any, authType: AuthorizationType, paramName: AuthorizationHeader) {
      // authentication
      const localVarCredential = this.configuration.lookupCredential(authType as string); // name
      if (localVarCredential) {
        return localVarHeaders.set(paramName as string, localVarCredential); // keyParamName
      }

      return localVarHeaders;
    }

    getAcceptType(localVarHttpHeaderAcceptSelected?: string): 'text' | 'json' | 'blob' {
      if (localVarHttpHeaderAcceptSelected) {
        if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
          return 'text';
        } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
          return 'json';
        } else {
          return 'blob';
        }
      }

      return 'json';
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            httpParams = httpParams.append(key!, JSON.stringify(value));
        } else {
            httpParams = httpParams.append(key!, value);
        }

        return httpParams;
    }

    /**
     * Получение списка досье по qr-коду для добавления в заданный акт.
     * **Required permission**: &#x60;EA.Dossier.Read&#x60; - Чтение досье  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  - &#x60;BankManager&#x60; - Менеджер Банка.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActActIdDossierByQrPost(requestParameters: ApiActActIdDossierByQrPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CreditDossierDtoResponseData>;
    public apiActActIdDossierByQrPost(requestParameters: ApiActActIdDossierByQrPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CreditDossierDtoResponseData>>;
    public apiActActIdDossierByQrPost(requestParameters: ApiActActIdDossierByQrPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CreditDossierDtoResponseData>>;
    public apiActActIdDossierByQrPost(requestParameters: ApiActActIdDossierByQrPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const actId = requestParameters.actId;

        const qrCode = requestParameters.qrCode;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (qrCode !== undefined && qrCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>qrCode, 'qrCode');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Act/${this.configuration.encodeParam({name: "actId", value: actId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/dossier/by-qr`;
        return this.httpClient.request<CreditDossierDtoResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получение списка досье для добавления в заданный акт.
     * **Required permission**: &#x60;EA.Dossier.Read&#x60; - Чтение досье  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  - &#x60;BankManager&#x60; - Менеджер Банка.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActActIdDossiersAvailablePost(requestParameters: ApiActActIdDossiersAvailablePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CreditDossierDtoPageResultDataResponseData>;
    public apiActActIdDossiersAvailablePost(requestParameters: ApiActActIdDossiersAvailablePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CreditDossierDtoPageResultDataResponseData>>;
    public apiActActIdDossiersAvailablePost(requestParameters: ApiActActIdDossiersAvailablePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CreditDossierDtoPageResultDataResponseData>>;
    public apiActActIdDossiersAvailablePost(requestParameters: ApiActActIdDossiersAvailablePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const actId = requestParameters.actId;

        const text = requestParameters.text;

        const pageNumber = requestParameters.pageNumber;

        const perPage = requestParameters.perPage;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (text !== undefined && text !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>text, 'text');
        }
        if (pageNumber !== undefined && pageNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageNumber, 'pageNumber');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'perPage');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Act/${this.configuration.encodeParam({name: "actId", value: actId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/dossiers/available`;
        return this.httpClient.request<CreditDossierDtoPageResultDataResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает акт по Id
     * **Required permission**: &#x60;EA.Act.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActActIdGet(requestParameters: ApiActActIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ActDtoResponseData>;
    public apiActActIdGet(requestParameters: ApiActActIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ActDtoResponseData>>;
    public apiActActIdGet(requestParameters: ApiActActIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ActDtoResponseData>>;
    public apiActActIdGet(requestParameters: ApiActActIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const actId = requestParameters.actId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Act/${this.configuration.encodeParam({name: "actId", value: actId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<ActDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Опись
     * **Required permission**: &#x60;EA.Act.Inventory&#x60; - Печать описи  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActActIdInventoryGet(requestParameters: ApiActActIdInventoryGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiActActIdInventoryGet(requestParameters: ApiActActIdInventoryGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiActActIdInventoryGet(requestParameters: ApiActActIdInventoryGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiActActIdInventoryGet(requestParameters: ApiActActIdInventoryGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const actId = requestParameters.actId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Act/${this.configuration.encodeParam({name: "actId", value: actId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/inventory`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Переводит акт в состояние Fnip.Electronic.Archive.Dal.Enums.ActState.Accepted
     * **Required permission**: &#x60;EA.Act.Accepted&#x60; - Перевод в состояние \&quot;Принято\&quot;  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActActIdStateAcceptedPost(requestParameters: ApiActActIdStateAcceptedPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiActActIdStateAcceptedPost(requestParameters: ApiActActIdStateAcceptedPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiActActIdStateAcceptedPost(requestParameters: ApiActActIdStateAcceptedPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiActActIdStateAcceptedPost(requestParameters: ApiActActIdStateAcceptedPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const actId = requestParameters.actId;

        const dossierAcceptSupportDto = requestParameters.dossierAcceptSupportDto;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Act/${this.configuration.encodeParam({name: "actId", value: actId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/Accepted`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: dossierAcceptSupportDto,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Переводит акт в состояние Fnip.Electronic.Archive.Dal.Enums.ActState.Transferred
     * **Required permission**: &#x60;EA.Act.Transferred&#x60; - Перевод в состояние \&quot;Передано\&quot;  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActActIdStateTransferredPost(requestParameters: ApiActActIdStateTransferredPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiActActIdStateTransferredPost(requestParameters: ApiActActIdStateTransferredPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiActActIdStateTransferredPost(requestParameters: ApiActActIdStateTransferredPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiActActIdStateTransferredPost(requestParameters: ApiActActIdStateTransferredPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const actId = requestParameters.actId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Act/${this.configuration.encodeParam({name: "actId", value: actId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/Transferred`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Переводит акт в состояние Fnip.Electronic.Archive.Dal.Enums.ActState.UnderReview
     * **Required permission**: &#x60;EA.Act.UnderReview&#x60; - Перевод в состояние \&quot;На проверке\&quot;  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActActIdStateUnderReviewPost(requestParameters: ApiActActIdStateUnderReviewPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiActActIdStateUnderReviewPost(requestParameters: ApiActActIdStateUnderReviewPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiActActIdStateUnderReviewPost(requestParameters: ApiActActIdStateUnderReviewPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiActActIdStateUnderReviewPost(requestParameters: ApiActActIdStateUnderReviewPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const actId = requestParameters.actId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Act/${this.configuration.encodeParam({name: "actId", value: actId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/state/UnderReview`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает страницу с актами по фильтру
     * **Required permission**: &#x60;EA.Act.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActGet(requestParameters: ApiActGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ActDtoPageWithStateResultDataResponseData>;
    public apiActGet(requestParameters: ApiActGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ActDtoPageWithStateResultDataResponseData>>;
    public apiActGet(requestParameters: ApiActGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ActDtoPageWithStateResultDataResponseData>>;
    public apiActGet(requestParameters: ApiActGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const pagePage = requestParameters.pagePage;

        const pagePerPage = requestParameters.pagePerPage;

        const filterId = requestParameters.filterId;

        const filterProductCode = requestParameters.filterProductCode;

        const filterType = requestParameters.filterType;

        const filterCreatedStart = requestParameters.filterCreatedStart;

        const filterCreatedFinish = requestParameters.filterCreatedFinish;

        const filterCreator = requestParameters.filterCreator;

        const filterState = requestParameters.filterState;

        const sortOrderBy = requestParameters.sortOrderBy;

        const sortFieldBy = requestParameters.sortFieldBy;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pagePage !== undefined && pagePage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagePage, 'page.page');
        }
        if (pagePerPage !== undefined && pagePerPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagePerPage, 'page.perPage');
        }
        if (filterId !== undefined && filterId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterId, 'filter.id');
        }
        if (filterProductCode !== undefined && filterProductCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterProductCode, 'filter.productCode');
        }
        if (filterType !== undefined && filterType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterType, 'filter.type');
        }
        if (filterCreatedStart !== undefined && filterCreatedStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterCreatedStart, 'filter.created.start');
        }
        if (filterCreatedFinish !== undefined && filterCreatedFinish !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterCreatedFinish, 'filter.created.finish');
        }
        if (filterCreator !== undefined && filterCreator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterCreator, 'filter.creator');
        }
        if (filterState !== undefined && filterState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterState, 'filter.state');
        }
        if (sortOrderBy !== undefined && sortOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrderBy, 'sort.orderBy');
        }
        if (sortFieldBy !== undefined && sortFieldBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortFieldBy, 'sort.fieldBy');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Act`;
        return this.httpClient.request<ActDtoPageWithStateResultDataResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Создаёт новый акт
     * **Required permission**: &#x60;EA.Act.Create&#x60; - Создание  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActPost(requestParameters: ApiActPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ActDtoResponseData>;
    public apiActPost(requestParameters: ApiActPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ActDtoResponseData>>;
    public apiActPost(requestParameters: ApiActPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ActDtoResponseData>>;
    public apiActPost(requestParameters: ApiActPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const type = requestParameters.type;

        const product = requestParameters.product;

        const requestBody = requestParameters.requestBody;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Act`;
        return this.httpClient.request<ActDtoResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: requestBody,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
