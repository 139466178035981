import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, tap } from 'rxjs';
import { LocalStorageService, Nullable, SelectOption } from '@lib-utils';
import { ProductArchiveApiService, ProductCode } from '@lib-archive/api';
import { ProductCodeMap } from '@lib-archive/api-middleware';

const ARCHIVE_ACTIVE_PRODUCT_KEY = 'activeActiveProduct';

@Injectable()
export class ArchiveProductService {
  readonly productApiService = inject(ProductArchiveApiService);
  readonly localStorageService = inject(LocalStorageService);

  readonly #activeProduct$ = new BehaviorSubject<Nullable<ProductCode>>(this.activeProduct);
  readonly #availableProducts$ = new BehaviorSubject<Nullable<SelectOption<unknown, ProductCode>[]>>(null);

  readonly activeProduct$ = this.#activeProduct$.asObservable();
  readonly availableProducts$ = this.#availableProducts$.asObservable();

  readonly activeProductName$ = this.activeProduct$.pipe(
    map((product) => (product && ProductCodeMap[product]) ?? 'Продукт не указан'),
  );

  get activeProduct() {
    return this.localStorageService.getFromLocalStorage<ProductCode>(ARCHIVE_ACTIVE_PRODUCT_KEY);
  }

  get availableProducts() {
    return this.#availableProducts$.value;
  }

  loadAvailableProducts$ = () => {
    return this.productApiService.apiProductAvailableGet().pipe(
      map(({ data }) => data),
      tap((products) => {
        if (!products?.length) {
          this.clearActiveProduct();
          this.clearAvailableProducts();
        } else if (!this.activeProduct || !products.includes(this.activeProduct)) this.setActiveProduct(products[0]);
      }),
      map((products) =>
        products?.map(
          (product) =>
            ({ label: ProductCodeMap[product] ?? 'Неизвестный продукт', value: product }) as SelectOption<
              unknown,
              ProductCode
            >,
        ),
      ),
      tap((products) => this.setAvailableProducts(products)),
    );
  };

  clearActiveProduct = () => {
    this.localStorageService.removeFromLocalStorage(ARCHIVE_ACTIVE_PRODUCT_KEY);
    this.#activeProduct$.next(null);
  };

  setActiveProduct = (product: ProductCode) => {
    this.localStorageService.setToLocalStorage<ProductCode>(ARCHIVE_ACTIVE_PRODUCT_KEY, product);
    this.#activeProduct$.next(product);
  };

  clearAvailableProducts = () => {
    this.#availableProducts$.next(null);
  };

  setAvailableProducts = (products: Nullable<SelectOption<unknown, ProductCode>[]>) => {
    this.#availableProducts$.next(products);
  };
}
