import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BoxesRoutingModule } from './boxes-routing.module';
import { BoxesComponent } from './boxes.component';
import { BoxModule } from './views/box';
import { BoxListModule } from './views/box-list';

@NgModule({
  declarations: [BoxesComponent],
  imports: [CommonModule, BoxesRoutingModule, BoxModule, BoxListModule],
})
export class BoxesModule {}
