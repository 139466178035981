import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { LabelValueOption, Nullable, SelectOption } from '@lib-utils';
import { ProductCode } from '@lib-archive/api';
import { ProductCodeMap } from '@lib-archive/api-middleware';

@Injectable()
export class ArchiveProductMockService {
  activeProduct = ProductCode.Mortgage;

  readonly #activeProduct$ = new BehaviorSubject<Nullable<ProductCode>>(this.activeProduct);
  readonly #availableProducts$ = new BehaviorSubject<Nullable<SelectOption<unknown, ProductCode>[]>>(null);

  readonly activeProduct$ = this.#activeProduct$.asObservable();
  readonly availableProducts$ = this.#availableProducts$.asObservable();

  readonly activeProductName$ = this.activeProduct$.pipe(
    map((product) => (product && ProductCodeMap[product]) ?? 'Продукт не указан'),
  );

  get availableProducts() {
    return this.#availableProducts$.value;
  }

  loadAvailableProducts$ = new BehaviorSubject<LabelValueOption<ProductCode>[]>([]);
  clearActiveProduct = () => this.#activeProduct$.next(null);
  setActiveProduct = (product: ProductCode) => this.#activeProduct$.next(product);
  clearAvailableProducts = () => this.#availableProducts$.next(null);
  setAvailableProducts = (products: Nullable<SelectOption<unknown, ProductCode>[]>) =>
    this.#availableProducts$.next(products);
}
