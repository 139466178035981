/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TechnicalError } from './technicalError';


/**
 * Информация об ошибке.
 */
export interface ErrorInfo { 
    /**
     * Числовой код ошибки.
     */
    numericCode?: number;
    /**
     * Код ошибки.
     */
    code?: string | null;
    /**
     * Не обработанная.
     */
    unhandled?: boolean;
    /**
     * Сообщения для пользователя.
     */
    message?: string | null;
    /**
     * Техническая информация.
     */
    technicalErrors?: Array<TechnicalError> | null;
    /**
     * Ошибки валидации.
     */
    validationErrors?: { [key: string]: Array<string>; } | null;
}

