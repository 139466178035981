import { GridOptions } from '@ag-grid-community/core';
import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import fromPairs from 'lodash-es/fromPairs';
import { EMPTY, filter, map, Subject, switchMap, tap } from 'rxjs';
import { getRouteParams$, Nullable, omitNullable } from '@lib-utils';
import { ConfirmationDialogType, DialogService, injectDialogService } from '@lib-widgets/dialog';
import {
  ColumnsCheckVisibleParams,
  getActionCellDef,
  GridComponent,
  GridGetDataCallback,
  tagCellDef,
} from '@lib-widgets/grid';
import { BoxArchiveApiService, BoxDto, BoxState, ProductCode } from '@lib-archive/api';
import { BoxStateRecord, DefaultTagClass } from '@lib-archive/api-middleware';
import { getBasePath, hasSupportRoleCb, SUPPORT_ROLES } from '@lib-archive/utils';
import { ArchiveProductService } from '../../../../services';

const GET_GRID_OPTIONS = (context: BoxListComponent): GridOptions<BoxDto> => ({
  columnDefs: [
    {
      field: 'id',
      initialWidth: 120,
      sortable: true,
      initialSort: 'desc',
      headerName: 'ID',
    },
    {
      field: 'boxNumber',
      headerName: 'Номер',
      initialWidth: 200,
    },
    {
      field: 'state',
      headerName: 'Статус',
      ...tagCellDef({
        labelMap: BoxStateRecord,
        getDefaultTagClass: () => DefaultTagClass,
      }),
    },
    {
      field: 'dossiers.length',
      headerName: 'Количество досье',
    },
    {
      field: 'stateSetting',
      headerName: 'Дата передачи на сопровождение',
      initialWidth: 350,
      valueFormatter: ({ value }) => value && formatDate(value, 'dd.MM.yyyy', 'ru'),
    },
    {
      headerName: 'Создан',
      valueGetter: ({ data }) => data?.creator?.name ?? data?.creator?.email,
      initialFlex: 1,
      minWidth: 200,
    },
    {
      colId: 'action',
      headerName: '',
      initialPinned: 'right',
      lockPinned: true,
      ...getActionCellDef<BoxDto>({
        icon: 'tuiIconEye',
        getLink: (data) => [context.basePath, 'boxes', data?.id!],
      }),
    },
    {
      colId: 'delete',
      headerName: '',
      initialPinned: 'right',
      lockPinned: true,
      lockVisible: true,
      ...getActionCellDef<BoxDto>({
        icon: 'tuiIconTrash',
        getAction$: (data) => (context.canDeleteBox(data) ? context.deleteBox(data?.id) : null),
      }),
    },
  ],
  context,
});

@Component({
  selector: 'fnip-box-list',
  templateUrl: './box-list.component.html',
  styleUrls: ['./box-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class BoxListComponent {
  @ViewChild(GridComponent) grid: Nullable<GridComponent>;

  private readonly boxArchiveApiService = inject(BoxArchiveApiService);
  private readonly router = inject(Router);
  readonly archiveProductService = inject(ArchiveProductService);
  readonly dialogService = injectDialogService();
  readonly basePath = getBasePath();

  gridOptions = GET_GRID_OPTIONS(this);

  stateMap: Record<string, string> = {};

  stateCount$ = new Subject<Record<string, number>>();

  routerParams$ = getRouteParams$<{ state: BoxState }>().pipe(
    tap(({ state = null }) => this.filters.patchValue({ state })),
  );

  filters = new FormGroup({
    boxNumber: new FormControl<Nullable<string>>(null),
    state: new FormControl<Nullable<BoxState>>(null),
  });

  SUPPORT_ROLES = SUPPORT_ROLES;

  hasSupportRole = hasSupportRoleCb();

  getBoxes$ =
    (product: Nullable<ProductCode>): GridGetDataCallback<BoxDto> =>
    (pagination, order) => {
      const { boxNumber, state } = this.filters.value;

      return this.boxArchiveApiService
        .apiBoxGet(
          omitNullable({
            pagePage: pagination.page,
            pagePerPage: pagination.perPage,
            filterBoxNumber: boxNumber,
            filterState: state,
            filterProductCode: product,
            sortFieldBy: order?.fieldBy,
            sortOrderBy: order?.orderBy,
          }),
        )
        .pipe(
          map(({ data }) => data),
          tap((data) => {
            const { states } = data || {};

            this.stateMap = fromPairs(states?.map(({ code, name }) => [code, name]));
            this.stateCount$.next(fromPairs(states?.map(({ code, count }) => [code, count])));
          }),
        );
    };

  columnsCheckVisibleParams: ColumnsCheckVisibleParams<BoxDto>[] = [
    {
      colId: 'delete',
      updateWithFetch: true,
      isVisible: (data: Nullable<BoxDto[]>) => !!data?.some(this.canDeleteBox),
    },
  ];

  createBoxAndNavigate = (productCode: Nullable<ProductCode>) => () =>
    this.boxArchiveApiService
      .apiBoxPost({ product: productCode ?? undefined })
      .pipe(switchMap((resp) => this.navigateToBox(resp.data?.id)));

  navigateToBox = (id: Nullable<number>) => this.router.navigate([this.basePath, 'boxes', id]);

  canDeleteBox = (box: Nullable<BoxDto>) =>
    this.hasSupportRole() && box?.state === BoxState.Filling && !box.dossiers?.length;

  deleteBox = (boxId: Nullable<number>) => () => {
    if (!boxId) return EMPTY;
    return this.dialogService
      .openConfirmation<boolean>({
        contextData: {
          type: ConfirmationDialogType.Question,
          text: `Вы действительно хотите удалить короб c ID ${boxId}?`,
        },
        hostOptions: {
          size: 'auto',
          isDismissible: false,
        },
      })
      .pipe(
        filter(Boolean),
        switchMap(() => this.boxArchiveApiService.apiBoxBoxIdDelete({ boxId })),
        tap(() => this.grid?.fetchData()),
      );
  };
}
