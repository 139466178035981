<fnip-request-wrapper #documentIdRs [request$]="documentId$">
  <ng-container *ngIf="setCheckState | executeWith: document : mode" />

  <div class="d-flex content-space-between mb-3">
    <div class="d-flex">
      <fnip-button icon="assets/icons/back.svg" btnSize="l" [actionCallback$]="navigateToDossier" />
      <h2 class="my-4 ml-3">Договор № {{ dossier?.loanAgreementNumber }}</h2>
    </div>

    <fnip-document-version
      *ngIf="documentIdRs.data"
      class="mr-10"
      [documentId]="documentIdRs.data"
      [historyDocuments$]="getDocumentHistoryRq$ | executeWith: documentIdRs.data"
      (versionChanged)="document = $event.document; isLastDocumentVersion = $event.isLastVersion"
    />
  </div>
  <div
    *tuiLet="{
      isDossierEditable: isDossierEditable | executeWith: dossier?.state,
      canUploadDocument:
        (canUploadDocumentForFrontOffice | executeWith: dossier?.state) ||
        (canUploadDocumentForSupport | executeWith: dossier?.state),
    } as dossierFlags"
    class="d-flex gap-10"
  >
    <div class="content h-100" [style.flex]="'1 1 65%'">
      <div class="content-header">
        <div class="content-header__title">{{ document?.documentType?.name }}</div>
      </div>
      <fnip-divider />
      <fnip-document-preview
        #documentPreview
        class="p-4"
        [style.--height]="'600px'"
        [fileIds]="getFileIds | executeWith: document"
        [previewFileIds]="getFileIdsWithPreview | executeWith: document"
        [hasFullScreenPreview]="true"
        [getFileByIdCallback$]="getFileByIdCallback$"
        [getFilePreviewByIdCallback$]="getFilePreviewByIdCallback$"
        [getFilePagesCount]="getFilePagesCountCallback | executeWith: document"
      >
        <fnip-button
          *ngIf="canChangeDocumentPage | executeWith: documentPreview.currentFile : document"
          fnipExtraDocumentPreviewActions
          appearance="outline"
          icon="tuiIconEdit2"
          btnSize="s"
          btnHint="Заменить страницу в документе"
          [actionCallback$]="openChangePageModal$ | executeWith: documentPreview.currentFile : document"
        />
        <fnip-button
          fnipExtraDocumentPreviewActions
          appearance="outline"
          icon="tuiIconXCircle"
          btnSize="s"
          [actionCallback$]="deleteCurrentFile$ | executeWith: documentPreview.currentFile : document"
          [isDisabled]="(document?.filesNew?.length ?? 0) <= 1"
          [btnHint]="getDeleteBtnHint | executeWith: document"
        />
      </fnip-document-preview>
    </div>
    <div class="content h-100 mr-10" [style.flex]="'1 1 35%'">
      <div class="content-header">
        <span *ngIf="dossierFlags.isDossierEditable" class="content-header__title">
          Критерии проверки <strong> {{ mode === DocumentMode.Paper ? 'Бумажного' : 'Электронного' }} документа</strong>
        </span>
        <span *ngIf="!dossierFlags.isDossierEditable" class="content-header__title">Информация по документу</span>
      </div>
      <fnip-divider />
      <div class="d-flex flex-column gap-5 px-8 py-6">
        <ng-container *ngIf="!dossierFlags.isDossierEditable">
          <fnip-labeled-content
            label="Статус"
            [content]="statusTemplate"
            [contentContext]="getDocumentState | executeWith: document?.state"
            [inline]="true"
          />
          <fnip-labeled-content
            label="Версия"
            [content]="statusTemplate"
            [contentContext]="document?.version"
            [inline]="true"
          />
          <fnip-labeled-content
            label="Дата загрузки"
            [content]="document?.created | date: 'dd.MM.yyyy'"
            [inline]="true"
          />
          <fnip-labeled-content
            label="Загрузил"
            [content]="document?.client?.name"
            [disableEllipsis]="true"
            [inline]="true"
          />
        </ng-container>
        <ng-container *ngIf="dossierFlags.isDossierEditable">
          <div class="d-flex flex-column gap-3">
            <fnip-reactive-button-checkbox
              *ngFor="let checkControl of form.controls.checks.controls; let i = index"
              [fieldId]="'checkLabel' + i"
              [label]="checkState.checks?.[i]?.check?.name ?? ''"
              [control]="checkControl"
            />
          </div>

          @if (
            getPreviousDocumentCommentsForms
              | executeWith: document?.version : previousDocumentComments : totalVersionCount;
            as previousDocumentComments
          ) {
            <fnip-accordion-item header="Комментарии к другим версиям документа" size="s" [isReadOnly]="true">
              <div class="d-flex flex-column gap-8">
                @for (data of previousDocumentComments; track data.version; let index = $index) {
                  <fnip-labeled-content
                    labelWidth="w-fit-content"
                    [style.--content-font-size.px]="14"
                    [style.--content-font-weight]="400"
                    [style.--label-font-weight]="600"
                    [disableEllipsis]="true"
                    [label]="'Комментарий к версии документа ' + data.version"
                    [content]="data.comment"
                  />
                }
              </div>
            </fnip-accordion-item>
          }

          <fnip-reactive-text-area
            *ngIf="form.controls.checks.controls.length"
            fieldId="comment"
            label="Комментарий"
            [control]="form.controls.comment"
            [requiredIf]="isCommentRequired | executeWith: form.controls.checks.value"
          />
        </ng-container>
        <ng-container *ngIf="dossierFlags.canUploadDocument">
          <fnip-reactive-file-input
            label="Документы"
            fieldId="document"
            [accept]="allowedFileTypes"
            [control]="fileControl"
            [multiple]="true"
            [maxSizeMb]="30"
            [isLocalUsage]="true"
            [showSize]="true"
            (maxSizeReached)="notificationService.showWarning('Общий размер файлов превышает 30 Мб')"
          />
          <fnip-reactive-checkbox
            *ngIf="document?.documentType?.mayHaveDigitalSignature"
            checkboxLabel="ПЭП / УКЭП"
            fieldId="hasDigitalSignature"
            size="l"
            [control]="hasDigitalSignatureControl"
            [isDisabled]="!fileControl?.value?.length"
          />
        </ng-container>
        <div
          *tuiLet="hasNextCheck | executeWith: documentIdRs.data : mode as nextCheckFlag"
          class="d-flex flex-column gap-3"
        >
          <fnip-button
            *ngIf="dossierFlags.canUploadDocument"
            appearance="primary"
            label="Заменить документы"
            btnSize="l"
            class="w-100"
            [actionCallback$]="onUploadFiles$"
            [isDisabled]="!fileControl?.value?.length"
          />
          <ng-container *ngIf="dossierFlags.isDossierEditable">
            <fnip-button
              *ngIf="!areChecksReadOnly"
              btnSize="l"
              appearance="primary"
              class="w-100"
              [isDisabled]="form.invalid"
              [label]="getActionButtonLabel | executeWith: nextCheckFlag"
              [actionCallback$]="saveAndNext$ | executeWith: nextCheckFlag"
            />
            <fnip-button
              *ngIf="document?.documentType?.hasPaper && document?.completenessCheck?.hasPaper"
              appearance="secondary-light-grey"
              btnSize="l"
              class="w-100"
              [label]="mode === DocumentMode.Paper ? 'К Электронному документу' : 'К бумажному документу'"
              [actionCallback$]="toggleDocumentMode"
              [iconRight]="errorCountTmp"
            />
          </ng-container>
          <fnip-button
            icon="tuiIconDownload"
            appearance="secondary-light-grey"
            label="Скачать документ"
            btnSize="l"
            class="w-100"
            [actionCallback$]="getZipDocumentFiles$"
          />
          <fnip-button
            icon="assets/icons/back.svg"
            appearance="secondary-light-grey"
            label="К досье"
            btnSize="l"
            class="w-100"
            [actionCallback$]="navigateToDossier"
          />
          <fnip-button
            icon="tuiIconExternalLinkLarge"
            appearance="secondary-light-grey"
            label="Открыть досье в новом окне"
            btnSize="l"
            class="w-100"
            [actionCallback$]="openDossierInNewWindow"
          />
        </div>
      </div>
    </div>
  </div>
</fnip-request-wrapper>

<ng-template #errorCountTmp>
  <tui-badge
    *ngIf="
      getErrorsCount
        | executeWith
          : document
          : (mode === DocumentMode.Paper ? DocumentMode.Digital : DocumentMode.Paper) as errorsCount
    "
    status="primary"
    [value]="errorsCount"
  />
</ng-template>

<ng-template #statusTemplate let-content>
  <div class="dossier-status">{{ content }}</div>
</ng-template>
