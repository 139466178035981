import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@lib-widgets/core';
import {
  ReactiveCheckboxModule,
  ReactiveInputDateModule,
  ReactiveInputModule,
  ReactiveInputNumberModule,
  ReactiveSelectModule,
  ReactiveToggleModule,
} from '@lib-widgets/reactive-fields';
import { ArchiveListFilterDialogComponent } from './archive-list-filter-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    ReactiveInputDateModule,
    ReactiveSelectModule,
    ReactiveInputModule,
    ReactiveInputNumberModule,
    ReactiveToggleModule,
    ReactiveCheckboxModule,
  ],
  declarations: [ArchiveListFilterDialogComponent],
  exports: [ArchiveListFilterDialogComponent],
})
export class ArchiveListFilterDialogModule {}
