/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, isDevMode, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BooleanResponseData } from '../model/booleanResponseData';
// @ts-ignore
import { DocumentDtoArrayResponseData } from '../model/documentDtoArrayResponseData';
// @ts-ignore
import { DocumentDtoResponseData } from '../model/documentDtoResponseData';
// @ts-ignore
import { DocumentTypeDtoArrayResponseData } from '../model/documentTypeDtoArrayResponseData';
// @ts-ignore
import { DocumentVerificationSummary } from '../model/documentVerificationSummary';
// @ts-ignore
import { DossierZipRequest } from '../model/dossierZipRequest';
// @ts-ignore
import { FileSaveInfoResponseData } from '../model/fileSaveInfoResponseData';
// @ts-ignore
import { Int64NullableResponseData } from '../model/int64NullableResponseData';
// @ts-ignore
import { Int64ResponseData } from '../model/int64ResponseData';
// @ts-ignore
import { ProblemDetails } from '../model/problemDetails';
// @ts-ignore
import { ProductCode } from '../model/productCode';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { ArchiveConfiguration }                                     from '../configuration';


export interface ApiDocumentCreatePreviewFilesForFileFileIdPostRequestParams {
    fileId: number;
}

export interface ApiDocumentDocumentIdCheckPostRequestParams {
    /** Документ */
    documentId: number;
    /** Результаты проверки */
    documentVerificationSummary?: DocumentVerificationSummary;
}

export interface ApiDocumentDocumentIdCheckResetPostRequestParams {
    /** Документ */
    documentId: number;
}

export interface ApiDocumentDocumentIdDeleteRequestParams {
    /**  */
    documentId: number;
}

export interface ApiDocumentDocumentIdFileFileIdSheetSheetNumberPostRequestParams {
    /**  */
    documentId: number;
    /**  */
    fileId: number;
    /**  */
    sheetNumber: number;
    file?: Blob;
}

export interface ApiDocumentDocumentIdFileIdDeleteRequestParams {
    /**  */
    documentId: number;
    /**  */
    id: number;
}

export interface ApiDocumentDocumentIdGetRequestParams {
    /** Id документа */
    documentId: number;
}

export interface ApiDocumentDocumentIdHistoryGetRequestParams {
    /** Id документа */
    documentId: number;
}

export interface ApiDocumentDocumentIdZipGetRequestParams {
    /** Документ */
    documentId: number;
}

export interface ApiDocumentDossierDossierIdGetRequestParams {
    /** Id досье. */
    dossierId: number;
    includePaperCheckList?: boolean;
    includeDigitalCheckList?: boolean;
    includeAllCheckLists?: boolean;
}

export interface ApiDocumentDossierDossierIdPostRequestParams {
    /** Id досье. */
    dossierId: number;
    documentTypeId?: number;
    clientId?: number;
    files?: Array<Blob>;
    hasDigitalSignature?: boolean;
}

export interface ApiDocumentDossierDossierIdZipGetRequestParams {
    /** Id досье. */
    dossierId: number;
    /**  */
    request?: DossierZipRequest;
}

export interface ApiDocumentFileIdConvertedPdfFirstPageGetRequestParams {
    /**  */
    id: number;
}

export interface ApiDocumentFileIdConvertedPdfGetRequestParams {
    /**  */
    id: number;
}

export interface ApiDocumentFileIdConvertedPng100hFirstPageGetRequestParams {
    /**  */
    id: number;
}

export interface ApiDocumentFileIdGetRequestParams {
    /**  */
    id: number;
}

export interface ApiDocumentFilePostRequestParams {
    file?: Blob;
}

export interface ApiDocumentTypesGetRequestParams {
    /** &lt;p&gt;&lt;/p&gt; */
    productCode?: ProductCode;
}


const enum AuthorizationType {
  Bearer = 'Bearer',
}

const enum AuthorizationHeader {
  Authorization = 'Authorization',
}

const AcceptHeader = 'Accept';

@Injectable()
export class DocumentArchiveApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new ArchiveConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: ArchiveConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    checkAuthentication(localVarHeaders: any, authType: AuthorizationType, paramName: AuthorizationHeader) {
      // authentication
      const localVarCredential = this.configuration.lookupCredential(authType as string); // name
      if (localVarCredential) {
        return localVarHeaders.set(paramName as string, localVarCredential); // keyParamName
      }

      return localVarHeaders;
    }

    getAcceptType(localVarHttpHeaderAcceptSelected?: string): 'text' | 'json' | 'blob' {
      if (localVarHttpHeaderAcceptSelected) {
        if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
          return 'text';
        } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
          return 'json';
        } else {
          return 'blob';
        }
      }

      return 'json';
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            httpParams = httpParams.append(key!, JSON.stringify(value));
        } else {
            httpParams = httpParams.append(key!, value);
        }

        return httpParams;
    }

    /**
     * Тест получить имя баскета
     * **Required permission**: &#x60;EA.Document.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentBucketGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<string>;
    public apiDocumentBucketGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<string>>;
    public apiDocumentBucketGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<string>>;
    public apiDocumentBucketGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/bucket`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Создать превью для файлов в досье:  1. Сконвертировать для файлов Doc/docx/tiff в отдельный pdf  2. Сохранить отдельно 1ю страницу pdf
     * **Required permission**: &#x60;EA.Document.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentCreatePreviewFilesForDossierPost(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<string>;
    public apiDocumentCreatePreviewFilesForDossierPost(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<string>>;
    public apiDocumentCreatePreviewFilesForDossierPost(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<string>>;
    public apiDocumentCreatePreviewFilesForDossierPost(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/create-preview-files-for-dossier`;
        return this.httpClient.request<string>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Создать превью для файла:  1. Сконвертировать для файлов Doc/docx/tiff в отдельный pdf  2. Сохранить отдельно 1ю страницу pdf
     * **Required permission**: &#x60;EA.Document.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentCreatePreviewFilesForFileFileIdPost(requestParameters: ApiDocumentCreatePreviewFilesForFileFileIdPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<string>;
    public apiDocumentCreatePreviewFilesForFileFileIdPost(requestParameters: ApiDocumentCreatePreviewFilesForFileFileIdPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<string>>;
    public apiDocumentCreatePreviewFilesForFileFileIdPost(requestParameters: ApiDocumentCreatePreviewFilesForFileFileIdPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<string>>;
    public apiDocumentCreatePreviewFilesForFileFileIdPost(requestParameters: ApiDocumentCreatePreviewFilesForFileFileIdPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const fileId = requestParameters.fileId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/create-preview-files-for-file/${this.configuration.encodeParam({name: "fileId", value: fileId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<string>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Создать превью для файлов:  1. Сконвертировать для файлов Doc/docx/tiff в отдельный pdf  2. Сохранить отдельно 1ю страницу в формате png 100h
     * **Required permission**: &#x60;EA.Document.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentCreatePreviewFilesPost(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<string>;
    public apiDocumentCreatePreviewFilesPost(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<string>>;
    public apiDocumentCreatePreviewFilesPost(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<string>>;
    public apiDocumentCreatePreviewFilesPost(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/create-preview-files`;
        return this.httpClient.request<string>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Сохраняет результаты проверки документа.  Переводит документ в соответствующее состояние.
     * **Required permission**: &#x60;EA.Document.Check&#x60; - Проверка  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDocumentIdCheckPost(requestParameters: ApiDocumentDocumentIdCheckPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDocumentDocumentIdCheckPost(requestParameters: ApiDocumentDocumentIdCheckPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDocumentDocumentIdCheckPost(requestParameters: ApiDocumentDocumentIdCheckPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDocumentDocumentIdCheckPost(requestParameters: ApiDocumentDocumentIdCheckPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const documentId = requestParameters.documentId;

        const documentVerificationSummary = requestParameters.documentVerificationSummary;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Document/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/check`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: documentVerificationSummary,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Вернуть документ на проверку
     * **Required permission**: &#x60;EA.Document.UnderReview&#x60; - Вернуть на проверку  **Required roles**:  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDocumentIdCheckResetPost(requestParameters: ApiDocumentDocumentIdCheckResetPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDocumentDocumentIdCheckResetPost(requestParameters: ApiDocumentDocumentIdCheckResetPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDocumentDocumentIdCheckResetPost(requestParameters: ApiDocumentDocumentIdCheckResetPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDocumentDocumentIdCheckResetPost(requestParameters: ApiDocumentDocumentIdCheckResetPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const documentId = requestParameters.documentId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/check/reset`;
        return this.httpClient.request<BooleanResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Удаляет документ по Id
     * **Required permission**: &#x60;EA.Document.Delete&#x60; - Удаление  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDocumentIdDelete(requestParameters: ApiDocumentDocumentIdDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDocumentDocumentIdDelete(requestParameters: ApiDocumentDocumentIdDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDocumentDocumentIdDelete(requestParameters: ApiDocumentDocumentIdDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDocumentDocumentIdDelete(requestParameters: ApiDocumentDocumentIdDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const documentId = requestParameters.documentId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<BooleanResponseData>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Заменяет страницу файла.
     * **Required permission**: &#x60;EA.Document.Replace&#x60; - Замена  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDocumentIdFileFileIdSheetSheetNumberPost(requestParameters: ApiDocumentDocumentIdFileFileIdSheetSheetNumberPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Int64NullableResponseData>;
    public apiDocumentDocumentIdFileFileIdSheetSheetNumberPost(requestParameters: ApiDocumentDocumentIdFileFileIdSheetSheetNumberPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Int64NullableResponseData>>;
    public apiDocumentDocumentIdFileFileIdSheetSheetNumberPost(requestParameters: ApiDocumentDocumentIdFileFileIdSheetSheetNumberPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Int64NullableResponseData>>;
    public apiDocumentDocumentIdFileFileIdSheetSheetNumberPost(requestParameters: ApiDocumentDocumentIdFileFileIdSheetSheetNumberPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const documentId = requestParameters.documentId;

        const fileId = requestParameters.fileId;

        const sheetNumber = requestParameters.sheetNumber;

        const file = requestParameters.file;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let localVarPath = `/api/Document/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/file/${this.configuration.encodeParam({name: "fileId", value: fileId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/sheet/${this.configuration.encodeParam({name: "sheetNumber", value: sheetNumber, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
        return this.httpClient.request<Int64NullableResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Удаляет файл.
     * **Required permission**: &#x60;EA.Document.Delete&#x60; - Удаление  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDocumentIdFileIdDelete(requestParameters: ApiDocumentDocumentIdFileIdDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<BooleanResponseData>;
    public apiDocumentDocumentIdFileIdDelete(requestParameters: ApiDocumentDocumentIdFileIdDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<BooleanResponseData>>;
    public apiDocumentDocumentIdFileIdDelete(requestParameters: ApiDocumentDocumentIdFileIdDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<BooleanResponseData>>;
    public apiDocumentDocumentIdFileIdDelete(requestParameters: ApiDocumentDocumentIdFileIdDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const documentId = requestParameters.documentId;

        const id = requestParameters.id;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/file/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<BooleanResponseData>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает документ по Id
     * **Required permission**: &#x60;EA.Document.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDocumentIdGet(requestParameters: ApiDocumentDocumentIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<DocumentDtoResponseData>;
    public apiDocumentDocumentIdGet(requestParameters: ApiDocumentDocumentIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<DocumentDtoResponseData>>;
    public apiDocumentDocumentIdGet(requestParameters: ApiDocumentDocumentIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<DocumentDtoResponseData>>;
    public apiDocumentDocumentIdGet(requestParameters: ApiDocumentDocumentIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const documentId = requestParameters.documentId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<DocumentDtoResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает историю документа
     * **Required permission**: &#x60;EA.Document.History&#x60; - Чтение истории  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDocumentIdHistoryGet(requestParameters: ApiDocumentDocumentIdHistoryGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<DocumentDtoArrayResponseData>;
    public apiDocumentDocumentIdHistoryGet(requestParameters: ApiDocumentDocumentIdHistoryGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<DocumentDtoArrayResponseData>>;
    public apiDocumentDocumentIdHistoryGet(requestParameters: ApiDocumentDocumentIdHistoryGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<DocumentDtoArrayResponseData>>;
    public apiDocumentDocumentIdHistoryGet(requestParameters: ApiDocumentDocumentIdHistoryGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const documentId = requestParameters.documentId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/history`;
        return this.httpClient.request<DocumentDtoArrayResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает Zip архив файлов документа.
     * **Required permission**: &#x60;EA.Document.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDocumentIdZipGet(requestParameters: ApiDocumentDocumentIdZipGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiDocumentDocumentIdZipGet(requestParameters: ApiDocumentDocumentIdZipGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiDocumentDocumentIdZipGet(requestParameters: ApiDocumentDocumentIdZipGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiDocumentDocumentIdZipGet(requestParameters: ApiDocumentDocumentIdZipGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const documentId = requestParameters.documentId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/zip`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Документы досье.
     * **Required permission**: &#x60;EA.Document.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDossierDossierIdGet(requestParameters: ApiDocumentDossierDossierIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<DocumentDtoArrayResponseData>;
    public apiDocumentDossierDossierIdGet(requestParameters: ApiDocumentDossierDossierIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<DocumentDtoArrayResponseData>>;
    public apiDocumentDossierDossierIdGet(requestParameters: ApiDocumentDossierDossierIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<DocumentDtoArrayResponseData>>;
    public apiDocumentDossierDossierIdGet(requestParameters: ApiDocumentDossierDossierIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;

        const includePaperCheckList = requestParameters.includePaperCheckList;

        const includeDigitalCheckList = requestParameters.includeDigitalCheckList;

        const includeAllCheckLists = requestParameters.includeAllCheckLists;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (includePaperCheckList !== undefined && includePaperCheckList !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includePaperCheckList, 'includePaperCheckList');
        }
        if (includeDigitalCheckList !== undefined && includeDigitalCheckList !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeDigitalCheckList, 'includeDigitalCheckList');
        }
        if (includeAllCheckLists !== undefined && includeAllCheckLists !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeAllCheckLists, 'includeAllCheckLists');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<DocumentDtoArrayResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Запрос на загрузку документа.
     * **Required permission**: &#x60;EA.Document.UploadDocument&#x60; - Загрузка  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDossierDossierIdPost(requestParameters: ApiDocumentDossierDossierIdPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Int64ResponseData>;
    public apiDocumentDossierDossierIdPost(requestParameters: ApiDocumentDossierDossierIdPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Int64ResponseData>>;
    public apiDocumentDossierDossierIdPost(requestParameters: ApiDocumentDossierDossierIdPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Int64ResponseData>>;
    public apiDocumentDossierDossierIdPost(requestParameters: ApiDocumentDossierDossierIdPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;

        const documentTypeId = requestParameters.documentTypeId;

        const clientId = requestParameters.clientId;

        const files = requestParameters.files;

        const hasDigitalSignature = requestParameters.hasDigitalSignature;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (documentTypeId !== undefined) {
            localVarFormParams = localVarFormParams.append('documentTypeId', <any>documentTypeId) as any || localVarFormParams;
        }
        if (clientId !== undefined) {
            localVarFormParams = localVarFormParams.append('clientId', <any>clientId) as any || localVarFormParams;
        }
        if (files) {
            files.forEach((element) => {
                localVarFormParams = localVarFormParams.append('files', <any>element) as any || localVarFormParams;
            })
        }
        if (hasDigitalSignature !== undefined) {
            localVarFormParams = localVarFormParams.append('hasDigitalSignature', <any>hasDigitalSignature) as any || localVarFormParams;
        }

        let localVarPath = `/api/Document/dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<Int64ResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает Zip архив файлов досье.
     * **Required permission**: &#x60;EA.Document.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDossierDossierIdZipGet(requestParameters: ApiDocumentDossierDossierIdZipGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiDocumentDossierDossierIdZipGet(requestParameters: ApiDocumentDossierDossierIdZipGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiDocumentDossierDossierIdZipGet(requestParameters: ApiDocumentDossierDossierIdZipGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiDocumentDossierDossierIdZipGet(requestParameters: ApiDocumentDossierDossierIdZipGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const dossierId = requestParameters.dossierId;

        const request = requestParameters.request;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (request !== undefined && request !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>request, 'request');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/dossier/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/zip`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает 1ю страницу сконвертированного pdf файла.
     * **Required permission**: &#x60;EA.Document.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentFileIdConvertedPdfFirstPageGet(requestParameters: ApiDocumentFileIdConvertedPdfFirstPageGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiDocumentFileIdConvertedPdfFirstPageGet(requestParameters: ApiDocumentFileIdConvertedPdfFirstPageGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiDocumentFileIdConvertedPdfFirstPageGet(requestParameters: ApiDocumentFileIdConvertedPdfFirstPageGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiDocumentFileIdConvertedPdfFirstPageGet(requestParameters: ApiDocumentFileIdConvertedPdfFirstPageGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/file/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/converted-pdf-first-page`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает сконвертированный pdf для файла.
     * **Required permission**: &#x60;EA.Document.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentFileIdConvertedPdfGet(requestParameters: ApiDocumentFileIdConvertedPdfGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiDocumentFileIdConvertedPdfGet(requestParameters: ApiDocumentFileIdConvertedPdfGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiDocumentFileIdConvertedPdfGet(requestParameters: ApiDocumentFileIdConvertedPdfGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiDocumentFileIdConvertedPdfGet(requestParameters: ApiDocumentFileIdConvertedPdfGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/file/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/converted-pdf`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает 1ю страницу сконвертированного pdf файла в формате png
     * **Required permission**: &#x60;EA.Document.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentFileIdConvertedPng100hFirstPageGet(requestParameters: ApiDocumentFileIdConvertedPng100hFirstPageGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiDocumentFileIdConvertedPng100hFirstPageGet(requestParameters: ApiDocumentFileIdConvertedPng100hFirstPageGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiDocumentFileIdConvertedPng100hFirstPageGet(requestParameters: ApiDocumentFileIdConvertedPng100hFirstPageGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiDocumentFileIdConvertedPng100hFirstPageGet(requestParameters: ApiDocumentFileIdConvertedPng100hFirstPageGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/file/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/converted-png-100h-first-page`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает сохранённый файл по id.
     * **Required permission**: &#x60;EA.Document.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentFileIdGet(requestParameters: ApiDocumentFileIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Blob>;
    public apiDocumentFileIdGet(requestParameters: ApiDocumentFileIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiDocumentFileIdGet(requestParameters: ApiDocumentFileIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiDocumentFileIdGet(requestParameters: ApiDocumentFileIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/file/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Сохраняет файл.   Этот метод не должен вызываться из Фронта.  Эндпоинт сделан только для тестирования.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentFilePost(requestParameters: ApiDocumentFilePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<FileSaveInfoResponseData>;
    public apiDocumentFilePost(requestParameters: ApiDocumentFilePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<FileSaveInfoResponseData>>;
    public apiDocumentFilePost(requestParameters: ApiDocumentFilePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<FileSaveInfoResponseData>>;
    public apiDocumentFilePost(requestParameters: ApiDocumentFilePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const file = requestParameters.file;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let localVarPath = `/api/Document/file`;
        return this.httpClient.request<FileSaveInfoResponseData>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Типы документов.
     * **Required permission**: &#x60;EA.Document.Read&#x60; - Чтение  **Required roles**:  - &#x60;Ea.Mlc&#x60; - Подготовка электронного досье к передаче на сопровождение.  - &#x60;Ea.Support&#x60; - Сопровождение электронного досье.  - &#x60;Ea.Auto.Curator&#x60; - Электронный Архив.Авто.Куратор.  - &#x60;Ea.Auto.Support&#x60; - Электронный Архив.Авто.Сотрудник сопровождения.  
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentTypesGet(requestParameters: ApiDocumentTypesGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<DocumentTypeDtoArrayResponseData>;
    public apiDocumentTypesGet(requestParameters: ApiDocumentTypesGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<DocumentTypeDtoArrayResponseData>>;
    public apiDocumentTypesGet(requestParameters: ApiDocumentTypesGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<DocumentTypeDtoArrayResponseData>>;
    public apiDocumentTypesGet(requestParameters: ApiDocumentTypesGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const productCode = requestParameters.productCode;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (productCode !== undefined && productCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productCode, 'productCode');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Document/types`;
        return this.httpClient.request<DocumentTypeDtoArrayResponseData>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
