import { GridOptions } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { getRouteParams$, Nullable, omitNullable, saveFile } from '@lib-utils';
import { dateTimeCellDef, getActionCellDef, GridGetDataCallback, tagCellDef } from '@lib-widgets/grid';
import { ReportBuilderTaskDto, ReportBuilderTaskStatus, ReportsArchiveApiService, ReportType } from '@lib-archive/api';
import { ReportBuilderTaskStatusRecord, ReportTypeMap } from '@lib-archive/api-middleware';

const ReporStatusTagMap = {
  [ReportBuilderTaskStatus.New]: 'light-gray-tag',
  [ReportBuilderTaskStatus.InProgres]: 'light-gray-tag',
  [ReportBuilderTaskStatus.Done]: 'success-tag',
  [ReportBuilderTaskStatus.Fail]: 'error-tag',
};

function GET_REPORT_LIST_GRID_OPTIONS(context: ReportListComponent): GridOptions<ReportBuilderTaskDto> {
  return {
    context,
    columnDefs: [
      {
        field: 'id',
        headerName: 'ID',
        sortable: true,
        initialSort: 'desc',
        initialWidth: 100,
      },
      {
        field: 'reportType',
        headerName: 'Тип отчета',
        refData: ReportTypeMap,
      },
      {
        field: 'initiatorUserName',
        headerName: 'Инициатор',
        initialFlex: 1,
        minWidth: 200,
      },
      {
        field: 'initiationDate',
        headerName: 'Дата запроса',
        initialFlex: 1,
        ...dateTimeCellDef(),
      },
      {
        field: 'progress',
        headerName: 'Готовность',
        valueFormatter: (params) => `${params.value}%`,
      },
      {
        field: 'status',
        headerName: 'Статус',
        ...tagCellDef({
          labelMap: ReportBuilderTaskStatusRecord,
          getDefaultTagClass: ({ value }) => ReporStatusTagMap[value],
        }),
      },
      {
        colId: 'action',
        headerName: '',
        initialPinned: 'right',
        lockPinned: true,
        ...getActionCellDef<ReportBuilderTaskDto>({
          icon: 'tuiIconDownload',
          isDisabledCallback$: (data) => of(data?.status !== ReportBuilderTaskStatus.Done),
          getAction$: (data) => context.downloadReport(data?.id!),
        }),
      },
    ],
  };
}

@Component({
  selector: 'fnip-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportListComponent {
  gridOptions = GET_REPORT_LIST_GRID_OPTIONS(this);

  routerParams$ = getRouteParams$<{ type: Nullable<ReportType> }>().pipe(
    tap(({ type = null }) => this.filterFg.patchValue({ type })),
  );

  filterFg = new FormGroup({
    type: new FormControl<Nullable<ReportType>>(null),
    id: new FormControl<Nullable<number>>(null),
    initiatorUserName: new FormControl<Nullable<string>>(null),
  });

  ReportTypeMap = ReportTypeMap;

  constructor(private reportsService: ReportsArchiveApiService) {}

  getData$: GridGetDataCallback = (pagination, order) => {
    const { type, id, initiatorUserName } = this.filterFg.value || {};
    return this.reportsService
      .apiReportsGet(
        omitNullable({
          pagePage: pagination.page,
          pagePerPage: pagination.perPage,
          filterId: id,
          filterReportType: type,
          filterInitiatorUserName: initiatorUserName,
          sortFieldBy: order?.fieldBy,
          sortOrderBy: order?.orderBy,
        }),
      )
      .pipe(map(({ data }) => data));
  };

  downloadReport = (reportId: number) => () =>
    this.reportsService.apiReportsReportIdFileGet({ reportId }, 'response').pipe(tap(saveFile));
}
