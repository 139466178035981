import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiLoaderModule, TuiNotificationModule } from '@taiga-ui/core';
import { TuiTagModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule, DividerModule } from '@lib-widgets/core';
import {
  ReactiveCheckboxModule,
  ReactiveFileInputModule,
  ReactiveInputDateModule,
  ReactiveInputModule,
  ReactiveInputNumberModule,
  ReactiveMultiSelectModule,
  ReactiveRadioGroupModule,
  ReactiveSelectModule,
} from '@lib-widgets/reactive-fields';
import { DossierSearchCardComponent } from '../../../../widgets/dossier-search-card';
import { DossierSearchComponent } from './dossier-search.component';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    DividerModule,
    DossierSearchCardComponent,
    ExecuteWithPipeModule,
    ReactiveCheckboxModule,
    ReactiveFileInputModule,
    ReactiveInputDateModule,
    ReactiveInputNumberModule,
    ReactiveInputModule,
    ReactiveRadioGroupModule,
    ReactiveMultiSelectModule,
    ReactiveSelectModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiTagModule,
    TuiNotificationModule,
  ],
  declarations: [DossierSearchComponent],
})
export class DossierSearchModule {}
