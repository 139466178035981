import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveCheckboxModule } from '@lib-widgets/reactive-fields';
import { DossierSearchCardComponent } from '../dossier-search-card';
import { SelectOptionsIslandComponent } from './select-options-island.component';

@NgModule({
  declarations: [SelectOptionsIslandComponent],
  exports: [SelectOptionsIslandComponent],
  imports: [CommonModule, DossierSearchCardComponent, ReactiveCheckboxModule],
})
export class SelectOptionsIslandModule {}
