/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LoanAgreementState = 'Issued' | 'Expired' | 'IncludedInTheAct' | 'Closed' | 'Operational' | 'Sold' | 'Securitized';

export const LoanAgreementState = {
    Issued: 'Issued' as LoanAgreementState,
    Expired: 'Expired' as LoanAgreementState,
    IncludedInTheAct: 'IncludedInTheAct' as LoanAgreementState,
    Closed: 'Closed' as LoanAgreementState,
    Operational: 'Operational' as LoanAgreementState,
    Sold: 'Sold' as LoanAgreementState,
    Securitized: 'Securitized' as LoanAgreementState
};

