import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormControlsOf, Nullable } from '@lib-utils';
import { CreditDossierDto, DossierParticipantDto, InsuranceProductType, PersonDto } from '@lib-archive/api';
import { FeDossierCreateDto, FeDossierFilterDto, FeInsuranceProductType } from '@lib-archive/api-middleware';

export const createDossierPersonForm = (person?: PersonDto, fieldsRequired = true) =>
  new FormGroup<FormControlsOf<PersonDto>>({
    id: new FormControl(person?.id),
    clientType: new FormControl(person?.clientType, fieldsRequired ? Validators.required : null),
    employeeType: new FormControl(person?.employeeType),
    email: new FormControl(person?.email),
    phone: new FormControl(person?.phone),
    name: new FormControl<Nullable<string>>(person?.name, fieldsRequired ? Validators.required : null),
  });

export const createDossierParticipantForm = (participant?: DossierParticipantDto) =>
  new FormGroup<FormControlsOf<DossierParticipantDto>>({
    role: new FormControl(participant?.role),
    participant: createDossierPersonForm(participant?.participant, false),
  });

export const createDossierInsurance = (insuranceInfo?: Nullable<{ [key: string]: InsuranceProductType }>) =>
  new FormGroup<FormControlsOf<Record<string, FeInsuranceProductType>>>({
    Life: new FormControl(insuranceInfo?.Life ?? FeInsuranceProductType.NotUsed),
    Title: new FormControl(insuranceInfo?.Title ?? FeInsuranceProductType.NotUsed),
    Property: new FormControl(insuranceInfo?.Property ?? FeInsuranceProductType.NotUsed),
  });

export const createDossierForm = (dossier?: Nullable<CreditDossierDto>) =>
  new FormGroup<FormControlsOf<FeDossierCreateDto>>({
    loanAgreementNumber: new FormControl(dossier?.loanAgreementNumber),
    loanAgreementState: new FormControl(dossier?.loanAgreementState),
    loanApplicationNumber: new FormControl(dossier?.loanApplicationNumber),
    interestRate: new FormControl(dossier?.interestRate),
    issueLoanDate: new FormControl(dossier?.issueLoanDate ? new Date(dossier?.issueLoanDate) : null),
    plannedEndDate: new FormControl(dossier?.plannedEndDate ? new Date(dossier?.plannedEndDate) : null),
    incomeVerificationMethod: new FormControl(dossier?.incomeVerificationMethod),
    initialPaymentMethod: new FormControl(dossier?.initialPaymentMethod),
    creditProgramId: new FormControl(dossier?.creditProgramId),
    borrowers: new FormArray(dossier?.borrowers?.map((borrower) => createDossierPersonForm(borrower)) ?? []),
    participants: new FormArray(dossier?.participants?.map(createDossierParticipantForm) ?? []),
    issuedLoanId: new FormControl(dossier?.issuedLoan?.id),
    issuedLoan: createDossierPersonForm(dossier?.issuedLoan, false),
    insuranceInfo: createDossierInsurance(dossier?.insuranceInfo),
    purpose: new FormControl(dossier?.purpose),
    registrationType: new FormControl(dossier?.registrationType),
    nonStandard: new FormControl(dossier?.nonStandard ?? false),
    nonStandardComment: new FormControl(dossier?.nonStandardComment),
    nonStandardType: new FormControl(dossier?.nonStandardType),
    city: new FormControl(dossier?.city),
    showroom: new FormControl(dossier?.showroom),
    withInsurance: new FormControl(dossier?.withInsurance ?? false),
    productCode: new FormControl(dossier?.productCode),
    dealDepartmentName: new FormControl(dossier?.dealDepartmentName),
    cftDepartment: new FormControl(dossier?.cftDepartment),
    cftCreditAgreementId: new FormControl(dossier?.cftCreditAgreementId),
  });

export const createDossierFilterForm = (value?: FeDossierFilterDto) =>
  new FormGroup<FormControlsOf<FeDossierFilterDto>>({
    text: new FormControl(value?.text),
    issueLoanDateFrom: new FormControl(value?.issueLoanDateFrom),
    issueLoanDateTo: new FormControl(value?.issueLoanDateTo),
    loanAgreementState: new FormControl(value?.loanAgreementState),
    lifeInsuranceTypes: new FormControl(value?.lifeInsuranceTypes),
    cftDepartment: new FormControl(value?.cftDepartment),
    dealDepartmentName: new FormControl(value?.dealDepartmentName),
    actId: new FormControl(value?.actId),
    withoutAct: new FormControl(value?.withoutAct),
  });
