<div *ngIf="historyDocuments$ | async as historyDocuments" tuiGroup [collapsed]="true">
  <ng-container *ngIf="setInitialVersion | executeWith: documentId : historyDocuments" />

  <fnip-button
    label="Актуальная версия документа"
    style="width: 340px"
    [actionCallback$]="setLastVersion | executeWith: historyDocuments"
    [appearance]="(isLastVersion | executeWith: versionControl.value : historyDocuments) ? 'secondary' : 'outline'"
  />

  <fnip-reactive-button-dropdown
    fieldId="versionControl"
    appearance="outline"
    [control]="versionControl"
    [showBadge]="false"
    [showSelectedValue]="false"
    [options]="getVersionOptions | executeWith: historyDocuments"
    [optionTmp]="optionTmp"
    (selectedOptionChange)="onVersionChanged($event, historyDocuments)"
  >
    <span class="mr-3"> Версии документа </span>
    <tui-badge [value]="versionControl.value" />
    <ng-template #optionTmp let-option>
      <div fnipTestAttributes class="d-flex gap-1" [testLabel]="option.version" [testId]="option.value">
        <span>
          Версия: <strong>{{ option.version }}</strong>
        </span>
        <span>загружен: {{ option.created | date: 'dd.MM.yyyy' }}</span>
      </div>
    </ng-template>
  </fnip-reactive-button-dropdown>
</div>
