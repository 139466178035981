import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import uniq from 'lodash-es/uniq';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { beToFeOptions, FileInfo, NotificationService, Nullable, omitNullable } from '@lib-utils';
import { injectDialogContext } from '@lib-widgets/dialog';
import { DocumentArchiveApiService, DocumentGroup, DocumentTypeDto, ProductCode } from '@lib-archive/api';
import { ClientTypeMap, DocumentGroupTypeMap } from '@lib-archive/api-middleware';
import { sortBorrowers } from '@lib-archive/utils';
import { DOSSIER_FILE_TYPES } from '../../../../utils';
import { AddDocumentsToDossierDialogContext } from './add-documents-to-dossier-dialog.interfaces';

@Component({
  selector: 'fnip-add-documents-to-dossier-dialog',
  templateUrl: './add-documents-to-dossier-dialog.component.html',
  styleUrls: ['./add-documents-to-dossier-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDocumentsToDossierDialogComponent {
  readonly context = injectDialogContext<Nullable<boolean>, AddDocumentsToDossierDialogContext>();

  form = new FormGroup({
    documentGroup: new FormControl<Nullable<DocumentGroup>>(null),
    documentTypeId: new FormControl<Nullable<number>>(null, [Validators.required]),
    clientId: new FormControl<Nullable<number>>(null),
    files: new FormControl<FileInfo[]>([], [Validators.required]),
    hasDigitalSignature: new FormControl<Nullable<boolean>>(null),
  });

  readonly allowedFileTypes = DOSSIER_FILE_TYPES;

  withDigitalSignature = false;

  DocumentGroup = DocumentGroup;

  borrowerOptions = beToFeOptions(sortBorrowers(this.context.data?.borrowers), {
    labelCallback: (person) => `${person.clientType && ClientTypeMap[person.clientType]}: ${person.name}`,
  });

  documentTypesRq$ = (productCode: Nullable<ProductCode>) =>
    this.documentArchiveApiService
      .apiDocumentTypesGet({ productCode: productCode ?? undefined })
      .pipe(map(({ data }) => data));

  getDocumentGroupOptions = (documentTypes: Nullable<DocumentTypeDto[]>) =>
    uniq(documentTypes?.map((type) => type.group)).map((group) => ({
      label: (group && DocumentGroupTypeMap.get(group)) ?? '',
      value: group,
    }));

  getDocumentTypeOptions = (documentTypes: Nullable<DocumentTypeDto[]>, documentGroup: Nullable<DocumentGroup>) =>
    beToFeOptions(documentTypes?.filter((type) => type.group === documentGroup));

  updateDigitalSignature(option: Nullable<DocumentTypeDto>) {
    this.withDigitalSignature = !!option?.mayHaveDigitalSignature;
    if (!this.withDigitalSignature) this.form.controls.hasDigitalSignature.setValue(null);
    else this.form.controls.hasDigitalSignature.setValue(false);
  }

  addDocumentToDossier$ = () => {
    return this.documentArchiveApiService
      .apiDocumentDossierDossierIdPost({
        dossierId: this.context.data?.dossierId!,
        files: this.form.value?.files?.map((f) => f.file) as Blob[],
        ...omitNullable({
          documentTypeId: this.form.value.documentTypeId,
          clientId: this.form.value.clientId,
          hasDigitalSignature: this.form.value.hasDigitalSignature,
        }),
      })
      .pipe(
        tap(() => this.context.ref?.close(true)),
        tap(this.notificationService.onSuccess('Документ успешно загружен')),
        tap(this.notificationService.onError('Ошибка загрузки')),
      );
  };

  empty$ = () => of({});

  constructor(
    private readonly documentArchiveApiService: DocumentArchiveApiService,
    protected readonly notificationService: NotificationService,
  ) {}
}
