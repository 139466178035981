import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import omit from 'lodash-es/omit';
import { Observable } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';
import {
  beToFeOptions,
  ExecuteWithPipeModule,
  FormControlsOf,
  FormGroupOf,
  Nullable,
  omitNullable,
  SelectOption,
} from '@lib-utils';
import { ButtonModule, LabeledContentComponent } from '@lib-widgets/core';
import { ReactiveInputModule, ReactiveSelectNewModule } from '@lib-widgets/reactive-fields';
import { ClientType, PersonDto } from '@lib-archive/api';
import { ClientTypeMap } from '@lib-archive/api-middleware';
import { AccessModule } from '@lib-mortgage/features/authorization';

@Component({
  selector: 'fnip-dossier-person-info',
  standalone: true,
  imports: [
    CommonModule,
    AccessModule,
    ButtonModule,
    ExecuteWithPipeModule,
    LabeledContentComponent,
    ReactiveInputModule,
    ReactiveSelectNewModule,
  ],
  templateUrl: './dossier-person-info.component.html',
  styleUrls: ['./dossier-person-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DossierPersonInfoComponent {
  @Input() label: Nullable<string>;
  @Input() person: Nullable<PersonDto>;
  @Input() canEdit: Nullable<boolean>;
  @Input() savePerson$: Nullable<(person: PersonDto) => Observable<unknown>>;
  @Input() addPerson$: Nullable<() => Observable<Nullable<PersonDto>>>;
  @Input() options: Nullable<(t: string) => Observable<SelectOption<PersonDto>[]>>;

  isEdit = false;

  form = new FormGroup<FormControlsOf<PersonDto>>({
    id: new FormControl(),
    name: new FormControl(),
    clientType: new FormControl(),
    employeeType: new FormControl(),
    email: new FormControl(),
    phone: new FormControl(),
  });

  getInitialPersonOption = (person: Nullable<FormGroupOf<PersonDto>['value']>): SelectOption<PersonDto>[] =>
    beToFeOptions(person?.id ? [omitNullable(person)] : []);

  getClientType = (type: Nullable<ClientType>) => (type && ClientTypeMap[type]) ?? '';

  toggleEditMode = () => {
    this.isEdit = !this.isEdit;
    this.form.reset(this.person ?? {});
  };

  addNewPerson$ = () =>
    this.addPerson$?.().pipe(
      filter(Boolean),
      tap((person) => {
        this.form.reset(person);
      }),
    );

  selectedPerson = (option: Nullable<SelectOption<PersonDto>>) => {
    this.form.patchValue(omit(option ?? {}, 'id'));
  };

  save$ = () => this.savePerson$?.(omitNullable(this.form.value)).pipe(finalize(() => (this.isEdit = false)));
}
