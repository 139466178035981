/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActState } from './actState';
import { ActType } from './actType';
import { ProductCode } from './productCode';
import { PersonDto } from './personDto';
import { DateTime } from '@lib-utils';
import { CreditDossierDto } from './creditDossierDto';


export interface ActDto { 
    id?: number;
    state?: ActState;
    stateSetting?: DateTime;
    allowedStates?: Array<ActState> | null;
    creator?: PersonDto;
    created?: DateTime;
    dossiers?: Array<CreditDossierDto> | null;
    acceptedSnapshot?: Array<number> | null;
    productCode?: ProductCode;
    type?: ActType;
}
export namespace ActDto {
}


