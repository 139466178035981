/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InsuranceProductType } from './insuranceProductType';
import { DossierParticipantDto } from './dossierParticipantDto';
import { ProductCode } from './productCode';
import { PersonDto } from './personDto';
import { LoanAgreementState } from './loanAgreementState';
import { DateTime } from '@lib-utils';
import { NonStandardType } from './nonStandardType';
import { DossierState } from './dossierState';
import { PhysicalType } from './physicalType';
import { RegistrationType } from './registrationType';
import { PaymentType } from './paymentType';
import { CreditPurpose } from './creditPurpose';
import { ProofOfIncome } from './proofOfIncome';
import { BoxShortDto } from './boxShortDto';


export interface CreditDossierDto { 
    id?: number;
    state?: DossierState;
    stateSetting?: DateTime;
    allowedStates?: Array<DossierState> | null;
    creator?: PersonDto;
    created?: DateTime;
    loanAgreementNumber?: string | null;
    loanAgreementState?: LoanAgreementState;
    loanApplicationNumber?: string | null;
    interestRate?: number;
    issueLoanDate?: DateTime;
    plannedEndDate?: DateTime | null;
    incomeVerificationMethod?: ProofOfIncome;
    initialPaymentMethod?: PaymentType;
    creditProgramId?: number | null;
    actId?: number | null;
    borrowers?: Array<PersonDto> | null;
    participants?: Array<DossierParticipantDto> | null;
    issuedLoan?: PersonDto;
    dealDepartmentName?: string | null;
    mortgageLendingCenterId?: number | null;
    insuranceInfo?: { [key: string]: InsuranceProductType; } | null;
    acceptToReview?: boolean | null;
    withdrawnOnDemandLocation?: string | null;
    numberOfChecks?: number | null;
    box?: BoxShortDto;
    numberInBox?: number | null;
    cftDepartment?: string | null;
    cftCreditAgreementId?: number | null;
    physicalType?: PhysicalType;
    registrationType?: RegistrationType;
    isManual?: boolean;
    purpose?: CreditPurpose;
    nonStandard?: boolean;
    nonStandardComment?: string | null;
    nonStandardType?: NonStandardType;
    productCode?: ProductCode;
    city?: string | null;
    showroom?: string | null;
    withInsurance?: boolean;
}
export namespace CreditDossierDto {
}


