import { BEErrorInfo, ErrorCheckMessage } from '@lib-error-interceptor';

export const ARCHIVE_ERROR_MESSAGES: ErrorCheckMessage<BEErrorInfo>[] = [
  {
    check: (url) => !!url && /api\/Dossier\/\d+\/state\/WithdrawnOnDemand/.test(url),
    message: (feError) => (feError?.technicalMessages?.map(({ message }) => message) ?? []).join(', '),
  },
  {
    check: (url) => !!url && /api\/Document\//.test(url),
    message: 'Не удалось загрузить документ',
  },
  {
    // NOTE: для таких мест можно использовать HEADERS -> errorCode
    check: (url, feError) => !!url && /api\/Document\/file/.test(url) && feError?.status === 404,
    message: 'Не удалось загрузить файл документа',
  },
];
