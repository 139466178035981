import { ActState } from '@lib-archive/api';

export const DirectActActionLabelMap = new Map([
  [ActState.Filling, 'Передать акт на сопровождение'],
  [ActState.Transferred, 'Принять акт'],
  [ActState.UnderReview, 'Завершить проверку'],
  [ActState.Accepted, 'Акт принят'],
]);

export const ReverseActActionLabelMap = new Map([
  [ActState.Filling, 'Передать акт'],
  [ActState.Transferred, 'Принять акт'],
  [ActState.UnderReview, 'Завершить проверку'],
  [ActState.Accepted, 'Акт принят'],
]);
