/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentCheckState = 'NoCheck' | 'ReadyToSend' | 'UnderReview' | 'NoErrors' | 'HasErrors';

export const DocumentCheckState = {
    NoCheck: 'NoCheck' as DocumentCheckState,
    ReadyToSend: 'ReadyToSend' as DocumentCheckState,
    UnderReview: 'UnderReview' as DocumentCheckState,
    NoErrors: 'NoErrors' as DocumentCheckState,
    HasErrors: 'HasErrors' as DocumentCheckState
};

