import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TuiPaginationModule } from '@taiga-ui/kit';
import { EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecuteWithPipeModule } from '@lib-utils';
import { injectDialogContext } from '@lib-widgets/dialog';
import { GridGetDataCallback, GridModule } from '@lib-widgets/grid';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { GET_DOSSIERS_GRID_OPTIONS, getBasePath } from '@lib-archive/utils';
import { ViewDossiersModalContext } from './view-dossiers-modal-context';

@Component({
  selector: 'fnip-view-dossiers-modal',
  standalone: true,
  imports: [CommonModule, ExecuteWithPipeModule, GridModule, RequestWrapperModule, TuiPaginationModule],
  templateUrl: './view-dossiers-modal.component.html',
  styleUrls: ['./view-dossiers-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewDossiersModalComponent {
  private readonly dialogContext = injectDialogContext<void, ViewDossiersModalContext>();

  gridOptions = GET_DOSSIERS_GRID_OPTIONS({ basePath: getBasePath() });

  getDossiers$: GridGetDataCallback = (pagination) =>
    this.dialogContext.data?.getDossiers$(pagination.page, pagination.perPage).pipe(map(({ data }) => data)) ?? EMPTY;
}
