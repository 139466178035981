import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { ArchiveConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ActArchiveApiService } from './api/act.service';
import { BoxArchiveApiService } from './api/box.service';
import { DebugArchiveApiService } from './api/debug.service';
import { DirectoryArchiveApiService } from './api/directory.service';
import { DocumentArchiveApiService } from './api/document.service';
import { DocumentTemplateArchiveApiService } from './api/documentTemplate.service';
import { DossierArchiveApiService } from './api/dossier.service';
import { DossierUploadArchiveApiService } from './api/dossierUpload.service';
import { FilteredDossiersArchiveApiService } from './api/filteredDossiers.service';
import { FilteredDossiersByFileArchiveApiService } from './api/filteredDossiersByFile.service';
import { ProductArchiveApiService } from './api/product.service';
import { ReportsArchiveApiService } from './api/reports.service';
import { TestDataGeneratorArchiveApiService } from './api/testDataGenerator.service';
import { UserArchiveApiService } from './api/user.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ActArchiveApiService,
    BoxArchiveApiService,
    DebugArchiveApiService,
    DirectoryArchiveApiService,
    DocumentArchiveApiService,
    DocumentTemplateArchiveApiService,
    DossierArchiveApiService,
    DossierUploadArchiveApiService,
    FilteredDossiersArchiveApiService,
    FilteredDossiersByFileArchiveApiService,
    ProductArchiveApiService,
    ReportsArchiveApiService,
    TestDataGeneratorArchiveApiService,
    UserArchiveApiService ]
})
export class FnipArchiveApiModule {
    public static forRoot(configurationFactory: () => ArchiveConfiguration): ModuleWithProviders<FnipArchiveApiModule> {
        return {
            ngModule: FnipArchiveApiModule,
            providers: [ { provide: ArchiveConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: FnipArchiveApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('FnipArchiveApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
