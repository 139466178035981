/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DossiersFilterForReports } from './dossiersFilterForReports';
import { ReportBuilderTaskStatus } from './reportBuilderTaskStatus';
import { DateTime } from '@lib-utils';
import { DossierListStrategyFilter } from './dossierListStrategyFilter';
import { ReportType } from './reportType';


export interface ReportBuilderTaskDto { 
    id?: number;
    filter?: DossiersFilterForReports;
    strategyFilter?: Array<DossierListStrategyFilter> | null;
    reportType?: ReportType;
    progress?: number;
    initiatorUserId?: number;
    initiatorUserName?: string | null;
    initiationDate?: DateTime;
    status?: ReportBuilderTaskStatus;
}
export namespace ReportBuilderTaskDto {
}


