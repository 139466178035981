/**
 * Fnip.Electronic.Archive
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PageResult } from './pageResult';
import { CreditDossierDto } from './creditDossierDto';


export interface CreditDossierDtoPageResultData { 
    data?: Array<CreditDossierDto> | null;
    page?: PageResult;
}

