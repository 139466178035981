<fnip-request-wrapper #documentTypesRs [request$]="documentTypesRq$ | executeWith: context.data?.productCode">
  <div class="d-flex flex-column gap-5">
    <h2 class="my-4">Добавить документ</h2>

    <fnip-reactive-select
      fieldId="documentGroup"
      label="Вид документа"
      textFieldSize="l"
      [control]="form.controls.documentGroup"
      [options]="getDocumentGroupOptions | executeWith: documentTypesRs.data"
      (valueChange)="form.controls.clientId.reset(); form.controls.documentTypeId.reset()"
    />

    <fnip-reactive-select
      *ngIf="form.controls.documentGroup.value === DocumentGroup.Borrower"
      fieldId="clientId"
      label="Участник"
      textFieldSize="l"
      [control]="form.controls.clientId"
      [options]="borrowerOptions"
      [requiredIf]="true"
    />

    <fnip-reactive-select
      #documentTypeSelect
      hasSearch
      fieldId="documentTypeId"
      label="Тип документа"
      textFieldSize="l"
      emptyTermMessage="Выберите вид документа"
      [control]="form.controls.documentTypeId"
      [options]="getDocumentTypeOptions | executeWith: documentTypesRs.data : form.controls.documentGroup.value"
      (selectedOptionChange)="updateDigitalSignature($event)"
    />

    <fnip-reactive-checkbox
      *ngIf="documentTypeSelect.selectedOption?.mayHaveDigitalSignature"
      checkboxLabel="ПЭП / УКЭП"
      fieldId="hasDigitalSignature"
      size="l"
      [control]="form.controls.hasDigitalSignature"
    />

    <fnip-reactive-file-input
      label="Файлы"
      fieldId="files"
      [control]="form.controls.files"
      [accept]="allowedFileTypes"
      [multiple]="true"
      [maxSizeMb]="30"
      [isLocalUsage]="true"
      [showSize]="true"
      [uploadFile$]="empty$"
      (maxSizeReached)="notificationService.showWarning('Общий размер файлов превышает 30 Мб')"
    />

    <fnip-button
      appearance="primary"
      btnSize="l"
      label="Добавить"
      class="ml-auto"
      [actionCallback$]="addDocumentToDossier$"
      [isDisabled]="form.invalid"
    />
  </div>
</fnip-request-wrapper>
