import { DossierState } from '@lib-archive/api';
import { DossierTab } from './dossier.enums';

export const ReworkButtonLabelMap = new Map([
  [DossierState.UnderRework, 'Доработка выполнена'],
  [DossierState.ReworkCompleted, 'Принять на проверку'],
  [DossierState.ReworkSent, 'Принять на проверку'],
]);

export const DossierTabMap = new Map([
  [DossierTab.Documents, 'Документы, входящие в состав КД'],
  [DossierTab.History, 'История движения'],
]);
